import { SymElementType } from "./types";

export class SymEnumValue {
    private enum_value_id: number;
    public name: string;
    public value1: number;
    public value2: number;
    public comment: string;

    constructor(data: any) {
        this.parse(data);
    }

    parse(data: any) {
        this.enum_value_id = typeof data.enum_value_id !== "undefined" ? data.enum_value_id : 0;
        this.name = data.name;
        this.value1 = data.value1;
        this.value2 = data.value2;
        this.comment = data.comment;
    }

    dump() {
        let rv = {
            enum_value_id: (typeof this.enum_value_id !== 'undefined' && this.enum_value_id > 0) ? this.enum_value_id : 0,
            name: this.name,
            value1: this.value1,
            value2:
                typeof this.value2 !== "undefined" ? this.value2 : this.value1,
            comment: this.comment
        };
        return rv;
    }

    copy() {
        return new SymEnumValue({
            name: this.name,
            value1: this.value1,
            value2: this.value2,
            comment: this.comment
        });
    }
}

export class SymEnum {
    //public static readonly sym_type: SymElementType = SymElementType.ENUM;
    readonly sym_type: SymElementType = SymElementType.ENUM;
    private enum_id: number;
    public name: string;
    public values: SymEnumValue[];
    public comment: string;

    constructor(data: any) {
        this.parse(data);
    }

    parse(data: any) {
        this.enum_id = typeof data.enum_id !== "undefined" ? data.enum_id : 0;
        this.name = data.name;
        this.comment = typeof data.comment !== "undefined" ? data.comment : "";
        this.values = [];
        if (typeof data.values !== "undefined") {
            for (let i = 0; i < data.values.length; i++) {
                this.values.push(new SymEnumValue(data.values[i]));
            }
        }
    }

    dump() {
        let rv = {
            enum_id: (typeof this.enum_id !== 'undefined' && this.enum_id > 0) ? this.enum_id : 0,
            name: this.name,
            comment: this.comment,
            values: <any>[]
        };
        this.values.forEach((ev: SymEnumValue) => {
            rv.values.push(ev.dump());
        });
        return rv;
    }
}
