import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

// TODO: we should add this class to component constructors
@Injectable({
    providedIn: 'root'
})
export class UserBrowserInfo {
    constructor (private platform: Platform) {
    }

    get is_ios(): boolean {
        return this.platform.IOS;
    }

    get is_mobile(): boolean {
        return this.platform.ANDROID || this.platform.IOS;
    }

    get is_tablet(): boolean {
        return false;
    }

    get is_desktop(): boolean {
        return !(this.is_mobile || this.is_tablet);
    }
}
