import { Component, HostBinding, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSettingsService } from './services/user/app-settings.service';
import { environment } from '../environments/environment';
import { AppService } from './app.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MonacoEditorService } from './services/monaco-editor/monaco-editor-service';

export let browserRefresh = false;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
    title = 'app';
    apiUrl = environment.apiUrl;
    subscription: Subscription;
    theme = this.settingsService.getTheme();
    constructor(
        private router: Router,
        private settingsService: AppSettingsService,
        private translate: TranslateService,
        private appService: AppService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private monacoEditorService: MonacoEditorService
    ) {
        // Recreated a component when we go to the same route.
        this.subscription = router.events.subscribe((event) => {
            if (event instanceof NavigationStart || event instanceof NavigationEnd) {
                this.appService.setRouterNavigated(router.navigated);
            }
        });

        let language = localStorage.getItem('language');
        if (!language) {
            language = environment.language;
            if (!language) {
                language = 'en';
            }
        }
        translate.setDefaultLang(language);

        this.setIcons();

        // Preload the Monaco Editor
        this.monacoEditorService.load();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setIcons() {
        this.addSvg('icon-add', '../assets/img/icons/add.svg');
        this.addSvg('icon-delete', '../assets/img/icons/delete.svg');
        this.addSvg('icon-settings', '../assets/img/icons/settings.svg');
        this.addSvg('icon-close', '../assets/img/icons/close.svg');
        this.addSvg('icon-close-circle', '../assets/img/icons/close-circle.svg');
        this.addSvg('icon-sync', '../assets/img/icons/sync.svg');
        this.addSvg('icon-download', '../assets/img/icons/download.svg');
        this.addSvg('icon-arrow', '../assets/img/icons/arrow.svg');
        this.addSvg('icon-check', '../assets/img/icons/check.svg');
        this.addSvg('icon-dog', '../assets/img/icons/dog.svg');
        this.addSvg('icon-gas', '../assets/img/icons/gas.svg');
        this.addSvg('icon-speedometer', '../assets/img/icons/speedometer.svg');
        this.addSvg('icon-warning', '../assets/img/icons/warning.svg');
        this.addSvg('icon-wifi', '../assets/img/icons/wifi.svg');
        this.addSvg('icon-triangle', '../assets/img/icons/triangle.svg');
        this.addSvg('icon-edit', '../assets/img/icons/edit.svg');
        this.addSvg('icon-edit-lock', '../assets/img/icons/pencil-lock.svg');
        this.addSvg('icon-reset', '../assets/img/icons/reset.svg');
        this.addSvg('icon-user', '../assets/img/icons/user.svg');
        this.addSvg('icon-source', '../assets/img/icons/source.svg');
        this.addSvg('icon-save', '../assets/img/icons/save.svg');
        this.addSvg('icon-exit', '../assets/img/icons/exit.svg');
        this.addSvg('more-time', '../assets/img/icons/more_time.svg');
        this.addSvg('icon-temperature', '../assets/img/widgets/filter/temperature.svg');
        this.addSvg('icon-trash', '../assets/img/widgets/filter/trash.svg');
        this.addSvg('icon-minimize', '../assets/img/icons/minimize.svg');
        this.addSvg('icon-maximize', '../assets/img/icons/maximize.svg');
        this.addSvg('icon-subtract', '../assets/img/icons/subtract.svg');
    }

    private addSvg(name: string, path: string) {
        this.matIconRegistry.addSvgIcon(
            name,
            this.domSanitizer.bypassSecurityTrustResourceUrl(path)
        );
    }

}
