import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplyCustomerDialogType, CustomerType, IApplyCustomerDialogData } from 'src/app/shared/models/common.model';
import { appConstants } from '../../services/constants';
import { ManageFactoryService } from '../../services/manage-factory/manage-factory.service';

@Component({
    selector: 'app-apply-customer-dialog',
    templateUrl: './apply-customer-dialog.component.html',
    styleUrls: ['./apply-customer-dialog.component.css']
})
export class ApplyCustomerDialogComponent {
    customerType: number;
    assignedCustomer: CustomerType;
    customerList: CustomerType[];
    constants: typeof appConstants = appConstants;
    customerRole = sessionStorage.customerRole;
    dialogType: typeof ApplyCustomerDialogType = ApplyCustomerDialogType;
    title: string;
    applyCustomerDialogType: ApplyCustomerDialogType;
    customerId: number;
    disableCancelButton: boolean = false;

    constructor(
        private manageFactory: ManageFactoryService,
        private dialogRef: MatDialogRef<ApplyCustomerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: IApplyCustomerDialogData,
    ) {
        this.title = this.data.title;
        this.disableCancelButton = this.data.disableCancelButton;
        this.applyCustomerDialogType = this.data.applyCustomerDialogType;
        this.customerType = this.data.customerType != this.customerRole ? this.data.customerType : null;
        if (!this.customerType)
            return;

        this.customerId = this.data.customerId;
        this.changeCustomerType();
    }

    changeCustomerType() {
        switch (this.customerType) {
            case this.constants.role_oem_user:
                this.manageFactory.oems.read()
                    .subscribe(this.handleApiResponse.bind(this));
                break;
            case this.constants.role_dealer_user:
                this.manageFactory.dealers.read()
                    .subscribe(this.handleApiResponse.bind(this));
                break;
            case this.constants.role_client_user:
                this.manageFactory.clients.read()
                    .subscribe(this.handleApiResponse.bind(this));
                break;
            default:
                break;
        }
    }

    private handleApiResponse(customerList: CustomerType[]) {
        this.customerList = customerList;
        this.assignedCustomer = this.customerList?.find(c => c.customer_id === this.data.customerId);
    }

    handleClose(): void {
        this.dialogRef.close();
    }

    handleApply(): void {
        this.assignedCustomer.customer_role = this.customerType;
        this.dialogRef.close(this.assignedCustomer);
    }
}
