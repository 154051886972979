<!-- span *ngIf="browserInfo.is_mobile; then mobile else mobile"></span -->
<span *ngIf="browserInfo.is_mobile; then mobile else desktop"></span>

<!-- MOBILE VERSION -->
<ng-template #mobile>
    <mat-accordion *ngIf="sym_lib" [ngClass]="getClassName()">
        <mat-expansion-panel *ngFor="let enum of sym_lib.enums;let i = index">
            <mat-expansion-panel-header class="ngstd-list-item" collapsedHeight="*" expandedHeight="*">
                <div class='ngstd-item-wrapper'>
                    <div class="ngstd-delete-indicator">
                        <i class="material-icons ngstd-delete-icon" *ngIf="isLeftSign()">delete_sweep</i>
                        <span>&nbsp;</span>
                        <i class="material-icons ngstd-delete-icon" *ngIf="isRightSign()">delete_sweep</i>
                    </div>
                    <div #elementRefrence class="ngstd-item-container "
                                    (panend)="panend($event, i, elementRefrence)" 
                                    (panmove)="panmove($event,elementRefrence)" 
                                    [@slideLeft]="ngstdIndexNumber === i"
                                    (@slideLeft.done)=alignComplete($event)>
                        <mat-panel-title><mat-icon mat-list-icon>list</mat-icon></mat-panel-title>
                        <mat-panel-description class="list-element-title">{{enum.name}}</mat-panel-description>
                    </div>
                </div>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <enum-prop
                    [sym_lib]="sym_lib"
                    [selected_enum]="enum"
                    (onAddEnumValue)="addNewEnumValue($event)"
                ></enum-prop>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</ng-template>

<!-- DESKTOP VERSION -->
<ng-template #desktop>
    <as-split direction="horizontal" [useTransition]="true" gutterSize="3">
        <as-split-area size="20">
            <mat-list *ngIf="sym_lib">
                <mat-list *ngIf="sym_lib">
                    <mat-list-item *ngFor="let enum of sym_lib.enums" (click)="onEnumClick(enum)" [class.selected]="enum==selected_enum">
                        <mat-icon mat-list-icon>list</mat-icon>
                        <h4 mat-line class="list-element-title">{{enum.name}}</h4>
                    </mat-list-item>
                </mat-list>
            </mat-list>
        </as-split-area>
        <as-split-area size="80" class='enum-properties-root'>
        	<enum-prop
                [sym_lib]="sym_lib"
                [selected_enum]="selected_enum"
                (onAddEnumValue)="addNewEnumValue($event)"
            ></enum-prop>
        </as-split-area>
    </as-split>
</ng-template>
