import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MediaChange, MediaObserver} from "@angular/flex-layout";
import {detect} from 'detect-browser';

@Injectable({providedIn: 'root'})
export class AppService {

  browserRefresh$ = new BehaviorSubject(false);
  afterLogin$ = new BehaviorSubject(false);
  isMobileWidth$ = new BehaviorSubject<boolean>(false);
  browser;

  constructor(
    private mediaObserver: MediaObserver,
  ) {

    this.mediaObserver.media$.subscribe((change: MediaChange) => {
      const activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';

      if ( change.mqAlias === 'xs') {
        this.isMobileWidth$.next(true);
      } else {
        this.isMobileWidth$.next(false);
      }
    });

    this.browser = detect();
  }

  setRouterNavigated(navigated) {
    this.browserRefresh$.next(navigated);
  }
}
