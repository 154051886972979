import { Component, OnInit, OnDestroy } from '@angular/core';
import { appConstants } from '../services/constants';
import { MqttService, IMqttMessage, IMqttServiceOptions } from 'ngx-mqtt';
import { Observable, Subscription } from 'rxjs';
import { ManageFactoryService } from '../services/manage-factory/manage-factory.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { FeedbackComponent } from '../dialogs/feedback/feedback.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ControlPosition } from '@agm/core';
import { DeviceFilterDialogComponent } from '../dialogs/device-filter-dialog/device-filter-dialog.component';
import { mqttOptions } from '../shared/models/mqtt.models';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from "@angular/material/snack-bar";
import { DeviceDashboardDialogComponent } from '../dialogs/device-dashboard-dialog/device-dashboard-dialog.component';
import { IDevice } from '../shared/models/common.model';

@Component({
    selector: 'app-live-map',
    templateUrl: './live-map.component.html',
    styleUrls: ['./live-map.component.scss']
})
export class LiveMapComponent implements OnInit, OnDestroy {
    constants = appConstants;
    allDevices: any = [];
    deviceKeyVal: Map<Number, any>;
    deviceActive = new Map();
    shownMarkers: IDevice[] = [];
    shownMarkersBkp: IDevice[] = [];
    isFilter: any = {};
    bounds = false;
    initBounds = false;
    default = {
        zoom: 4,
        lat: 39,
        lng: -84
    };
    zoom: number = this.default['zoom'];
    lat: number = this.default['lat'];
    lng: number = this.default['lng'];
    dialogRef: MatDialogRef<FeedbackComponent>;
    isMobile: boolean;
    isClient: boolean;
    private subscription: Subscription;
    private connectSubscription: Subscription;

    constructor(
        private manageFactory: ManageFactoryService,
        private router: Router,
        private mqttService: MqttService,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.deviceKeyVal = new Map();
        this.isMobile = (window.innerWidth) < 650;
        this.isClient = +sessionStorage.getItem('customerRole') == this.constants['role_client_user'];
        this.findMe();

        setTimeout(() => {
            this.translate.get('live_map.devices_loading').subscribe((title: string) => {
                this.snackBar.open(title, "", {
                    duration: 3000,
                    horizontalPosition: 'left'
                });
            });
        });

        // mqtt connect
        const clientId = 'liveMapClient-' + Math.random().toString(16).substr(2, 8);
        this.mqttService.connect(mqttOptions({
            clientId
        }));
        this.connectSubscription = this.mqttService.onConnect.subscribe(() => {
            this.onConnect();
        });
    }

    onMapReady(map) {
        map.setOptions({
            fullscreenControl: true,
            fullscreenControlOptions: {
                position: ControlPosition.RIGHT_TOP
            },
            zoomControlOptions: {
                position: ControlPosition.LEFT_BOTTOM
            },
            streetViewControlOptions: {
                position: ControlPosition.LEFT_BOTTOM
            }
        });
    }

    /**
     * This function is used to get current zoom level in google maps
     * @param newZoomValue will provide the current zoom level
     */
    onZoomChange(newZoomValue: number): void {
        this.zoom = newZoomValue;
    }

    onConnect() {
        this.manageFactory.devices.read({ enabled: true })
            .subscribe(result => {
                this.allDevices = result;

                this.allDevices.forEach((device) => {
                    this.deviceKeyVal.set(device.id, device);
                });
                
                this.initBounds = true;

                this.subscription = this.mqttService.observe('mrs/d/+/mon/location').subscribe((message: IMqttMessage) => {
                    this.onMessageArrived(message);
                });

                // allow 5 seconds then turn off bounds
                setTimeout(() => {
                    this.initBounds = false;
                }, 5000);
            });
    }

    onConnectionLost(responseObject) {
        if (responseObject.errorCode !== 0) {
            console.log('onConnectionLost:' + responseObject.errorMessage);
        }
    }

    onMessageArrived(message) {
        try {
            const payload = JSON.parse(message.payload.toString());
            const deviceId = +message.topic.replace("mrs/d/", "").split("/")[0];
            if (this.deviceKeyVal.has(deviceId)) {
                const obj = this.deviceKeyVal.get(deviceId);
                const devIdent = (obj['userIdentifier'] && this.isClient) ? obj['userIdentifier'] : obj['identifier'];
                const markTitle = (obj['name']) ? devIdent + '\n' + obj['name'] : devIdent;

                let productImage = obj['productImage'];
                // If no product image is specified, use the placeholder.
                if (!productImage) {
                    productImage = '/images/Placeholder-model.png';
                }
                productImage = environment.apiUrl + productImage;

                const device: IDevice = {
                    allowTelematics: obj['allowTelematics'],
                    lat: payload['lat'],
                    lng: payload['lon'],
                    id: obj['id'],
                    identifier: devIdent,
                    title: markTitle,
                    oemName: obj['oemName'],
                    oemId: obj['oemId'],
                    dealerName: obj['dealerName'],
                    dealerId: obj['dealerId'],
                    clientName: obj['clientName'],
                    clientId: obj['customerId'],
                    modelName: obj['name'],
                    productImage: productImage
                };

                this.deviceActive.set(device.id, device);
                this.shownMarkersBkp = [...this.shownMarkers];
                const deviceChanged = this.shownMarkers.find((d: IDevice) => d.id === device.id);
                // if already on map -> just change props.
                if (deviceChanged) {
                    Object.assign(deviceChanged, device);
                } else { // if new device
                    this.shownMarkers.push(device);
                }
                // if a new message comes in and the user has used a filter
                // we'll make sure to only show the markers that meets the filter
                if (this.isFilter && Object.keys(this.isFilter).length >= 1) {
                    this.showFilteredDevices(this.isFilter);
                }
            }
        } catch (e) {
        }
    }

    onResize(event) {
        this.isMobile = (event.target.innerWidth) < 650;
    }

    ngOnDestroy() {

        this.mqttService.disconnect(true);
        this.connectSubscription.unsubscribe();
        if (typeof this.subscription !== 'undefined') {
            this.subscription.unsubscribe();
        }
    }

    findMe() {
        // centers map on user location over secure connections
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.lat = position['coords']['latitude'];
                this.lng = position['coords']['longitude'];
            });
        } else {
            this.translate.get('live_map.no_geolocation').subscribe((text: string) => {
                alert(text);
            });
        }
    }

    openFilter() {
        this.translate.get(['live_map.filter_devices', 'btn.confirm']).subscribe((translated: string) => {
            let dialog = this.dialog.open(DeviceFilterDialogComponent, {
                panelClass: 'toolbar-dialog',
                data: { title: translated['live_map.filter_devices'], confirm: translated['btn.confirm'], current: this.isFilter }
            });

            dialog.afterClosed()
                .subscribe(response => {
                    if (response) {
                        this.isFilter = response;
                        this.showFilteredDevices(response);
                    } else {
                        // dialog was canceled or closed
                        // so all markers are shown
                        this.isFilter = {};
                        this.shownMarkers = Array.from(this.deviceActive.values());
                        this.zoom = this.default['zoom'];
                    }
                })
        });
    }

    showFilteredDevices(shown) {
        const marks = this.shownMarkers.filter(function (item) {
            for (const key in shown) {
                if (item[key] === undefined || item[key] != shown[key]) {
                    return false;
                }
            }
            return true;
        });
        this.shownMarkers = marks;
        const isNewDeviceAdded = marks.map(m => m.id).filter(f => !this.shownMarkersBkp.map(m => m.id).includes(f));
        if (!(this.zoom > this.default['zoom']) || isNewDeviceAdded.length > 0) {
            // to bypass bug with agmFitBounds we set bounds to false so
            // having no markers doesn't send us to the middle of the ocean
            this.bounds = (this.shownMarkers.length >= 1) ? true : false;
            setTimeout(() => {
                this.bounds = false;
            }, 3000);
        }
    }

    goToAlerts(id) {
        let goTo = 'alerts';
        let param = id;
        this.router.navigate([goTo, { filter: param }]);
    }

    openDashboard(device) {
        if (device.allowTelematics === 1) {
            this.manageFactory.dashboards.getDashboardList({
                deviceId: device.id
            }).subscribe((result: any[]) => {

                // if device has few dashboards -> show modal
                if (result && (result.length > 1 || result.length === 0)) {
                    const dialog = this.dialog.open(DeviceDashboardDialogComponent, {
                        panelClass: 'toolbar-dialog',
                        data: {
                            deviceId: device.id,
                            dashboardArray: result
                        }
                    });

                    dialog.afterClosed()
                        .subscribe(res => {
                            if (res) {
                                const param = res['dashboardId'] ? res['dashboardId'] : 'new';
                                const dashType = res['oldDashboard'] ? res['oldDashboard'] : false;

                                if (dashType === false) {

                                    if (res['newTab']) {
                                        window.open(`dashboard/${param}`, '_blank');
                                    } else {
                                        this.router.navigate([`../dashboard/${param}`], { relativeTo: this.activatedRoute });
                                    }

                                }
                                else {
                                    let goTo = this.getPath();
                                    goTo += '/#/dashboard/';
                                    goTo += param;
                                    window.open(goTo, '_blank');
                                }
                            }
                        });
                } else if (result && result.length === 1) {
                    this.router.navigate([`../dashboard/${result[0].dashboard_id}`], { relativeTo: this.activatedRoute });
                }

            });



        } else {
            this.translate.get('devices.telematics_not_enabled').subscribe((translated: string) => {
                this.snackBar.open(translated, '', {
                    duration: 4000,
                    horizontalPosition: 'left'
                });
            });
        }
    }

    getPath() {
        let path;
        if (window.location.origin.includes('app.spoke.zone')) {
            path = window.location.origin.replace("app", "dashboard");
        } else {
            path = window.location.origin.replace("4200", "3000");
        }
        return path;
    }
}
