import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IReportAggregate } from 'src/app/chart/chart.model';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-frequency-distribution-dialog',
  templateUrl: './frequency-distribution-dialog.component.html',
  styleUrls: ['./frequency-distribution-dialog.component.scss']
})
export class FrequencyDistributionDialogComponent implements OnInit {

  minimumValue: number = 0.01;
  form: FormGroup;
  isValueExist: boolean = false;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<FrequencyDistributionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { minMaxArray: IReportAggregate[] }
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      selectedVariable: [null, Validators.required],
      minValue: [{ value: '', disabled: true }],
      maxValue: [{ value: '', disabled: true }],
      frequency: [null, [Validators.required, Validators.min(this.minimumValue)]]
    });
  }

  /**
   * get frequency formControl from the formGroup
  */
  get frequency() {
    return this.form.get('frequency');
  }

  /**
   * Closes dialog when called.
  */
  close(): void {
    this.dialogRef.close();
  }

  /**
   * Performs a calculation and closes the dialog if the form is valid.
  */
  calculate(): void {
    if (this.form.valid) {
      this.form.controls['minValue'].enable();
      this.form.controls['maxValue'].enable();
      this.dialogRef.close(this.form.value);
    }
  }

  /**
   * Finds the minimum and maximum values based on the selected variable and sets them in the form.
   * @param value - The MatSelectChange event object containing the selected value.
  */
  findMinMax(value: MatSelectChange): void {
    this.data.minMaxArray.forEach(element => {
      if (element.selectedVariable == value.value) {
        if (element.min != undefined && element.max != undefined) {
          this.form.controls['minValue'].setValue(element.min);
          this.form.controls['maxValue'].setValue(element.max);
          this.isValueExist = true;
        }
        else {
          this.form.controls['minValue'].setValue('');
          this.form.controls['maxValue'].setValue('');
          this.isValueExist = false;
        }
      }
    });
  }
}
