import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-text-input-dialog',
    templateUrl: './text-input-dialog.component.html',
    styleUrls: ['./text-input-dialog.component.css']
})
export class TextInputDialogComponent {
    response = this.data.input;

    constructor(public dialogRef: MatDialogRef<TextInputDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService) { }

    confirm() {
        this.dialogRef.close(this.response);
    }

}
