import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ManageFactoryService } from '../services/manage-factory/manage-factory.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
    token: any;
    form = new FormGroup({
        password: new FormControl('', [Validators.required]),
        confirmPassword: new FormControl('', [Validators.required])

    });

    constructor(private router: Router, public snackBar: MatSnackBar, private manageService: ManageFactoryService, private route: ActivatedRoute, private translate: TranslateService) {
        this.route.params
            .subscribe(params => {
                this.token = params['token']
            });
    }

    ngOnInit() { }

    hasUpper() {
        let upper = false;
        if (/[A-Z]/.test(this.form.get('password').value)) {
            upper = true;
        }
        return upper;
    }

    hasLower() {
        let lower = false;
        if (/[a-z]/.test(this.form.get('password').value)) {
            lower = true;
        }
        return lower;
    }

    hasNumber() {
        let number = false;
        if (/\d/.test(this.form.get('password').value)) {
            number = true;
        }
        return number;

    }

    hasSpecial() {
        let special = false;
        if (/\W/.test(this.form.get('password').value)) {
            special = true;
        }
        return special;
    }

    isMatch() {
        let isSame = false;
        if (this.form.get('password').value === this.form.get('confirmPassword').value) {
            isSame = true;
        }
        return isSame;
    }

    getRequirements() {
        let isValid = false;
        let count = 0;
        if (this.hasLower()) {
            count++;
        }
        if (this.hasUpper()) {
            count++;
        }
        if (this.hasNumber()) {
            count++;
        }
        if (this.hasSpecial()) {
            count++;
        }
        if (count >= 3) {
            isValid = true;
        }

        return isValid;
    }


    cancel() {
        this.router.navigate(['']);
    }

    resetPassword() {
        this.manageService.forgot.resetPassword({
            token: this.token,
            password: this.form.get('password').value,
        })
            .subscribe(response => {
                if (response['status'] === 200) {
                    this.translate.get('reset_password.success').subscribe((text: string) => {
                        this.snackBar.open(text, "", {
                            duration: 4000,
                            horizontalPosition: 'left'
                        });
                        if (response['appId'] === "CPK") {
                            this.router.navigate(['resetPasswordSuccess']);
                        } else {
                            this.router.navigate(['']);
                        }
                    });
                } else {
                    this.translate.get('reset_password.password_could_not_be_updated').subscribe((text: string) => {
                        alert(text);
                    });
                }

            }, error => {
                this.translate.get('reset_password.password_could_not_be_updated').subscribe((text: string) => {
                    alert(text);
                });
            })
    }

}




