import { SymElementType } from "./types";
import { SymEnum } from "./sym_enum";

export enum SymSymbolVaribaleType {
    BIT = 1,
    CHAR = 2,
    STRING = 3,
    SIGNED = 4,
    UNSIGNED = 5,
    FLOAT = 6,
    ENUM = 7,
    DOUBLE = 8,
    RAW = 9
}

export enum SymSymbolVaribaleDisplayMode {
    OFF = 0,
    ONLY_VALUE = 1,
    ALL = 2
}

export enum SymSymbolVaribaleDataFormat {
    MOTOROLA = 0,
    INTEL = 1
}

export enum SymSymbolVaribaleFormat {
    DECIMAL = 0,
    HEX = 1,
    BINARY = 2
}

export class SymSymbolVariable {
    readonly sym_type: SymElementType = SymElementType.SYMBOL_VAR;
    private symbol_var_id: number;
    public name: string;
    public data_type: SymSymbolVaribaleType;
    public data_type_enum: SymEnum; // store enum name
    private data_type_enum_name: string; // this variable only for parsing after this 'enum_name' can be wrong (use 'data_type_enum' property)
    public starting_bit: number;
    public length: number;
    public value_offset: number;
    public comment: string;
    public display_decimal_places: number;
    public is_signed: boolean;
    public minimum_value: number;
    public maximum_value: number;
    public value_scalar: number;
    public value_uom: string;
    public display_mode: SymSymbolVaribaleDisplayMode;
    public is_logged: boolean; // trace
    public is_automatic_min_max_calculation: boolean;
    public data_format: SymSymbolVaribaleDataFormat;
    public auto_calculation: boolean; // ??
    public default_value: string;
    public standard: number;
    public output_format: SymSymbolVaribaleFormat;
    public long_name: string;
    public _data: any;

    constructor(data: any) {
        this.parse_values(data);
        this._data = data;
    }

    copy(enums: SymEnum[]) {
        let rv: SymSymbolVariable = new SymSymbolVariable(this.dump());
        rv.fillEnum(enums);
        return rv;
    }

    parse_values(data: any) {
        this.symbol_var_id = typeof data.message_value_id !== "undefined" ? data.message_value_id : 0;
        this.name = data.value_name;
        switch (data.data_type) {
            case "bit":
                this.data_type = SymSymbolVaribaleType.BIT;
                break;
            case "char":
                this.data_type = SymSymbolVaribaleType.CHAR;
                break;
            case "string":
                this.data_type = SymSymbolVaribaleType.STRING;
                break;
            case "signed":
                this.data_type = SymSymbolVaribaleType.SIGNED;
                break;
            case "unsigned":
                this.data_type = SymSymbolVaribaleType.UNSIGNED;
                break;
            case "float":
                this.data_type = SymSymbolVaribaleType.FLOAT;
                break;
            case "double":
                this.data_type = SymSymbolVaribaleType.DOUBLE;
                break;
            case "raw":
                this.data_type = SymSymbolVaribaleType.RAW;
                break;
            default:
                if (typeof data.data_type !== "undefined") {
                    this.data_type = SymSymbolVaribaleType.ENUM;
                    this.data_type_enum_name = data.data_type;
                } else {
                    this.data_type = SymSymbolVaribaleType.UNSIGNED;
                }
                break;
        }
        this.starting_bit = data.starting_bit ? parseInt(data.starting_bit) : 0;
        this.length = data.length ? parseInt(data.length) : 1;
        this.value_offset = data.value_offset ? parseFloat(data.value_offset) : 0;
        this.comment = typeof data.comment !== "undefined" ? data.comment : "";
        this.display_decimal_places =
            typeof data.display_decimal_places !== "undefined"
                ? data.display_decimal_places
                : 2;
        this.is_signed = data.data_type === 'signed';
        this.minimum_value =
            typeof data.minimum_value !== "undefined" ? data.minimum_value : 0;
        this.maximum_value =
            typeof data.maximum_value !== "undefined" ? data.maximum_value : 0;
        this.value_scalar =
            typeof data.value_scalar === "undefined" ||
            Math.abs(data.value_scalar) < 1e-8
                ? 1
                : parseFloat(data.value_scalar);
        this.value_uom =
            typeof data.value_uom !== "undefined" ? data.value_uom : "";
        this.display_mode =
            typeof data.display_mode !== "undefined"
                ? data.display_mode
                : SymSymbolVaribaleDisplayMode.ALL;
        this.is_logged = typeof data.trace !== "undefined" ? data.trace : true;
        this.is_automatic_min_max_calculation =
            typeof data.auto_calculation !== "undefined"
                ? data.auto_calculation
                : true;
        this.data_format =
            typeof data.is_intel !== "undefined"
                ? data.is_intel
                : SymSymbolVaribaleDataFormat.INTEL;
        this.auto_calculation =
            typeof data.auto_calculation !== "undefined"
                ? data.auto_calculation
                : true;
        this.default_value =
            typeof data.default_value !== "undefined" ? data.default_value : 0;
        this.standard =
            typeof data.standard !== "undefined" ? data.standard : 0;
        this.output_format =
            typeof data.format !== "undefined"
                ? data.format
                : SymSymbolVaribaleFormat.DECIMAL;
        this.long_name =
            typeof data.long_name !== "undefined" ? data.long_name : "";
    }

    dataType_toString() {
        let rv: string;
        switch (this.data_type) {
            case SymSymbolVaribaleType.BIT:
                rv = "bit";
                break;
            case SymSymbolVaribaleType.CHAR:
                rv = "char";
                break;
            case SymSymbolVaribaleType.STRING:
                rv = "string";
                break;
            case SymSymbolVaribaleType.SIGNED:
                rv = "signed";
                break;
            case SymSymbolVaribaleType.UNSIGNED:
                rv = "unsigned";
                break;
            case SymSymbolVaribaleType.FLOAT:
                rv = "float";
                break;
            case SymSymbolVaribaleType.DOUBLE:
                rv = "double";
                break;
            case SymSymbolVaribaleType.RAW:
                rv = "raw";
                break;
            case SymSymbolVaribaleType.ENUM:
                if (typeof this.data_type_enum !== "undefined") {
                    rv = this.data_type_enum.name;
                } else {
                    rv = "unknown_enum";
                }
                break;
            default:
                rv = "unknown";
                break;
        }
        return rv;
    }

    dump() {
        let rv = {
            message_value_id: (typeof this.symbol_var_id !== 'undefined' && this.symbol_var_id > 0) ? this.symbol_var_id : 0,
            value_name: typeof this.name !== "undefined" ? this.name : "",
            starting_bit:
                typeof this.starting_bit !== "undefined"
                    ? this.starting_bit
                    : 0,
            length: typeof this.length !== "undefined" ? this.length : 1,
            value_scalar:
                typeof this.value_scalar !== "undefined"
                    ? this.value_scalar
                    : 1,
            value_offset:
                typeof this.value_offset !== "undefined"
                    ? this.value_offset
                    : 0,
            value_uom:
                typeof this.value_uom !== "undefined" ? this.value_uom : "",
            minimum_value:
                typeof this.minimum_value !== "undefined"
                    ? this.minimum_value
                    : 0,
            maximum_value:
                typeof this.maximum_value !== "undefined"
                    ? this.maximum_value
                    : 1,
            display_decimal_places:
                typeof this.display_decimal_places !== "undefined"
                    ? this.display_decimal_places
                    : 2,
            is_intel:
                this.data_format === SymSymbolVaribaleDataFormat.INTEL ? 1 : 0,
            is_signed: this.is_signed ? 1 : 0,
            data_type: this.dataType_toString(),
            comment: typeof this.comment !== "undefined" ? this.comment : "",
            long_name:
                typeof this.long_name !== "undefined" ? this.long_name : ""
        };
        return rv;
    }

    fillEnum(enums: SymEnum[]) {
        if (this.data_type === SymSymbolVaribaleType.ENUM) {
            enums.forEach((e: SymEnum) => {
                if (this.data_type_enum_name == e.name) {
                    this.data_type_enum = e;
                }
            });
        }
    }
}
