import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ManageFactoryService } from '../services/manage-factory/manage-factory.service';
import { DowntimeNotification, MomentCategory, TimeStampData } from '../shared/models/common.model';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-generate-service-downtime',
  templateUrl: './generate-service-downtime.component.html',
  styleUrls: ['./generate-service-downtime.component.scss']
})
export class GenerateServiceDowntimeComponent implements OnInit, OnDestroy {

  minDate = new Date();
  fromDate: Date;
  toDate: Date;
  form: FormGroup;
  desc: string = 'service_downtime.description';
  destroy$ = new Subject();
  isInvalidDate: boolean = false;

  constructor(
    private fb: FormBuilder,
    private manageFactory: ManageFactoryService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      startDate: [null, Validators.required],
      startTime: [null, Validators.required],
      endDate: [null, Validators.required],
      endTime: [null, Validators.required],
      desc: ['', Validators.required],
      additionalInfo: [''],
    });

    const environmentOrigin = environment.origin;

    this.form.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(data => { // subscribe to form changes to set value for notification description
        if (data.startDate && data.startTime && data.endDate && data.endTime) {
          const fromDateTimeStamp: TimeStampData = this.generateTimeStamp(data.startDate, data.startTime);
          const toDateTimeStamp: TimeStampData = this.generateTimeStamp(data.endDate, data.endTime);
          this.fromDate = fromDateTimeStamp.timeStamp;
          this.toDate = toDateTimeStamp.timeStamp;
          const dataDifference = moment(this.toDate).diff(this.fromDate, MomentCategory.MilliSeconds);

          if (dataDifference < 0) {
            this.isInvalidDate = true;
            return;
          } else {
            this.isInvalidDate = false;
          }
          this.translate.get(this.desc,
            {
              environmentOrigin: environmentOrigin,
              fromDate: fromDateTimeStamp.germanyTimeStamp,
              toDate: toDateTimeStamp.germanyTimeStamp
            }).subscribe((translatedString: string) => {
              this.form.controls.desc.setValue(translatedString);
            });
        }
      });
  }

  /**
   * This method is called to generate service downtime based on the selected dates.
   */
  generateServiceDowntime(): void {
    const downtimeDataReq: DowntimeNotification = {
      description: this.form.controls.desc.value + (this.form.controls.additionalInfo.value ?? ''),
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
    this.manageFactory.downtime.generate(downtimeDataReq).subscribe(data => this.form.reset());
  }

  /**
   * This method is called to generate the time stamp according to selected date and time
   * @param dateValue Contains the selected date value
   * @param timeValue Contains the selected time value
   * @returns {TimeStampData} Return the generated timestamp object
   */
  generateTimeStamp(dateValue: string, timeValue: string): TimeStampData {
    const datePart = moment(dateValue).format('YYYY-MM-DD');
    const utcTimeStamp: Date = new Date(`${datePart} ${timeValue}`);
    const germanTimeStamp: string = utcTimeStamp.toLocaleString('en-GB',
      {
        timeZone: 'Europe/Berlin',
        timeZoneName: 'short',
      });
    return {timeStamp: utcTimeStamp, germanyTimeStamp: germanTimeStamp};
  }

  /**
   * This webhook is called on the destruction of the component
   */
  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
