<mat-tab-group class="main-toolbar" animationDuration="0ms" (selectedIndexChange)="onSelectTab($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="main-toolbar-tab-icon" style="background-image: url('/assets/img/lib_black.png');"></mat-icon>{{'library_editor.symbols' | translate}}
    </ng-template>
    <ng-template matTabContent>
        <symbol-editor
            [sym_lib]="sym_lib"
            [selected_symbol]="selected_symbol"
            [updateLibraryEvents]="eventUpdateLibrary.asObservable()"
            (onSelectSymbol)="onChangeSymbol($event)"
            (onSymbolDelete)="deleteSymbol($event)">
        </symbol-editor>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="main-toolbar-tab-icon">list</mat-icon>{{'library_editor.enums' | translate}}
    </ng-template>
    <ng-template matTabContent>
        <enum-editor
            [sym_lib]="sym_lib"
            [selected_enum]="selected_enum"
            [updateLibraryEvents]="eventUpdateLibrary.asObservable()"
            (onSelectEnum)="onChangeEnum($event)"
            (onDeleteElement)="deleteEnum($event)">
        ></enum-editor>
    </ng-template>
  </mat-tab>
  <!-- buttons tab -->
  <mat-tab disabled>
    <ng-template mat-tab-label>
      <button
        *ngIf="!browserInfo.is_mobile"
        mat-icon-button
        matTooltip="{{'library_editor.delete' | translate}}"
        (click)="deleteBtnClick()"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-icon-button matTooltip="{{'library_editor.add' | translate}}" (click)="addBtnClick()">
        <mat-icon>add</mat-icon>
      </button>

      <button
        *ngIf="!browserInfo.is_mobile"
        mat-icon-button
        matTooltip="{{'library_editor.save' | translate}}"
        (click)="saveLibrary(0)"
        [disabled]="!(isSymbolFormEditValid$ | async)"
      >
        <mat-icon>save</mat-icon>
      </button>

    </ng-template>
  </mat-tab>
</mat-tab-group>

<button
  *ngIf="browserInfo.is_mobile"
  mat-fab
  (click)="saveLibrary(0)"
  class="md-fab-bottom-mobile-menu"
  [disabled]="!(isSymbolFormEditValid$ | async)"
>
  <mat-icon>save</mat-icon>
</button>
