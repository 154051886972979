<!-- span *ngIf="browserInfo.is_mobile; then mobile else mobile"></span -->
<span *ngIf="browserInfo.is_mobile; then mobile else desktop"></span>

<!-- MOBILE VERSION -->
<ng-template #mobile>
    <mat-accordion *ngIf="sym_lib" [ngClass]="getClassName()">
        <mat-expansion-panel *ngFor="let symbol of sym_lib.symbols;let i = index">
            <mat-expansion-panel-header class="ngstd-list-item" collapsedHeight="*" expandedHeight="*">
                <div class='ngstd-item-wrapper'>
                    <div class="ngstd-delete-indicator">
                        <i class="material-icons ngstd-delete-icon" *ngIf="isLeftSign()">delete_sweep</i>
                        <span>&nbsp;</span>
                        <i class="material-icons ngstd-delete-icon" *ngIf="isRightSign()">delete_sweep</i>
                    </div>
                    <div #elementRefrence class="ngstd-item-container "
                                    (panend)="panend($event, i, elementRefrence)"
                                    (panmove)="panmove($event,elementRefrence)"
                                    [@slideLeft]="ngstdIndexNumber === i"
                                    (@slideLeft.done)=alignComplete($event)>
                        <mat-panel-title>
                            <mat-icon mat-list-icon class="symbol-icon">mail_outline</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                            <p mat-line class="list-element-title">{{symbol.name}}</p>
                            <p mat-line> {{symbol.can_id | hexadecimal}} </p>
                        </mat-panel-description>
                    </div>
                </div>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <symbol-details
                    [sym_lib]="sym_lib"
                    [selected_symbol]="symbol"
                    [events]="eventUpdateLibrary.asObservable()"
                    (onSymbolDelete)="onSymbolDeleteEvent($event)"
                    (onAddSymbolVariable)="addNewSymbolVariable($event)">
                </symbol-details>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</ng-template>

<!-- DESKTOP VERSION -->
<ng-template #desktop>
    <as-split direction="horizontal" [useTransition]="true" gutterSize="3" style="height: calc(100vh - 115px);">
        <as-split-area size="20">
            <mat-list *ngIf="sym_lib">
                <mat-list-item *ngFor="let symbol of sym_lib.symbols" (click)="onSymbolClick(symbol)" [class.selected]="symbol==selected_symbol">
                    <mat-icon mat-list-icon class="symbol-icon">mail_outline</mat-icon>
                    <p mat-line class="list-element-title">{{symbol.name}}</p>
                    <p mat-line> {{symbol.can_id | hexadecimal}} </p>
                </mat-list-item>
            </mat-list>
        </as-split-area>
        <as-split-area size="80">
            <as-split direction="vertical" gutterSize="3">
                <as-split-area size="40">
                    <symbol-prop
                        *ngIf="selected_symbol"
                        [sym_lib]="sym_lib"
                        [selected_symbol]="selected_symbol"
                        (onSymbolUpdate)="onSymbolUpdateEvent($event)"
                        (onValidFormChange)="handleValidFormChange($event)"
                    ></symbol-prop>
                </as-split-area>
                <as-split-area size="60">
                    <symbol-details
                        [sym_lib]="sym_lib"
                        [selected_symbol]="selected_symbol"
                        [events]="eventUpdateLibrary.asObservable()"
                        (onAddSymbolVariable)="addNewSymbolVariable($event)"
                    ></symbol-details>
                </as-split-area>
            </as-split>
        </as-split-area>
    </as-split>
</ng-template>
