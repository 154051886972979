import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'fileName' })
export class FileNamePipe implements PipeTransform {
    transform(value: string): string {
        if (value && value.includes('/')) {
            let shorterName = value.split('/');
            return shorterName.pop();
        } else {
            return value;
        }
    }
}