import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "hexadecimal"
})
export class HexadecimalPipe implements PipeTransform {
    transform(value: number): any {
        if (!value && value != 0) {
            return "";
        }

        return "0x" + value.toString(16).toUpperCase() + "h";
    }
}
