
export enum SymElementType {
    ENUM = 0,
    SYMBOL = 1,
    SYMBOL_VAR = 2,
    VIRTUAL_VAR = 3,
}

export enum UpdateLibraryEventType {
	SYMBOL_LENGTH_CHANGE = 0,
	SYMBOL_VARIABLE_NEW = 1,
	ENUM_VALUE_NEW = 2,
	ENUM_NEW = 3,
	SYMBOL_NEW = 2,
}
