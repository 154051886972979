import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { appConstants } from "../services/constants";
import { AppSettingsService } from "../services/user/app-settings.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { CustomValidator } from "../shared/custom.validators";
import { environment } from "src/environments/environment";
import { formatNumber, parseNumber } from "libphonenumber-js";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { ManageFactoryService } from "../services/manage-factory/manage-factory.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { OverlayContainer } from "@angular/cdk/overlay";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Theme } from "../theme.model";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from '../services/shared/shared.service';
import { ICountry } from '../shared/models/common.model';

@Component({
    selector: 'app-customer-editor',
    templateUrl: './customer-editor.component.html',
    styleUrls: ['./customer-editor.component.css']
})
export class CustomerEditorComponent implements OnInit, OnDestroy {
    canEdit = false;
    isSaved = false;
    constants = appConstants;
    currentRoute: string;
    countryList: ICountry[] = [];
    country: any;
    themes = Theme.themes;
    customer = {};
    customerUserList: any = [];
    oemCustomerList: any = [];
    dealerCustomerList: any = [];
    id: any;
    userCustomerRole = -1;
    routerSubscription: Subscription;
    logoUrl: string = "";
    logoUrlFinal: string = "";
    faviconUrl: string = "";
    faviconUrlFinal: string = "";
    currentThemeHex: string = "";
    displayedFields = ['customerName', 'userId', 'phone', 'customerEmail', 'address', 'websiteUrl', 'oem', 'dealer', 'notes', 'theme'];

    destroy$ = new Subject();

    customerEditorForm = new FormGroup({
        customer_name: new FormControl('', Validators.required),
        user_id: new FormControl(null),
        phone: new FormControl(''),
        customer_email: new FormControl('', [Validators.required, CustomValidator.email]),
        address: new FormControl(null),
        website_url: new FormControl(null),
        oem: new FormControl(''),
        dealer: new FormControl(''),
        notes: new FormControl(null),
        theme: new FormControl(''),
        favicon: new FormControl(''),
        logo: new FormControl(''),
    });

    constructor(
        private router: Router,
        private appSettingsService: AppSettingsService,
        public overlayContainer: OverlayContainer,
        private manageFactory: ManageFactoryService,
        public snackBar: MatSnackBar,
        private location: Location,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private sharedService: SharedService
    ) {

        router.events.pipe(
          takeUntil(this.destroy$)
        ).subscribe(event => {
            if (event instanceof NavigationEnd) {
                // this will catch the route whether it was the original url or redirected after login
                this.currentRoute = event.urlAfterRedirects.split('/')[1] + 's';
            }
        });
        // get list of countries which are not sanctioned
        this.countryList = this.sharedService.getCountries();
        this.country = 'US';
        this.userCustomerRole = +sessionStorage.getItem('customerRole');
    }

    ngOnInit() {
        this.setUpForm();
    }

    ngOnDestroy() {
        // we need to turn this off because we don't want this activated stuff affecting other components.
        this.routerSubscription.unsubscribe();
        this.destroy$.next();
    }

    setUpForm() {
        this.canEdit = (+sessionStorage.getItem('userRole') == this.constants.user_read_write) ? true : false;
        this.routerSubscription = this.activatedRoute.params.subscribe(params => {
            if (this.canEdit) {
                this.loadExisting(params);
            }
            else {
                this.getTheCustomer(params['id']);
            }
        });
    }

    loadExisting(params) {
        if (this.userCustomerRole < this.constants.role_oem_user) {
            this.manageFactory.oems.read().subscribe(result => {
                this.oemCustomerList = result;
                this.manageFactory.dealers.read().subscribe(result => {
                    this.dealerCustomerList = result;
                    this.getTheCustomer(params['id']);
                });
            });
        }
        else if (this.userCustomerRole == this.constants.role_oem_user) {
            // if i am an OEM viewing this screen, i just need to have my dealers list ready.
            this.manageFactory.dealers.read().subscribe(result => {
                this.dealerCustomerList = result;
                this.getTheCustomer(params['id']);
            });
        }
        else {
            this.getTheCustomer(params['id']);
        }
    }

    getTheCustomer(pCustomerId) {
        if (pCustomerId !== undefined) {
            // load the existing one.
            this.id = pCustomerId;
            this.manageFactory[this.currentRoute].readOne({ id: this.id })
                .subscribe(result => {
                    this.customer = result;
                    this.customerEditorForm.patchValue(this.customer);
                    this.logoUrl = this.logoUrlFinal = this.customer["logo"];
                    this.faviconUrl = this.faviconUrlFinal = this.customer["favicon"];
                    this.customerEditorForm.controls['theme'].setValue(this.customer['theme']);
                    let thisCountry = result['phone'] ? parseNumber(result['phone']) : false;
                    this.country = thisCountry['country'] ? thisCountry['country'] : 'US';
                    this.currentThemeHex = this.themes.find(currentTheme => currentTheme.label == this.customerEditorForm.controls['theme'].value).hex;
                    // now go get the right contact
                    // this would be done by getting the customer's available users.
                    this.manageFactory.users.readUserList({ id: this.id }).subscribe(result => {
                        this.customerUserList = result;
                    });

                    // get the OEM and Dealer, if necessary
                    if (this.currentRoute == 'dealers') {
                        this.customerEditorForm.controls['oem'].setValue(this.customer['oem_id']);
                    } else if (this.currentRoute == 'clients') {
                        this.customerEditorForm.controls['oem'].setValue(this.customer['oem_id']);
                        this.manageFactory.clients.readDealer({ id: this.id }).subscribe(result => {
                            this.customerEditorForm.controls['dealer'].setValue(result['customer_id']);
                        })

                    }

                });
        } else {
            // else, get ready to do an insert.
            this.customerEditorForm.controls['theme'].setValue("Default");
            this.currentThemeHex = this.themes.find(currentTheme => currentTheme.label == "Default").hex
        }

    }

    get customerName() {
        return this.customerEditorForm.get('customer_name');
    }

    get userId() {
        return this.customerEditorForm.get('user_id');
    }

    get phone() {
        return this.customerEditorForm.get('phone');
    }

    get customerEmail() {
        return this.customerEditorForm.get('customer_email');
    }

    get address() {
        return this.customerEditorForm.get('address');
    }

    get websiteUrl() {
        return this.customerEditorForm.get('website_url');
    }

    get oem() {
        return this.customerEditorForm.get('oem');
    }

    get dealer() {
        return this.customerEditorForm.get('dealer');
    }

    get notes() {
        return this.customerEditorForm.get('notes');
    }

    getLogo() {
        return this.logoUrl ? environment.apiUrl + this.logoUrl : environment.apiUrl + '/images/mrs-logo.png';
    }

    get theme() {
        return this.customerEditorForm.get('theme');
    }

    getFavicon() {
        return this.faviconUrl ? environment.apiUrl + this.faviconUrl : environment.apiUrl + '/images/favicon.ico';
    }

    validatePhone() {
        let phoneNum = (this.phone.value != null) ? this.phone.value.toString() : false;
        if (phoneNum) {
            let parsed = parseNumber(phoneNum, this.country);
            // @ts-ignore
            let formatted = (parsed['country'] == this.country) ? formatNumber(parsed, 'International') : false;
            if (formatted == false) {
                this.customerEditorForm.controls['phone'].setErrors({ 'invalid': true });
            } else {
                this.customerEditorForm.controls['phone'].setValue(formatted);
            }
        }
    }

    changeTheme() {
        this.currentThemeHex = this.themes.find(currentTheme => currentTheme.label == this.customerEditorForm.controls['theme'].value).hex;
    }

    onLogoChange(event) {
        if (event.target.files && event.target.files.length > 0) {
            //check to see if file is too large
            if (event.target.files[0].size < 1024000) {
                let ext = event.target.files[0].name.split('.').pop().toUpperCase();
                //check to see if file is correct type
                if (ext === "PNG" || ext === "JPG" || ext === "JPEG" || ext === "BMP") {
                    event.data = {
                        customerId: this.id,
                        uploadType: 'logo'
                    }
                    const formData: FormData = new FormData();
                    formData.append("customerId", (this.id));
                    formData.append("uploadType", ("logo"));
                    formData.append("files", event.target.files[0]);
                    //upload file to api
                    this.manageFactory.uploads.uploadFile(formData).subscribe(
                        show => {
                            // if (this.id === undefined) {
                            this.logoUrl = show['tempDestinationPath'];
                            this.logoUrlFinal = show['finalDestinationPath'];
                            //save to api using paths created by api during upload
                            this.manageFactory.accountSettings.saveLogo({
                                customer_id: this.id,
                                path: show['finalDestinationPath'],
                                tempName: show['tempFileName'],
                                name: show['originalFileName']
                            }).subscribe(
                                res => {
                                    this.translate.get("snackbar.upload_complete").subscribe((upload_complete: string) => {
                                        this.snackBar.open(upload_complete, "", {
                                            duration: 4000,
                                            horizontalPosition: 'left'
                                        });
                                    });
                                }
                            )
                        }
                    )
                } else {
                    this.translate.get("alert.error.logo_upload1").subscribe((err_str: string) => {
                        alert(err_str);
                    });
                }
            } else {
                this.translate.get("alert.error.logo_upload2").subscribe((err_str: string) => {
                    alert(err_str);
                });
            }
        }
    }

    openLogoUpload() {
        document.getElementById('logoUpload').click();
    }

    onIconChange(event) {
        if (event.target.files && event.target.files.length > 0) {
            let ext = event.target.files[0].name.split('.').pop();
            //check to see if file has corrent type
            if (ext.toUpperCase() === "ICO") {
                //check to see if file is too large
                if (event.target.files[0].size < 1024000) {
                    event.data = {
                        customerId: this.id,
                        uploadType: 'favicon'
                    }
                    const formData: FormData = new FormData();
                    formData.append("customerId", (this.id));
                    formData.append("uploadType", ("favicon"));
                    formData.append("files", event.target.files[0]);

                    this.manageFactory.uploads.uploadFile(formData).subscribe(
                        show => {
                            //update settings with new image paths
                            this.faviconUrl = show['tempDestinationPath'];
                            this.faviconUrlFinal = show['finalDestinationPath'];
                            //save favicon to directory in api
                            this.manageFactory.accountSettings.saveFavicon({
                                customer_id: this.id,
                                path: show['finalDestinationPath'],
                                tempName: show['tempFileName'],
                                name: show['originalFileName']
                            }).subscribe(
                                res => {
                                    this.snackBar.open("Upload Complete", "", {
                                        duration: 4000,
                                        horizontalPosition: 'left'
                                    });

                                }
                            )
                        }
                    )
                } else {
                    this.translate.get("alert.error.logo_upload2").subscribe((err_str: string) => {
                        alert(err_str);
                    });
                }
            } else {
                this.translate.get("alert.error.icon_upload1").subscribe((err_str: string) => {
                    alert(err_str);
                });
            }
        }
    }

    openIconInput() {
        document.getElementById('iconUpload').click();
    }

    cancel() {
        this.location.back();
    }

    saveSettings() {

        this.customer['customer_name'] = this.customerEditorForm.get('customer_name').value;
        this.customer['user_id'] = this.customerEditorForm.get('user_id').value;
        this.customer['phone'] = this.customerEditorForm.get('phone').value;
        this.customer['customer_email'] = this.customerEditorForm.get('customer_email').value;
        this.customer['address'] = this.customerEditorForm.get('address').value;
        this.customer['website_url'] = this.customerEditorForm.get('website_url').value;
        this.customer['notes'] = this.customerEditorForm.get('notes').value;
        this.customer['theme'] = this.customerEditorForm.get('theme').value;
        // do not include the logo and favicon if we're updating and existing customer record.
        this.customer['logo'] = this.id === undefined ? this.logoUrl : this.logoUrlFinal;
        this.customer['favicon'] = this.id === undefined ? this.faviconUrl : this.faviconUrlFinal;
        let who_is = '';
        switch (this.currentRoute) {
            case "administrators":
                who_is = "customer_editor.administrator"
                this.customer['customer_role'] = appConstants.role_administrator;
                break;
            case "oems":
                who_is = 'customer_editor.oem';
                this.customer['customer_role'] = appConstants.role_oem_user;
                break;
            case "dealers":
                // if a logged in user from an OEM is creating this dealer, the from id needs to be
                // their customer id, otherwise, get it from the drop down.
                if (this.userCustomerRole == appConstants.role_oem_user) {
                    this.customer['from_id'] = sessionStorage.getItem("customerId");
                    this.customer['oem_id'] = sessionStorage.getItem("customerId");
                } else if (this.userCustomerRole < appConstants.role_oem_user) {
                    this.customer['from_id'] = this.customerEditorForm.get('oem').value;
                    this.customer['oem_id'] = this.customerEditorForm.get('oem').value;
                }
                who_is = 'customer_editor.dealer';
                this.customer['customer_role'] = appConstants.role_dealer_user;

                break;
            case "clients":
                if (this.userCustomerRole == appConstants.role_oem_user) {
                    // if an oem is creating a client, then utilize the dealer drop down to get the dealer, but
                    // use the logged in user's customer ID for the OEM
                    this.customer['oem_id'] = sessionStorage.getItem("customerId");
                    this.customer['from_id'] = this.customerEditorForm.get('dealer').value;

                } else if (this.userCustomerRole < appConstants.role_oem_user) {
                    // both drop downs will need to be used if an administrator is doing its thing.
                    this.customer['from_id'] = this.customerEditorForm.get('dealer').value;
                    this.customer['oem_id'] = this.customerEditorForm.get('oem').value;
                } else if (this.userCustomerRole == appConstants.role_dealer_user) {
                    this.customer['from_id'] = sessionStorage.getItem('customerId');
                    this.customer['oem_id'] = sessionStorage.getItem('oemCustomerId');
                }
                who_is = 'customer_editor.client';
                this.customer['customer_role'] = appConstants.role_client_user;
                break;
            default:
                this.customer['customer_role'] = appConstants.role_client_user;  // let's make them clients too just incase something wierd happens.
                who_is = 'customer_editor.customer';
                break;
        }

        if (this.id === undefined) {
            this.isSaved = true;
            this.manageFactory[this.currentRoute].create(this.customer).subscribe(
                result => {
                    // evaluate the result first! if it does not have an errno property, then it's good!
                    if (result.errno === undefined) {
                        this.customerEditorForm.controls['customer_email'].setErrors({ 'duplicateCustomerSupportEmail': false });
                        this.customerEditorForm.controls['customer_name'].setErrors({ 'duplicateCustomerName': false });

                        this.translate.get(who_is).subscribe((who_is_str: string) => {
                            this.translate.get("customer_editor.successfully_created", { who_is: who_is_str }).subscribe((message: string) => {
                                this.snackBar.open(message, '', {
                                    duration: 4000,
                                    horizontalPosition: 'left'
                                });
                                this.location.back();
                            });
                        });
                    } else {
                        if (result.errno == 1062) {
                            if (result.sqlMessage.includes("customer_email")) {
                                this.customerEditorForm.controls['customer_email'].setErrors({ 'duplicateCustomerSupportEmail': true });

                            } else if (result.sqlMessage.includes("customer_name")) {
                                this.customerEditorForm.controls['customer_name'].setErrors({ 'duplicateCustomerName': true });
                            }
                        } else {
                            this.translate.get("alert.error.saving_record").subscribe((err_str: string) => {
                                this.snackBar.open(err_str, '', {
                                    duration: 4000,
                                    horizontalPosition: 'left'
                                });
                            });
                        }
                    }

                }
            );
        } else {
            this.isSaved = true;
            this.manageFactory[this.currentRoute].update(this.customer).subscribe(
                result => {
                    if (result.errno === undefined) {
                        this.customerEditorForm.controls['customer_email'].setErrors({ 'duplicateCustomerSupportEmail': false });
                        this.customerEditorForm.controls['customer_name'].setErrors({ 'duplicateCustomerName': false });

                        this.translate.get(who_is).subscribe((who_is_str: string) => {
                            this.translate.get("customer_editor.successfully_updated", { who_is: who_is_str }).subscribe((message: string) => {
                                this.snackBar.open(message, '', {
                                    duration: 4000,
                                    horizontalPosition: 'left'
                                });
                                this.location.back();
                            });
                        });
                    } else {
                        if (result.errno == 1062) {
                            if (result.sqlMessage.includes("customer_email")) {
                                this.customerEditorForm.controls['customer_email'].setErrors({ 'duplicateCustomerSupportEmail': true });
                            } else if (result.sqlMessage.includes("customer_name")) {
                                this.customerEditorForm.controls['customer_name'].setErrors({ 'duplicateCustomerName': true });
                            }
                        } else {
                            this.translate.get("alert.error.saving_record").subscribe((err_str: string) => {
                                this.snackBar.open(err_str, '', {
                                    duration: 4000,
                                    horizontalPosition: 'left'
                                });
                            });
                        }
                    }

                }
            );
        }
    }
}