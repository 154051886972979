import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { UserBrowserInfo } from 'src/app/library-editor/model/user_browser_info';
import { ChartType } from 'src/app/log-chart/model/log-chart.model';
import { CsvExportService } from 'src/app/services/csv-export/csv-export.service';
import { Chart, IChartVariableData, IEchartData, IFrequencyDistribution, IReportAggregate, IPercentageFrequency } from '../chart.model';
import { LineChartComponent } from '../line-chart/line-chart.component';
import { SharedService } from 'src/app/services/shared/shared.service';
import { canWrite } from 'src/app/shared/common';
import { FrequencyDistributionDialogComponent } from 'src/app/dialogs/frequency-distribution-dialog/frequency-distribution-dialog.component';
import { PanelClass } from 'src/app/shared/models/common.model';
import { ReportItemService } from '../../reports/services/report-item.service';

@Component({
    selector: 'chart-card',
    templateUrl: './chart-card.component.html',
    styleUrls: ['./chart-card.component.scss'],
    providers: [
        UserBrowserInfo
    ],
})
export class ChartCardComponent implements OnChanges {

    @Input() chart: Chart;
    @Input() index: any;
    @Input() showToolBar: boolean;
    @Output() editEvent = new EventEmitter();
    @Output() deleteEvent = new EventEmitter();
    @Output() columnWidth = new EventEmitter();
    @Output() cardLoaded = new EventEmitter();
    @ViewChild('eLineChart') lineChart: LineChartComponent;

    chartType = ChartType;

    destroy$ = new Subject();
    minMaxArray: IReportAggregate[] = [];
    isZoomApplied: boolean = false;
    percentageFrequency: IPercentageFrequency[];
    selectedVariableName: string;
    timesView: boolean = true;
    columnWidthMapping = {
        2: '30%',
        3: '48%',
        4: '75%',
        5: '100%',
    };
    gridColumnList: number[] = [2, 3, 4, 5];

    constructor(
        public browserInfo: UserBrowserInfo,
        public csvExportService: CsvExportService,
        private dialog: MatDialog,
        private sharedService: SharedService,
        private reportService: ReportItemService
    ) {
        this.reportService.reportRefresh$.subscribe((res: boolean) => {
            if (res && this.percentageFrequency && this.percentageFrequency.length) {
                this.percentageFrequency = [];
            }
        });
    }

    /**
     * This lifecycle method is called when any data-bound property of a directive changes
     */
    ngOnChanges(): void {
        this.sharedService.isEchartDataAvailable$.subscribe((resp: boolean) => {
            if (!resp) {
                return;
            }
            this.calculateMinMax(this.chart.echartData);
        });
    }

    get isReadOnly(): boolean {
        return !canWrite();
    }

    /**
     * This method calculates the min, max and mean values of the chart
     * @param echartData get array of chart data
     */
    private calculateMinMax(echartData: IEchartData[]): void {
        if (!echartData) {
            return;
        }
        this.minMaxArray = echartData.map((element: IEchartData) => {
            const min: number = this.minValue(element.values);
            const max: number = this.maxValue(element.values);
            const mean: number = this.meanValue(element.values);
            const selectedVariable: string = this.getSelectedVariable(element.id);
            return { min, max, mean, selectedVariable } as IReportAggregate;
        });
    }

    /**
     * This function is used to calculate the minimum value from an array of numbers
     * @param numArray gets a 2D-array of numbers
     * @returns {number} returns the minimum value from an array of numbers
     */
    private minValue(numArray: number[][]): number {
        if (!numArray || !numArray.length) {
            return;
        }

        let min: number = Number.MAX_VALUE;
        numArray.forEach((e: number[]) => min > e[1] && (min = e[1]));
        return min;
    }

    /**
     * This function is used to calculate the maximum value from an array of numbers
     * @param numArray gets a 2D-array of numbers
     * @returns {number} returns the maximum value from an array of numbers
     */
    private maxValue(numArray: number[][]): number {
        if (!numArray || !numArray.length) {
            return;
        }

        let max: number = -Number.MAX_VALUE;
        numArray.forEach((e: number[]) => max < e[1] && (max = e[1]));
        return max;
    }

    /**
     * This function is used to calculate the mean value from an array of numbers
     * @param numArray gets a 2D-array of numbers
     * @returns {number} returns the mean value for an array of numbers
     */
    private meanValue(numArray: number[][]): number {
        if (!numArray || !numArray.length) {
            return;
        }

        const mean: number = numArray.reduce((sum: number, el: number[]) => sum + el[1], 0) / numArray.length;
        return isNaN(mean) ? null : this.sharedService.roundNumber(mean);
    }

    /**
     * This function is used to find the selected variable from the given data
     * @param variableId get the variable id from the response
     * @returns {string} return the selected variable in string format
     */
    private getSelectedVariable(variableId: number): string {
        return this.chart.selectedVariables.find((element: IChartVariableData) => element.variableId === variableId).variableName;
    }

    /**
     * This function is used to reset the zoom applied on the chart
     */
    resetChartData(): void {
        this.lineChart.initializeChart();
        this.isZoomApplied = false;
    }

    /**
     * This function is used to check if the zoom applied on the chart or not
     * @param value gets a boolean value indicating whether the chart is zoomed or not
     */
    checkZoom(value: boolean): void {
        this.isZoomApplied = value;
    }

    editChart() {
        this.editEvent.emit();
    }

    deleteChart(index: number) {
        const dialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titleTerm: 'dialog.confirm_delete',
                textTerm: 'chart_cards.dialog.delete.text',
                confirmTerm: 'chart_cards.dialog.delete.confirm'
            }
        });
        dialog.afterClosed().subscribe(result => {
            if (result) {
                this.deleteEvent.emit(index);
            }
        });
    }

    exportToCsv(chart) {
        // Add a "Time" column to the beginning.
        let labels = ['Time', ...chart.labels];
        this.csvExportService.exportToCsv(chart.chartPoints, 'chart', labels);
    }

    toggleScale() {
        this.lineChart.toggleScale();
    }

    /**
     * This method is called when user want to change axis view of the chart
     */
    toggleChartView(): void {
        this.lineChart.initializeChart();
        this.timesView = !this.timesView;
    }

    /**
     * This function is used to get the filtered chart data and pass it to calculateMinMax function
     * @param filteredEchartData have the filtered chart data
     */
    filteredChartData(filteredEchartData: IEchartData[]): void {
        this.calculateMinMax(filteredEchartData);
    }

    /**
     * Displays a dialog box to accept inputs for frequency distribution and returns the percentage frequency distribution.
     * @function checkPercentageDistribution
     */
    checkPercentageDistribution(): void {
        const dialog: MatDialogRef<FrequencyDistributionDialogComponent, IFrequencyDistribution> = this.dialog.open(FrequencyDistributionDialogComponent, {
            panelClass: PanelClass.ToolbarDialog,
            data: {
                minMaxArray: this.minMaxArray
            }
        });

        dialog.afterClosed()
            .subscribe((response: IFrequencyDistribution) => {
                if (!response) {
                    return;
                }
                this.selectedVariableName = response.selectedVariable;
                const frequency: number = response.frequency;
                const minValue: number = response.minValue;
                const maxValue: number = response.maxValue;
                this.percentageFrequency = this.getPercentageFrequencyDistribution(this.chart.echartData, frequency, minValue, maxValue, this.selectedVariableName);
            });
    }

    /**
     * This function is used to find the selected variable id from the given data
     * @param {string} selectedVariableName get the variable name from the response
     * @returns {number} return the selected variable id in number format
     */
    private getSelectedVariableId(selectedVariableName: string): number {
        return this.chart.selectedVariables.find((element: IChartVariableData) => element.variableName === selectedVariableName)?.variableId;
    }

    /**
     * Calculates the percentage frequency distribution for a given data array, bin size, min and max values and variable.
     * @param {IEchartData[]} data - An array of objects with the structure of IEchartData.
     * @param {number} binSize - The bin size as a string which will be converted to a number.
     * @param {number} minValue - The min value of the distribution.
     * @param {number} maxValue - The max value of the distribution.
     * @param {string} variableName - The name of the variable used in the calculation.
     * @returns {IPercentageFrequency[]} frequencies - An array of objects with range and percentage value fields
     */
    getPercentageFrequencyDistribution(data: IEchartData[], binSize: number, minValue: number, maxValue: number, variableName: string): IPercentageFrequency[] {
        const frequencies: IPercentageFrequency[] = [];
        const freqLabelFractionDigits: number = 3;
        const freqPercentageFractionDigits: number = 2;

        if (minValue === undefined || maxValue === undefined || !binSize || binSize === undefined || minValue > maxValue) {
            return frequencies;
        }

        const variableId: number = this.getSelectedVariableId(variableName);

        // Calculate the frequency for each bin
        for (let i = minValue; i < maxValue; i += binSize) {
            const binStart: string = i.toFixed(freqLabelFractionDigits);
            const binEnd: string = (i + binSize).toFixed(freqLabelFractionDigits);
            const range: string = `${binStart} - ${binEnd}`;
            let frequency: number = 0;

            data.filter(item => item.id === variableId).forEach((item) => {
                item.values.forEach(([timestamp, value]) => {
                    if (value >= i && value < i + binSize) {
                        frequency++;
                    }
                });
            });
            // Convert the frequency to percentage
            const percentageValue: string = frequency > 0 ? (frequency / data[0]?.values?.length * 100).toFixed(freqPercentageFractionDigits) : '0';
            frequencies.push({ range, value: `${percentageValue}%` });
        }

        return frequencies;
    }

    /**
     * This function is used to set the width of a chart card.
     * @param {number} columnValue 
     */
    handleInnerColumnWidth(columnValue: number): void {
        const newWidth: string = this.columnWidthMapping[columnValue] || '';
        this.columnWidth.emit({ columnWidth: newWidth, id: this.chart.id });
    }

    /**
     * This method is called when the card is fully loaded
     * @param {boolean}isLoaded 
     */
    checkCardLoading(isLoaded: boolean) {
        if (isLoaded) {
            this.cardLoaded.emit(true);
        }
    }
}
