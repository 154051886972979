import { Component, OnInit, ViewEncapsulation, HostBinding, OnDestroy } from '@angular/core';
import { ManageFactoryService } from '../services/manage-factory/manage-factory.service';
import { User } from '../services/user/user.model';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AppSettingsService } from '../services/user/app-settings.service';
import { Theme } from '../theme.model';
import { Location } from '@angular/common';
import { parseNumber, formatNumber, ParsedNumber } from 'libphonenumber-js';
import { UpdatePasswordComponent } from '../dialogs/update-password/update-password.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DEFAULT_COUNTRY, appConstants } from '../services/constants';
import { CustomValidator } from '../shared/custom.validators';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DateTimeService } from '../services/date-time.service';
import { SessionStorageService } from '../services/session-storage/session-storage.service';
import { SessionStorage } from '../shared/enums/shared.enums';
import { SharedService } from '../services/shared/shared.service';
import { ICountry } from '../shared/models/common.model';

@Component({
    selector: 'app-account-settings',
    templateUrl: './account-settings.component.html',
    styleUrls: ['./account-settings.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AccountSettingsComponent implements OnInit, OnDestroy {
    id: string = null;
    themes = Theme.themes;
    constants = appConstants;
    user: User = {} as User;
    oldTheme = '';
    dialogRef: MatDialogRef<UpdatePasswordComponent>;
    isSaved = false;
    userRole = sessionStorage.getItem('userRole');
    canEdit = false;
    countryList: ICountry[] = [];
    country: any;
    languages = [
            {key: "en", text: "English"},
            {key: "ru", text: "Русский"},
            {key: "es", text: "Español"},
        ];
    date_now = new Date();
    timeFormatList = ["hh:mm:ss a", "HH:mm:ss"];
    dateFormatList = ["MM/dd/yyyy", "dd-MM-yyyy", "yyyy-MM-dd", "yyyy.MM.dd"];

    local_time = new FormControl('');

    settingsForm = new FormGroup({
        first_name: new FormControl('', Validators.required),
        last_name: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, CustomValidator.email]),
        phone: new FormControl(''),
        logo: new FormControl(''),
        favicon: new FormControl(''),
        theme: new FormControl(''),
        language: new FormControl(''),
        time_format: new FormControl(''),
        date_format: new FormControl(''),
        local_time: this.local_time,
    });

    // storing selected language so that if user doesnt save new language
    // language settings can be reverted back to original settings
    initialSelectedLanguage: string = '';
    newSelectedLanguage: string = '';
    isSettingsChanged: boolean = false;
    defaultCountry: string = DEFAULT_COUNTRY;

    get first_name() {
        return this.settingsForm.get('first_name');
    }

    get last_name() {
        return this.settingsForm.get('last_name');
    }

    get email() {
        return this.settingsForm.get('email');
    }

    get phone() {
        return this.settingsForm.get('phone');
    }

    get time_format() {
        return this.settingsForm.get('time_format');
    }

    get date_format() {
        return this.settingsForm.get('date_format');
    }

    constructor(
        public overlayContainer: OverlayContainer,
        private manageFactory: ManageFactoryService,
        private location: Location,
        private appSettingsService: AppSettingsService,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private translate: TranslateService,
        private titleService: Title,
        private dateTimeService: DateTimeService,
        private sessionStorageService: SessionStorageService,
        private sharedService: SharedService
        ) { }

    @HostBinding('class') componentCssClass;
    ngOnInit() {
        this.sessionStorageService.setSessionStorageListener(SessionStorage.userId, (id: string) => this.getUserInformation(id));

        // determines if user can edit certain settings or not based on user Role
        this.canEdit = (+this.userRole == this.constants.user_read_write) ? true : false;

        // save old theme and logo so when cancelled, it will return to original theme
        this.oldTheme = sessionStorage.getItem('theme');

        // fills form with users info
        this.manageFactory.accountSettings.getSettings()
            .subscribe(settingResult => {
                this.settingsForm.patchValue(settingResult);
                // in case when account setting is directly opened
                // if old theme is not set, then it will get from API
                if (!this.oldTheme) this.oldTheme = settingResult['theme'];
            });

        // set up international phone number validation
        this.manageFactory.users.readOne({
            id: this.id
        }).subscribe(
            (userResult: User) => {
                this.settingsForm.patchValue(userResult);
                if (userResult.phone) {
                    const parsedData: Partial<ParsedNumber> = parseNumber(userResult.phone);
                    this.country = parsedData.country ?? this.defaultCountry;
                } else if (userResult.country_code) {
                    this.country = userResult.country_code;
                } else {
                    this.country = this.defaultCountry;
                }
            }
        )
        // get list of countries which are not sanctioned 
        this.countryList = this.sharedService.getCountries();
        this.initialSelectedLanguage = this.translate.currentLang || this.translate.getDefaultLang();
        this.settingsForm.get('language').setValue(this.initialSelectedLanguage);
        this.settingsForm.get('language').valueChanges.subscribe(val => {
            this.newSelectedLanguage = val;
            this.setLanguageSettings(val);
        });
    }   

    getUserInformation(id: string): void {
        this.manageFactory.users.readOne({
            id
        }).subscribe(
            (userResult: User) => {
                this.settingsForm.patchValue(userResult);
                if (userResult.phone) {
                    const parsedData: ParsedNumber | {} = parseNumber(userResult.phone);
                    this.country = (<ParsedNumber>parsedData).country ? (<ParsedNumber>parsedData).country : this.defaultCountry;
                } else {
                    this.country = this.defaultCountry;
                }
            }
        )
    }

    ngOnDestroy(): void {
        // go back to previous settings if user doesnt save the changes
        if (!this.isSettingsChanged) {
            this.setLanguageSettings(this.initialSelectedLanguage);
            this.setThemeSettings(this.oldTheme);
        }
    }

    setLanguageSettings(lang: string) {
        this.translate.use(lang);
        this.translate.get('title.account_settings').subscribe((title: string) => {
            this.titleService.setTitle(title);
        });
    }

    getLogo() {
        return this.appSettingsService.getLogoImage();
    }

    getFavicon() {
        return this.appSettingsService.getFaviconImage();
    }

    getTheme() {
        return this.appSettingsService.getTheme();
    }

    isVisible() {
        return (+this.userRole == this.constants.user_read_only);
    }

    validatePhone() {
        let phoneNum = (this.phone.value != null) ? this.phone.value.toString() : false;
        if (phoneNum) {
            let parsed = parseNumber(phoneNum, this.country);
            // @ts-ignore
            let formatted = (parsed['country'] == this.country) ? formatNumber(parsed, 'International') : false;
            if (formatted === false) {
                this.settingsForm.controls['phone'].setErrors({ 'invalid': true });
            } else {
                this.settingsForm.controls['phone'].setValue(formatted);
            }
        }
    }

    onLogoChange(event) {
        if (event.target.files && event.target.files.length > 0) {
            // check to see if file is too large
            if (event.target.files[0].size < 1024000) {
                let ext = event.target.files[0].name.split('.').pop().toUpperCase();
                // check to see if file is correct type
                if (ext === 'PNG' || ext === 'JPG' || ext === 'JPEG' || ext === 'BMP') {
                    event.data = {
                        customerId: sessionStorage.getItem('customerId'),
                        uploadType: 'logo'
                    }
                    const formData: FormData = new FormData();
                    formData.append("customerId", (sessionStorage.getItem('customerId')));
                    formData.append("uploadType", ("logo"));
                    formData.append("files", event.target.files[0]);
                    // upload file to api
                    this.manageFactory.uploads.uploadFile(formData).subscribe(
                        show => {
                            // save to api using paths created by api during upload
                            this.manageFactory.accountSettings.saveLogo({
                                customer_id: sessionStorage.getItem('customerId'),
                                path: show['finalDestinationPath'],
                                tempName: show['tempFileName'],
                                name: show['originalFileName']
                            }).subscribe(
                                res => {
                                    this.appSettingsService.setLogo(show['finalDestinationPath']);
                                    this.settingsForm['logo'] = show['finalDestinationPath'];
                                    this.translate.get("snackbar.upload_complete").subscribe((upload_complete: string) => {
                                        this.snackBar.open(upload_complete, "", {
                                            duration: 4000,
                                            horizontalPosition: 'left'
                                        });
                                    });
                                }
                            )
                        }
                    )
                } else {
                    this.translate.get("alert.error.logo_upload1").subscribe((err_str: string) => {
                        alert(err_str);
                    });
                }
            } else {
                this.translate.get("alert.error.logo_upload2").subscribe((err_str: string) => {
                    alert(err_str);
                });
            }
        }
    }

    onIconChange(event) {
        if (event.target.files && event.target.files.length > 0) {
            let ext = event.target.files[0].name.split('.').pop();
            // check to see if file has corrent type
            if (ext.toUpperCase() === "ICO") {
                // check to see if file is too large
                if (event.target.files[0].size < 1024000) {
                    event.data = {
                        customerId: sessionStorage.getItem('customerId'),
                        uploadType: 'favicon'
                    }
                    const formData: FormData = new FormData();
                    formData.append("customerId", (sessionStorage.getItem('customerId')));
                    formData.append("uploadType", ("favicon"));
                    formData.append("files", event.target.files[0]);

                    this.manageFactory.uploads.uploadFile(formData).subscribe(
                        show => {
                            // update settings with new image paths
                            this.appSettingsService.setFavicon(show['finalDestinationPath']);
                            this.settingsForm['favicon'] = show['finalDestinationPath'];
                            // save favicon to directory in api
                            this.manageFactory.accountSettings.saveFavicon({
                                customer_id: sessionStorage.getItem('customerId'),
                                path: show['finalDestinationPath'],
                                tempName: show['tempFileName'],
                                name: show['originalFileName']
                            }).subscribe(
                                res => {
                                    this.translate.get("snackbar.upload_complete").subscribe((upload_complete: string) => {
                                        this.snackBar.open(upload_complete, "", {
                                            duration: 4000,
                                            horizontalPosition: 'left'
                                        });
                                    });
                                    //insert new link attribute to index.html head for favicon change
                                    let src = this.appSettingsService.getFaviconImage() + '?=' + Math.random();
                                    let link = document.createElement('link'),
                                        oldLink = document.getElementById('favicon');
                                    link.id = 'favicon';
                                    link.rel = 'shortcut icon';
                                    link.href = src;
                                    link.setAttribute('sizes', '16x16');
                                    if (oldLink) document.head.removeChild(oldLink);
                                    document.head.appendChild(link);
                                }
                            )
                        }
                    )
                } else {
                    this.translate.get("alert.error.logo_upload2").subscribe((err_str: string) => {
                        alert(err_str);
                    });
                }
            } else {
                this.translate.get("alert.error.icon_upload1").subscribe((err_str: string) => {
                    alert(err_str);
                });
            }
        }
    }

    changeTheme() {
        const label = this.settingsForm.controls['theme'].value;
        this.setThemeSettings(label);
    }

    setThemeSettings(theme: string) {
        this.appSettingsService.setTheme(theme);
        this.overlayContainer.getContainerElement().classList.remove(sessionStorage.getItem('theme'));
        this.overlayContainer.getContainerElement().classList.add(this.appSettingsService.getTheme().toString());
        this.componentCssClass = this.appSettingsService.getTheme().toString();
        sessionStorage.setItem('theme', theme);
    }

    saveSettings() {
        this.isSaved = true;
        this.user.first_name = this.first_name.value;
        this.user.last_name = this.last_name.value;
        this.user.email = this.email.value;
        this.user.phone = this.phone.value;
        this.user.user_id = sessionStorage.getItem('userId');
        this.user.local_time = this.local_time.value;
        this.user.country_code = this.country;

        this.dateTimeService.setUtcTime(!this.local_time.value);

        if(this.time_format.value != null) {
            this.user.time_format = this.time_format.value;
            sessionStorage.setItem('time_format', this.time_format.value);
        }

        if(this.date_format.value != null) {
            this.user.date_format = this.date_format.value;
            sessionStorage.setItem('date_format', this.date_format.value);
        }
        this.manageFactory.users.update(this.user).subscribe(
            result => {
                this.setLanguageSettings(this.newSelectedLanguage || this.translate.currentLang);
                this.translate.get("account.account_updated").subscribe((account_updated: string) => {
                    this.snackBar.open(account_updated, "", {
                        duration: 4000,
                        horizontalPosition: 'left'
                    });
                });
            }, error => {
                console.log(error);
            })

        this.manageFactory.accountSettings.updateSettings(this.appSettingsService.getAccountSettings())
            .subscribe()
        sessionStorage.setItem('username', this.first_name.value);
        //the flag is used if user has pressed the save button, previous 
        //settings will be saved else settings will be reverted
        this.isSettingsChanged = true;
        const language: string = this.translate.currentLang ? this.translate.currentLang : 'en';
        localStorage.setItem('language', language);
        this.location.back();
    }

    canDeactivate() {
        if (!this.isSaved && this.settingsForm.dirty) {
            let tr_key = 'account.unsaved_changes';
            let txt = this.translate.instant(tr_key);
            if (txt == tr_key) {
                txt = 'You have unsaved changes. Do you want to continue?';
            };
            return window.confirm(txt);
        }
        return true;
    }

    openDialog() {
        this.dialogRef = this.dialog.open(UpdatePasswordComponent, {
            disableClose: true,
            panelClass: 'my-panel'
        });
    }

    cancel() {
        this.isSettingsChanged = false;
        this.location.back();
    }

    openLogoUpload() {
        document.getElementById('logoUpload').click();
    }

    openIconInput() {
        document.getElementById('iconUpload').click();
    }
}
