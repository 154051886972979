<mat-toolbar class="mat-primary">
    <h2>{{ title | translate }}</h2>
    <span class="spacer"></span>
    <button mat-icon-button (click)="handleClose()" [disabled]="disableCancelButton">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <div>
        <p>{{ 'dialog.customer_type' | translate }}:</p>
        <mat-radio-group [(ngModel)]="customerType" (change)="changeCustomerType()">
            <mat-radio-button *ngIf="customerRole <= constants.role_administrator" [value]="constants.role_oem_user">
                {{ 'dialog.oem' | translate }}
            </mat-radio-button>
            <mat-radio-button *ngIf="customerRole <= constants.role_oem_user" [value]="constants.role_dealer_user">
                {{ 'dialog.dealer' | translate }}
            </mat-radio-button>
            <mat-radio-button [value]="constants.role_client_user">
                {{ 'dialog.client' | translate }}
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div *ngIf="customerType">
        <mat-form-field>
            <mat-select [(ngModel)]="assignedCustomer" [placeholder]="'dialog.customer_name' | translate"
                ariaLabel="Customer Name">
                <mat-option *ngFor="let customer of customerList" [value]="customer">
                    {{ customer.customer_name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="applyCustomerDialogType == dialogType.Devices && customerType == constants.role_oem_user && customerRole <= constants.role_administrator">
        <div class="center-inside">
            <div class="oem-warn">{{ 'dialog.assigning_device_to_new_oem' | translate }}</div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="handleClose()" [disabled]="disableCancelButton">
        {{ 'btn.cancel' | translate }}
    </button>
    <button mat-button class="mat-primary" [disabled]="!assignedCustomer" (click)="handleApply()">
        {{ 'btn.apply' | translate }}
    </button>
</mat-dialog-actions>
