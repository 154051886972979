import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { TranslateService } from '@ngx-translate/core';

import { SymEnumValue, SymEnum } from "../model/sym_enum";

@Component({
    selector: "dialog-enum-new-prop-dialog",
    templateUrl: "./enum-new-property.component.html",
    styleUrls: ["./dialog.component.css"]
})
export class EnumNewPropertyDialog {
    constructor(
        public dialogRef: MatDialogRef<EnumNewPropertyDialog>,
        @Inject(MAT_DIALOG_DATA) public data: SymEnum,
        private translate: TranslateService
    ) {
        if (typeof data === "undefined") {
            this.data = new SymEnum({});
        }
    }

    submitByEnter() {
        this.dialogRef.close(this.data);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
