<div *ngIf="selected_enum">
    <form class="formDecoration">
        <mat-form-field class="full-width">
            <input matInput placeholder="{{'library_editor.name' | translate}}" [(ngModel)]="selected_enum.name" name="enum_name" maxlength="64">
        </mat-form-field>
        <mat-form-field class="full-width">
            <input matInput placeholder="{{'library_editor.comment' | translate}}" [(ngModel)]="selected_enum.comment" name="enum_comment" maxlength="100">
        </mat-form-field>
    </form>

    <mat-tab-group animationDuration="0ms">
        <mat-tab #valuesTab label="{{'library_editor.values' | translate}}">
            <ng-template matTabContent>
                <table mat-table [dataSource]="enumValuesDataSource" class="mat-elevation-z8 full-width" 
                       [class.ngstd-main-row]="browserInfo.is_mobile">
                    <!-- Checkbox Column -->
                    <ng-container *ngIf="!browserInfo.is_mobile" matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                          [checked]="enumSelection.hasValue() && isAllSelected()"
                                          [indeterminate]="enumSelection.hasValue() && !isAllSelected()"
                                          [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                          (change)="$event ? enumSelection.toggle(row) : null"
                                          [checked]="enumSelection.isSelected(row)"
                                          [aria-label]="checkboxLabel(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <!-- end checkbox Column -->
                    <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef> {{'library_editor.value' | translate}} </th>
                        <td mat-cell *matCellDef="let element; let i = index"> 
                            <ng-template [ngIf]="browserInfo.is_mobile" [ngIfElse]="desktopTD">            
                              <div class='ngstd-item-wrapper'>
                                  <div class="ngstd-delete-indicator">
                                      <i class="material-icons ngstd-delete-icon" *ngIf="isLeftSign()">delete_sweep</i>
                                      <span>&nbsp;</span>
                                      <i class="material-icons ngstd-delete-icon" *ngIf="isRightSign()">delete_sweep</i>
                                  </div>
                                  <div #elementRefrence class="ngstd-item-container "
                                                  (panend)="panend($event, i, elementRefrence)" 
                                                  (panmove)="panmove($event,elementRefrence)" 
                                                  [@slideLeft]="ngstdIndexNumber === i"
                                                  (@slideLeft.done)=alignComplete($event)>
                                      {{element.value1}}            
                                  </div>
                              </div>
                            </ng-template>
                            <ng-template #desktopTD>
                                  {{element.value1}}            
                            </ng-template>    
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef> {{'library_editor.name' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="comment">
                        <th mat-header-cell *matHeaderCellDef> {{'library_editor.comment' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="enumValuesDataDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: enumValuesDataDisplayedColumns;" [ngClass]="{hover_table_row: browserInfo.is_desktop}" (click)="tableEnumRowClick(row)"></tr>
                </table>
            </ng-template>
        </mat-tab>
        <mat-tab label="{{'library_editor.used_items' | translate}}">
            <ng-template matTabContent>
                <table mat-table [dataSource]="enumUsedVarsDataSource" class="mat-elevation-z8 full-width">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> {{'library_editor.name' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="enumUsedVarsDataDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: enumUsedVarsDataDisplayedColumns;"></tr>
                </table>
            </ng-template>
        </mat-tab>
        <!-- buttons tab -->
        <mat-tab disabled> 
            <ng-container *ngIf="valuesTab.isActive">
                <ng-template mat-tab-label>
                    <button *ngIf="!browserInfo.is_mobile" mat-icon-button matTooltip="{{'library_editor.delete' | translate}}" 
                            (click)="deleteBtnClick()" [disabled]="enumSelection.isEmpty()">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="{{'library_editor.add' | translate}}" (click)="addBtnClick()"><mat-icon>add</mat-icon></button>
                </ng-template>
            </ng-container>    
        </mat-tab>
    </mat-tab-group>
</div>
