import { HttpUrlEncodingCodec } from '@angular/common/http';

/**
 * Class for encoding and decoding strings using HttpUrlEncodingCodec.
 */
export class CustomEncoder extends HttpUrlEncodingCodec {
    /**
     * Encodes a key string.
     * @param {string} k - The key string to be encoded.
     * @returns {string} The encoded key string.
     */
    encodeKey(k: string): string {
        return encodeURIComponent(k);
    }

    /**
     * Encodes a value string.
     * @param {string} v - The value string to be encoded.
     * @returns {string} The encoded value string.
     */
    encodeValue(v: string): string {
        return encodeURIComponent(v);
    }

    /**
     * Decodes a key string.
     * @param {string} k - The key string to be decoded.
     * @returns {string} The decoded key string.
     */
    decodeKey(k: string): string {
        return decodeURIComponent(k);
    }

    /**
     * Decodes a value string.
     * @param {string} v - The value string to be decoded.
     * @returns {string} The decoded value string.
     */
    decodeValue(v: string): string {
        return decodeURIComponent(v);
    }
}