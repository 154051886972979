export const Theme = {
    themes: [

        {
            label: 'Default',
            color: 'indigo',
            hex: '#3f51b5'
        },

        {
            label: 'Red',
            color: 'red',
            hex: '#f44336'
        },

        {
            label: 'Pink',
            color: 'pink',
            hex: '#e91e63'
        },
        {
            label: 'Purple',
            color: 'purple',
            hex: '#9c27b0'
        },
        {
            label: 'Deep-Purple',
            color: 'deep-purple',
            hex: '#673ab7'
        },
        {
            label: 'Blue',
            color: 'blue',
            hex: '#2196f3'
        },
        {
            label: 'Light-Blue',
            color: 'light-blue',
            hex: '#03a9f4'
        },
        {
            label: 'Cyan',
            color: 'cyan',
            hex: '#00BCD4'
        },
        {
            label: 'Teal',
            color: 'teal',
            hex: '#009688'
        },
        {
            label: 'Green',
            color: 'green',
            hex: '#4CAF50'
        },
        {
            label: 'Light-Green',
            color: 'light-green',
            hex: '#8BC34A'
        },
        {
            label: 'Lime',
            color: 'lime',
            hex: '#CDDC39'
        },
        {
            label: 'Yellow',
            color: 'yellow',
            hex: '#FFEB3B'
        },
        {
            label: 'Amber',
            color: 'amber',
            hex: '#FFC107'
        },
        {
            label: 'Orange',
            color: 'orange',
            hex: '#FF9800'
        },
        {
            label: 'Deep-Orange',
            color: 'deep-orange',
            hex: '#FF5722'
        },
        {
            label: 'Brown',
            color: 'brown',
            hex: '#795548'
        },
        {
            label: 'Grey',
            color: 'grey',
            hex: '#9E9E9E'
        },
        {
            label: 'Blue-Grey',
            color: 'blue-grey',
            hex: '#607D8B'
        }
    ]
}


