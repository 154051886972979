<div id='confirm-dialog' class='mat-typography'>
    <h1><mat-icon class="icon" *ngIf="data.icon">{{ data.icon }}</mat-icon>{{ data.title }}</h1>
    <mat-dialog-content>
        {{ data.text }}
    </mat-dialog-content>
    <div id='confirm-dialog-buttons' fxLayout='row' fxLayoutAlign='end' fxLayoutGap='15px'>
        <button *ngIf="showCancelButton"  mat-stroked-button (click)="dialogRef.close()">
            {{'btn.cancel' | translate}}
        </button>
        <button mat-stroked-button class="mat-primary" (click)="confirm()" *ngIf="!this.data.isClientsFound">
            {{ data.confirm }}
        </button>
    </div>
</div>