<div
  *ngIf="isSavingProcess"
  class="spinner-wrap"
>
  <mat-spinner></mat-spinner>
</div>

<div class="scrollBelow" (window:resize)="onResize($event)">
    <div fxLayout="column">

        <div fxFlex.gt-sm="50" fxFlex="100" class="title">
            <mat-form-field>
                <input matInput type="text" placeholder="{{'rule_editor.rule_name' | translate}}" name="rule.title" #parameters_name="ngModel"
                    [disabled]="!canEdit" [(ngModel)]="rule.title" required>
                <mat-error *ngIf="!rule.title">{{'form.field_required' | translate}}</mat-error>
            </mat-form-field>
        </div>

        <form #configForm="ngForm">
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>gps_not_fixed</mat-icon><span class="tab-text" fxHide fxShow.gt-sm>{{'rule_editor.geo_fencing' | translate}}</span>
                    </ng-template>
                    <ng-template matTabContent>
                        <div leaflet
                             id="map"
                             leafletDraw
                             [leafletOptions]="options"
                             [leafletDrawOptions]="drawOptions"
                             [leafletLayers]="layers"
                             [leafletMinZoom]="minZoomRatio"
                             [leafletFitBounds]="fitBounds"
                             [leafletFitBoundsOptions]="fitBoundsOptions"
                             (leafletDrawCreated)="onDrawCreated($event)"
                             (leafletMapReady)="onMapReady($event)">
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>error_outline</mat-icon><span class="tab-text" fxHide fxShow.gt-sm>{{'rule_editor.alert_options' | translate}}</span>
                    </ng-template>
                    <ng-template matTabContent>
                        <mat-accordion class="headers-align">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="panelTitle">
                                        <span>{{'rule_editor.email_alerts' | translate}}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-toolbar>
                                    <span class="spacer"></span>
                                    <button mat-icon-button [disabled]="selectedEmail.selected.length != 1 || !canEdit"
                                        (click)="addEmail(true)">
                                        <mat-icon matTooltip="{{'rule_editor.edit_email_alert' | translate}}">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button [disabled]="selectedEmail.selected.length < 1 || !canEdit"
                                        (click)="deleteEmail()">
                                        <mat-icon matTooltip="{{'rule_editor.delete_email_alert' | translate}}">delete</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="addEmail(false) || !canEdit">
                                        <mat-icon matTooltip="{{'rule_editor.add_email_alert' | translate}}">add</mat-icon>
                                    </button>
                                </mat-toolbar>
                                <table mat-table [dataSource]="emailDatasource">

                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selectedEmail.toggle(row) : null"
                                                [checked]="selectedEmail.isSelected(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emailAddresses">
                                        <th mat-header-cell *matHeaderCellDef>{{'rule_editor.email_addresses' | translate}}</th>
                                        <td mat-cell *matCellDef="let row">{{row.email_addresses}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="email_subject">
                                        <th mat-header-cell *matHeaderCellDef>{{'rule_editor.subject' | translate}}</th>
                                        <td mat-cell *matCellDef="let row">{{row.email_subject}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="when">
                                        <th mat-header-cell *matHeaderCellDef>{{'rule_editor.when' | translate}}</th>
                                        <td mat-cell *matCellDef="let row">{{row.when}}</td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsEmail; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsEmail;"
                                        (click)="selectedEmail.toggle(row)"
                                        [ngClass]="{'selected-row': selectedEmail.isSelected(row)}"></tr>
                                </table>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="panelTitle">
                                        <span>{{'rule_editor.text_alerts' | translate}}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-toolbar>
                                    <span class="spacer"></span>
                                    <button mat-icon-button [disabled]="selectedText.selected.length != 1 || !canEdit"
                                        (click)="addText(true)">
                                        <mat-icon matTooltip="{{'rule_editor.edit_text_alert' | translate}}">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button [disabled]="selectedText.selected.length < 1 || !canEdit"
                                        (click)="deleteText()">
                                        <mat-icon matTooltip="{{'rule_editor.delete_text_alert' | translate}}">delete</mat-icon>
                                    </button>
                                    <button mat-icon-button [disabled]="!canEdit" (click)="addText(false)">
                                        <mat-icon matTooltip="{{'rule_editor.add_text_alert' | translate}}">add</mat-icon>
                                    </button>
                                </mat-toolbar>
                                <table mat-table [dataSource]="textDatasource">

                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selectedText.toggle(row) : null"
                                                [checked]="selectedText.isSelected(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="phoneNumbers">
                                        <th mat-header-cell *matHeaderCellDef>{{'rule_editor.phone_numbers' | translate}}</th>
                                        <td mat-cell *matCellDef="let row">{{row.phone_nbr}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="text_message">
                                        <th mat-header-cell *matHeaderCellDef>{{'rule_editor.message' | translate}}</th>
                                        <td mat-cell *matCellDef="let row">{{row.text_message}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="when">
                                        <th mat-header-cell *matHeaderCellDef>{{'rule_editor.when' | translate}}</th>
                                        <td mat-cell *matCellDef="let row">{{row.when}}</td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsText; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsText;"
                                        (click)="selectedText.toggle(row)"
                                        [ngClass]="{'selected-row': selectedText.isSelected(row)}"></tr>
                                </table>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="panelTitle">
                                        <span>{{'rule_editor.log_alerts' | translate}}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-toolbar>
                                    <span class="spacer"></span>
                                    <button mat-icon-button [disabled]="selectedLog.selected.length != 1 || !canEdit"
                                        (click)="addLog(true)">
                                        <mat-icon matTooltip="{{'rule_editor.rule_editor.edit_log_alert' | translate}}">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button [disabled]="selectedLog.selected.length < 1 || !canEdit"
                                        (click)="deleteLog()">
                                        <mat-icon matTooltip="{{'rule_editor.rule_editor.delete_log_alert' | translate}}">delete</mat-icon>
                                    </button>
                                    <button mat-icon-button [disabled]="!canEdit" (click)="addLog(false)">
                                        <mat-icon matTooltip="{{'rule_editor.rule_editor.add_log_alert' | translate}}">add</mat-icon>
                                    </button>
                                </mat-toolbar>
                                <table mat-table [dataSource]="logDatasource">

                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selectedLog.toggle(row) : null"
                                                [checked]="selectedLog.isSelected(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="when">
                                        <th mat-header-cell *matHeaderCellDef>{{'rule_editor.when' | translate}}</th>
                                        <td mat-cell *matCellDef="let row">{{row.when}}</td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsLog; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsLog;"
                                        (click)="selectedLog.toggle(row)"
                                        [ngClass]="{'selected-row': selectedLog.isSelected(row)}"></tr>
                                </table>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="panelTitle">
                                        <span>{{'rule_editor.mqtt_alerts' | translate}}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-toolbar>
                                    <span class="spacer"></span>
                                    <button mat-icon-button [disabled]="selectedMqtt.selected.length != 1 || !canEdit"
                                        (click)="addMqtt(true)">
                                        <mat-icon matTooltip="{{'rule_editor.edit_mqtt_alert' | translate}}">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button [disabled]="selectedMqtt.selected.length < 1 || !canEdit"
                                        (click)="deleteMqtt()">
                                        <mat-icon matTooltip="{{'rule_editor.delete_mqtt_alert' | translate}}">delete</mat-icon>
                                    </button>
                                    <button mat-icon-button [disabled]="!canEdit" (click)="addMqtt(false)">
                                        <mat-icon matTooltip="{{'rule_editor.add_mqtt_alert' | translate}}">add</mat-icon>
                                    </button>
                                </mat-toolbar>
                                <table mat-table [dataSource]="mqttDatasource">

                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selectedMqtt.toggle(row) : null"
                                                [checked]="selectedMqtt.isSelected(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="when">
                                        <th mat-header-cell *matHeaderCellDef>{{'rule_editor.when' | translate}}</th>
                                        <td mat-cell *matCellDef="let row">{{row.when}}</td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsMqtt; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsMqtt;"
                                        (click)="selectedMqtt.toggle(row)"
                                        [ngClass]="{'selected-row': selectedMqtt.isSelected(row)}"></tr>
                                </table>
                            </mat-expansion-panel>
                        </mat-accordion>

                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>device_hub</mat-icon><span class="tab-text" fxHide fxShow.gt-sm>{{'rule_editor.devices' | translate}}</span>
                    </ng-template>
                    <ng-template matTabContent>
                        <div fxLayout="column">
                            <div class="config-row" fxFlex="100" fxFlex.gt-xs="30">
                                <div fxFlex="100" fxFlex.gt-xs="30">
                                    <mat-form-field>
                                        <mat-select placeholder="{{'rule_editor.device_selection_option' | translate}}"
                                            [(ngModel)]="rule.deviceSelectOption" [disabled]="!canEdit"
                                            name="deviceSelectOption" id="deviceSelectOption"
                                            #deviceSelectOption="ngModel" required
                                            (selectionChange)="updateDeviceSelection(false)">
                                            <mat-option *ngFor="let device of deviceSelectArray"
                                                [value]="device.type_id">
                                                {{device.type_desc}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="!rule.deviceSelectOption">{{'form.field_required' | translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="rule.deviceSelectOption == 2" fxFlex="100" fxFlex.gt-xs="30">
                                    <mat-form-field>
                                        <mat-select placeholder="{{'rule_editor.oem_selection_option' | translate}}" [(ngModel)]="rule.selectedOem"
                                            [disabled]="!canEdit" name="oemSelection" id="oemSelection"
                                            #oemSelection="ngModel" required (selectionChange)="oemChange()">
                                            <mat-option *ngFor="let oem of oemArray" [value]="oem.customer_id">
                                                {{oem.customer_name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="!rule.selectedOem">{{'form.field_required' | translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="rule.deviceSelectOption == 3" fxFlex="100" fxFlex.gt-xs="30">
                                    <mat-form-field>
                                        <mat-select placeholder="{{'rule_editor.dealer_selection_option' | translate}}"
                                            [(ngModel)]="rule.selectedDealer" [disabled]="!canEdit"
                                            name="dealerSelection" id="dealerSelection" #dealerSelection="ngModel"
                                            required (selectionChange)="dealerChange()">
                                            <mat-option *ngFor="let dealer of dealerArray" [value]="dealer.customer_id">
                                                {{dealer.customer_name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="!rule.selectedDealer">{{'form.field_required' | translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <table mat-table [dataSource]="deviceDatasource" matSort matSortActive="created"
                                matSortDisableClear matSortDirection="asc">

                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="$event ? selectRowDevice(row) : null"
                                            [checked]="selectedDevices.isSelected(row)"
                                            [disabled]="rule.deviceSelectOption != 1">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="identifier">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'rule_editor.device_identifier' | translate}}</th>
                                    <td mat-cell *matCellDef="let row">{{row.identifier}}</td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'rule_editor.model_name' | translate}}</th>
                                    <td mat-cell *matCellDef="let row">{{row.name}}</td>
                                </ng-container>

                                <ng-container matColumnDef="device_type">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'rule_editor.device_type' | translate}}</th>
                                    <td mat-cell *matCellDef="let row">{{row.device_type}}</td>
                                </ng-container>

                                <ng-container matColumnDef="dealer_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'rule_editor.dealer' | translate}}</th>
                                    <td mat-cell *matCellDef="let row">{{row.dealer_name}}</td>
                                </ng-container>

                                <ng-container matColumnDef="client_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'rule_editor.client' | translate}}</th>
                                    <td mat-cell *matCellDef="let row">{{row.client_name }}</td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumnsDevice; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsDevice;"
                                    (click)="selectRowDevice(row)"
                                    [ngClass]="{'selected-row': selectedDevices.isSelected(row)}"></tr>
                            </table>
                            <mat-paginator #paginator (page)="getPageSize($event)" [length]="deviceResultsLength" (page)="pageEvent($event)"
                                [pageSize]="pageSize" [pageSizeOptions]="pageLengthOptions"></mat-paginator>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>

            <div class="save-cancel">
                <button mat-raised-button (click)="cancel()">
                    {{'btn.cancel' | translate}}
                </button>
                <button mat-raised-button class="mat-primary confirm" [disabled]="preventSave()" (click)="save()">
                    {{'btn.save' | translate}}
                </button>
            </div>
        </form>
    </div>
</div>
