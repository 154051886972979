import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  /**
 * A function to setup a listener for a specific session storage item.
 * If the item is not found, the function will continuously check for its presence every 100ms,
 * and call the provided callback with the item's value when it's found.
 * If the item is already present, the function will call the provided callback immediately with the item's value.
 *
 * @param {string} key - The key of the session storage item to listen for.
 * @param {(value: string) => void} callback - The callback function to be called with the item's value.
 */
  setSessionStorageListener(key: string, callback: (value: string) => void): void {
    if (sessionStorage.getItem(key)) {
      callback(sessionStorage.getItem(key));
    } else {
      const sessionInterval: ReturnType<typeof setInterval> = setInterval(() => {
        if (sessionStorage.getItem(key)) {
          callback(sessionStorage.getItem(key));
          clearInterval(sessionInterval);
        }
      }, 100);
    }
  }
}
