import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { share } from 'rxjs/operators';
import { IReportIds } from 'src/app/reports/models/custom-reports.model';
import { ICountRequest, ICount, IDeviceParameterDetails, IParameters, IStatistic, IStatistics, IModel } from 'src/app/statistics/interfaces/statistics.model';
import { DowntimeNotification } from "src/app/shared/models/common.model";
import { AlertDetails } from "src/app/list-view/list-view.model";

@Injectable({
    providedIn: "root"
})
export class ManageFactoryService {
    token = sessionStorage.getItem("token");
    urlBase = environment.apiUrl + "/api/v1";
    urlBaseV2 = environment.apiUrl + "/api/v2";

    constructor(private http: HttpClient) { }

    isV2Route(route: string) {
        const v2Routes = ['widgets'];
        return v2Routes.includes(route);
    }

    forgot = {
        resetToken: data => {
            return this.http.post(environment.apiUrl + "/resetToken/", data);
        },
        resetPassword: data => {
            return this.http.post(environment.apiUrl + "/resetPassword/", data);
        }
    };

    customers = {
        getMany: (options = {}) =>
            this.http.get(this.urlBaseV2 + `/customers`, options),
        getOne: data => this.http.get(this.urlBaseV2 + `/customers` + data.id)
    };

    administrators = {
        read: (options = {}) => {
            return this.http.get(
                this.urlBase + `/superUser/read-only/customer/admins`,
                options
            );
        },
        readOne: data => {
            return this.http.get(
                this.urlBase + "/superUser/read-only/customer/admin/" + data.id
            );
        },
        create: data => {
            return this.http.post(
                this.urlBase + "/superUser/read-write/customer/admin/",
                data
            );
        },
        update: data => {
            return this.http.put(
                this.urlBase +
                "/superUser/read-write/customer/admin/" +
                data.customer_id,
                data
            );
        },
        destroy: data => {
            return this.http.delete(
                this.urlBase + "/superUser/read-write/customer/admin/" +
                data.id
            );
        }
    };

    oems = {
        read: (options = {}) => {
            return this.http.get(
                this.urlBase + `/adminUser/read-only/customer/oems`,
                options
            );
        },
        readOne: data => {
            return this.http.get(
                this.urlBase + "/adminUser/read-only/customer/oem/" + data.id
            );
        },
        create: data => {
            return this.http.post(
                this.urlBase + "/adminUser/read-write/customer/oem/",
                data
            );
        },
        update: data => {
            return this.http.put(
                this.urlBase +
                "/adminUser/read-write/customer/oem/" +
                data.customer_id,
                data
            );
        },
        destroy: data => {
            return this.http.delete(
                this.urlBase + "/adminUser/read-write/customer/oem/" + data.id
            );
        }
    };

    dealers = {
        read: (options = {}) => {
            return this.http.get(
                this.urlBase + `/oemUser/read-only/customer/dealers`,
                options
            );
        },
        readOne: data => {
            return this.http.get(
                this.urlBase + "/oemUser/read-only/customer/dealer/" + data.id
            );
        },
        create: data => {
            return this.http.post(
                this.urlBase + "/oemUser/read-write/customer/dealer/",
                data
            );
        },
        update: data => {
            return this.http.put(
                this.urlBase +
                "/oemUser/read-write/customer/dealer/" +
                data.customer_id,
                data
            );
        },
        destroy: data => {
            return this.http.delete(
                this.urlBase + "/oemUser/read-write/customer/dealer/" + data.id
            );
        }
    };
    clients = {
        read: (options = {}) => {
            return this.http.get(
                this.urlBase + `/dealerUser/read-only/customer/clients`,
                options
            );
        },
        readOne: data => {
            return this.http.get(
                this.urlBase +
                "/dealerUser/read-only/customer/client/" +
                data.id
            );
        },
        readDealer: data => {
            return this.http.get(
                this.urlBase +
                "/dealerUser/read-only/customer/client/" +
                data.id +
                "/dealer"
            );
        },
        create: data => {
            return this.http.post(
                this.urlBase + "/dealerUser/read-write/customer/client/",
                data
            );
        },
        update: data => {
            return this.http.put(
                this.urlBase +
                "/dealerUser/read-write/customer/client/" +
                data.customer_id,
                data
            );
        },
        destroy: data => {
            return this.http.delete(
                this.urlBase +
                "/dealerUser/read-write/customer/client/" +
                data.id
            );
        },
        getAll: () => {
            return this.http.get(
                this.urlBase + "/dealerUser/read-only/customers"
            );
        }
    };

    accountSettings = {
        getSettings: () => {
            return this.http.get(
                this.urlBase + "/clientUser/read-only/customer/settings"
            );
        },
        updateSettings: data => {
            return this.http.put(
                this.urlBase + "/clientUser/read-write/customer/settings",
                data
            );
        },
        saveLogo: data => {
            return this.http.put(
                this.urlBase + "/clientUser/read-write/customer/logo",
                data
            );
        },
        saveFavicon: data => {
            return this.http.put(
                this.urlBase + "/clientUser/read-write/customer/favicon",
                data
            );
        }
    };

    users = {
        read: (options = {}) => {
            return this.http.get(this.urlBase + `/clientUser/read-only/users`, options);
        },
        readUserList: data => {
            return this.http.get(
                this.urlBase + "/clientUser/read-only/users/" + data.id
            );
        },
        readOne: data => {
            return this.http.get(
                this.urlBase + "/clientUser/read-only/user/" + data.id
            );
        },
        create: data => {
            return this.http.post(
                this.urlBase + "/clientUser/read-write/user/",
                data
            );
        },
        update: data => {
            return this.http.put(
                this.urlBase + "/clientUser/read-only/user/" + data.user_id,
                data
            );
        },
        destroy: data => {
            return this.http.delete(
                this.urlBase + "/clientUser/read-write/user/" + data.id
            );
        },
        readUserRoles: () => {
            return this.http.get(
                this.urlBase + "/clientUser/read-only/userRoles"
            );
        },
        updatePassword: data => {
            return this.http.put(
                this.urlBase +
                "/clientUser/read-only/user/updatePassword/" +
                data.user_id,
                data
            );
        },
        comparePasswords: data => {
            return this.http.post(
                this.urlBase + "/clientUser/read-only/user/comparePasswords",
                data
            );
        }
    };

    devices = {
        read: (options = {}) => {
            return this.http.get(this.urlBaseV2 + "/devices", options);
        },
        readOne: data => {
            return this.http.get(this.urlBaseV2 + "/devices/" + data.deviceId);
        },
        updateDashboardJSON: data => {
            return this.http.put(
                this.urlBase +
                "/dealerUser/read-write/device/updateDashboardJSON/",
                data
            );
        },
        updateClientUserDashboardJSON: data => {
            return this.http.put(
                this.urlBase +
                "/clientUser/read-write/device/updateclientUserDashboardJSON/",
                data
            );
        },
        updateStatus: data => {
            return this.http.post(
                this.urlBase + "/oemUser/read-write/device/updateStatus/",
                data
            );
        },
        updateModel: data => {
            return this.http.put(
                this.urlBase + "/dealerUser/read-write/device/updateModel/",
                data
            );
        },
        updateCustomer: data => {
            return this.http.put(
                this.urlBase + "/dealerUser/read-write/device/updateCustomer/",
                data
            );
        },
        updateIdentifier: data => {
            return this.http.put(
                this.urlBase +
                "/clientUser/read-write/device/updateIdentifier/" +
                data.deviceId,
                data
            );
        },
        updateIccid: data => {
            return this.http.put(
                this.urlBase + "/adminUser/read-write/device/updateIccid/",
                data
            );
        },
        getSimData: data => {
            return this.http.get(
                this.urlBase +
                "/clientUser/read-only/device/getSimData/" +
                data.id
            );
        },
        getDeviceTypes: () => {
            return this.http.get(
                this.urlBase + "/clientUser/read-only/device/getDeviceTypes"
            );
        },
        getDeviceRatePlans: () => {
            return this.http.get(
                this.urlBase + '/clientUser/read-only/device/getDeviceRatePlans'
            );
        },
        getDevicesWithReports: () => {
            return this.http.get(
                this.urlBase + '/clientUser/read-only/device/getDevicesWithReports'
            );
        }
    };

    reports = {
        getReports: (options = {}) => {
            return this.http.get(
                this.urlBase + `/clientUser/read-only/reports/getReports`,
                options
            );
        },
        downloadReport: (ids: string) => {
            return this.http.get(`${this.urlBase}/clientUser/read-only/reports/download/${ids}`,
                { responseType: "arraybuffer", observe: "response" }
            );
        },
        getGps: (options) => {
            return this.http.get<Observable<Object>>(
                this.urlBaseV2 + "/reports/getGps/" + options.id
            );
        },
        getDebugLog: (options) => {
            return this.http.get<Observable<Object>>(
                this.urlBaseV2 + "/reports/getDebugLog/" + options.id,
                { params: options.params }
            );
        },
        searchDebugLog: (options) => {
            return this.http.get<Observable<Object>>(
                this.urlBaseV2 + "/reports/searchDebugLog/" + options.id,
                { params: options.params }
            );
        },
        deleteReport: (data) => {
            return this.http.delete(
                this.urlBase + `/clientUser/read-write/deleteLogFile/${data.id}`
            );
        },
    };

    documents = {
        downloadDocument: data => {
            return this.http.get(
                this.urlBase +
                "/clientUser/read-only/documents/download/" +
                data.id,
                { responseType: "arraybuffer", observe: "response" }
            );
        }
    };

    dashboards = {
        // TODO: use V2 route
        read: (options = {}) => {
            return this.http.get(
                this.urlBase + `/clientUser/read-only/dashboards/getDashboards`,
                options
            );
        },
        readOne: data => {
            return this.http.get(this.urlBaseV2 + "/dashboards/" + data.id);
        },
        getDashboards: (options = {}) => {
            return this.http.get(this.urlBaseV2 + "/dashboards/", options);
        },
        getDashboard: (data) => {
            return this.http.get(this.urlBase + "/clientUser/read-only/dashboards/getDashboard/" + data.id).pipe(share());
        },
        getDashboardList: data => {
            return this.http.get(
                this.urlBase +
                "/clientUser/read-only/dashboards/getDashboardList/" +
                data.deviceId
            );
        },
        destroy: data => {
            return this.http.delete(
                this.urlBase +
                "/clientUser/read-write/dashboards/deleteDashboard/" +
                data.id
            );
        },
        updateDashboardName: data => {
            return this.http.post(
                this.urlBase +
                "/clientUser/read-write/dashboards/updateDashboardName",
                data
            );
        },
        new: (data) => {
            return this.http.post(`${this.urlBaseV2}/dashboards/`, data).pipe(share());
        },
        updateDashboard: (data) => {
            return this.http.put(
                this.urlBase +
                '/clientUser/read-write/dashboards/updateDashboard/' +
                data.dashboard_id, data).pipe(share());
        },
        getDeviceDashboard: data => {
            return this.http.get(
                this.urlBase +
                "/clientUser/read-only/dashboards/getDeviceDashboard/" +
                data.dashboardId
            );
        },
        getSystemDashboard: data => {
            return this.http.get(
                this.urlBase +
                "/clientUser/read-only/dashboards/getSystemDashboard/" +
                data.dashboardId
            );
        },
        createDeviceDashboard: data => {
            return this.http.post(
                this.urlBase +
                "/clientUser/read-write/dashboards/createDeviceDashboard",
                data
            );
        },
        updateDeviceDashboard: data => {
            return this.http.put(
                this.urlBase +
                "/clientUser/read-write/dashboards/updateDeviceDashboard",
                data
            );
        },
        createSystemDashboard: data => {
            return this.http.post(
                this.urlBase +
                "/clientUser/read-write/dashboards/createSystemDashboard",
                data
            );
        },
        updateSystemDashboard: data => {
            return this.http.put(
                this.urlBase +
                "/clientUser/read-write/dashboards/updateSystemDashboard/" +
                data.dashboard_id,
                data
            );
        }
    };

    models = {
        create: data => {
            return this.http.post(
                this.urlBase + "/dealerUser/read-write/models/",
                data
            );
        },
        update: data => {
            return this.http.put(
                this.urlBase + "/dealerUser/read-write/models/" + data.model_id,
                data
            );
        },
        read: (options = {}) => {
            return this.http.get(
                this.urlBase + `/dealerUser/read-only/models/getModels`,
                options
            );
        },
        readOne: data => {
            return this.http.get(
                this.urlBase +
                "/clientUser/read-only/models/getOneModel/" +
                data.id
            );
        },
        destroy: data => {
            return this.http.delete(
                this.urlBase + "/dealerUser/read-write/models/" + data.id
            );
        },
        getModelDash: id => {
            return this.http.get(
                this.urlBase +
                "/clientUser/read-only/models/getModelDash/" +
                id
            );
        },
        updateModelDashboardJSON: data => {
            return this.http.put(
                this.urlBase +
                "/dealerUser/read-write/models/updateModelDashboardJSON/" +
                data.model_id,
                data
            );
        },
        getSymbolFiles: data => {
            return this.http.get(
                this.urlBase +
                "/dealerUser/read-only/models/getSymbolFiles/" +
                data.id
            );
        },
        deleteSymbolFile: data => {
            return this.http.delete(
                this.urlBase +
                "/dealerUser/read-write/models/deleteSymbolFile/" +
                data.id
            );
        },
        changeActiveSymFile: data => {
            return this.http.put(
                this.urlBase +
                "/dealerUser/read-write/models/changeActiveSymFile/" +
                data.library_id,
                data
            );
        },
        getFirmwareFiles: data => {
            return this.http.get(
                this.urlBase +
                "/dealerUser/read-only/models/getFirmwareFiles/" +
                data.id
            );
        },
        deleteFirmwareFile: data => {
            return this.http.delete(
                this.urlBase +
                "/dealerUser/read-write/models/deleteFirmwareFile/" +
                data.id
            );
        },
        changeActiveFirmFile: data => {
            return this.http.put(
                this.urlBase +
                "/dealerUser/read-write/models/changeActiveFirmFile/" +
                data.id,
                data
            );
        },
        updateFirmwareFile: data => {
            return this.http.put(
                this.urlBase +
                "/dealerUser/read-write/models/updateFirmwareFile/" +
                data.firmware_id,
                data
            );
        },
        updateSymFile: data => {
            return this.http.put(
                this.urlBase +
                "/dealerUser/read-write/models/updateSymFile/" +
                data.library_id,
                data
            );
        },
        updateDocumentFile: data => {
            return this.http.put(
                this.urlBase +
                '/dealerUser/read-write/models/updateDocFile/' +
                data.doc_id,
                data
            );
        },
        getBaudRates: () => {
            return this.http.get(
                this.urlBase + "/dealerUser/read-only/models/getBaudRates/"
            );
        },
        getBusNumbers: () => {
            return this.http.get(
                this.urlBase + "/clientUser/read-only/models/getBusNumbers/"
            );
        },
        getProtocols: () => {
            return this.http.get(
                this.urlBase + "/dealerUser/read-only/models/getProtocols/"
            );
        },
        getModelTypes: () => {
            return this.http.get(this.urlBaseV2 + "/models/getModelTypes");
        },
        getDocuments: (data) => {
            return this.http.get(
                this.urlBase + `/dealerUser/read-only/models/getDocuments/${data.id}`
            );
        },
        deleteDocumentFile: (data) => {
            return this.http.delete(
                this.urlBase + `/dealerUser/read-write/models/deleteDocumentFile/${data.id}`
            );
        },
        getAssociationCount: (modelId, libraryId = null) => {
            const library: string = libraryId ? `&libraryId=` + libraryId : '';
            return this.http.get(
                this.urlBaseV2 + `/models/getModelDependedCount?modelId=` + modelId + library
            );
        }
    };

    values = {
        sendFeedback: data => this.http.post(`${this.urlBaseV2}/send-feedback`, data)
    };

    rules = {
        create: data => {
            return this.http.post(
                this.urlBase + "/dealerUser/read-write/rules/",
                data
            );
        },
        update: data => {
            return this.http.put(
                this.urlBase +
                "/dealerUser/read-write/rules/updateRules/" +
                data.nameId,
                data
            );
        },
        getShapes: data => {
            return this.http.get(
                this.urlBase + "/dealerUser/read-only/rules/getShapes/" + data
            );
        },
        read: (options = {}) => {
            return this.http.get(
                this.urlBase + `/dealerUser/read-only/rules/getRules`,
                options
            );
        },
        readOne: data => {
            return this.http.get(
                this.urlBase +
                "/dealerUser/read-only/rules/getRules/getOneRule/" +
                data.id
            );
        },
        deleteShapes: data => {
            return this.http.delete(
                this.urlBase +
                "/dealerUser/read-write/rules/deleteShapes/" +
                data.id
            );
        },
        destroy: data => {
            return this.http.delete(
                this.urlBase +
                "/dealerUser/read-write/rules/deleteRule/" +
                data.id
            );
        },
        getConfig: data => {
            return this.http.get(
                this.urlBase +
                "/dealerUser/read-only/rules/getConfig/" +
                data.id
            );
        },
        getAllDevices: data => {
            return this.http.get(
                this.urlBase +
                "/dealerUser/read-only/rules/getAllDevices/" +
                data
            );
        },
        readDeviceSelectOptions: () => {
            return this.http.get(
                this.urlBase +
                "/dealerUser/read-only/rules/readDeviceSelectOptions"
            );
        },
        getDevices: data => {
            return this.http.get(
                this.urlBase + "/dealerUser/read-only/rules/getDevices/" + data
            );
        }
    };

    alerts = {
        getValueNames: data => {
            return this.http.get(
                this.urlBase +
                "/clientUser/read-only/serviceAlerts/getValueNames/" +
                data
            );
        },
        getAllDeviceByModel: data => {
            return this.http.get(
                this.urlBase +
                "/oemUser/read-only/serviceAlerts/getAllDeviceByModel/" +
                data.modelId
            );
        },
        read: (options = {}) => {
            return this.http.get(
                this.urlBase +
                `/oemUser/read-only/serviceAlerts/getServiceAlerts`,
                options
            );
        },
        create: data => {
            return this.http.post(
                this.urlBase + "/oemUser/read-write/serviceAlerts/create/",
                data
            );
        },
        deleteSettings: data => {
            return this.http.delete(
                this.urlBase +
                "/oemUser/read-write/serviceAlerts/deleteSettings/" +
                data
            );
        },
        update: data => {
            return this.http.put(
                this.urlBase +
                "/oemUser/read-write/serviceAlerts/update/" +
                data.savedAlert,
                data
            );
        },
        getConfig: data => {
            return this.http.get(
                this.urlBase +
                "/oemUser/read-only/serviceAlerts/getConfig/" +
                data
            );
        },
        getParams: data => {
            return this.http.get(
                this.urlBase +
                "/oemUser/read-only/serviceAlerts/getParams/" +
                data
            );
        },
        getDateParams: data => {
            return this.http.get(
                this.urlBase +
                "/oemUser/read-only/serviceAlerts/getDateParams/" +
                data
            );
        },
        getDevicesByParam: data => {
            return this.http.get(
                this.urlBase +
                "/oemUser/read-only/serviceAlerts/getDevicesByParam/" +
                data
            );
        },
        destroy: data => {
            return this.http.delete(
                this.urlBase +
                "/oemUser/read-write/serviceAlerts/deleteAlert/" +
                data.id
            );
        },
        updateDeviceJson: data => {
            return this.http.post(
                this.urlBase +
                "/oemUser/read-write/serviceAlerts/updateDeviceJson",
                data
            );
        },
        updateIsActive: (data: AlertDetails) => this.http.post(`${this.urlBaseV2}/alerts/update-is-active`, data),
    };

    uploads = {
        uploadFile: data => {
            return this.http.post(
                this.urlBase + "/clientUser/read-only/saveFile",
                data
            );
        }
    };

    // Symbold library/trace file analyzer injection
    // @todo:
    // temporary, for testing purpose, replace to commented string when api will be ready
    //
    kLibraryEndPoint = this.urlBaseV2 + "/symbol_libraries/";
    libraries = {
        readOne: data => {
            return this.http.get(this.kLibraryEndPoint + data);
        },
        create: data => {
            return this.http.post(this.kLibraryEndPoint, data);
        },
        update: data => {
            return this.http.put(this.kLibraryEndPoint + data.library_id, data);
        },
        destroy: data => {
            return this.http.delete(this.kLibraryEndPoint + data);
        }
    };

    traces = {
        readLogsInfoIds: (query = '') => {
            return this.http.get(this.urlBaseV2 + `/logs${query}`);
        },
        readInfo: trace_id => {
            return this.http.get(this.urlBaseV2 + "/logs/" + trace_id);
        },
        readValues: (trace_id, variable_ids, device_id = null) => {
            const deviceId = device_id ? `&deviceId=${device_id}` : '';
            return this.http.get(
                this.urlBaseV2 +
                `/logs/${trace_id}/values?ids=` +
                variable_ids.join(",") + deviceId
            );
        },
        readDeviceValues: (query) => {
            return this.http.get(
                this.urlBaseV2 + `/logs/device/values?` + query
            );
        },
        readReportIds: (query: HttpParams) => this.http.get<IReportIds>(`${this.urlBaseV2}/logs/device/logFilesList?${query}`),
        downloadConnectionLogs: (device_id) => {
            return this.http.get(
                this.urlBaseV2 + `/logs/connection/${device_id}`
            );
        }
    };

    // Symbold library injection
    invoices = {
        destroy: data => {
            return this.http.delete(this.urlBaseV2 + "/invoices/" + data.id);
        },
        read: (query = '') => {
            return this.http.get(this.urlBaseV2 + `/invoices${query}`);
        },
        getStatuses: () => {
            return this.http.get(this.urlBaseV2 + "/invoices/statuses/");
        },
        readOne: data => {
            return this.http.get(this.urlBaseV2 + "/invoices/" + data.id);
        },
        create: data => {
            const headers = new HttpHeaders({
                "Content-Type": "application/json; charset=utf-8"
            });
            return this.http.post(this.urlBaseV2 + "/invoices/", data, {
                headers
            });
        },
        update: (id, data) => {
            const headers = new HttpHeaders({
                "Content-Type": "application/json; charset=utf-8"
            });
            return this.http.put(this.urlBaseV2 + "/invoices/" + id, data, {
                headers
            });
        },
        getPayPeriods: () => {
            return this.http.get(this.urlBaseV2 + "/invoices/");
        }
    };

    custom_reports = {
        create: data => {
            return this.http.post(this.urlBaseV2 + "/custom_reports/", data);
        },

        update: data => {
            const { body, id } = data;
            const headers = new HttpHeaders({
                "Content-Type": "application/multipart/form-data; charset=utf-8"
            });
            return this.http.put(this.urlBaseV2 + `/custom_reports/${id}`, body);
        },
        read: (options = {}) => {
            return this.http.get(this.urlBaseV2 + `/custom_reports`, options);
        },
        readOne: data => {
            return this.http.get(this.urlBaseV2 + "/custom_reports/" + data.id);
        },
        destroy: data => {
            return this.http.delete(this.urlBaseV2 + "/custom_reports/" + data.id);
        },
    };

    widgets = {
        getMany: (params = new HttpParams()) => {
            return this.http.get(this.urlBaseV2 + '/widgets', { params });
        },
        getOne: (id) => {
            return this.http.get(this.urlBaseV2 + '/widgets/' + id);
        },
        create: (data) => {
            return this.http.post(this.urlBaseV2 + '/widgets', data);
        },
        update: (id, data) => {
            return this.http.patch(this.urlBaseV2 + '/widgets/' + id, data);
        },
        delete: (params = new HttpParams()) => {
            return this.http.delete(this.urlBaseV2 + '/widgets', { params });
        },
        getScript: (id) => {
            return this.http.get(this.urlBaseV2 + '/widgets/' + id + '/script',
                { responseType: 'text' });
        },
        uploadScript: (id, scriptContent) => {
            let formData = new FormData();
            formData.append('files', new File([scriptContent], 'widget.js'));
            return this.http.post(this.urlBaseV2 + '/widgets/' + id + '/script', formData);
        }
    };

    versions = {
        history: () => {
            return this.http.get(this.urlBaseV2 + "/versions/history");
        },
    };

    home = {
        get: () => {
            return this.http.get(this.urlBaseV2 + "/home");
        },
        update: data => {
            return this.http.put(this.urlBaseV2 + "/home", data);
        },
        data: () => {
            return this.http.get(this.urlBaseV2 + "/home/data");
        },
    };

    statistics = {
        read: (id: number) => this.http.get<IStatistic>(`${this.urlBaseV2}/statistics/${id}`),
        readAll: (options: { params: HttpParams }) => this.http.get<IStatistic[]>(`${this.urlBaseV2}/statistics`, options),
        delete: (id: number) => this.http.delete(`${this.urlBaseV2}/statistics/${id}`),
        create: (data: IStatistic) => this.http.post(`${this.urlBaseV2}/statistics`, data),
        update: (data: IStatistic) => this.http.put(`${this.urlBaseV2}/statistics/${data.id}`, data),
        getParameters: () => this.http.get<IParameters[]>(`${this.urlBaseV2}/statistics/parameters`),
        getTemplates: () => this.http.get<IStatistic[]>(`${this.urlBaseV2}/statistics/templates`),
        generate: (query: HttpParams) => this.http.get<IStatistics[]>(`${this.urlBaseV2}/statistics/generateStatistic?${query}`),
        getDeviceData: (query: HttpParams) => this.http.get<IDeviceParameterDetails>(`${this.urlBaseV2}/statistics/getParametersData?${query}`),
        generateCount: (data: ICountRequest) => this.http.post<ICount[]>(`${this.urlBaseV2}/statistics/getCount`, data),
        getModels: (query: HttpParams) => this.http.get<IModel[]>(`${this.urlBaseV2}/statistics/models?${query}`)
    };

    downtime = {
        generate: (params: DowntimeNotification) => this.http.post(`${this.urlBaseV2}/notifications/notify-downtime`, params)
    };
}
