<div class="scrollBelow" (window:resize)="onResize($event)">
    <div fxLayout="column">

        <div fxFlex.gt-sm="50" fxFlex="100" class="title">
            <mat-form-field>
                <input matInput type="text" placeholder="{{'alert_editor.alert_name' | translate}}" name="alert.parameters_name"
                    #parameters_name="ngModel" [disabled]="!canEdit" [(ngModel)]="alert.parameters_name" required>
                <mat-error *ngIf="!alert.parameters_name">{{'form.field_required' | translate}}</mat-error>
            </mat-form-field>
        </div>

        <form #configForm="ngForm">
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>settings_applications</mat-icon><span class="tab-text" fxHide
                            fxShow.gt-sm>{{'alert_editor.tab_config' | translate}}</span>
                    </ng-template>
                    <ng-template matTabContent>
                        <div fxLayout="column">
                            <div>
                                <div class="float-right">
                                    <button mat-icon-button [disabled]="!canAddParam()"
                                        (click)="addParam()">
                                        <mat-icon matTooltip="{{'alert_editor.btn.add_param' | translate}}">add_circle</mat-icon>
                                    </button>
                                    <button mat-icon-button [disabled]="!canAddDateParam()"
                                        (click)="addDateParam()">
                                        <mat-icon matTooltip="{{'alert_editor.btn.add_date_param' | translate}}">date_range</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="config-row" fxLayout="column" fxLayout.gt-xs="row">
                                <div>
                                    <mat-form-field>
                                        <mat-select placeholder="{{'alert_editor.list_of_models' | translate}}"
                                            (ngModelChange)="confirmModelChange($event)" [(ngModel)]="alert.model_id"
                                            [disabled]="!canEdit" name="model_id" id="model_id" #model_id="ngModel">
                                            <mat-option *ngFor="let model of modelArray" [value]="model.model_id">
                                                {{model.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="!alert.model_id">{{'form.field_required' | translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field>
                                        <input matInput type="text" placeholder="{{'alert_editor.alert_frequency' | translate}}"
                                            name="alert_frequency" #alert_frequency="ngModel"
                                            [(ngModel)]="alert.alert_frequency" [disabled]="!canEdit" required
                                            id="alert_frequency">
                                        <mat-error *ngIf="!alert.alert_frequency">{{'form.field_required' | translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div>
                                <div class="card-array" *ngFor="let param of paramArray; let i = index">
                                    <mat-card>
                                        <mat-card-content>
                                            <div fxLayout="column" fxLayout.gt-xs="row">
                                                <div fxLayout="column" fxLayout.gt-sm="row" fxFlex="100"
                                                    fxFlex.gt-xs="90" fxFlex.gt-md="95">
                                                    <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="30">
                                                        <mat-form-field>
                                                            <mat-select placeholder="{{'alert_editor.message_name' | translate}}"
                                                                [(ngModel)]="param.message_id" [disabled]="!canEdit"
                                                                id="message_id{{i}}" name="message_id{{i}}" #message_id="ngModel" required>
                                                                <mat-optgroup *ngFor="let library of libraryArray" [label]="library.bus_number_type"
                                                                    [disabled]="library.disabled">
                                                                    <mat-option (click)="messageChange(message, param, value_id)" *ngFor="let message of library.messages" [value]="message.message_id">
                                                                        {{ message.descriptor }}
                                                                    </mat-option>
                                                                </mat-optgroup>
                                                            </mat-select>
                                                            <mat-error *ngIf="!param.descriptor && message_id.touched">{{'form.field_required' | translate}}</mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="30">
                                                        <mat-form-field>
                                                            <mat-select placeholder="{{'alert_editor.variable_name' | translate}}"
                                                                [(ngModel)]="param.value_id" [disabled]="!canEdit"
                                                                (ngModelChange)="valueChange(param)" id="value_id{{i}}"
                                                                name="value_id{{i}}" #value_id="ngModel" required>
                                                                <mat-option *ngFor="let value of param.valueArray"
                                                                    [value]="value.message_value_id">
                                                                    {{value.value_name}}
                                                                </mat-option>
                                                            </mat-select>
                                                            <mat-error *ngIf="!param.value_name && value_id.touched">{{'form.field_required' | translate}}</mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div fxLayout="column" fxLayout.gt-xs="row" fxFlex="100"
                                                        fxFlex.gt-xs="50" fxFlex.gt-sm="35">
                                                        <div fxFlex="100" fxFlex.gt-xs="50">
                                                            <mat-form-field>
                                                                <mat-select placeholder="{{'alert_editor.operator' | translate}}"
                                                                    [(ngModel)]="param.operator" [disabled]="!canEdit"
                                                                    name="operator{{i}}" #operator="ngModel" required>
                                                                    <mat-option *ngFor="let op of operatorArray"
                                                                        [value]="op.value">
                                                                        {{op.text | translate}}
                                                                    </mat-option>
                                                                </mat-select>
                                                                <mat-error *ngIf="!param.operator">{{'form.field_required' | translate}}</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                        <div fxFlex="100" fxFlex.gt-xs="50">
                                                            <mat-form-field>
                                                                <input matInput type="text" placeholder="{{'alert_editor.parameter' | translate}}"
                                                                    name="parameter{{i}}" #parameter="ngModel"
                                                                    [(ngModel)]="param.parameter" [disabled]="!canEdit"
                                                                    required>
                                                                <span matSuffix>{{param.value_uom}}</span>
                                                                <mat-error *ngIf="!param.parameter">{{'form.field_required' | translate}}</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                    <div fxLayout="column" fxLayout.gt-xs="row" fxFlex="100"
                                                        fxFlex.gt-xs="50" fxFlex.gt-sm="35">
                                                        <div fxFlex="100" fxFlex.gt-xs="50">
                                                            <mat-form-field>
                                                                <mat-select placeholder="{{'alert_editor.statistic' | translate}}"
                                                                    [(ngModel)]="param.statistic" [disabled]="!canEdit"
                                                                    name="statistic{{i}}" #statistic="ngModel">
                                                                    <mat-option *ngFor="let stat of filteredStatistics()" [value]="stat">
                                                                        {{stat | translate}}
                                                                    </mat-option>
                                                                </mat-select>
                                                                <mat-error *ngIf="!param.statistic">{{'form.field_required' | translate}}</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                        <div fxFlex="100" fxFlex.gt-xs="50">
                                                            <mat-form-field>
                                                                <input matInput type="text"
                                                                    placeholder="{{'alert_editor.period' | translate}}" name="period{{i}}"
                                                                    #period="ngModel" [(ngModel)]="param.period"
                                                                    [disabled]="!canEdit" required>
                                                                <mat-error *ngIf="!param.period">{{'form.field_required' | translate}}
                                                                </mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div fxFlex="100" fxFlex.gt-xs="10" fxFlex.gt-md="5"
                                                    class="delete-button">
                                                    <button mat-icon-button [disabled]="!canEdit"
                                                        (click)="deleteParam(param)">
                                                        <mat-icon matTooltip="{{'alert_editor.btn.delete_param' | translate}}">delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="card-array" *ngFor="let dateParam of dateParamArray; let j = index">
                                    <mat-card>
                                        <mat-card-content>
                                            <div fxLayout="column" fxLayout.gt-xs="row">
                                                <div fxLayout="column" fxLayout.gt-sm="row" fxFlex="100"
                                                    fxFlex.gt-xs="90" fxFlex.gt-md="95">
                                                    <div fxFlex="100" fxFlex.gt-sm="50">
                                                        <mat-form-field>
                                                            <mat-select placeholder="{{'alert_editor.days' | translate}}"
                                                                [(ngModel)]="dateParam.dayList" [disabled]="!canEdit"
                                                                multiple name="dayList{{j}}" #dayList="ngModel"
                                                                required>
                                                                <mat-option *ngFor="let day of daysOfWeek"
                                                                    [value]="day.value">{{day.name | translate}}</mat-option>
                                                                <mat-error *ngIf="error">{{'form.field_required' | translate}}
                                                                </mat-error>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div fxLayout="column" fxLayout.gt-xs="row" fxFlex="100"
                                                        fxFlex.gt-sm="50">
                                                        <div fxFlex="100" fxFlex.gt-xs="50">
                                                            <div class="timeInput">
                                                                <span class="label">{{'alert_editor.btn.start_time' | translate}}</span>
                                                                <input type="time" 
                                                                    [(ngModel)]="dateParam.startTime"
                                                                    [disabled]="!canEdit" name="start_time{{j}}"
                                                                    #start_time="ngModel" required>
                                                                <mat-error *ngIf="error">{{'form.field_required' | translate}}</mat-error>
                                                            </div>
                                                        </div>
                                                        <div fxFlex="100" fxFlex.gt-xs="50">
                                                            <div class="timeInput">
                                                                <span class="label">{{'alert_editor.btn.end_time' | translate}}</span>
                                                                <input type="time"
                                                                    [(ngModel)]="dateParam.endTime"
                                                                    [disabled]="!canEdit" name="end_time{{j}}"
                                                                    #end_time="ngModel" required>
                                                                <mat-error *ngIf="error">{{'form.field_required' | translate}}
                                                                </mat-error>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div fxFlex="100" fxFlex.gt-xs="10" fxFlex.gt-md="5"
                                                    class="delete-button">
                                                    <button mat-icon-button [disabled]="!canEdit"
                                                        (click)="deleteDateParam(dateParam)">
                                                        <mat-icon matTooltip="{{'alert_editor.btn.delete_date_param' | translate}}">delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>error_outline</mat-icon><span class="tab-text" fxHide fxShow.gt-sm>{{'alert_editor.alert_options' | translate}}</span>
                    </ng-template>
                    <ng-template matTabContent>
                        <mat-accordion class="headers-align">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="panelTitle">
                                        <span>{{'alert_editor.email_alerts' | translate}}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-toolbar>
                                    <span class="spacer"></span>
                                    <button mat-icon-button [disabled]="selectedEmail.selected.length != 1 || !canEdit"
                                        (click)="addEmail(true)">
                                        <mat-icon matTooltip="{{'alert_editor.btn.edit_email_alert' | translate}}">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button [disabled]="selectedEmail.selected.length < 1 || !canEdit"
                                        (click)="deleteEmail()">
                                        <mat-icon matTooltip="{{'alert_editor.btn.delete_email_alert' | translate}}">delete</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="addEmail(false) || !canEdit">
                                        <mat-icon matTooltip="{{'alert_editor.btn.add_email_alert' | translate}}">add</mat-icon>
                                    </button>
                                </mat-toolbar>
                                <table mat-table [dataSource]="emailDatasource">

                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selectedEmail.toggle(row) : null"
                                                [checked]="selectedEmail.isSelected(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emailAddresses">
                                        <th mat-header-cell *matHeaderCellDef>{{'alert_editor.email_addresses' | translate}}</th>
                                        <td mat-cell *matCellDef="let row">{{row.email_addresses}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="email_subject">
                                        <th mat-header-cell *matHeaderCellDef>{{'alert_editor.subject' | translate}}</th>
                                        <td mat-cell *matCellDef="let row">{{row.email_subject}}</td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsEmail; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsEmail;"
                                        (click)="selectedEmail.toggle(row)"
                                        [ngClass]="{'selected-row': selectedEmail.isSelected(row)}"></tr>
                                </table>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="panelTitle">
                                        <span>{{'alert_editor.text_alerts' | translate}}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-toolbar>
                                    <span class="spacer"></span>
                                    <button mat-icon-button [disabled]="selectedText.selected.length != 1 || !canEdit"
                                        (click)="addText(true)">
                                        <mat-icon matTooltip="{{'alert_editor.btn.edit_text_alert' | translate}}">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button [disabled]="selectedText.selected.length < 1 || !canEdit"
                                        (click)="deleteText()">
                                        <mat-icon matTooltip="{{'alert_editor.btn.delete_text_alert' | translate}}">delete</mat-icon>
                                    </button>
                                    <button mat-icon-button [disabled]="!canEdit" (click)="addText(false)">
                                        <mat-icon matTooltip="{{'alert_editor.btn.add_text_alert' | translate}}">add</mat-icon>
                                    </button>
                                </mat-toolbar>
                                <table mat-table [dataSource]="textDatasource">

                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selectedText.toggle(row) : null"
                                                [checked]="selectedText.isSelected(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="phoneNumbers">
                                        <th mat-header-cell *matHeaderCellDef>{{'alert_editor.phone_numbers' | translate}}</th>
                                        <td mat-cell *matCellDef="let row">{{row.phone_nbr}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="text_message">
                                        <th mat-header-cell *matHeaderCellDef>{{'alert_editor.message' | translate}}</th>
                                        <td mat-cell *matCellDef="let row">{{row.text_message}}</td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsText; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsText;"
                                        (click)="selectedText.toggle(row)"
                                        [ngClass]="{'selected-row': selectedText.isSelected(row)}"></tr>
                                </table>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="panelTitle">
                                        <span>{{'alert_editor.log_alerts' | translate}}</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-slide-toggle class="mat-primary" [disabled]="!canEdit"
                                    [checked]="alertArray.log.length > 0" (change)="toggleLog()">
                                    <span *ngIf="alertArray.log.length > 0">{{'alert_editor.alerts_will_be_logged' | translate}}</span>
                                    <span *ngIf="alertArray.log.length < 1">{{'alert_editor.alerts_will_not_be_logged' | translate}}</span>
                                </mat-slide-toggle>
                            </mat-expansion-panel>
                        </mat-accordion>

                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon>device_hub</mat-icon><span class="tab-text" fxHide fxShow.gt-sm>{{'alert_editor.devices' | translate}}</span>
                    </ng-template>
                    <ng-template matTabContent>
                        <div fxLayout="column">
                            <div class="config-row" fxFlex="100" fxFlex.gt-xs="30">
                                <div>
                                    <mat-form-field>
                                        <mat-select placeholder="{{'alert_editor.device_selection_option' | translate}}"
                                            [(ngModel)]="alert.device_select_type" [disabled]="!canEdit"
                                            name="device_select_type" id="device_select_type"
                                            #device_select_type="ngModel" required
                                            (selectionChange)="updateDeviceSelection()">
                                            <mat-option *ngFor="let device of deviceSelectArray"
                                                [value]="device.type_id">
                                                {{device.type_desc | translate}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="!alert.device_select_type">{{'form.field_required' | translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <table mat-table [dataSource]="deviceDatasource" matSort matSortActive="created"
                                matSortDisableClear matSortDirection="asc">

                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="$event ? selectRowDevice(row) : null"
                                            [checked]="selectedDevices.isSelected(row)"
                                            [disabled]="alert.device_select_type != 1">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="identifier">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'alert_editor.device_id' | translate}}</th>
                                    <td mat-cell *matCellDef="let row">{{row.identifier}}</td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'alert_editor.model_name' | translate}}</th>
                                    <td mat-cell *matCellDef="let row">{{row.name}}</td>
                                </ng-container>

                                <ng-container matColumnDef="device_type">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'alert_editor.device_type' | translate}}</th>
                                    <td mat-cell *matCellDef="let row">{{row.device_type}}</td>
                                </ng-container>

                                <ng-container matColumnDef="dealer_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'alert_editor.dealer' | translate}}</th>
                                    <td mat-cell *matCellDef="let row">{{row.dealer_name}}</td>
                                </ng-container>

                                <ng-container matColumnDef="client_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'alert_editor.client' | translate}}</th>
                                    <td mat-cell *matCellDef="let row">{{row.client_name}}</td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumnsDevice; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsDevice;"
                                    (click)="selectRowDevice(row)"
                                    [ngClass]="{'selected-row': selectedDevices.isSelected(row)}"></tr>
                            </table>
                            <mat-paginator #paginator (page)="getPageSize($event)" [pageSize]="pageSize" [length]="deviceResultsLength"
                                [pageSizeOptions]="pageLengthOptions"></mat-paginator>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>

            <div class="save-cancel">
                <button mat-raised-button (click)="goBack()">
                    {{'btn.cancel' | translate}}
                </button>
                <button mat-raised-button class="mat-primary confirm"
                    [disabled]="!alert.parameters_name || configForm.invalid || (alertArray.email.length < 1 && alertArray.text.length < 1 && alertArray.log.length < 1) || selectedDevices.selected.length == 0 || paramArray.length == 0 || !canEdit"
                    (click)="prepareToSave()">
                    {{'btn.save' | translate}}
                </button>
            </div>
        </form>
    </div>
</div>
