import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { ManageFactoryService } from 'src/app/services/manage-factory/manage-factory.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { IDashboardName } from 'src/app/shared/models/common.model';
import { PreviousUrl } from 'src/app/dashboard/models/common.model';

@Component({
    selector: 'app-device-dashboard-dialog',
    templateUrl: './device-dashboard-dialog.component.html',
    styleUrls: ['./device-dashboard-dialog.component.css']
})
export class DeviceDashboardDialogComponent implements OnInit {
    dashboardArray: IDashboardName[];
    newTab = new FormControl();

    form = new FormGroup({
        dashboardId: new FormControl('', Validators.required),
        oldDashboard: new FormControl(),
        newTab: this.newTab
    });

    get id() {
        return this.form.get('dashboardId');
    }

    get oldDashboard() {
        return this.form.get('oldDashboard');
    }

  constructor(
    private manageFactory: ManageFactoryService,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

    ngOnInit() {

      if (this.data.dashboardArray) {
        this.initResult(this.data.dashboardArray);
      } else {
        this.manageFactory.dashboards.getDashboardList({
          deviceId: this.data['deviceId']
        }).subscribe((result: IDashboardName[]) => {
          this.initResult(result);
        });
      }
    }

  /**
   * This method is used to create new dashboard against a device
   */
  create() {
    const resObj = {
      createNew: true,
      dashboardId: null,
      newTab: this.newTab.value
    };
    sessionStorage.setItem('deviceId', this.data.deviceId);
    if (this.newTab.value) {
      localStorage.setItem('previous_url', PreviousUrl.NEW_TAB);
    }
    else {
      localStorage.setItem('previous_url', PreviousUrl.DEVICES);
    }
    this.dialogRef.close(resObj);
  }

    /**
     * This method is used to go to an existing board
     */
    confirm() {
        const resObj = {
          createNew: false,
          dashboardId: this.id.value,
          oldDashboard: this.oldDashboard.value,
          newTab: this.newTab.value
        };
        this.dialogRef.close(resObj);
    }

  initResult(dashboardArry: IDashboardName[]) {
    this.dashboardArray = [...new Map(dashboardArry.map(item => [item.dashboard_id, item])).values()];

    // Select the first one in the list
    if (this.dashboardArray.length > 0) {
      this.form.patchValue({ 'dashboardId': this.dashboardArray[0].dashboard_id });
    }
  }
}
