import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
    showCancelButton: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService) {
        if (data['titleTerm']) {
            this.translate.get(data['titleTerm']).subscribe((translated: string) => {
                this.data.title = translated;
            });
        }
        if (data['textTerm']) {
            this.translate.get(data['textTerm']).subscribe((translated: string) => {
                this.data.text = translated;
            });
        }
        if (data['confirmTerm']) {
            this.translate.get(data['confirmTerm']).subscribe((translated: string) => {
                this.data.confirm = translated;
            });
        }
        if (data['hideConfirmButton']) {
            this.showCancelButton = false;
        }
    }

    confirm() {
        this.dialogRef.close(true);
    }
}
