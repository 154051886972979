import { AboutComponent } from "./about/about.component";
import { AccountSettingsComponent } from "./account-settings/account-settings.component";
import { AdministratorEditorComponent } from "./administrator-editor/administrator-editor.component";
import { AdministratorsComponent } from "./administrators/administrators.component";
import { AlertEditorComponent } from "./alert-editor/alert-editor.component";
import { AlertsComponent } from "./alerts/alerts.component";
import { BadRouteComponent, BadRouteKind } from "./bad-route/bad-route.component";
import { ClientEditorComponent } from "./client-editor/client-editor.component";
import { ClientsComponent } from "./clients/clients.component";
import { DashboardsComponent } from "./dashboards/dashboards.component";
import { DealerEditorComponent } from "./dealer-editor/dealer-editor.component";
import { DealersComponent } from "./dealers/dealers.component";
import { DebugLogViewerComponent } from "./log-chart/containers/debug-log-viewer/debug-log-viewer.component";
import { DevicesComponent } from "./devices/devices.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { HelpComponent } from "./help/help.component";
import { InvoicesComponent } from "./invoices/invoices.component";
import { LibraryEditorComponent, LibraryEditorModule } from "./library-editor/library-editor.module";
import { LiveMapComponent } from "./live-map/live-map.component";
import { LogChartModule } from "./log-chart/containers/log-chart/log-chart.module";
import { LoginComponent } from "./login/login.component";
import { ModelsComponent } from "./models/models.component";
import { NgModule } from "@angular/core";
import { OemEditorComponent } from "./oem-editor/oem-editor.component";
import { OemsComponent } from "./oems/oems.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ResetPasswordSuccessComponent } from "./reset-password-success/reset-password-success.component";
import { RootComponent } from "./root/root.component";
import { RouterIcon } from "./root/model/root.model";
import { RouterModule, Routes } from "@angular/router";
import { RuleEditorComponent } from "./rule-editor/rule-editor.component";
import { RulesComponent } from "./rules/rules.component";
import { UserComponent } from "./user/user.component";
import { UsersComponent } from "./users/users.component";
import { CustomWidgetsComponent } from './custom-widgets/custom-widgets.component';
import { CustomWidgetEditorComponent } from './custom-widget-editor/custom-widget-editor.component';
import { GenerateServiceDowntimeComponent } from './generate-service-downtime/generate-service-downtime.component';
import { ServiceDowntimeComponent } from './service-downtime/service-downtime.component';

const routes: Routes = [
    { path: "forgotPassword", component: ForgotPasswordComponent },
    { path: "login", component: LoginComponent },
    { path: "resetPassword", component: ResetPasswordComponent },
    { path: "resetPassword/:token", component: ResetPasswordComponent },
    { path: "resetPasswordSuccess", component: ResetPasswordSuccessComponent },
    { path: "not-found", component: BadRouteComponent, data: { kind: BadRouteKind.NotFound } },
    { path: "not-auth", component: BadRouteComponent, data: { kind: BadRouteKind.NotAuth } },
    { path: "service-downtime", component: ServiceDowntimeComponent },
    {
        path: '',
        component: RootComponent,
        children: [
            { path: "", redirectTo: "home", pathMatch: "full" },
            {
                path: "accountSettings",
                component: AccountSettingsComponent,
                data: { title: "title.account_settings", icon: RouterIcon.ACCOUNT_SETTINGS }
            },
            {
                path: 'home',
                loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
                data: { title: 'title.home', icon: RouterIcon.HOME, canSearch: false }
            },
            {
                path: "administrators",
                component: AdministratorsComponent,
                data: { title: "title.administrators", icon: RouterIcon.ADMINISTRATORS }
            },
            {
                path: "administrator/:id",
                component: AdministratorEditorComponent,
                data: { title: "title.edit_administrator", icon: RouterIcon.ADMINISTRATORS, canSearch: false }
            },
            {
                path: "administrator",
                component: AdministratorEditorComponent,
                data: { title: "title.new_administrator", icon: RouterIcon.ADMINISTRATORS, canSearch: false }
            },
            {
                path: "oems",
                component: OemsComponent,
                data: { title: "title.oems", icon: RouterIcon.OEMS }
            },
            {
                path: "oem/:id",
                component: OemEditorComponent,
                data: { title: "title.edit_oem", icon: RouterIcon.OEMS, canSearch: false }
            },
            {
                path: "oem",
                component: OemEditorComponent,
                data: { title: "title.new_oem", icon: RouterIcon.OEMS, canSearch: false }
            },
            {
                path: "dealers",
                component: DealersComponent,
                data: { title: "title.dealers", icon: RouterIcon.DEALERS }
            },
            {
                path: "dealer/:id",
                component: DealerEditorComponent,
                data: { title: "title.edit_dealer", icon: RouterIcon.DEALERS, canSearch: false }
            },
            {
                path: "dealer",
                component: DealerEditorComponent,
                data: { title: "title.new_dealer", icon: RouterIcon.DEALERS, canSearch: false }
            },
            {
                path: "clients",
                component: ClientsComponent,
                data: { title: "title.clients", icon: RouterIcon.CLIENTS }
            },
            {
                path: "client/:id",
                component: ClientEditorComponent,
                data: { title: "title.edit_client", icon: RouterIcon.CLIENTS, canSearch: false }
            },
            {
                path: "client",
                component: ClientEditorComponent,
                data: { title: "title.new_client", icon: RouterIcon.CLIENTS, canSearch: false }
            },
            {
                path: "users",
                component: UsersComponent,
                data: { title: "title.users", icon: RouterIcon.USERS }
            },
            {
                path: "user/:id",
                component: UserComponent,
                data: { title: "title.edit_user", icon: RouterIcon.USERS, canSearch: false }
            },
            {
                path: "user",
                component: UserComponent,
                data: { title: "title.new_user", icon: RouterIcon.USERS, canSearch: false }
            },
            {
                path: "devices",
                component: DevicesComponent,
                data: { title: "title.devices", icon: RouterIcon.DEVICES }
            },
            // new synthax lazy loading in angular 8 https://angular.io/guide/lazy-loading-ngmodules
            {
                path: 'dashboard',
                runGuardsAndResolvers: 'always',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
                data: { title: 'Dashboard', type: 'dashboard', icon: RouterIcon.DASHBOARDS, canSearch: false }
            },
            {
                path: 'modelDashboard',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
                data: { title: 'Model Dashboard', type: 'model', icon: RouterIcon.DASHBOARDS }
            },
            {
                path: "dashboards",
                component: DashboardsComponent,
                data: { title: "title.dashboards", icon: RouterIcon.DASHBOARDS }
            },
            {
                path: "widgets",
                component: CustomWidgetsComponent,
                data: { title: "title.custom_widgets", icon: RouterIcon.CUSTOM_WIDGETS }
            },
            {
                path: "widget/:id",
                component: CustomWidgetEditorComponent,
                data: { title: "title.custom_widget", icon: RouterIcon.CUSTOM_WIDGETS, canSearch: false }
            },
            {
                path: "models",
                component: ModelsComponent,
                data: { title: "title.models", icon: RouterIcon.MODELS }
            },
            {
                path: 'model/:id',
                loadChildren: () => import('./model-editor/model-editor-root.module')
                    .then(m => m.ModelEditorRootModule),
                data: { title: 'title.edit_model', icon: RouterIcon.MODELS, canSearch: false }
            },
            {
                path: 'model',
                loadChildren: () => import('./model-editor/model-editor-root.module')
                    .then(m => m.ModelEditorRootModule),
                data: { title: 'title.new_model', icon: RouterIcon.MODELS, canSearch: false }
            },
            {
                path: "rules",
                component: RulesComponent,
                data: { title: "title.geo_fencing_rules", icon: RouterIcon.GEO_FENCING }
            },
            {
                path: "rule/:id",
                component: RuleEditorComponent,
                data: { title: "title.edit_geo_fencing_rule", icon: RouterIcon.GEO_FENCING, canSearch: false }
            },
            {
                path: "rule",
                component: RuleEditorComponent,
                data: { title: "title.new_geo_fencing_rule", icon: RouterIcon.GEO_FENCING, canSearch: false }
            },
            {
                path: "alerts",
                component: AlertsComponent,
                data: { title: "title.alerts", icon: RouterIcon.ALERTS }
            },
            {
                path: "alert/:id",
                component: AlertEditorComponent,
                data: { title: "title.edit_alert", icon: RouterIcon.ALERTS, canSearch: false }
            },
            {
                path: "alert",
                component: AlertEditorComponent,
                data: { title: "title.new_alert", icon: RouterIcon.ALERTS, canSearch: false }
            },
            {
                path: "liveMap",
                component: LiveMapComponent,
                data: { title: "title.live_map", icon: RouterIcon.LIVE_MAP, canSearch: false }
            },
            {
                path: "invoices",
                component: InvoicesComponent,
                data: { title: "title.invoices", icon: RouterIcon.INVOICES }
            },
            {
                path: "help",
                component: HelpComponent,
                data: { title: "title.help", icon: RouterIcon.HELP, canSearch: false }
            },
            {
                path: "help/generate-service-downtime",
                component: GenerateServiceDowntimeComponent,
                data: { title: "title.generate_service_downtime", icon: RouterIcon.SERVICE_DOWNTIME, canSearch: false }
            },
            {
                path: "help/:doc",
                component: HelpComponent,
                data: { title: "title.help", icon: RouterIcon.HELP }
            },
            {
                path: "about",
                component: AboutComponent,
                data: { title: "title.about", icon: RouterIcon.ABOUT, canSearch: false }
            },
            // Symbol library/Trace file analyser injection injection
            // Replace to if lazy loading ...
            // ,{ path: 'library', loadChildren: './library-editor/library-editor.module#LibraryEditorModule', data: { title: 'Edit Symbol Library' } }
            // ,{ path: 'library', loadChildren: './library-editor/library-editor.module#LogChartComponent', data: { title: 'Analyze trace log' } }
            {
                path: 'data',
                loadChildren: () => import('./log-chart/log-chart-root.module').then(m => m.LogChartRootModule),
                data: { title: 'title.data_files', icon: RouterIcon.REPORTS }
            },
            {
                path: "debug-log/:id",
                component: DebugLogViewerComponent,
                data: { title: "title.debug_log_view", icon: RouterIcon.REPORTS }
            },
            {
                path: "library",
                component: LibraryEditorComponent,
                data: { title: "title.new_symbol_library", icon: RouterIcon.DEFAULT }
            },
            {
                path: "library/:id",
                component: LibraryEditorComponent,
                data: { title: "title.edit_symbol_library", icon: RouterIcon.DEFAULT }
            },
            {
                path: 'reports',
                loadChildren: () => import('./reports/custom-reports.module').then(m => m.CustomReportsModule),
                data: { title: 'title.reports', icon: RouterIcon.CUSTOM_REPORTS }
            },
            {
                path: 'statistics',
                loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule),
                data: { title: 'title.statistics', icon: RouterIcon.STATISTICS }
            }
        ],
        runGuardsAndResolvers: "always"
    },
    { path: "**", redirectTo: "not-found" }
];

@NgModule({
    // remove LibraryEditorModule,LogChartModule if we're doing lazy loading.
    imports: [
        LogChartModule,
        LibraryEditorModule,
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: "reload",
            scrollPositionRestoration: "enabled",
            anchorScrolling: "enabled"
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
