<mat-toolbar class="mat-primary">
    <h2>{{ data.title }}</h2>
    <span class="spacer"></span>
    <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>
            close
        </mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    {{ data.text }}
    <br />
    <mat-form-field>
        <input matInput type="text" [(ngModel)]="response" name="response">
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()">
        {{'btn.cancel' | translate}}
    </button>
    <button mat-button class="mat-primary confirm" [disabled]="!response" (click)="confirm()">
        {{'btn.apply' | translate}}
    </button>
</mat-dialog-actions>