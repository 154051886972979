<cdk-virtual-scroll-viewport tvsItemSize="48">

  <table mat-table [dataSource]="datasource">

    <tr mat-header-row *matHeaderRowDef="labels; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: labels;"></tr>

    <ng-container *ngFor="let col of labels; let i = index" [matColumnDef]="col">
        <th mat-header-cell *matHeaderCellDef> {{ i === 0 ? ( "reports.time" | translate ) : col }} </th>
        <td mat-cell *matCellDef="let element"> {{ i === 0 ? ( element[i] | date: [dateTimeFormat] ) : element[i] }} </td>
    </ng-container>

  </table>

</cdk-virtual-scroll-viewport>
