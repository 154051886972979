import { NgModule } from '@angular/core';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { DataTableComponent } from './data-table.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';

@NgModule({
  imports: [ AppMaterialModule, ScrollingModule, TableVirtualScrollModule ],
  declarations: [ DataTableComponent ],
  exports: [ DataTableComponent ]
})
export class DataTableModule { }
