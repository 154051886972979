import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-oem-editor',
    templateUrl: './oem-editor.component.html',
    styleUrls: ['./oem-editor.component.css']
})
export class OemEditorComponent implements OnInit {

    constructor() { }

    ngOnInit() { }
}
