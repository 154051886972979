export class EditorCustomer {
    id: number;
    value: string;

    constructor(id: number, value: string) {
        this.id = id;
        this.value = value;
    }

    static empty(): EditorCustomer {
        return new EditorCustomer(undefined, undefined);
    }
}
