import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    appSettings = {
        customer_id: '',
        favicon: '',
        logo: '',
        theme: '',
    }

    constructor() { }

    setFavicon(favicon) {
        this.appSettings.favicon = favicon;
    }

    setLogo(logo) {
        this.appSettings.logo = logo;
    }

    setTheme(theme) {
        this.appSettings.theme = theme;
    }

    setCustomer(id) {
        this.appSettings.customer_id = id;
    }

    getAccountSettings() {
        return this.appSettings;
    }

    getFavicon() {
        return this.appSettings.favicon;
    }

    getLogo() {
        return this.appSettings.logo;
    }

    getTheme() {
        return this.appSettings.theme;
    }

    getFaviconImage() {
        let image = this.appSettings.favicon ? this.appSettings.favicon : '/images/favicon.ico';
        return environment.apiUrl + image;
    }

    getLogoImage() {
        let image = this.appSettings.logo ? this.appSettings.logo : '/images/mrs-logo.png';
        return environment.apiUrl + image;
    }

}
