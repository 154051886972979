import { ModuleWithProviders, NgModule } from '@angular/core';
import { SecurePipe } from '../services/securePipe';
import { FileNamePipe } from '../services/fileNamePipe';
import { OrderByTranslationPipe } from './pipes/order-by-translation/order-by-translation.pipe';

@NgModule({
  imports: [],
  exports: [
    SecurePipe,
    FileNamePipe,
    OrderByTranslationPipe
  ],
  declarations: [
    SecurePipe,
    FileNamePipe,
    OrderByTranslationPipe
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
