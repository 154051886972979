import { SelectionModel } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { AppService } from '../app.service';
import { ApplyCustomerDialogComponent } from '../dialogs/apply-customer-dialog/apply-customer-dialog.component';
import { ApplyModelDialogComponent } from '../dialogs/apply-model-dialog/apply-model-dialog.component';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { DeviceDashboardDialogComponent } from '../dialogs/device-dashboard-dialog/device-dashboard-dialog.component';
import { DeviceSimDialogComponent } from '../dialogs/device-sim-dialog/device-sim-dialog.component';
import { UserTableDataSource } from '../list-view/user-table-data-source';
import { appConstants } from '../services/constants';
import { ManageFactoryService } from '../services/manage-factory/manage-factory.service';
import { SearchService } from '../services/search/search.service';
import { WindowRef } from '../services/windowRef';
import { isMobileDevice } from '../shared/common';
import { ApplyCustomerDialogType, PanelClass } from '../shared/models/common.model';
import { Route } from '../shared/constants/routes.constant';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit, OnDestroy {
    constants = appConstants;
    dataSource: UserTableDataSource;
    subscription: Subscription;
    displayedColumns: string[];
    selection = new SelectionModel(true, []);
    filterListBy: string;
    multipleSelect: boolean;
    toolBar: string[];
    isMobile: boolean;
    enabledDevices: boolean;
    customerRole: number;
    userRole: number;
    nativeWindow: any;

    isLoadingResults = false;
    resultsLength = 0;
    pageSize = '50';
    pageLengthOptions = [10, 25, 50, 100];

    device_types: any;
    deviceRateplans: any;
    destroy$ = new Subject();

    scrollWidth;
    scrollHeight;
    sortColumn = '';
    sortOrder = '';
    isMobileDevice: boolean;
    searchString = '';
    fromPage = '';
    isNewTab = false;
    filterFlag: boolean = true;

    isLenticul: boolean = environment.isLenticul;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('viewPort', { static: true }) viewPort: CdkVirtualScrollViewport;
    @ViewChild('tableContainer', { static: true }) tableContainer: ElementRef;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private manageFactory: ManageFactoryService,
        private windowRef: WindowRef,
        private searchService: SearchService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
        private zone: NgZone,
        private appService: AppService
    ) {
        // this.dataSource = new MatTableDataSource<any>();
        this.isLoadingResults = true;
        this.activatedRoute.params.subscribe(params => {
            this.fromPage = params['from'];
            this.filterListBy = params['filter'];
            if (this.filterFlag) {
                this.filterFlag = false;
                return;
            }
            this.refresh();
        });
        this.nativeWindow = windowRef.getNativeWindow();

        this.isMobileDevice = isMobileDevice();
    }

    ngOnInit() {
        this.isMobile = (window.innerWidth) < 650;
        this.customerRole = +sessionStorage.getItem('customerRole');
        this.userRole = +sessionStorage.getItem('userRole');

        const rect = this.tableContainer.nativeElement.getBoundingClientRect();
        this.scrollWidth = rect.width;
        this.scrollHeight = rect.height;

        this.enabledDevices = true;
        this.initDatasource();

        // this.getDeviceData(true);
        this.getDeviceTypes();
        this.getDeviceRatePlans();
        this.setColumns();
        this.toolBar = ['enabled', 'model', 'customer', 'dashboard', 'identifier', 'disableEnable'];
        if (this.userRole >= this.constants.user_read_only) this.toolBar = ['dashboard'];
        this.multipleSelect = true;
        this.enabledDevices = true;

        this.subscription = this.searchService.searchObservable$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(res => {
            this.searchString = res.value;
            this.dataSource.destroy();
            this.initDatasource();
        }
        );
        this.setPageSize();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        if ('deviceId' in sessionStorage && this.isNewTab) {
            sessionStorage.removeItem('deviceId');
        }
    }

    private initDatasource() {
        this.selection.clear();
        this.dataSource = new UserTableDataSource({ pageSize: this.pageSize });

        this.dataSource.setScrollUpdateCallback((data) => this.getDataSourceData(data));

        // tells the sort to be case insensitive
        this.dataSource.matTableDataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
            if (sortHeaderId == 'primary_contact') return data['full_name'] ? data['full_name'].toLocaleLowerCase() : data['full_name'];
            if (typeof data[sortHeaderId] === 'string') return data[sortHeaderId].toLocaleLowerCase();

            return data[sortHeaderId];
        };

        this.dataSource.matTableDataSource.sort = this.sort;
        this.dataSource.attach(this.viewPort);
    }

    get inverseOfTranslation(): string {
        if (this.isMobileDevice) {
            return this.appService.browser.os === 'iOS' ? '0px' : `-${this.dataSource.scrollStartRange$.getValue() * 48}px`;
        }

        if (!this.viewPort || !this.viewPort["_renderedContentOffset"]) {
            return "-0px";
        }
        let offset = this.viewPort["_renderedContentOffset"];
        return `-${offset}px`;
    }

    handleSortChange(ev) {
        this.dataSource.destroy();
        this.sortColumn = ev.active || '';
        this.sortOrder = ev.direction || '';
        this.initDatasource();
    }

    getDeviceRatePlans() {
        if (this.isLenticul) {
            return;
        }
        this.manageFactory.devices.getDeviceRatePlans().subscribe((res) => {
            this.deviceRateplans = res;
        });
    }


    getDataSourceData(page) {
        /*
        * Supporting parameters:
         *   page_number - page number for pagination
         *   page_size   – number of elements on page
         *   sort        - sorting result by column name
         *   sort_order  - sorting direction (can be 'asc' and 'desc')
         */

        //     https://angular.io/guide/http#configuring-http-url-parameters
        let params = new HttpParams();

        let pageSize = parseInt(this.pageSize);
        params = params.append('limit', pageSize);
        params = params.append('offset', page * pageSize);

        if (this.sortColumn) {
            params = params.append('sort', this.sortColumn);
        }
        if (this.sortOrder) {
            params = params.append('sortOrder', this.sortOrder);
        }

        if (this.searchString) {
            params = params.append('searchTerm', this.searchString);
            params = params.append('searchFields', `serialNumber,id,iccid,${this.displayedColumns.filter(c => c !== 'select').join(',')}`);
        }

        if (this.filterListBy && this.fromPage) {
            if (this.fromPage === 'administrators') {
                params = params.append('administratorId', this.filterListBy);
            } else if (this.fromPage === 'oems') {
                params = params.append('oemId', this.filterListBy);
            } else if (this.fromPage === 'clients') {
                params = params.append('customerId', this.filterListBy);
            } else if (this.fromPage === 'dealers') {
                params = params.append('dealerId', this.filterListBy);
            } else if (this.fromPage === Route.MODELS) {
                params = params.append('modelId', this.filterListBy);
            }
        }

        this.isLoadingResults = true;

        if (this.enabledDevices) {
            params = params.append('statusId[lte]', 2);
        } else {
            params = params.append('statusId', 3);
        }
        return this.manageFactory.devices.read({ params })
            .pipe(
                tap((result: any) => {
                    // this.dataSource.matTableDataSource.data = result;
                    if (this.filterListBy) {
                        this.isLoadingResults = false;
                    } else {

                        // tells the sort to be case insensitive
                        this.dataSource.matTableDataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
                            if (typeof data[sortHeaderId] === 'string') return data[sortHeaderId].toLocaleLowerCase();

                            return data[sortHeaderId];
                        };

                        // this.dataSource.paginator = this.paginator;
                        this.dataSource.matTableDataSource.sort = this.sort;
                        this.resultsLength = this.dataSource.matTableDataSource.data.length;
                        this.isLoadingResults = false;
                        if (this.resultsLength > 100) this.pageLengthOptions = [10, 25, 50, 100, this.resultsLength];
                    }
                })
            )
    }

    // get device types
    getDeviceTypes() {
        this.manageFactory.devices.getDeviceTypes()
            .subscribe(result => {
                this.device_types = result;
            });

    }

    // set the columns for the table and icons in the menu
    setColumns() {
        let columns = ['select', 'identifier', 'name', 'deviceType'];
        if (this.customerRole <= this.constants.role_administrator) columns.push('oemName');
        if (this.customerRole <= this.constants.role_oem_user) columns.push('dealerName');
        if (this.customerRole <= this.constants.role_dealer_user) columns.push('clientName');
        this.displayedColumns = this.isMobile ? ['select', 'identifier'] : columns;
    }

    onResize(event) {
        const rect = this.tableContainer.nativeElement.getBoundingClientRect();
        this.scrollWidth = rect.width;
        this.scrollHeight = rect.height;

        this.isMobile = (event.target.innerWidth) < 650;
        this.setColumns();
    }

    // allows for single or multiple row selection
    selectRow(row) {
        // dynamically added items run out of Angular
        this.zone.run(() => {
            if (this.multipleSelect) {
                this.selection.toggle(row);
            } else {
                if (this.selection.isSelected(row)) {
                    this.selection.clear();
                } else {
                    this.selection.clear();
                    this.selection.select(row);
                }
            }
        });
    }

    doubleClickRow(row) {
        // dynamically added items run out of Angular
        this.zone.run(() => {
            if (this.selection != null) {
                this.selection.clear();
            }
            this.selectRow(row);
            this.editIdentifier();
        });
    }

    toolbarShow(tool) {
        if (this.toolBar.includes(tool)) return true;
        return false;
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.matTableDataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.matTableDataSource.data.forEach(row => this.selection.select(row));
    }

    applyFilter(filterValue: string) {
        this.dataSource.matTableDataSource.filter = filterValue ? filterValue.trim().toLowerCase() : null;
    }

    seeAll() {
        let goTo = this.router.url.split(';')[0];
        this.dataSource.matTableDataSource.filter = '';
        this.filterListBy = null;
        this.router.navigate([goTo]);
        this.initDatasource();
    }

    showEnabled() {
        this.enabledDevices = !(this.enabledDevices);
        this.initDatasource();
        // this.getDeviceData(this.enabledDevices);
    }

    getStatusColor(status) {
        switch (status) {
            case 'NOT_AVAILABLE':
                return 'gray';
            case 'DEACTIVATED':
                return 'red';
            case 'ACTIVATION_READY':
                return '#CC8500';
            case 'ACTIVATED':
                return '#2BAA1D';
            default:
                return 'gray';
        }
    }

    centerDiv(device) {
        if ((this.customerRole != this.constants.role_client_user && !device['serialNumber']) || (this.customerRole == this.constants.role_client_user && !device['userIdentifier'])) {
            return {
                'padding-top': '15px'
            }
        } else {
            return {
                'padding-top': '10px'
            }
        }
    }

    applyModel() {
        const isModelIdAvailable: boolean = this.selection.selected.some(s => s.modelId);

        this.translate.get("devices.apply_model").subscribe((translated: string) => {
            let dialog = this.dialog.open(ApplyModelDialogComponent, {
                panelClass: 'toolbar-dialog',
                data: { title: translated, isModelIdAvailable }
            });

            dialog.afterClosed()
                .subscribe(result => {
                    if (result) {
                        let deviceIds = this.selection.selected.map((x) => x.id);
                        this.manageFactory.devices.updateModel({
                            deviceIds: deviceIds,
                            modelId: result.model_id
                        })
                            .subscribe(response => {
                                if (response) {
                                    for (let x in this.selection.selected) {
                                        let index = this.dataSource.matTableDataSource.data.findIndex(i => i.id == this.selection.selected[x].id);
                                        this.dataSource.matTableDataSource.data[index].name = result.name;
                                        this.dataSource.matTableDataSource.data[index].allowTelematics = result.allowTelematics;
                                        this.dataSource.matTableDataSource.data[index].modelId = result.model_id;

                                        if (+x == (this.selection.selected.length - 1)) {
                                            this.translate.get("devices.model_applied").subscribe((translated: string) => {
                                                this.snackBar.open(translated, "", {
                                                    duration: 4000,
                                                    horizontalPosition: 'left'
                                                });
                                                this.selection.clear();
                                            });
                                        }
                                    }
                                }
                            })
                    }
                })
        });
    }

    applyCustomer() {
        const panelClass: string = PanelClass.ToolbarDialog;
        const title: string = 'apply_customer_dialog.apply_customer';

        const dialog = this.dialog.open(ApplyCustomerDialogComponent, {
            panelClass: panelClass,
            data: {
                title: title,
                applyCustomerDialogType: ApplyCustomerDialogType.Devices
            }
        });

            dialog.afterClosed()
                .subscribe(result => {
                    if (result) {
                        let deviceIds = this.selection.selected.map((x) => x.id);
                        this.manageFactory.devices.updateCustomer({
                            deviceIds: deviceIds,
                            customerId: result.customer_id,
                            customerRole: result.customer_role
                        })
                            .subscribe(response => {
                                if (response) {
                                    for (let x in this.selection.selected) {
                                        let index = this.dataSource.matTableDataSource.data.findIndex(i => i.id == this.selection.selected[x].id);
                                        this.dataSource.matTableDataSource.data[index].dealer_name = '';
                                        this.dataSource.matTableDataSource.data[index].client_name = '';
                                        if (result.customer_role == this.constants.role_oem_user) {
                                            this.dataSource.matTableDataSource.data[index].oem_name = result.customer_name;
                                            this.dataSource.matTableDataSource.data[index].oem_id = result.oem_id;
                                            this.dataSource.matTableDataSource.data[index].name = null;
                                        } else if (result.customer_role == this.constants.role_dealer_user) {
                                            this.dataSource.matTableDataSource.data[index].dealer_name = result.customer_name;
                                            this.dataSource.matTableDataSource.data[index].dealer_id = result.customer_id;
                                            this.dataSource.matTableDataSource.data[index].customer_id = result.customer_id;
                                        } else {
                                            this.dataSource.matTableDataSource.data[index].dealer_name = result.dealer_name;
                                            this.dataSource.matTableDataSource.data[index].client_name = result.customer_name;
                                            this.dataSource.matTableDataSource.data[index].customer_id = result.customer_id;
                                        }

                                        if (+x == (this.selection.selected.length - 1)) {
                                            this.translate.get("devices.customer_applied").subscribe((translated: string) => {
                                                this.snackBar.open(translated, "", {
                                                    duration: 4000,
                                                    horizontalPosition: 'left'
                                                });
                                                this.selection.clear();
                                            });
                                        }
                                    }
                                    this.refresh();
                                }
                            })
                    }
                })
    }

    openDashboard() {
        let item = this.selection.selected[0];
        if (item.allowTelematics == 1) {
            let dialog = this.dialog.open(DeviceDashboardDialogComponent, {
                panelClass: 'toolbar-dialog',
                data: { deviceId: item['id'] }
            });

            dialog.afterClosed()
                .subscribe(result => {
                    if (result) {
                        this.isNewTab = result['newTab'];
                        const param = result['dashboardId'] ? result['dashboardId'] : 'new';
                        const dashType = result['oldDashboard'] ? result['oldDashboard'] : false;

                        if (dashType == false) {

                            if (result['newTab']) {
                                window.open(`dashboard/${param}`, '_blank');
                            } else {
                                this.router.navigate([`../dashboard/${param}`], { relativeTo: this.activatedRoute });
                            }
                        }
                        else {
                            let goTo = this.getPath();
                            goTo += '/#/dashboard/';
                            goTo += param;
                            window.open(goTo, '_blank');
                        }
                    }
                });
        } else {
            this.translate.get("devices.telematics_not_enabled").subscribe((translated: string) => {
                this.snackBar.open(translated, "", {
                    duration: 4000,
                    horizontalPosition: 'left'
                });
            });
        }
    }

    getPath() {
        let path;
        if (window.location.origin.includes('app.spoke.zone')) {
            path = window.location.origin.replace("app", "dashboard");
        } else {
            path = window.location.origin.replace("4200", "3000");
        }
        return path;
    }

    editIdentifier() {
        let isClient = (this.customerRole == this.constants.role_client_user);
        let device = { ...this.selection.selected[0] };
        device['clientRole'] = isClient;
        device['adminRole'] = (this.customerRole <= this.constants.role_administrator);
        device['device_types'] = this.device_types;
        let index = this.dataSource.matTableDataSource.data.findIndex(i => i.id === device.id);
        let dialog = this.dialog.open(DeviceSimDialogComponent, {
            panelClass: 'toolbar-dialog',
            data: {
                device,
                plans: this.deviceRateplans,
                isMultiple: this.selection.selected.length > 1
            }
        });

        dialog.afterClosed()
            .subscribe(result => {
                if (result) {

                    if (this.isMultipleRowsSelected()) {
                        const arrResult = [];
                        this.selection.selected.forEach((d) => {
                            const idResult = {
                                identifier: d.identifier,
                                iccid: d.iccid,
                                userIdentifier: d.userIdentifier,
                                deviceId: d.id,
                                simStatus: d.iccid && result.simStatus ? result.simStatus : d.simStatus,
                                deviceTypeId: +result.deviceTypeId ? +result.deviceTypeId : d.deviceTypeId,
                                ratePlanId: result.ratePlanId ? result.ratePlanId : d.ratePlanId,
                                internationalPlan: !!result['internationalPlan'],
                            };

                            arrResult.push(idResult);
                        });
                        const arrRequest = arrResult.map(r => this.manageFactory.devices.updateIdentifier(r));
                        forkJoin(...arrRequest).pipe(
                            takeUntil(this.destroy$)
                        ).subscribe(resp => {

                            this.dataSource.matTableDataSource.data.forEach((d, i) => {
                                const resultItem = arrResult.find(r => r.deviceId === d.id);
                                if (resultItem) {
                                    Object.assign(this.dataSource.matTableDataSource.data[i], resultItem);
                                    for (let j = 0; j < this.device_types.length; j++) {
                                        if (this.device_types[j].id === result['deviceTypeId']) {
                                            this.dataSource.matTableDataSource.data[i].deviceType = this.device_types[j].name;
                                            this.dataSource.matTableDataSource.data[i].deviceTypeId = this.device_types[j].id;
                                            break;
                                        }
                                    }
                                }
                            });

                            this.selection.clear();
                            this.snackBar.open('Update successful!', '', {
                                duration: 4000,
                                horizontalPosition: 'left'
                            });
                        }, (err) => {
                            this.snackBar.open('Something went wrong!', '', {
                                duration: 4000,
                                horizontalPosition: 'left'
                            });
                        });
                    } else {
                        const idResult = {
                            deviceId: result['id'],
                            identifier: result['identifier'],
                            userIdentifier: result['userIdentifier'],
                            simStatus: result['simStatus'],
                            iccid: result['iccid'],
                            deviceTypeId: result['deviceTypeId'],
                            ratePlanId: result['ratePlanId'],
                            internationalPlan: result['internationalPlan'],
                        };

                        this.manageFactory.devices.updateIdentifier(idResult)
                            .subscribe(response => {
                                this.dataSource.matTableDataSource.data[index].iccid = result['iccid'];
                                this.dataSource.matTableDataSource.data[index].simStatus = result['simStatus'];
                                this.dataSource.matTableDataSource.data[index].identifier = result['identifier'];
                                this.dataSource.matTableDataSource.data[index].userIdentifier = result['userIdentifier'];
                                this.dataSource.matTableDataSource.data[index].ratePlanId = result['ratePlanId'];
                                this.dataSource.matTableDataSource.data[index].internationalPlan = result['internationalPlan'];
                                for (let i = 0; i < this.device_types.length; i++) {
                                    if (this.device_types[i].id === result['deviceTypeId']) {
                                        this.dataSource.matTableDataSource.data[index].deviceType = this.device_types[i].name;
                                        this.dataSource.matTableDataSource.data[index].deviceTypeId = this.device_types[i].id;
                                        break;
                                    }
                                }
                                this.selection.clear();
                            });
                    }
                }
            });

    }

    isMultipleRowsSelected(): boolean {
        return this.selection.selected.length > 1;
    }

    enableDisableDevices() {
        let dialog_title = this.enabledDevices ? 'devices.dialog.title_enabled' : 'devices.dialog.title_disabled';
        let dialog_desc;
        let dialog_confirm_btn = this.enabledDevices ? 'devices.dialog.confirm_btn_enabled' : 'devices.dialog.confirm_btn_disabled';
        let result_msg;
        if (this.enabledDevices) {
            dialog_desc = this.selection.selected.length > 1 ? 'devices.dialog.desc_enabled_devices' : 'devices.dialog.desc_enabled_device';
            result_msg = this.selection.selected.length > 1 ? 'devices.success_enabled_devices' : 'devices.success_enabled_device';
        } else {
            dialog_desc = this.selection.selected.length > 1 ? 'devices.dialog.desc_disabled_devices' : 'devices.dialog.desc_disabled_device';
            result_msg = this.selection.selected.length > 1 ? 'devices.success_disabled_devices' : 'devices.success_disabled_device';
        };

        this.translate.get([dialog_title, dialog_desc, dialog_confirm_btn, result_msg]).subscribe((translated: string) => {
            let dialog = this.dialog.open(ConfirmDialogComponent, {
                data: { title: translated[dialog_title], text: translated[dialog_desc], confirm: translated[dialog_confirm_btn] }
            });

            dialog.afterClosed()
                .subscribe(result => {
                    //if we get a true from the dialog change the device's status
                    if (result) {
                        for (let x = 0; x < this.selection.selected.length; x++) {
                            let newStatus = this.enabledDevices ? this.constants.device_disabled : this.constants.device_active;
                            let selectedId = this.selection.selected[x].id;

                            this.manageFactory.devices.updateStatus({
                                id: selectedId,
                                status: newStatus
                            })
                                .subscribe(response => {
                                    if (+x == (this.selection.selected.length - 1)) {
                                        // this.getDeviceData(this.enabledDevices);
                                        this.initDatasource();
                                        this.snackBar.open(translated[result_msg], "", {
                                            duration: 4000,
                                            horizontalPosition: 'left'
                                        });
                                    }
                                })
                        }
                    }
                })
        });
    }

    refresh() {
        this.sortColumn = '';
        this.sortOrder = '';
        this.dataSource.destroy();
        this.initDatasource();
    }

    setPageSize() {
        this.pageSize = sessionStorage.getItem('page_size');
        if (!this.pageSize || parseInt(this.pageSize, 10) > this.pageLengthOptions[this.pageLengthOptions.length - 1]) {
            this.pageSize = '50';
        }
    }

    getPageSize(e) {
        sessionStorage.setItem('page_size', e.pageSize);
    }

    handleDownloadLogs() {
        const id = this.selection.selected[0].id;

        this.manageFactory.traces.downloadConnectionLogs(id).pipe(
            take(1)
        ).subscribe((data: any) => {
            const element = document.createElement('a');
            element.setAttribute('href',
                'data:text/plain;charset=utf-8,' + encodeURIComponent(data.message.data));
            element.setAttribute('download', id);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        });
    }
}
