import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import { Subject } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class ReportItemService {

  mapIcon = '/images/map-thumb.png';
  reportRefresh$ = new Subject<boolean>();

  constructor(
    private http: HttpClient
  ) {
  }

  getStaticMap(query) {
    return this.http.get(query);
  }

}

