<div class="container" style="margin-top:30px">
    <div class="panel panel-default">
        <mat-card-header>
            <mat-card-title><strong>{{'reset_password_success.title' | translate}}</strong></mat-card-title>
        </mat-card-header>
    </div>
    <mat-card class="col-mat-4 col-mat-offset-4">
        <div class="panel-body">

            <div style="text-align:center;">{{'reset_password_success.content' | translate}}</div>

        </div>
    </mat-card>
</div>