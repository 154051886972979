import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { appConstants } from 'src/app/services/constants';
import { ManageFactoryService } from 'src/app/services/manage-factory/manage-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeService } from 'src/app/services/date-time.service';
import * as _ from 'lodash';
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { SharedService } from 'src/app/services/shared/shared.service';
import { IDevice } from 'src/app/dashboard/models/dashboard.model';
import { SortingKey } from 'src/app/shared/models/common.model';

@Component({
    selector: 'app-device-filter-dialog',
    templateUrl: './device-filter-dialog.component.html',
    styleUrls: ['./device-filter-dialog.component.css'],
})

export class DeviceFilterDialogComponent implements OnInit, OnDestroy {
    constants = appConstants;
    role: number;
    maxDate = new Date();
    deviceList: any = [];
    oemList: any = [];
    dealerList: any = [];
    clientList: any = [];
    showOem: boolean = false;
    showDealer: boolean = false;
    showClient: boolean = false;
    includeFilter: any;

    deviceFilterCtrl: FormControl = new FormControl();
    isFilterDeviceNone: boolean = true;
    filteredDevices: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    oemFilterCtrl: FormControl = new FormControl();
    isFilterOEMNone: boolean = true;
    filteredOEMs: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    dealerFilterCtrl: FormControl = new FormControl();
    isFilterDealerNone: boolean = true;
    filteredDealers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    clientFilterCtrl: FormControl = new FormControl();
    isFilterClientNone: boolean = true;
    filteredClient: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    filter = {
        identifier: null,
        oem_id: null,
        dealer_id: null,
        client_id: null,
        report_type: null,
        start_date: null,
        end_date: null
    };

    constructor(private manageFactory: ManageFactoryService, private _dateTimeService: DateTimeService, private translate: TranslateService, public dialogRef: MatDialogRef<DeviceFilterDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private sharedService: SharedService) { }

    ngOnInit() {
        this._dateTimeService.getDateFormat();
        this.role = +sessionStorage.getItem('customerRole');
        if (this.data['include']) this.includeFilter = this.data['include'];

        this.makeDeviceList();
        if (this.role <= this.constants.role_administrator) this.makeOemList();
        if (this.role <= this.constants.role_oem_user) this.makeDealerList();
        if (this.role <= this.constants.role_dealer_user) this.makeClientList();
        if (this.data['current']) this.applyCurrentFilters();

        this.deviceFilterCtrl.valueChanges
            .pipe()
            .subscribe(() => {
                this.isFilterDeviceNone = this.filterList(this.deviceList, this.deviceFilterCtrl, this.filteredDevices, 'identifier');
            });

        this.oemFilterCtrl.valueChanges
            .pipe()
            .subscribe(() => {
                this.isFilterOEMNone = this.filterList(this.oemList, this.oemFilterCtrl, this.filteredOEMs, 'customer_name');
            });

        this.dealerFilterCtrl.valueChanges
            .pipe()
            .subscribe(() => {
                this.isFilterDealerNone = this.filterList(this.dealerList, this.dealerFilterCtrl, this.filteredDealers, 'customer_name');
            });

        this.clientFilterCtrl.valueChanges
            .pipe()
            .subscribe(() => {
                this.isFilterClientNone = this.filterList(this.clientList, this.clientFilterCtrl, this.filteredClient, 'customer_name');
            });
    }

    ngOnDestroy() {
        this.filteredDevices.unsubscribe();
        this.filteredOEMs.unsubscribe();
        this.filteredDealers.unsubscribe();
        this.filteredClient.unsubscribe();
    }

    filterList(list, ctrl, filtered, field) {
        if (!list) {
            return;
        }

        let search = ctrl.value;
        let isFiltered = false;

        if (!search) {
            filtered.next(list.slice());
            isFiltered = true;
            return;
        } else {
            search = search.toLowerCase();
            if ('none'.indexOf(search) > -1) {
                isFiltered = true;
            } else {
                isFiltered = false;
            }
        }

        filtered.next(
            list.filter(obj => obj[field].toLowerCase().indexOf(search) > -1)
        );

        return isFiltered;
    }

    makeDeviceList() {
        this.manageFactory.devices.read({ enabled: true })
            .subscribe((devResult: IDevice[]) => {
                this.deviceList = this.sharedService.sort(devResult, SortingKey.Identifier);
                this.filteredDevices.next(this.deviceList.slice());
            });
    }

    makeOemList() {
        this.showOem = true;
        this.manageFactory.oems.read()
            .subscribe(oemResult => {
                this.oemList = oemResult;
                this.filteredOEMs.next(this.oemList.slice());
            })
    }

    makeDealerList() {
        this.showDealer = true;
        this.manageFactory.dealers.read()
            .subscribe(dealerResult => {
                this.dealerList = dealerResult;
                this.filteredDealers.next(this.dealerList.slice());
            })
    }

    makeClientList() {
        this.showClient = true;
        this.manageFactory.clients.read()
            .subscribe(clientResult => {
                this.clientList = clientResult;
                this.filteredClient.next(this.clientList.slice());
            })
    }

    completeDate(type: string, event: MatDatepickerInputEvent<Date>) {
        event.value = new Date(event.value);
        let day = _.cloneDeep(event.value);
        if (type === 'start') {
            this.filter.start_date = event.value;
            this.filter.start_date.setHours(0, 0, 0);
            if (this.filter.start_date && !this.filter.end_date) {
                day.setDate(day.getDate() + 1);
                this.filter.end_date = day;
                this.filter.end_date.setHours(23, 59, 59);
            }
        }
        if (type === 'end') {
            this.filter.end_date = event.value;
            this.filter.end_date.setHours(23, 59, 59);
            if (this.filter.end_date && !this.filter.start_date) {
                day.setDate(day.getDate() - 1);
                this.filter.start_date = day;
                this.filter.start_date.setHours(0, 0, 0);
            }
        }
    }

    applyCurrentFilters() {
        for (let key in this.data['current']) {
            this.filter[key] = this.data['current'][key];
        }

        if (this.filter.start_date) {
            const start_date = new Date(this.filter.start_date);
            const timezoneOffsetS = start_date.getTimezoneOffset() * 60000;
            this.filter.start_date = new Date(start_date.getTime() + timezoneOffsetS);
        }

        if (this.filter.end_date) {
            const end_date = new Date(this.filter.end_date);
            const timezoneOffsetE = end_date.getTimezoneOffset() * 60000;
            this.filter.end_date = new Date(end_date.getTime() + timezoneOffsetE);
        }
    }

    confirm() {
        let returnFilter = {};
        if (this.filter.identifier) returnFilter['identifier'] = this.filter.identifier;
        if (this.filter.oem_id) returnFilter['oem_id'] = this.filter.oem_id;
        if (this.filter.dealer_id) returnFilter['dealer_id'] = this.filter.dealer_id;
        if (this.filter.client_id) returnFilter['client_id'] = this.filter.client_id;
        if (this.filter.report_type) returnFilter['report_type'] = this.filter.report_type;
        if (this.filter.start_date && this.filter.end_date) {
            const start_date = new Date(this.filter.start_date);
            const timezoneOffsetS = start_date.getTimezoneOffset() * 60000;
            returnFilter['start_date'] = new Date(start_date.getTime() - timezoneOffsetS);

            const end_date = new Date(this.filter.end_date);
            const timezoneOffsetE = end_date.getTimezoneOffset() * 60000;
            returnFilter['end_date'] = new Date(end_date.getTime() - timezoneOffsetE);
        }
        this.dialogRef.close(returnFilter);
    }
}
