<mat-toolbar class="mat-primary">
    <h2>{{ data.title }}</h2>
    <span class="spacer"></span>
    <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>
            close
        </mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <form [formGroup]="form" fxLayout="column">
        <mat-form-field class="select-array">
            <mat-select formControlName="returnText" placeholder="{{data.label}}">
                <mat-option *ngFor="let obj of selectArray" [value]="obj.text">
                    {{obj.text}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()">
        {{'btn.cancel' | translate}}
    </button>
    <button mat-button class="mat-primary confirm" [disabled]="!returnText.value" (click)="confirm()">
        {{ data.confirm }}
    </button>
</mat-dialog-actions>