import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { parseNumber, formatNumber } from 'libphonenumber-js';
import { TranslateService } from '@ngx-translate/core';
import { CustomValidator } from '../shared/custom.validators';
import { ManageFactoryService } from '../services/manage-factory/manage-factory.service';
import { User } from '../services/user/user.model';
import { appConstants, CustomerRole } from '../services/constants';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { ActionType, ISQLResponse, SQL_ER_DUP_ENTRY, ICountry } from '../shared/models/common.model';
import { IsPrimaryContact, Email } from './user.model';
import { AuthFactoryService } from '../services/auth-factory/auth-factory.service';
import { take } from 'rxjs/operators';
import { Route } from '../shared/constants/routes.constant';
import { IUser } from '../services/auth-factory/auth-factory.model';
import { SharedService } from '../services/shared/shared.service';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
    countryList: ICountry[] = [];
    associationArray: any = [];
    permissionArray: any = [];
    id: any = null;
    constants = appConstants;
    canEdit = (+sessionStorage.getItem('userRole') == this.constants.user_read_write);
    isSaved = false;
    user: any = {} as User;
    lockedStatus = '';
    isDisabled = false;
    customersArray = [
        { customerRoleId: CustomerRole.Super, userRole: 'Super', users: [] },
        { customerRoleId: CustomerRole.Administrator, userRole: 'Administrator', users: [] },
        { customerRoleId: CustomerRole.OEM, userRole: 'OEM', users: [] },
        { customerRoleId: CustomerRole.Dealer, userRole: 'Dealer', users: [] },
        { customerRoleId: CustomerRole.Client, userRole: 'Client', users: [] },
        { customerRoleId: CustomerRole.Mobile, userRole: 'Mobile', users: [] },
        { customerRoleId: CustomerRole.Device, userRole: 'Device', users: [] },
    ];

    userForm = new FormGroup({
        first_name: new FormControl('', Validators.required),
        last_name: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, CustomValidator.email]),
        phone: new FormControl(''),
        country: new FormControl('', Validators.required),
        customer_id: new FormControl('', Validators.required),
        user_role_id: new FormControl('', Validators.required),
        mqtt_permission_id: new FormControl('', Validators.required),
        is_primary_contact: new FormControl(false)
    });

    get first_name() {
        return this.userForm.get('first_name');
    }

    get last_name() {
        return this.userForm.get('last_name');
    }

    get email() {
        return this.userForm.get('email');
    }

    get phone() {
        return this.userForm.get('phone');
    }

    get country() {
        return this.userForm.get('country');
    }

    get customer_id() {
        return this.userForm.get('customer_id');
    }

    get user_role_id() {
        return this.userForm.get('user_role_id');
    }

    get mqtt_permission_id() {
        return this.userForm.get('mqtt_permission_id');
    }

    get is_primary_contact() {
        return this.userForm.get('is_primary_contact');
    }

    constructor(private activatedRoute: ActivatedRoute,
        private manageFactory: ManageFactoryService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private location: Location,
        private translate: TranslateService,
        private router: Router,
        private authService: AuthFactoryService,
        private sharedService: SharedService
        ) {
        // get list of countries which are not sanctioned
        this.countryList = this.sharedService.getCountries();
        this.country.setValue('US');
        this.activatedRoute.params.subscribe(params => {
            if (params['id'] !== undefined) {
                this.id = params['id'];
                this.manageFactory.users.readOne({ id: this.id })
                    .subscribe(result => {
                        this.user = result;
                        this.userForm.patchValue(result);
                        let thisCountry = result['phone'] ? parseNumber(result['phone']) : false;
                        if (thisCountry['country']) this.country.setValue(thisCountry['country']);
                    });
            } else {
                this.user['is_locked'] = this.constants.user_unlocked;
            }
        });
    }

    ngOnInit() {
        if (sessionStorage.length === 0) {
            this.setUpSessionListener();
        }
        this.manageFactory.clients.getAll()
            .subscribe(result => {
                this.associationArray = result;
                this.createCustomerGroups(this.associationArray);
            });

        this.manageFactory.users.readUserRoles()
            .subscribe(roleResult => {
                this.permissionArray = roleResult;
            });

        if (!this.canEdit) {
            this.userForm.disable();
        }
    }

    validatePhone() {
        let phoneNum = (this.phone.value != null) ? this.phone.value.toString() : false;
        if (phoneNum) {
            let parsed = parseNumber(phoneNum, this.country.value);
            // @ts-ignore
            let formatted = (parsed['country'] == this.country.value) ? formatNumber(parsed, 'International') : false;
            if (formatted == false) {
                this.userForm.controls['phone'].setErrors({ 'invalid': true });
            } else {
                this.userForm.controls['phone'].setValue(formatted);
            }
        }
    }

    openDialog() {
        this.translate.get(['list_view.reset_password', 'list_view.reset_password_desc', 'btn.reset', 'forgot_password.email_sended'], {email: this.email.value}).subscribe((translated: string) => {
            const dialog = this.dialog.open(ConfirmDialogComponent, {
                data: { title: translated['list_view.reset_password'], text: translated['list_view.reset_password_desc'], confirm: translated['btn.reset'] }
            });

            dialog.afterClosed()
                .subscribe(response => {
                    if (response) {
                        this.manageFactory.forgot.resetToken({
                            email: this.userForm.get('email').value,
                            applicationId: 'CGW'
                        })
                            .subscribe(response => {
                                this.snackBar.open(translated['forgot_password.email_sended'], "", {
                                    duration: 4000,
                                    horizontalPosition: 'left'
                                });
                            });
                    }
                });
        });
    }

    toggleLock() {
        this.user.is_locked = !this.user.is_locked;
    }

    loadUser() {
        let user = {};
        user['first_name'] = this.first_name.value;
        user['last_name'] = this.last_name.value;
        user['email'] = this.email.value;
        user['phone'] = this.phone.value;
        user['customer_id'] = this.customer_id.value;
        user['user_role_id'] = this.user_role_id.value;
        user['mqtt_permission_id'] = this.mqtt_permission_id.value;
        user['is_locked'] = this.user.is_locked;
        if (this.id) user['user_id'] = this.id;

        return user;
    }

    close() {
        let translate_id = this.id ? 'user.updated' : 'user.created';
        this.translate.get(translate_id).subscribe((text: string) => {
             this.snackBar.open(text, '', {
                duration: 4000,
                horizontalPosition: 'left'
            });
            this.location.back();
        });
    }

    /**
     * Navigate to user listing page when user click cancel button
     */
    closeEditor(): void {
        this.router.navigate([Route.USERS]);
    }

    saveCustomer() {
        let cust = this.associationArray.find(i => i['customer_id'] == this.customer_id.value);
        cust['user_id'] = this.is_primary_contact.value ? this.id : null;
        let type = '';

        // get the customer type
        switch (cust['customer_role']) {
            case this.constants.role_oem_user:
                type = 'oems';
                break;
            case this.constants.role_dealer_user:
                type = 'dealers';
                break;
            case this.constants.role_client_user:
                type = 'clients';
                break;
        }

        if (type) {
            this.manageFactory[type].update(cust)
                .subscribe(result => {
                    this.close();
                })
        } else {
            this.close();
        }
    }

    saveSettings(): void {
        this.isSaved = true;
        let user = this.loadUser();

        const successHandler = (actionType: ActionType) => (result: ISQLResponse): void => this.handleSuccessResponse(actionType, result),
            errorHandler = () => (httpErrorResponse: HttpErrorResponse): void => this.handleHttpErrorResponse(httpErrorResponse);

        if (this.id === null) {
            this.manageFactory.users.create(user)
                .subscribe(successHandler(ActionType.CREATE), errorHandler());
        } else {
            this.manageFactory.users.update(user)
                .subscribe(successHandler(ActionType.UPDATE), errorHandler());
        }
    }

    /**
     * This method show snackbar if there is error while saving user
     */
    private errorSavingMessage(): void {
        this.translate.get('alert.error.saving_record').subscribe((errorMessage: string) => {
            this.snackBar.open(errorMessage, '', {
                duration: 4000,
                horizontalPosition: 'left'
            });
        });
    }

    /**
     * This method is called to handle the response when user is being saved
     * @param actionType
     * @param response
     * @returns
     */
    private handleSuccessResponse(actionType: ActionType, response: ISQLResponse): void {
        this.email.setErrors(null);

        if (actionType === ActionType.CREATE) {
            this.id = response.insertId;
            this.is_primary_contact.value ? this.saveCustomer() : this.close();
        } else {
            (this.is_primary_contact.value || this.user[IsPrimaryContact]) ? this.saveCustomer() : this.close();
        }
    }

    /**
     * This method will handle HTTP error response
     * @param httpErrorResponse
     * @returns
     */
    private handleHttpErrorResponse(httpErrorResponse: HttpErrorResponse): void {
        if (httpErrorResponse?.error?.errno === SQL_ER_DUP_ENTRY && httpErrorResponse?.error?.sqlMessage?.includes(Email)) {
            this.email.setErrors({ duplicateEmail: true });
            return;
        }
        this.errorSavingMessage();
    }

    createCustomerGroups(associationArray: any[]) {
        this.customersArray.forEach(customer => {
            customer.users = associationArray.filter(f => f.customer_role === customer.customerRoleId);
        });
        this.customersArray = this.customersArray.filter(a => a.users.length !== 0);
    }

    /**
     * This method setup listener for session storage \
     * As soon session storage is available data will emitted so that we can unable/disable form
     */
    private setUpSessionListener(): void {
        this.authService.sessionObs.pipe(take(1)).subscribe((data: IUser) => {
            this.canEdit = data.user_role == this.constants.user_read_write;
            if (!this.canEdit) {
                this.userForm.disable();
                return;
            }
            this.userForm.enable();
        });
    }
}
