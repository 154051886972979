import { Component, Inject, Input } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { debounceTime, map } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';

import {
    SymSymbolVariable,
    SymSymbolVaribaleType,
    SymSymbolVaribaleDataFormat
} from "../model/sym_symbol_variable";
import { SymLibrary } from "../model/sym_library";
import { SymEnum } from "../model/sym_enum";
import { UserBrowserInfo } from "../model/user_browser_info";
import { SymSymbol } from "../model/sym_symbol";

@Component({
    selector: "dialog-symbol-variable-edit-dialog",
    templateUrl: "./symbol-variable-edit.component.html",
    styleUrls: ["./dialog.component.css"],
    providers: [UserBrowserInfo]
})
export class SymbolVariableEditDialog {
    public symbol_var: SymSymbolVariable;
    public sym_lib: SymLibrary;
    public title: string;
    public data_format_title: string;

    startBitFormControl: FormControl = new FormControl(0, [
        Validators.required,
        Validators.min(0),
        Validators.max(800),
        Validators.pattern("[0-9]*")
    ]);
    
    lengthFormControl: FormControl = new FormControl({value: 0, disabled: false }, [
        Validators.required,
        Validators.min(1),
        Validators.max(800),
        Validators.pattern("[0-9]*")
    ]);

    constructor(
        public dialogRef: MatDialogRef<SymbolVariableEditDialog>,
        public browserInfo: UserBrowserInfo,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.symbol_var = this.data.sym_var;
        this.sym_lib = this.data.sym_lib;
        if (typeof this.symbol_var.name === "undefined") {
            this.title = "library_editor.dialog.title_add_symbol_var";
        } else {
            this.title = "library_editor.dialog.title_edit_symbol_var";
        }

        this.data_format_title = this.browserInfo.is_mobile
            ? "library_editor.dialog.data_format_title_short"
            : "library_editor.dialog.data_format_title";

        this.startBitFormControl.setValue(this.symbol_var.starting_bit);
        this.lengthFormControl.setValue(this.symbol_var.length);
        this.startBitFormControl.valueChanges
            .pipe(debounceTime(500))
            .subscribe((val: number) => {
                if (this.startBitFormControl.valid) {
                    this.sym_variable_starting_bit = val;
                }
            });
        this.lengthFormControl.valueChanges
            .pipe(debounceTime(500))
            .subscribe((val: number) => {
                if (this.lengthFormControl.valid) {
                    this.sym_variable_length = val;
                }
            });
        this.startBitFormControl.markAsTouched();
        this.lengthFormControl.markAsTouched();
        this.lengthEdit();
    }

    submitByEnter() {
        this.dialogRef.close(this.symbol_var);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    @Input()
    set symVariableValueUom(value_uom: string) {
        this.symbol_var.value_uom = value_uom;
    }
    get symVariableValueUom() {
        return this.symbol_var.value_uom;
    }

    @Input()
    set sym_symbol_variable_data_type(data_type: SymSymbolVaribaleType) {
        this.symbol_var.data_type = data_type;
        this.lengthEdit();
    }
    get sym_symbol_variable_data_type() {
        return this.symbol_var.data_type;
    }

    @Input()
    set sym_symbol_variable_enum(enum_name: string) {
        this.sym_lib.enums.forEach((e: SymEnum) => {
            if (enum_name === e.name) {
                this.symbol_var.data_type_enum = e;
            }
        });
    }
    get sym_symbol_variable_enum() {
        return typeof this.symbol_var.data_type_enum !== "undefined"
            ? this.symbol_var.data_type_enum.name
            : undefined;
    }

    @Input()
    set sym_variable_value_scalar(scalar: number | null) {
        if (scalar !== null) {
            this.symbol_var.value_scalar = scalar;
            //this.calc_symbol_var_man_max(this.symbol_var);
        }
    }
    get sym_variable_value_scalar() {
        return this.symbol_var.value_scalar;
    }

    @Input()
    set sym_variable_value_offset(offset: number | null) {
        if (offset !== null) {
            this.symbol_var.value_offset = offset;
            //this.calc_symbol_var_man_max(this.symbol_var);
        }
    }
    get sym_variable_value_offset() {
        return this.symbol_var.value_offset;
    }

    @Input()
    set sym_variable_starting_bit(s_bit: number | null) {
        if (s_bit !== null) {
            this.symbol_var.starting_bit = s_bit;
        }
    }
    get sym_variable_starting_bit() {
        return this.symbol_var.starting_bit;
    }

    @Input()
    set sym_variable_length(l: number | null) {
        if (l !== null) {
            this.symbol_var.length = l;
        }
    }
    get sym_variable_length() {
        return this.symbol_var.length;
    }

    @Input()
    set sym_variable_data_format(v: boolean) {
        this.symbol_var.data_format = v
            ? SymSymbolVaribaleDataFormat.INTEL
            : SymSymbolVaribaleDataFormat.MOTOROLA;
    }
    get sym_variable_data_format() {
        return (
            this.symbol_var.data_format === SymSymbolVaribaleDataFormat.INTEL
        );
    }

    lengthEdit() {
        switch (this.symbol_var.data_type) {
            case SymSymbolVaribaleType.BIT:
                this.symbol_var.length = 1;
                this.lengthFormControl.disable();
                break;
            case SymSymbolVaribaleType.CHAR:
                this.symbol_var.length = 8;
                this.lengthFormControl.disable();
                break;
            case SymSymbolVaribaleType.STRING:
            case SymSymbolVaribaleType.RAW:
                this.symbol_var.length =
                    Math.ceil(this.symbol_var.length / 8) * 8;
                this.lengthFormControl.enable();
                break;
            case SymSymbolVaribaleType.FLOAT:
                this.symbol_var.length = 32;
                this.lengthFormControl.disable();
                break;
            case SymSymbolVaribaleType.DOUBLE:
                this.symbol_var.length = 64;
                this.lengthFormControl.disable();
                break;
            default:
                this.lengthFormControl.enable();
                break;
        }
    }
    isEnum(el: SymSymbolVariable) {
        return el.data_type === SymSymbolVaribaleType.ENUM;
    }
    isFactorShown(el: SymSymbolVariable) {
        return [
            SymSymbolVaribaleType.SIGNED,
            SymSymbolVaribaleType.UNSIGNED,
            SymSymbolVaribaleType.FLOAT,
            SymSymbolVaribaleType.DOUBLE
        ].includes(el.data_type);
    }
    isOffsetShown(el: SymSymbolVariable) {
        return [
            SymSymbolVaribaleType.SIGNED,
            SymSymbolVaribaleType.UNSIGNED,
            SymSymbolVaribaleType.FLOAT,
            SymSymbolVaribaleType.DOUBLE
        ].includes(el.data_type);
    }
}
