import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, filter } from "rxjs/operators";

@Injectable()

export class SearchService {
    private searchValue = new Subject<any>();

    searchObservable$ = this.searchValue.asObservable().pipe(
        debounceTime(500),
        filter((res) => {
            return res.hasOwnProperty("option") && res.option === "call_child" && res.value !== null
        }),
    );

    constructor() {}

    public searchNotify(data: any) {
        if (data) {
            this.searchValue.next(data);
        }
    }
}
