import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';

// Uncomment if lazy loading
// import { Routes, RouterModule } from '@angular/router';
// Lazy loading

import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSplitModule } from 'angular-split';

import { AppMaterialModule } from '../services/app-material/app-material.module';

import { SwipeHammerConfig } from './hammer/hammer-gesture-config';
import { SymbolEditorComponent } from './symbol-editor/symbol-editor.component';
import { SymbolPropComponent } from './symbol-editor/symbol-properties/symbol-properties.component';
import { SymbolDetailsComponent } from './symbol-editor/symbol-details/symbol-details.component';
import { EnumEditorComponent } from './enum-editor/enum-editor.component';
import { EnumPropComponent } from './enum-editor/enum-properties/enum-properties.component';
import { EnumEditPropertyValueDialog } from './dialogs/enum-edit-property-value.component';
import { EnumNewPropertyDialog } from './dialogs/enum-new-property.component';
import { SymbolNewDialog } from './dialogs/symbol-new.component';
import { SymbolVariableEditDialog } from './dialogs/symbol-variable-edit.component'

import { HexadecimalPipe } from "./pipes/hexadecimal.pipe";
import { HexadecimalDirective } from './directives/hexadecimal.directive';

import { LibraryEditorComponent } from "./library-editor.component";
export { LibraryEditorComponent };

// Uncomment if lazy loading
/*const routes: Routes = [
                { path: '', component: LibraryEditorComponent, data: { title: 'New Symbol Library' } },
                { path: ':id', component: LibraryEditorComponent, data: { title: 'Edit Symbol Library' } }
                 ];
*/
// Lazy loading

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LibraryEditService } from './service/library-edit.service';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        `/assets/i18n/library-editor/`,
        '.json'
    );
}

@NgModule({
    declarations: [
        EnumEditPropertyValueDialog,
        EnumNewPropertyDialog,
        SymbolNewDialog,
        SymbolVariableEditDialog,
        SymbolEditorComponent,
        SymbolPropComponent,
        SymbolDetailsComponent,
        EnumEditorComponent,
        EnumPropComponent,
        HexadecimalPipe,
        HexadecimalDirective,
        LibraryEditorComponent
    ],
    providers: [
        { provide: HAMMER_GESTURE_CONFIG, useClass: SwipeHammerConfig },
        LibraryEditService
    ],
    imports: [
        CommonModule,
        AppMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        AngularSplitModule,
        // Uncomment if lazy loading
        /*RouterModule.forChild(routes),*/
        // Lazy loading
        HttpClientModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: true
        })
    ],
    exports: [
        LibraryEditorComponent
    ],
})
export class LibraryEditorModule { }
