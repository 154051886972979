import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../../services/app-material/app-material.module';
import { LogChartComponent } from './log-chart.component';
import { ChartCardModule } from 'src/app/chart/chart-card/chart-card.module';

export { LogChartComponent };

@NgModule({
    imports: [
        AppMaterialModule,
        ChartCardModule
    ],
    exports: [
        LogChartComponent
    ],
    declarations: [
        LogChartComponent,
    ],
})
export class LogChartModule { }
