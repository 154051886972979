/*
 * based on https://codeburst.io/digit-only-directive-in-angular-3db8a94d80c3
 */
import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
    selector: "[hexadecimal]"
})
export class HexadecimalDirective {
    private decimalCounter = 0;
    private navigationKeys = [
        "Backspace",
        "Delete",
        "Tab",
        "Escape",
        "Enter",
        "Home",
        "End",
        "ArrowLeft",
        "ArrowRight",
        "Clear",
        "Copy",
        "Paste"
    ];
    inputElement: HTMLElement;

    private keyRegEx = "[0-9A-Fa-f]";
    private keyReqEx_ = "[^0-9A-Fa-f]";

    constructor(public el: ElementRef) {
        this.inputElement = el.nativeElement;
    }

    @HostListener("keydown", ["$event"])
    onKeyDown(e: KeyboardEvent) {
        if (
            this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
            (e.key === "a" && e.ctrlKey === true) || // Allow: Ctrl+A
            (e.key === "c" && e.ctrlKey === true) || // Allow: Ctrl+C
            (e.key === "v" && e.ctrlKey === true) || // Allow: Ctrl+V
            (e.key === "x" && e.ctrlKey === true) || // Allow: Ctrl+X
            (e.key === "a" && e.metaKey === true) || // Allow: Cmd+A (Mac)
            (e.key === "c" && e.metaKey === true) || // Allow: Cmd+C (Mac)
            (e.key === "v" && e.metaKey === true) || // Allow: Cmd+V (Mac)
            (e.key === "x" && e.metaKey === true) // Allow: Cmd+X (Mac)
        ) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        let _re = new RegExp(this.keyRegEx, "g");
        if (e.key === " " || !e.key.match(_re)) {
            e.preventDefault();
        }
    }

    @HostListener("keyup", ["$event"])
    onKeyUp(e: KeyboardEvent) {
        return;
    }

    @HostListener("paste", ["$event"])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();
        const pastedInput: string = event.clipboardData.getData("text/plain");

        let _re = new RegExp(this.keyReqEx_, "g");
        document.execCommand("insertText", false, pastedInput.replace(_re, ""));
    }

    @HostListener("drop", ["$event"])
    onDrop(event: any) {
        event.preventDefault();
        const textData = event.dataTransfer.getData("text");
        this.inputElement.focus();

        let _re = new RegExp(this.keyReqEx_, "g");
        document.execCommand("insertText", false, textData.replace(_re, ""));
    }
}
