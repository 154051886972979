import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ManageFactoryService } from '../../services/manage-factory/manage-factory.service';
import { TranslateService } from '@ngx-translate/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-update-password',
    templateUrl: './update-password.component.html',
    styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent {

    constructor(public dialogRef: MatDialogRef<UpdatePasswordComponent>, private snackBar: MatSnackBar, private manageFactory: ManageFactoryService, private translate: TranslateService) { }
    name = '';
    form = new FormGroup({
        currentPassword: new FormControl('', [Validators.required]),
        confirmPassword: new FormControl('', [Validators.required]),
        newPassword: new FormControl('', [Validators.required])
    });

    get new() {
        return this.form.get('newPassword');
    }

    get confirm() {
        return this.form.get('confirmPassword');
    }

    get current() {
        return this.form.get('currentPassword');
    }

    savePassword() {
        let saveUser = {
            user_id: sessionStorage.getItem('userId'),
            enterPassword: this.new.value
        }

        this.manageFactory.users.comparePasswords({
            currentPassword: this.current.value
        }).subscribe(
            pwResult => {
                if (!pwResult) {
                    this.form.controls['currentPassword'].setErrors({ 'incorrect': true });
                } else {
                    this.form.controls['currentPassword'].setErrors({ 'incorrect': false });
                    this.manageFactory.users.updatePassword(saveUser).subscribe(
                        updateResult => {
                            this.translate.get("dialog.your_password_updated").subscribe((text: string) => {
                                this.snackBar.open(text, "", {
                                    duration: 4000,
                                    horizontalPosition: 'left'
                                });
                                sessionStorage.setItem('resetPassword', '0');
                                this.dialogRef.close();
                            });
                        });
                }
            })
    }


    hasUpper() {
        return (/[A-Z]/.test(this.new.value));
    }

    hasLower() {
        return (/[a-z]/.test(this.new.value));
    }

    hasNumber() {
        return (/\d/.test(this.new.value));
    }

    hasSpecial() {
        return (/\W/.test(this.new.value));
    }

    isMatch() {
        return (this.new.value === this.confirm.value);
    }

    isOld() {
        if (this.new.value === this.current.value) this.form.controls['newPassword'].setErrors({ 'notNew': true });
    }

    getRequirements() {
        let count = 0;
        if (this.hasLower()) count++;
        if (this.hasUpper()) count++;
        if (this.hasNumber()) count++;
        if (this.hasSpecial()) count++;

        return (count >= 3) ? true : false;
    }

}
