<mat-toolbar class="mat-primary">
    <div>
        <h2>{{ 'frequency_distribution.heading' | translate }}</h2>
    </div>
    <span class="spacer"></span>
    <button mat-icon-button (click)="close()">
        <mat-icon>
            <i class="material-icons">close</i>
        </mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
  <form fxLayout="column" fxLayoutGap="3px" [formGroup]="form">
    <mat-form-field class='full-width'>
      <mat-label>{{ 'frequency_distribution.select_variable' | translate }}</mat-label>
      <mat-select formControlName='selectedVariable' (selectionChange)="findMinMax($event)">
        <mat-option *ngFor="let item of data.minMaxArray" [value]="item.selectedVariable">
          {{ item.selectedVariable }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'frequency_distribution.min_value' | translate }}</mat-label>
      <input matInput formControlName="minValue" class="form-control">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'frequency_distribution.max_value' | translate }}</mat-label>
      <input matInput formControlName="maxValue" class="form-control">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'frequency_distribution.range_steps' | translate }}</mat-label>
      <input matInput formControlName="frequency" class="form-control" type="number">
      <mat-error *ngIf="frequency.touched && frequency.invalid && frequency?.errors?.min">
        {{ 'frequency_distribution.frequency_value_error' | translate }} {{minimumValue}}.
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="close()">{{ 'btn.cancel' | translate }}</button>
  <button mat-button class="mat-primary confirm" [disabled]="!form.valid || !isValueExist"  (click)="calculate()" >{{ 'frequency_distribution.calculate' | translate }}</button>
</mat-dialog-actions>
