import {
    Component, ElementRef, EventEmitter, HostListener, Input, OnChanges,
    OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild
} from '@angular/core';
import * as echarts from 'echarts';
import moment from 'moment';
import { ChartSyncService } from 'src/app/services/chart-sync/chart-sync.service';
import { ChartType, MomentCategory, TimeDifferenceDetails } from 'src/app/shared/models/common.model';
import { DateTimeService } from '../../services/date-time.service';
import { ChartXAxisType, IChartThreshold, IEchartData, ITooltipVariable, DispatchActionType } from '../chart.model';
import { DEFAULT_PRECISION_VALUE } from 'src/app/services/constants';
import { secondsToMilliseconds } from 'src/app/shared/common';

@Component({
    selector: 'line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnChanges, OnDestroy, OnInit {
    @Input() public data: IEchartData[];
    @Input() public labels: string[];
    @Input() public index: number;
    @Input() public chartAxisView: boolean;
    @Output() public filteredData = new EventEmitter<IEchartData[]>();
    @Output() public zoomApplied = new EventEmitter<boolean>();
    @Input() public thresholds: IChartThreshold[];
    @Output() chartLoaded = new EventEmitter<boolean>();

    @ViewChild('eLineChart', { static: true }) eLineChart: ElementRef;
    previousIndex: number;
    isHighlightChecked: boolean = true;
    timeDifference: TimeDifferenceDetails = { isEmptyRegionHidden: false, timeDifference: 0 };
    maxValueLength: number = 6;

    @HostListener('window:resize', ['$event']) onResize(event) {
        if (this.myChart) {
            this.myChart.resize();
        }
    }

    options: any;
    myChart;
    scale = true;
    mergedValues: number[]

    constructor(
        private chartSyncService: ChartSyncService,
        private dateTimeService: DateTimeService,
        private renderer2: Renderer2
    ) {}

    public ngOnChanges(changes: SimpleChanges) {
        if (!changes) {
            return;
        }
        if (this.data && this.labels && this.thresholds) {
            this.options = this.setEchartParameters(this.data, this.labels, this.thresholds);
            this.eChartInit();
            this.mergedValues = [].concat(...this.data.map(d => d.values));
            this.myChart.on('datazoom', () => {
                if (!this.data) {
                    return;
                }
                const option = this.myChart.getOption();
                let startValue: number;
                let endValue: number;
                const zoomValue: { startValue: number, endValue: number } = option.dataZoom[0];
                if (this.chartAxisView) {
                    startValue = zoomValue.startValue / 1000;
                    endValue = zoomValue.endValue / 1000;
                }
                else {
                    startValue = this.data ? this.mergedValues[zoomValue.startValue][0] : 0;
                    endValue = this.data ? this.mergedValues[zoomValue.endValue][0] : 0;
                }
                
                
                const dataInWindow: IEchartData[] = this.data.map((item: IEchartData) => {
                    const values = item.values.filter((i: number[]) => i[0] >= startValue && i[0] <= endValue);
                    return { id: item.id, values: values } as IEchartData;
                });
                this.filteredData.emit(dataInWindow);
                this.zoomApplied.emit(true);
            });
        }
        if (!this.myChart) {
            this.eChartInit();
        }
    }

    ngOnInit(): void {
        this.chartSyncService.timeDifferenceValue$.subscribe((value: TimeDifferenceDetails) => {
            this.timeDifference = value;
            if (this.data && this.labels && this.thresholds) {
                this.options = this.setEchartParameters(this.data, this.labels, this.thresholds);
                this.eChartInit();
            }
        });
    }

    ngAfterViewInit() {
        this.chartLoaded.emit(true);
    }

    ngOnDestroy(): void {
        if (this.myChart) {
            this.myChart.dispose();
        }
    }

    getImage() {
        return this.myChart.getDataURL({
            excludeComponents: ['dataZoom', 'legend']
        });
    }

    toggleScale() {
        this.scale = !this.scale;
        this.myChart.setOption({
            yAxis: {
                scale: this.scale
            },
            dataZoom: [
                {
                    type: 'slider',
                    xAxisIndex: [0],
                    filterMode: 'filter'
                },
                {
                    type: 'slider',
                    yAxisIndex: [0],
                    show: !this.scale,
                    disabled: !this.scale,
                    filterMode: 'none'
                }
            ],
        });
        this.myChart.dispatchAction({
            type: 'dataZoom',
            dataZoomIndex: 1,
            start: 0,
            end: 100
        });
    }

    setEchartParameters(data, labels, thresholds: IChartThreshold[]) {
        let valueSeries: any[] = data?.map((data, index) => {
            let points = [];
            if (data.values != undefined) {
                points = [].concat(...data.values
                    .filter((v) => v[1] != null)
                    .map(this.createChartPoints()));
            }
            this.chartSyncService.EchartPoints = points;
            return {
                name: labels[index] + '-' + (index + 1),
                type: 'line',
                symbol: 'circle',
                symbolSize: 1.5,
                smooth: false,
                lineStyle: {
                    normal: {
                        width: 1,
                    }
                },
                markLine: {
                    data: thresholds,
                    precision: DEFAULT_PRECISION_VALUE
                },
                data: points,
            };
        });
        let valueLegends: any[] = labels.map((label, index: number) => (
            {
                name: label + '-' + (index + 1),
                icon: 'rect'
            }
        ));

        let chartOptions: any = {
            hoverLayerThreshold: Infinity,
            style: {
                lineWidth: 0.5
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params: ITooltipVariable[]) => this.customTooltip(params)
            },
            dataZoom: [
                {
                    type: 'slider',
                    xAxisIndex: [0],
                    filterMode: 'filter'
                }
            ],
            xAxis: {
                type: this.chartAxisView ? ChartXAxisType.Time : ChartXAxisType.Category,
                axisLabel: this.chartAxisView ? {} : {
                    formatter: (value: string) => moment(value).format(this.dateTimeService.getDateAndTimeFormat(true))
                }
            },
            yAxis: {
                type: 'value',
                scale: true
            },
            legend: {
                data: valueLegends,
            },
            series: valueSeries
        };
        return chartOptions;
    }

    /**
     * Creates chart points in the form of [Date, number] from a given array.
     * @returns {(v: [number, number | string], index: number, array: [number, number][]) => 
     * [Date, number | string][]} Returns a function which creates chart points in the form of [Date, number] from a given array.
     */
    private createChartPoints(): (v: [number, number | string], index: number, array: [number, number][]) => [Date, number | string][] {
        return (v, index, array): [Date, number | string][] => {
            const date: Date = new Date(secondsToMilliseconds(v[0]));
            const nextValue: [number, number | string] = array[index + 1];
            const value: number | string = typeof v[1] === 'number' ?
                v[1] : (v[1].toString().length > this.maxValueLength ? parseFloat(v[1]).toExponential(this.maxValueLength) : parseFloat(v[1]));

            if (!nextValue)
                return [[date, value]];
            const milliSecondsValue: number = secondsToMilliseconds(nextValue[0]);
            const getSecondsValue: number = moment(milliSecondsValue).diff(moment(date), MomentCategory.Seconds);
            return getSecondsValue >= this.timeDifference.timeDifference && this.timeDifference.isEmptyRegionHidden ?
                [[date, value], [null, null]] : [[date, value]];
        };
    }

    /**
     * This function is used to design overall structure of tooltip for line chart
     * @param params get tooltip properties
     * @returns {string} tooltip html in string format
     */
    private customTooltip(params: ITooltipVariable[]): string {
        const dateTimeStr: string = moment(params[0].axisValueLabel).format(this.dateTimeService.getDateAndTimeFormat(true));
        const tooltipVariables: string = this.tooltipVariables(params);
        const divElement = this.renderer2.createElement('div');

        divElement.classList.add('tooltip-container');
        divElement.appendChild(this.renderer2.createText(dateTimeStr));

        return divElement.outerHTML + tooltipVariables;
    }

    /**
     * This function is used to design the selected parameters in the tooltip for line chart
     * @param params get tooltip properties
     * @returns {string} Generated html of variables in string format
     */
    private tooltipVariables(params: ITooltipVariable[]): string {
        const divElement = this.renderer2.createElement('div');
        params.forEach((f: ITooltipVariable) => divElement.appendChild(this.createDOMElement(f)));
        return divElement.outerHTML;
    }

    /**
     * This function is used to create a DOM elements for tooltip variables
     * @param obj
     * @returns The html node for a tooltip variable
     */
    private createDOMElement(obj: ITooltipVariable) {
        const parentDiv = this.renderer2.createElement('div');
        const variableName = this.renderer2.createElement('span');
        const variableValue = this.renderer2.createElement('span');

        variableName.appendChild(this.renderer2.createText(obj.seriesName));
        variableValue.appendChild(this.renderer2.createText(obj.value[1].toString()));

        parentDiv.classList.add('tooltip-container');
        variableValue.classList.add('param-values');

        parentDiv.innerHTML = obj.marker;
        parentDiv.appendChild(variableName);
        parentDiv.appendChild(variableValue);

        return parentDiv;
    }

    eChartInit() {
        this.myChart = echarts.init(this.eLineChart.nativeElement);
        this.myChart.clear();
        this.myChart.group = 'echart';

        setTimeout(() => {
            this.myChart.resize();
        });

        if (this.options != undefined) {
            this.myChart.setOption(this.options);
        }
    }

    /**
     * This function initializes the chart with the original values and chart options
     */
    initializeChart(): void {
        this.eChartInit();
        this.filteredData.emit(this.data);
    }

    onHighlight(chartSync: boolean) {
        if (chartSync) {
            this.myChart.on(DispatchActionType.Highlight, (evt) => {
                const option = this.myChart.getOption();
                if (this.isHighlightChecked && option) {
                    const timeDateValue: Date = (this.chartAxisView && this.data)
                        ? new Date(option.xAxis[0]?.axisPointer?.value ?? 0)
                        : new Date((this.mergedValues[option.xAxis[0]?.axisPointer?.value] ?? [0])[0] * 1000);
                    this.chartSyncService.highlightChartPoint(timeDateValue, ChartType.MAP);
                }
            });
        } else {
            this.myChart.off(DispatchActionType.Highlight);
        }
    }

    /**
    * Highlights a point on the chart.
    * @param {number} index - Index of the point to be highlighted.
    */
    highlightPoint(index: number) {
        this.isHighlightChecked = false;
        if (this.previousIndex !== undefined) {
            this.chartDispatchAction(DispatchActionType.Downplay, this.previousIndex);
        }
        this.chartDispatchAction(DispatchActionType.Highlight, index);
        this.chartDispatchAction(DispatchActionType.ShowTip, index);
        this.previousIndex = index;
        this.isHighlightChecked = true;
    }

    /**
    * Dispatches an action to a chart at the given index.
    * @param {string} type - The type of action to dispatch.
    * @param {number} index - The index of the data point to apply the action to.
    * @returns {void}
    */
    chartDispatchAction(type: string, index: number) {
        this.myChart.dispatchAction({
            type: type,
            seriesIndex: 0,
            dataIndex: index
        });
    }
}
