<mat-tab-group class="editor-details">
  <mat-tab *ngIf="browserInfo.is_mobile" label="Properties">
    <ng-template matTabContent>
      <symbol-prop
        [sym_lib]="sym_lib"
        [selected_symbol]="selected_symbol"
        (onSymbolDelete)="onSymbolDeleteEvent($event)"
      ></symbol-prop>
    </ng-template>
  </mat-tab>
  <mat-tab #variableTab label="{{'library_editor.tab_variables' | translate}}">
    <ng-template matTabContent>
        <div style='overflow: auto;' [ngClass]="{addMobileTableScroll: browserInfo.is_ios}">
          <table mat-table [dataSource]="signalDataSource" 
                 class="mat-elevation-z8 full-width" [class.ngstd-main-row]="browserInfo.is_mobile">

              <!-- Checkbox Column -->
              <ng-container matColumnDef="select" sticky>
                  <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="signalSelection.hasValue() && isAllSelected()"
                                    [indeterminate]="signalSelection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                      </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row">
                      <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? signalSelection.toggle(row) : null"
                                    [checked]="signalSelection.isSelected(row)"
                                    [aria-label]="checkboxLabel(row)">
                      </mat-checkbox>
                  </td>
              </ng-container>

              <ng-container *ngIf="browserInfo.is_mobile" matColumnDef="name" sticky>
                  <th mat-header-cell *matHeaderCellDef> {{'library_editor.name' | translate}} </th>
                  <td mat-cell *matCellDef="let element; let i = index"> 
                      <div class='ngstd-item-wrapper'>
                          <div class="ngstd-delete-indicator">
                              <i class="material-icons ngstd-delete-icon" *ngIf="isLeftSign()">delete_sweep</i>
                              <span>&nbsp;</span>
                              <i class="material-icons ngstd-delete-icon" *ngIf="isRightSign()">delete_sweep</i>
                          </div>
                          <div #elementRefrence class="ngstd-item-container "
                                          (panend)="panend($event, i, elementRefrence)" 
                                          (panmove)="panmove($event,elementRefrence)" 
                                          [@slideLeft]="ngstdIndexNumber === i"
                                          (@slideLeft.done)=alignComplete($event)>
                              {{element.name}}            
                          </div>
                      </div>
                  </td>
              </ng-container>
              <ng-container *ngIf="!browserInfo.is_mobile" matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> {{'library_editor.name' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>

              <ng-container matColumnDef="data_type">
                  <th mat-header-cell *matHeaderCellDef> {{'library_editor.data_type' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{symbolVariableType(element)}} </td>
              </ng-container>

              <ng-container matColumnDef="value_uom">
                <th mat-header-cell *matHeaderCellDef> {{'library_editor.unit_of_measure' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.value_uom}} </td>
              </ng-container>

              <ng-container matColumnDef="factor">
                  <th mat-header-cell *matHeaderCellDef> {{'library_editor.factor' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> 
                    {{symbolVariableFactor(element)}} 
                  </td>
              </ng-container>

              <ng-container matColumnDef="offset">
                  <th mat-header-cell *matHeaderCellDef> {{'library_editor.offset' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> 
                    {{symbolVariableOffset(element)}} 
                  </td>
              </ng-container>

              <ng-container matColumnDef="start_bit">
                  <th mat-header-cell *matHeaderCellDef> {{'library_editor.start_bit' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> 
                    {{element.starting_bit}} 
                  </td>
              </ng-container>

              <ng-container matColumnDef="len">
                  <th mat-header-cell *matHeaderCellDef> {{'library_editor.length' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> 
                      {{element.length}} 
                  </td>
              </ng-container>

              <ng-container matColumnDef="data_format">
                  <th mat-header-cell *matHeaderCellDef> {{'library_editor.data_format' | translate}} </th>
                  <td mat-cell *matCellDef="let element"> {{symbolVariableDataFormat(element)}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="signalDataDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: signalDataDisplayedColumns;" [ngClass]="{hover_table_row: browserInfo.is_desktop}" (click)="tableSignalsRowClick(row)"></tr>
          </table>
        </div>  
    </ng-template>
  </mat-tab>
  <mat-tab label="{{'library_editor.tab_layout' | translate}}" *ngIf="!browserInfo.is_mobile">
    <ng-template matTabContent>
        <mat-grid-list cols="9" rowHeight="4:1">
            <mat-grid-tile class="layout_bits_title"></mat-grid-tile>
            <mat-grid-tile class="layout_bits_title">{{'library_editor.bit' | translate:{number: 7} }}</mat-grid-tile>
            <mat-grid-tile class="layout_bits_title">{{'library_editor.bit' | translate:{number: 6} }}</mat-grid-tile>
            <mat-grid-tile class="layout_bits_title">{{'library_editor.bit' | translate:{number: 5} }}</mat-grid-tile>
            <mat-grid-tile class="layout_bits_title">{{'library_editor.bit' | translate:{number: 4} }}</mat-grid-tile>
            <mat-grid-tile class="layout_bits_title">{{'library_editor.bit' | translate:{number: 3} }}</mat-grid-tile>
            <mat-grid-tile class="layout_bits_title">{{'library_editor.bit' | translate:{number: 2} }}</mat-grid-tile>
            <mat-grid-tile class="layout_bits_title">{{'library_editor.bit' | translate:{number: 1} }}</mat-grid-tile>
            <mat-grid-tile class="layout_bits_title">{{'library_editor.bit' | translate:{number: 0} }}</mat-grid-tile>

            <ng-container *ngFor="let tile of bits; let i = index">
                <mat-grid-tile class="layout_bits_title">{{'library_editor.byte' | translate:{number: i} }}</mat-grid-tile>
                <mat-grid-tile *ngFor="let tile of bits[i]" [style.background]="tile.color" matTooltip="{{tile.tooltip}}"><span [class.warning-tile-circle]="tile.bits_overlap" [style.background-color]="tile.color2"></span>{{tile.title}}</mat-grid-tile>
            </ng-container>
        </mat-grid-list>
    </ng-template>
  </mat-tab>
  <!-- buttons tab -->
  <mat-tab disabled> 
    <ng-container *ngIf="variableTab.isActive">
      <ng-template mat-tab-label>
        <button *ngIf="!browserInfo.is_mobile" 
                 mat-icon-button matTooltip="{{'library_editor.delete' | translate }}" (click)="deleteBtnClick()" [disabled]="signalSelection.isEmpty()">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{'library_editor.add' | translate }}" (click)="addBtnClick()">
          <mat-icon>add</mat-icon>
        </button>
      </ng-template>
    </ng-container>
  </mat-tab>
</mat-tab-group>