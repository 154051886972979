export class EditorPayPeriod {
    id: number;
    year: string;
    monthDay: string;

    constructor(id: number, year: string, monthDay: string) {
        this.id = id;
        this.year = year;
        this.monthDay = monthDay;
    }

    static empty(): EditorPayPeriod {
        return new EditorPayPeriod(undefined, undefined, undefined);
    }
}
