<mat-toolbar class="mat-primary">
    <h2>{{ data.title }}</h2>
    <span class="spacer"></span>
    <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>
            close
        </mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <form [formGroup]="form" fxLayout="column">
        <mat-form-field>
            <mat-select placeholder="{{'user.country' | translate}}" [(ngModel)]="country" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let country of countryList" [value]="country.code">
                    {{ country.code}} - {{country.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="chip-list">
            <mat-chip-list #chipList>
                <mat-chip *ngFor="let text of textAlert.phoneNumbers" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(text)">
                    {{text}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="{{'rule_editor.phone_numbers' | translate}}" formControlName="text" type="phone" [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)">
                <mat-error *ngIf="textInput.touched && textInput.invalid" class="alert alert-danger">
                    <mat-error *ngIf="textInput.errors.phoneFormat">{{'form.phone_number_not_valid' | translate}}</mat-error>
                </mat-error>
            </mat-chip-list>
        </mat-form-field>
        <mat-form-field>
            <input matInput #text_message placeholder="{{'dialog.text_message' | translate}}" formControlName="text_message" maxlength="1600"
                required>
            <mat-hint align="end">{{ textMessage.value.length }} / 1600</mat-hint>
            <mat-error *ngIf="textMessage.touched && textMessage.invalid" class="alert alert-danger">
                <mat-error *ngIf="textMessage.errors.required">{{'form.text_message_required' | translate}}</mat-error>
            </mat-error>
        </mat-form-field>
        <div *ngIf="whenArray">
            <mat-form-field class="select-array">
                <mat-select formControlName="when" placeholder="{{'rule_editor.send_alert_when' | translate}}" ariaLabel="Send Alert When">
                    <mat-option *ngFor="let obj of whenArray" [value]="obj.text">
                        {{obj.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
    <div class="interpolation-message">
        {{'alert.interpolation_msg' | translate}}
        <br>
        <a [routerLink]="['../../help/user-doc']" (click)="dialogRef.close()">{{'help.user_documentation' | translate}}</a>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()">
        {{'btn.cancel' | translate}}
    </button>
    <button mat-button class="mat-primary confirm"
        [disabled]="textMessage.invalid || textAlert.phoneNumbers.length == 0 || (whenArray && !sendWhen.value)"
        (click)="confirm()">
        {{'btn.apply' | translate}}
    </button>
</mat-dialog-actions>