import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { DateTimeService } from 'src/app/services/date-time.service';
import { StatisticsService } from 'src/app/statistics/services/statistics.service';

@Component({
    selector: 'data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
    dateTimeFormat: string = this.dateTimeService.getDateAndTimeFormat();

    @Input('data') set tableData(data) {
        this.datasource = new TableVirtualScrollDataSource(data);
    }
    @Input('labels') set tableLabels(labels: string[]) {
        if (!!labels) {
            this.labels = ['time', ...labels];
        }
    }
    @ViewChild(CdkVirtualScrollViewport, { static: false })

    private cdkVirtualScrollViewport: CdkVirtualScrollViewport;
    public datasource;
    public labels: string[] = [];

    constructor(private dateTimeService: DateTimeService,
        private statisticsService: StatisticsService) { }

    ngOnInit(): void {
        this.statisticsService.matTabChanged.subscribe((flag: boolean): void => {
            if (!flag) {
                return;
            }
            this.cdkVirtualScrollViewport?.checkViewportSize();
        });
    }
}