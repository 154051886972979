

export enum MenuNavItem {
    HOME,
    ADMINISTRATORS,
    OEMS,
    DEALERS,
    CLIENTS,
    USERS,
    DEVICES,
    DASHBOARDS,
    MODELS,
    GEO_FENCING,
    ALERTS,
    LIVE_MAP,
    REPORTS,
    ACCOUNT_SETTINGS,
    INVOICES,
    CUSTOM_REPORTS,
    HELP,
    ABOUT,
    STATISTICS
}

export enum RouterIcon {
    HOME = 'home',
    ADMINISTRATORS = 'security',
    OEMS = 'domain',
    DEALERS = 'account-box-multiple',
    CLIENTS = 'account-outline',
    USERS = 'account-multiple',
    DEVICES = 'hubspot',
    DASHBOARDS = 'view-dashboard',
    CUSTOM_WIDGETS = 'shape',
    MODELS = 'book-variant-multiple',
    GEO_FENCING = 'crosshairs',
    ALERTS = 'alert-decagram',
    LIVE_MAP = 'map-marker',
    REPORTS = 'file-chart',
    CUSTOM_REPORTS = 'chart-line',
    ACCOUNT_SETTINGS = 'settings',
    INVOICES = 'credit-card-outline',
    HELP = 'help-circle-outline',
    ABOUT = 'information-outline',
    DEFAULT = 'domain',
    STATISTICS = 'equalizer',
    SERVICE_DOWNTIME = 'update'
}

export const MenuNavIcon = {
    [MenuNavItem.OEMS]: RouterIcon.OEMS,
    [MenuNavItem.DEALERS]: RouterIcon.DEALERS,
    [MenuNavItem.CLIENTS]: RouterIcon.CLIENTS,
    [MenuNavItem.USERS]: RouterIcon.USERS,
    [MenuNavItem.DEVICES]: RouterIcon.DEVICES,
    [MenuNavItem.DASHBOARDS]: RouterIcon.DASHBOARDS,
    [MenuNavItem.MODELS]: RouterIcon.MODELS,
    [MenuNavItem.GEO_FENCING]: RouterIcon.GEO_FENCING,
    [MenuNavItem.ALERTS]: RouterIcon.ALERTS,
    [MenuNavItem.LIVE_MAP]: RouterIcon.LIVE_MAP,
    [MenuNavItem.REPORTS]: RouterIcon.REPORTS,
    [MenuNavItem.ACCOUNT_SETTINGS]: RouterIcon.ACCOUNT_SETTINGS,
    [MenuNavItem.INVOICES]: RouterIcon.INVOICES,
    [MenuNavItem.HELP]: RouterIcon.HELP,
    [MenuNavItem.ABOUT]: RouterIcon.ABOUT,
    [MenuNavItem.STATISTICS]: RouterIcon.STATISTICS,
};
