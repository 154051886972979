<mat-toolbar class="mat-primary">
    <div>
        <h2>{{ 'time_difference.heading' | translate }}</h2>
    </div>
    <span class="spacer"></span>
    <button mat-icon-button (click)="close()">
        <mat-icon>
            <i class="material-icons">close</i>
        </mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'time_difference.heading' | translate }} (m)</mat-label>
            <input matInput formControlName="time" class="form-control" type="number">
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="close()">{{ 'btn.cancel' | translate }}</button>
    <button mat-button class="mat-primary confirm" [disabled]="!form.valid" (click)="setTimeDifferenceValue()">
        {{ 'btn.confirm' | translate }}
    </button>
</mat-dialog-actions>