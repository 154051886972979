import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { MapLogModule } from 'src/app/chart/map-log/map-log.module';
import { DataTableModule } from 'src/app/chart/data-table/data-table.module';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { LineChartModule } from '../line-chart/line-chart.module';
import { ChartCardComponent } from './chart-card.component';


@NgModule({
  imports: [
    AppMaterialModule,
    ScrollingModule,
    MapLogModule,
    LineChartModule,
    DataTableModule
  ],
  exports: [ ChartCardComponent ],
  declarations: [ ChartCardComponent ],
})
export class ChartCardModule { }
