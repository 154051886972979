import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dealer-editor',
    templateUrl: './dealer-editor.component.html',
    styleUrls: ['./dealer-editor.component.css']
})
export class DealerEditorComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
