import { NgModule } from '@angular/core';
import { LineChartComponent } from './line-chart.component';


@NgModule({
  imports: [ ],
  declarations: [ LineChartComponent ],
  exports: [ LineChartComponent ]
})
export class LineChartModule { }
