import { Component, Inject } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

import { SymEnumValue } from "../model/sym_enum";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: "dialog-enum-edit-prop-val-dialog",
    templateUrl: "./enum-edit-property-value.component.html",
    styleUrls: ["./dialog.component.css"]
})
export class EnumEditPropertyValueDialog {
    public title: string;

    constructor(
        public dialogRef: MatDialogRef<EnumEditPropertyValueDialog>,
        @Inject(MAT_DIALOG_DATA) public data: SymEnumValue,
        private translate: TranslateService
    ) {
        if (typeof data.name === "undefined") {
            this.title = "library_editor.dialog.title_add_enum_prop";
        } else {
            this.title = "library_editor.dialog.title_edit_enum_prop";
        }
    }

    submitByEnter() {
        this.dialogRef.close(this.data);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
