export enum DeviceRatePlan {
  'MRS Electronics - 1MB Plan' = '1MB',
  'MRS Electronics - 3MB Plan' = '3MB',
  'MRS Electronics - 5MB Plan' = '5MB',
  'MRS Electronics - 10MB Plan' = '10MB',
  'MRS Electronics - 50MB Plan' = '50MB',
  'MRS Electronics - 100MB Plan' = '100MB',
  'MRS Electronics - 250MB Plan' = '250MB',
  'MRS Electronics - 500MB Plan' = '500MB',
  'MRS Electronics - 1GB Plan' = '1GB',
  'MRS Electronics - 2GB Plan' = '2GB',
  'MRS Electronics - 5GB Plan' = '5GB',
  'MRS Electronics - 10GB Plan' = '10GB',
  'MRS Electronics - 20GB Plan' = '20GB',
  'MRS Electronics - 50GB Plan' = '50GB',
  'MRS Electronics - 100GB Plan' = '100GB'
}
