
<ng-container *ngIf="!isLenticul; else elseLenticul">
<div (window:resize)="onResize($event)">
    <mat-toolbar class="mat-primary">
      <h2>{{'dialog.device_details' | translate}} {{isMultipleEdit ? '' : '· ' + data.id}}</h2>
      <span class="spacer"></span>
        <button mat-icon-button (click)="dialogRef.close()">
            <mat-icon>
                close
            </mat-icon>
        </button>
    </mat-toolbar>

    <mat-dialog-content>
      <form [formGroup]="form">
        <div
          class="align-icon"
          *ngIf="!data.clientRole"
          [hidden]="isMultipleEdit"
        >
            <mat-icon *ngIf="!isMobile" class="align">input</mat-icon>
            <mat-form-field>
                <input
                  matInput
                  [formControl]="identifier"
                  placeholder="{{'live_map.identifier' | translate}}"
                  id="identifier"
                  ngDefaultControl
                >
            </mat-form-field>
        </div>

        <div class="align-icon">
            <mat-icon *ngIf="!isMobile" class="align"></mat-icon>
            <mat-form-field>
                <mat-select
                  formControlName="deviceTypeId"
                  placeholder="{{'devices.device_type' | translate}}"
                >
                    <mat-option
                      *ngFor="let dt of data.device_types"
                      [value]="dt.id"
                      ngDefaultControl
                    >
                        {{dt.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div
          *ngIf="data.clientRole"
          class="align-icon"
          [hidden]="isMultipleEdit"
        >
            <mat-icon *ngIf="!isMobile" class="align">input</mat-icon>
            <mat-form-field>
                <input
                  matInput
                  id="userIdentifier"
                  [formControl]="userIdentifier"
                  placeholder="{{'live_map.identifier' | translate}}"
                  ngDefaultControl
                >
            </mat-form-field>
        </div>

        <div
          class="align-icon"
          [hidden]="isMultipleEdit"
        >
            <mat-icon *ngIf="!isMobile" class="align">settings_remote</mat-icon>
            <mat-form-field>
                <input
                  matInput
                  [formControl]="iccid"
                  placeholder="{{'dialog.iccid' | translate}}"
                  ngDefaultControl
                  (input)="changeIccid()"
                >
                <button
                  mat-button
                  *ngIf="iccid.value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  [disabled]="!data.adminRole"
                  (click)="clearIccid()"
                >
                  <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="align-icon">
            <mat-icon
              *ngIf="!isMobile"
              class="align"
              [ngStyle]="{'color': getStatusColor(simStatus.value)}">
                brightness_1</mat-icon>
            <mat-form-field>
                <mat-select
                  [formControl]="simStatus"
                  placeholder="{{'list_view.status' | translate}}"
                >
                    <mat-option
                      *ngFor="let stat of simStatusValues"
                      [value]="stat.value"
                      ngDefaultControl
                    >
                        {{stat.view}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="align-icon">
          <mat-icon *ngIf="!isMobile" class="align"></mat-icon>
          <mat-form-field>
            <mat-select
              formControlName="ratePlanId"
              placeholder="{{'dialog.rate_plan' | translate}}"
            >
              <mat-option
                *ngFor="let plan of ratePlans"
                [value]="plan.value"
                ngDefaultControl
              >
                {{plan.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="checkbox-wrap">
          <mat-checkbox formControlName="internationalPlan">
            {{'dialog.international' | translate}}
          </mat-checkbox>
        </div>

        <div *ngIf="hasSim">
            <div class="center-inside">
                <mat-spinner [diameter]="20" class="simSpin"></mat-spinner>
                <span class="space-between">{{'dialog.loading_sim_data' | translate}}</span>
            </div>
        </div>

        <div *ngIf="simError">
            <div class="center-inside sim-error">
                <mat-icon>report_problem</mat-icon>
                <span class="space-between">{{'dialog.error_fetching_sim_data' | translate}}</span>
            </div>
        </div>

        <div *ngIf="!hasSim && !simError && !isMultipleEdit" class="sim-values">
            <table>
                <tr>
                    <td>{{'dialog.in_session' | translate}}:</td>
                    <td>
                        <div class="align-icon">
                            <mat-icon [ngStyle]="{'color': getStatusColor(obj.session)}">brightness_1</mat-icon>
                            <span class="align-text">{{ obj.session }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>{{'dialog.usage' | translate}}:</td>
                    <td>{{ obj.usage }}</td>
                </tr>
            </table>
        </div>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button (click)="dialogRef.close()">
            {{'btn.cancel' | translate}}
        </button>
        <button
          mat-button
          class="mat-primary confirm"
          [disabled]="(data.clientRole && !userIdentifier.value && !isMultipleEdit) || (!data.clientRole && !identifier.value && !isMultipleEdit)"
          (click)="submit()"
        >
          {{'btn.apply' | translate}}
        </button>
    </mat-dialog-actions>
</div>
</ng-container>

<ng-template #elseLenticul>
  <div (window:resize)="onResize($event)">
    <mat-toolbar class="mat-primary">
      <h2>{{ 'dialog.device_details' | translate }} {{ isMultipleEdit ? '' : '· ' + data.id }}</h2>
      <span class="spacer"></span>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>

    <mat-dialog-content>
      <form [formGroup]="form">
        <div class="align-icon" *ngIf="!data.clientRole" [hidden]="isMultipleEdit">
          <mat-icon *ngIf="!isMobile" class="align">input</mat-icon>
          <mat-form-field>
            <input matInput [formControl]="identifier" [placeholder]="'live_map.identifier' | translate" id="identifier"
              ngDefaultControl>
          </mat-form-field>
        </div>

        <div class="align-icon">
          <mat-icon *ngIf="!isMobile" class="align"></mat-icon>
          <mat-form-field>
            <mat-select formControlName="deviceTypeId" [placeholder]="'devices.device_type' | translate">
              <mat-option *ngFor="let dt of data.device_types" [value]="dt.id" ngDefaultControl>
                {{ dt.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="data.clientRole" class="align-icon" [hidden]="isMultipleEdit">
          <mat-icon *ngIf="!isMobile" class="align">input</mat-icon>
          <mat-form-field>
            <input matInput id="userIdentifier" [formControl]="userIdentifier"
              [placeholder]="'live_map.identifier' | translate" ngDefaultControl>
          </mat-form-field>
        </div>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-button (click)="closeDialog()">
        {{ 'btn.cancel' | translate }}
      </button>
      <button mat-button class="mat-primary confirm"
        [disabled]="(data.clientRole && !userIdentifier.value && !isMultipleEdit) || (!data.clientRole && !identifier.value && !isMultipleEdit)"
        (click)="submit()">
        {{ 'btn.apply' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
