import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from "@angular/material/tooltip";
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ManageFactoryService } from "../services/manage-factory/manage-factory.service";
import { ConfirmDialogComponent } from "../dialogs/confirm-dialog/confirm-dialog.component";

import { UpdateLibraryEventType } from "./model/types";
import { SymEnumValue, SymEnum } from "./model/sym_enum";
import {
    SymSymbol
} from "./model/sym_symbol";
import {
    SymLibrary
} from "./model/sym_library";
import { UserBrowserInfo } from "./model/user_browser_info";
import { TooltipDefaults } from "./model/tooltip_defaults";

import { EnumEditPropertyValueDialog } from "./dialogs/enum-edit-property-value.component";
import { EnumNewPropertyDialog } from "./dialogs/enum-new-property.component";
import { SymbolNewDialog } from "./dialogs/symbol-new.component";
import { LibraryEditService } from './service/library-edit.service';
import { AssociatedCount } from '../shared/models/common.model';
import { AssignedDetailsDialog } from '../dialogs/assigned-details-dialog/assigned-details-dialog.component';
import { Translations } from '../model-editor/model/model-editor';

@Component({
    selector: "editor-app",
    templateUrl: "./library-editor.component.html",
    styleUrls: ["./library-editor.component.css"],
    providers: [
        UserBrowserInfo,
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: TooltipDefaults }
    ]
})
export class LibraryEditorComponent implements OnInit {
    id: string;
    private subscription: Subscription;
    public eventUpdateLibrary: Subject<any> = new Subject<any>();

    sym_lib: SymLibrary;
    selected_symbol: SymSymbol;
    selected_enum: SymEnum;
    route_model_id: number;
    route_protocol_id: number;
    private tab_selected: number = 0;

    isSymbolFormEditValid$: BehaviorSubject<boolean>;

    constructor(
        private activateRoute: ActivatedRoute,
        private manageFactory: ManageFactoryService,
        public enumEditPropDialog: MatDialog,
        public editDialog: MatDialog,
        public symbolNewDialog: MatDialog,
        public confirmDialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router,
        public browserInfo: UserBrowserInfo,
        private title: Title,
        private translate: TranslateService,
        private libraryEditService: LibraryEditService,
    ) {
        const navigation = this.router.getCurrentNavigation();
        const state = navigation.extras.state as { model_id: number, protocol_id: number };
        if (state) {
            this.route_model_id = state.model_id;
            this.route_protocol_id = state.protocol_id;
        }
    }

    ngOnInit() {
        let self = this;
        this.subscription = this.activateRoute.params.subscribe(
            (params: any) => {
                console.log("params:", params);
                self.id = params["id"] || "0";

                setTimeout(() => {
                    self.update();
                }, 100);
            }
        );

        this.isSymbolFormEditValid$ = this.libraryEditService.isSymbolFormEditValid$;
    }

    update() {
        let self = this;
        this.translate.get("library_editor.loading").subscribe((tr_loading: string) => {
            this.snackBar.open(tr_loading, null, { horizontalPosition: "left" });
            this.manageFactory.libraries.readOne(this.id).subscribe(
                async (res: any) => {
                    if (res.status == 200) {
                        console.log("Loading:", res.data);
                        this.sym_lib = new SymLibrary(res.data);
                        if (this.sym_lib.symbols.length) {
                            this.selected_symbol = this.sym_lib.symbols[0];
                        }
                        if (this.sym_lib.enums.length) {
                            this.selected_enum = this.sym_lib.enums[0];
                        }
                    } else {
                        this.sym_lib = new SymLibrary({ library_id: self.id });
                    }
                    this.snackBar.dismiss();
                },
                (err: any) => {
                    console.log(err);
                    this.sym_lib = new SymLibrary({ library_id: self.id });
                    this.snackBar.dismiss();
                }
            );
        });
    }

    addNewEnumValue() {
        let new_row = new SymEnumValue({});
        const dialogRef = this.enumEditPropDialog.open(
            EnumEditPropertyValueDialog,
            {
                width: "450px",
                data: new_row
            }
        );

        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.selected_enum.values.push(data);
                this.eventUpdateLibrary.next({
                    type: UpdateLibraryEventType.ENUM_VALUE_NEW,
                    object: this.selected_enum
                });
            }
        });
    }

    addNewEnum() {
        const dialogRef = this.editDialog.open(EnumNewPropertyDialog, {
            panelClass: "toolbar-dialog",
            width: "450px",
            data: new SymEnum({})
        });

        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                if (typeof this.sym_lib !== "undefined") {
                    this.sym_lib.enums.push(data);
                    this.selected_enum = data;
                    this.eventUpdateLibrary.next({
                        type: UpdateLibraryEventType.ENUM_NEW,
                        object: this.selected_enum
                    });
                }
            }
        });
    }

    addNewSymbol() {
        const dialogRef = this.editDialog.open(SymbolNewDialog, {
            panelClass: "toolbar-dialog",
            width: "450px",
            data: new SymSymbol({})
        });

        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                if (typeof this.sym_lib !== "undefined") {
                    this.sym_lib.symbols.push(data);
                    this.selected_symbol = data;
                    this.eventUpdateLibrary.next({
                        type: UpdateLibraryEventType.SYMBOL_NEW,
                        object: this.selected_symbol
                    });
                }
            }
        });
    }

    deleteEnum(enum2delete: SymEnum) {
        this.translate.get(["library_editor.dialog.title_delete", "library_editor.dialog.desc_delete", "library_editor.dialog.btn_delete"], { name: enum2delete.name }).subscribe((translated: string) => {
            let dialog = this.confirmDialog.open(ConfirmDialogComponent, {
                data: {
                    title: translated['library_editor.dialog.title_delete'],
                    text: translated['library_editor.dialog.desc_delete'],
                    confirm: translated['library_editor.dialog.btn_delete']
                }
            });
            dialog.afterClosed().subscribe(result => {
                //if we get a true from the dialog change the device's status
                if (result) {
                    let found = false;
                    for (let i = 0; i < this.sym_lib.enums.length; i++) {
                        if (this.sym_lib.enums[i] === enum2delete) {
                            this.sym_lib.enums.splice(i, 1);
                            found = true;
                            break;
                        }
                    }
                }
            });
        });
    }

    deleteSymbol(symbol2delete: SymSymbol) {
        this.translate.get(["library_editor.dialog.title_delete", "library_editor.dialog.desc_delete", "library_editor.dialog.btn_delete"], { name: symbol2delete.name }).subscribe((translated: string) => {
            let dialog = this.confirmDialog.open(ConfirmDialogComponent, {
                data: {
                    title: translated['library_editor.dialog.title_delete'],
                    text: translated['library_editor.dialog.desc_delete'],
                    confirm: translated['library_editor.dialog.btn_delete']
                }
            });

            dialog.afterClosed().subscribe(result => {
                //if we get a true from the dialog change the device's status
                if (result) {
                    let found = false;
                    for (let i = 0; i < this.sym_lib.symbols.length; i++) {
                        if (this.sym_lib.symbols[i] === symbol2delete) {
                            this.sym_lib.symbols.splice(i, 1);
                            found = true;
                            break;
                        }
                    }
                }
            });
        });
    }

    saveLibrary(mode: number) {
        let data = this.sym_lib.dump();
        data['save_mode'] = mode;
        if (!data["model_id"] && this.route_model_id) {
            data["model_id"] = this.route_model_id;
        }
        if (!data["protocol_id"] && this.route_protocol_id) {
            data["protocol_id"] = this.route_protocol_id;
        }
        console.log("Saving Library:", data);

        this.translate.get("library_editor.saving").subscribe((tr_saving: string) => {
            this.snackBar.open(tr_saving, null, { horizontalPosition: "left" });
            let self = this;
            if (this.id === "0") {
                this.manageFactory.libraries.create(data).subscribe(
                    (res: any) => {
                        if (res.status === 200) {
                            console.log("Current route" + this.router.url);

                            self.id = res.data.library_id;
                            self.router.navigate(['', "library", self.id]);
                        };
                        self.snackBar.dismiss();
                    },
                    (err: any) => {
                        self.snackBar.dismiss();
                    }
                );
            } else {
                if (data["library_id"] === undefined) data["library_id"] = this.id;
                this.manageFactory.libraries.update(data).subscribe(
                    (res: any) => {
                        console.log(res);
                        if (res.values) {
                            const associatedCount: AssociatedCount = res;
                            const dlgDesc = Translations.VariableAssociationFound;
                            this.translate.get([dlgDesc, Translations.BtnSaveAnyway, Translations.TitleBindedAlert])
                                .subscribe((translated: string[]) => {
                                    const dialog = this.confirmDialog.open(AssignedDetailsDialog, {
                                        data: {
                                            title: translated[Translations.TitleBindedAlert],
                                            text: translated[dlgDesc],
                                            confirm: translated[Translations.BtnSaveAnyway],
                                            dependedCount: associatedCount,
                                            symLibrary: true
                                        }
                                    });

                                    dialog.afterClosed().subscribe(result => {
                                        //if we get a true from the dialog change the device's status
                                        if (result) {
                                            this.saveLibrary(1);
                                        }
                                    });
                                });
                        };
                        self.snackBar.dismiss();
                    },
                    (err: any) => {
                        self.snackBar.dismiss();
                    }
                );
            }
        });
    }

    onChangeSymbol(_symbol: SymSymbol) {
        this.selected_symbol = _symbol;
    }
    onChangeEnum(_enum: SymEnum) {
        this.selected_enum = _enum;
    }

    sym_var_img() {
        return this.tab_selected == 1 ? "add_variable" : "add_variable_color";
    }

    onSelectTab(idx: number) {
        this.tab_selected = idx;
    }

    deleteBtnClick() {
        if (this.tab_selected === 1) {
            this.deleteEnum(this.selected_enum);
            if (this.sym_lib.enums.length) {
                this.selected_enum = this.sym_lib.enums[0];
            }
        } else if (this.tab_selected === 0) {
            this.deleteSymbol(this.selected_symbol);
            if (this.sym_lib.symbols.length) {
                this.selected_symbol = this.sym_lib.symbols[0];
            }
        }
    }
    addBtnClick() {
        if (this.tab_selected === 1) {
            this.addNewEnum();
        } else if (this.tab_selected === 0) {
            this.addNewSymbol();
        }
    }
}
