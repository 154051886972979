import {
    Component,
    Input,
    Output,
    EventEmitter
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
// import { MAT_TOOLTIP_DEFAULT_OPTIONS } from "@angular/material";
import { NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from '@ngx-translate/core';

import { TooltipDefaults } from "../../model/tooltip_defaults";
import { UserBrowserInfo } from "../../model/user_browser_info";
import { SymElementType, UpdateLibraryEventType } from "../../model/types";
import { SymLibrary } from "../../model/sym_library";
import { SymSymbol } from "../../model/sym_symbol";
import {
    SymSymbolVariable,
    SymSymbolVaribaleType,
    SymSymbolVaribaleDataFormat
} from "../../model/sym_symbol_variable";
import { SymbolVariableEditDialog } from "../../dialogs/symbol-variable-edit.component";

// SWIPE component: should be moved there
import {
    trigger,
    style,
    keyframes,
    transition,
    animate,
    query,
    stagger
} from "@angular/animations";
import { MatSort, MatSortable } from '@angular/material/sort';

const Constants = {
    DEFAULT_SLIDE_THRESHOLD: 40,
    NUMBER_OF_DELETE_ICONS: 2,
    DEFAULT_CLASS_NAME: `ngstd-main-canvas`
};
// SWIPE component: should be moved there

export interface BitElement {
    color: string;
    title: string;
    tooltip: string;
    bits_overlap: boolean;
    color2: string;
}

const colors = [
    "red",
    "orange",
    "yellow",
    "olive",
    "green",
    "purple",
    "fuchsia",
    "lime",
    "teal",
    "aqua",
    "blue",
    "navy"
];
const color_bit_empty: string = "lightgray";
const color_bit_inactive: string = "gray";

@Component({
    selector: "symbol-details",
    templateUrl: "./symbol-details.component.html",
    styleUrls: ["./symbol-details.component.css"],
    providers: [UserBrowserInfo],
    // SWIPE component: should be moved there
    animations: [
        trigger("slideLeft", [
            transition(
                "* => *",
                animate(
                    100,
                    keyframes([
                        style({ left: "*", offset: 0 }),
                        style({ left: "0", offset: 1 })
                    ])
                )
            )
        ])
    ]
    // SWIPE component: should be moved there
})
export class SymbolDetailsComponent {
    @Input() public sym_lib: SymLibrary;
    private _selected_symbol: SymSymbol;

    @Output() onAddSymbolVariable = new EventEmitter<SymSymbol>();

    // events from parent
    private eventsSubscriptionUpdateLibrary: any = null;
    @Input() events: Observable<void>;

    signalDataDisplayedColumns: string[] = [
        "select",
        "name",
        "data_type",
        "value_uom",
        "factor",
        "offset",
        "start_bit",
        "len",
        "data_format"
    ];
    signalDataSource = new MatTableDataSource<SymSymbolVariable>();
    signalSelection = new SelectionModel<SymSymbolVariable>(true, []);

    bits: BitElement[][] = [];
    private bits_overlap: boolean = false;

    constructor(
        public browserInfo: UserBrowserInfo,
        public newSymbolVariableDialog: MatDialog,
        public symbolVarEditPropDialog: MatDialog,
        private translate: TranslateService
    ) {
        if (this.browserInfo.is_mobile) {
            this.signalDataDisplayedColumns.splice(0, 1);
        }
    }

    ngOnInit() {
        this.bits_overlap = false;
        if (this.events) {
            this.eventsSubscriptionUpdateLibrary = this.events.subscribe(
                (dt: any) => {
                    console.log("eventsSubscriptionUpdateLibrary: ", dt);
                    if (
                        UpdateLibraryEventType.SYMBOL_LENGTH_CHANGE ===
                        dt.type ||
                        UpdateLibraryEventType.SYMBOL_VARIABLE_NEW === dt.type
                    ) {
                        this.__update_bits_layout(dt.object);
                    }
                    if (
                        UpdateLibraryEventType.SYMBOL_VARIABLE_NEW === dt.type
                    ) {
                        this.signalDataSource.data = this._selected_symbol.variables;
                    }
                }
            );
        }
    }

    ngOnDestroy() {
        if (this.eventsSubscriptionUpdateLibrary) {
            this.eventsSubscriptionUpdateLibrary.unsubscribe();
        }
    }

    @Input()
    set selected_symbol(selected_symbol: SymSymbol) {
        this._selected_symbol = selected_symbol;
        if (this._selected_symbol) {
            this.signalSelection.clear();
            this.signalDataSource.data = this._selected_symbol.variables;

            this.update_bits_layout();
        }
    }
    get selected_symbol() {
        return this._selected_symbol;
    }

    // vk {
    onSubmitCell(element: SymSymbolVariable, attribute: string, f: NgForm) {
        if (!f.valid) {
            return;
        }
        element[attribute] = f.value[attribute];
        this.update_bits_layout();
    }
    // vk }

    isAllSelected() {
        const numSelected = this.signalSelection.selected.length;
        const numRows = this.signalDataSource.data.length;
        return numSelected === numRows;
    }
    masterToggle() {
        this.isAllSelected()
            ? this.signalSelection.clear()
            : this.signalDataSource.data.forEach(row =>
                this.signalSelection.select(row)
            );
    }
    checkboxLabel(row?: SymSymbolVariable): string {
        if (!row) {
            return `${this.isAllSelected() ? "select" : "deselect"} all`;
        }
        return `${this.signalSelection.isSelected(row) ? "deselect" : "select"
            } row ${row.name + 1}`;
    }

    symbolVariableType(el: SymSymbolVariable) {
        let t: string;
        switch (el.data_type) {
            case SymSymbolVaribaleType.BIT:
                t = "Bit";
                break;
            case SymSymbolVaribaleType.CHAR:
                t = "Char";
                break;
            case SymSymbolVaribaleType.STRING:
                t = "String";
                break;
            case SymSymbolVaribaleType.SIGNED:
                t = "Signed";
                break;
            case SymSymbolVaribaleType.UNSIGNED:
                t = "Unsigned";
                break;
            case SymSymbolVaribaleType.FLOAT:
                t = "Float";
                break;
            case SymSymbolVaribaleType.ENUM:
                t = el.data_type_enum ? el.data_type_enum.name : "Enum";
                break;
            case SymSymbolVaribaleType.DOUBLE:
                t = "Double";
                break;
            case SymSymbolVaribaleType.RAW:
                t = "Raw";
                break;

            default:
                t = "Unknown";
                break;
        }
        return t;
    }
    symbolVariableFactor(el: SymSymbolVariable) {
        if (
            [
                SymSymbolVaribaleType.SIGNED,
                SymSymbolVaribaleType.UNSIGNED,
                SymSymbolVaribaleType.FLOAT,
                SymSymbolVaribaleType.DOUBLE
            ].includes(el.data_type)
        ) {
            return el.value_scalar.toString() || "-";
        } else {
            return "-";
        }
    }
    symbolVariableOffset(el: SymSymbolVariable) {
        if (
            [
                SymSymbolVaribaleType.SIGNED,
                SymSymbolVaribaleType.UNSIGNED,
                SymSymbolVaribaleType.FLOAT,
                SymSymbolVaribaleType.DOUBLE
            ].includes(el.data_type)
        ) {
            return el.value_offset || "0";
        } else {
            return "-";
        }
    }
    symbolVariableDataFormat(el: SymSymbolVariable) {
        let rv: string = "-";
        switch (el.data_format) {
            case SymSymbolVaribaleDataFormat.MOTOROLA:
                rv = "Motorola";
                break;
            case SymSymbolVaribaleDataFormat.INTEL:
                rv = "Intel";
                break;
        }
        return rv;
    }

    __update_bits_layout(symbol: SymSymbol) {
        if (symbol) {
            // clear fields
            let _bits_tmp: BitElement[][] = [];
            for (let i = 0; i < this._selected_symbol.data_length; i++) {
                _bits_tmp.push([]);
                for (let j = 0; j < 8; j++) {
                    let _val: BitElement = {
                        title: "",
                        tooltip: "",
                        color: color_bit_empty,
                        color2: color_bit_empty,
                        bits_overlap: false
                    };
                    _bits_tmp[i].push(_val);
                }
            }
            this.bits = _bits_tmp;

            // fill field
            let be: BitElement;
            let bc: boolean = false;
            symbol.variables.forEach((variable, index) => {
                for (let bit = 0; bit < (variable.length || 1); bit++) {
                    let byteNo = Math.floor((variable.starting_bit + bit) / 8);
                    if (byteNo < this.bits.length) {
                        be = this.bits[byteNo][
                            7 - ((variable.starting_bit + bit) % 8)
                        ];
                        // check bits collision
                        if (be.color !== color_bit_empty) {
                            be.tooltip += " and " + variable.name;
                            be.bits_overlap = true;
                            bc = true;
                            be.color2 = be.color;
                            be.color = colors[index % colors.length];
                        } else {
                            be.color = colors[index % colors.length];
                            be.tooltip = variable.name;
                        }
                    }
                }
            }, this);
            this.bits_overlap = bc;
        }
    }

    update_bits_layout() {
        this.__update_bits_layout(this.selected_symbol);
    }

    deleteBtnClick() {
        let sel = this.signalSelection.selected;
        if (sel.length) {
            sel.forEach((row: SymSymbolVariable) => {
                this.selected_symbol.variables.forEach(
                    (_signal: SymSymbolVariable, idx: number) => {
                        if (_signal === row) {
                            this.selected_symbol.variables.splice(idx, 1);
                        }
                    }
                );
            });
            this.signalSelection.clear();
            this.signalDataSource.data = this._selected_symbol.variables;
            this.update_bits_layout();
        }
    }

    addBtnClick() {
        const dialogRef = this.symbolVarEditPropDialog.open(
            SymbolVariableEditDialog,
            {
                panelClass: "toolbar-dialog",
                width: "450px",
                data: {
                    sym_var: new SymSymbolVariable({}),
                    sym_lib: this.sym_lib
                }
            }
        );

        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                if (typeof this.sym_lib !== "undefined") {
                    this.selected_symbol.variables.push(data);
                    this.selected_symbol.sortVariables();
                    if (this.onAddSymbolVariable) {
                        this.onAddSymbolVariable.emit(this.selected_symbol);
                    }
                }
            }
        });
    }

    tableSignalsRowClick(_sym_var: SymSymbolVariable) {
        const dialogRef = this.symbolVarEditPropDialog.open(
            SymbolVariableEditDialog,
            {
                panelClass: "toolbar-dialog",
                width: "450px",
                data: {
                    sym_var: _sym_var.copy(this.sym_lib.enums),
                    sym_lib: this.sym_lib
                }
            }
        );

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                _sym_var.parse_values(result.dump());
                _sym_var.fillEnum(this.sym_lib.enums);
                this._selected_symbol.sortVariables();
                this.signalDataSource.data = this._selected_symbol.variables;
                this.update_bits_layout();
            }
        });
    }

    // SWIPE component: should be moved there
    private _isLeftSign: boolean = true;
    ngstdIndexNumber: number = null;
    numberOfDeleteIcon: number = Constants.NUMBER_OF_DELETE_ICONS;
    slideThreshold: number = Constants.DEFAULT_SLIDE_THRESHOLD;

    getClassName() {
        return `${Constants.DEFAULT_CLASS_NAME}`;
    }
    isLeftSign() {
        return this._isLeftSign;
    }
    isRightSign() {
        return this.numberOfDeleteIcon === 2 && !this.isLeftSign();
    }
    alignComplete(event: any) {
        event.element.style.left = "0px";
        this._isLeftSign = event.element.offsetLeft > 0;
        this.ngstdIndexNumber = null;
    }
    panend(action, index, elementRefrence) {
        const currentMargin = this.getLeftPosition(elementRefrence);
        if (
            currentMargin > this.slideThreshold ||
            (currentMargin < -this.slideThreshold &&
                this.numberOfDeleteIcon === Constants.NUMBER_OF_DELETE_ICONS)
        ) {
            this.removeElement(index);
        } else {
            this.ngstdIndexNumber = index;
        }
    }
    panmove(action, elementRefrence) {
        if (action.deltaX > 0)
            // to right side only
            elementRefrence.style.left = action.deltaX + "px";
        this._isLeftSign = elementRefrence.offsetLeft > 0;
    }
    removeElement(index) {
        this._selected_symbol.variables.splice(index, 1);
        this.signalDataSource.data = this._selected_symbol.variables;
    }
    getLeftPosition(elementRefrence) {
        const currentleftPosition = elementRefrence.style.left.slice(0, -2);
        if (currentleftPosition !== null) {
            return (
                (parseInt(currentleftPosition, 10) * 100) / window.innerWidth
            );
        } else {
            return 0;
        }
    }
    // SWIPE component: should be moved there
}
