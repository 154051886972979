import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { SearchService } from './../services/search/search.service';
import { appConstants } from '../services/constants';
import { ManageFactoryService } from '../services/manage-factory/manage-factory.service';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { TextInputDialogComponent } from '../dialogs/text-input-dialog/text-input-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { UserTableDataSource } from "./user-table-data-source";
import { catchError, map, takeUntil, tap } from "rxjs/operators";
import { isMobileDevice } from "../shared/common";
import { HttpParams } from '@angular/common/http';
import { AppService } from '../app.service';
import { RouterIcon } from '../root/model/root.model';
import { PreviousUrl } from '../dashboard/models/common.model';
import { AlertDetails, Translations, RequestedParam, UserRoles } from './list-view.model';
import { AssignedDetailsDialog } from '../dialogs/assigned-details-dialog/assigned-details-dialog.component';
import {
    DealerName, IConfirmDialogData, OemName, SNACKBAR_DURATION,
    SNACKBAR_POSITION, AssociatedCount, AssociationCategories, AssociationDetails, ClientDetail
} from '../shared/models/common.model';
import { Route } from '../shared/constants/routes.constant';

@Component({
    selector: 'app-list-view',
    templateUrl: './list-view.component.html',
    styleUrls: ['./list-view.component.css']
})
export class ListViewComponent implements OnInit, OnDestroy {
    constants = appConstants;
    routerIcon = RouterIcon;
    dataSource: UserTableDataSource;
    subscription: Subscription;
    displayedColumns: string[];
    selection = new SelectionModel(true, [])
    currentRoute: string;
    currentRoute_tr: string;
    filterListBy: number = -1;
    multipleSelect: boolean;
    toolBar: string[];
    isMobile: boolean;
    enabledDevices: boolean;
    get customerRole(): number {
        return +sessionStorage.getItem('customerRole');
    }
    get userRole() {
        return +sessionStorage.getItem('userRole');
    }
    dateTimeFormat = sessionStorage.getItem('date_format') + " " + sessionStorage.getItem('time_format');

    isLoadingResults = false;
    resultsLength = 0;
    pageSize = '50';
    pageLengthOptions = [10, 25, 50, 100];

    offset: Observable<number>;
    transform: Observable<number>;

    sortColumn = '';
    sortOrder = '';

    scrollWidth;
    scrollHeight;
    isMobileDevice: boolean;
    searchString = '';
    destroy$ = new Subject();
    fromPage: string = '';

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('viewPort', { static: true }) viewPort: CdkVirtualScrollViewport;
    @ViewChild('tableContainer', { static: true }) tableContainer: ElementRef;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private manageFactory: ManageFactoryService,
        private searchService: SearchService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
        private zone: NgZone,
        private appService: AppService
    ) {
        // this.dataSource = new MatTableDataSource([]);
        this.isLoadingResults = true;


        router.events.pipe(
            takeUntil(this.destroy$)
        ).subscribe(event => {
            if (event instanceof NavigationEnd && this.isLoadingResults) {
                // this will catch the route whether it was the original url or redirected after login
                this.currentRoute = event.urlAfterRedirects.split('/').pop().split(';')[0];
                this.translate.get(this.currentRoute).subscribe((route_tr: string) => {
                    this.currentRoute_tr = route_tr;
                });
            }
        });

        this.isMobileDevice = isMobileDevice();
    }

    ngOnInit() {
        this.isMobile = (window.innerWidth) < 650;

        const rect = this.tableContainer.nativeElement.getBoundingClientRect();
        this.scrollWidth = rect.width;
        this.scrollHeight = rect.height;

        this.setColumns();

        this.activatedRoute.params.pipe(
            takeUntil(this.destroy$)
        ).subscribe(params => {
            this.fromPage = params['from'];
            this.filterListBy = params['filter'];
            this.initDatasource();
        });

        this.subscription = this.searchService.searchObservable$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(res => {
            this.searchString = res.value;
            this.dataSource.destroy();
            this.initDatasource();
        }
        );

        this.setPageSize();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.subscription.unsubscribe();
    }

    private initDatasource() {
        this.selection.clear();
        this.dataSource = new UserTableDataSource({ pageSize: this.pageSize });

        this.dataSource.setScrollUpdateCallback((data) => {
            if (this.currentRoute === 'alerts' && this.filterListBy) {
                return this.getDataSourceData(data).pipe(
                    map((d) => {
                        return this.filterAlertResults(this.filterListBy, d);
                    })
                );
            } else {
                return this.getDataSourceData(data);
            }
        });

        // tells the sort to be case insensitive
        this.dataSource.matTableDataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
            if (sortHeaderId == 'full_name') return data['full_name'] ? data['full_name'].toLocaleLowerCase() : data['full_name'];
            if (typeof data[sortHeaderId] === 'string') return data[sortHeaderId].toLocaleLowerCase();

            return data[sortHeaderId];
        };

        this.dataSource.matTableDataSource.sort = this.sort;
        this.dataSource.attach(this.viewPort);
    }

    get inverseOfTranslation(): string {
        if (this.isMobileDevice) {
            return this.appService.browser.os === 'iOS' ? '0px' : `-${this.dataSource.scrollStartRange$.getValue() * 48}px`;
        }

        if (!this.viewPort || !this.viewPort["_renderedContentOffset"]) {
            return "-0px";
        }
        let offset = this.viewPort["_renderedContentOffset"];
        return `-${offset}px`;
    }

    convertField(field) {
        let fieldMap = new Map([['widgetName', 'name']]);
        if (fieldMap.has(field)) {
            return fieldMap.get(field);
        }
        return field;
    }

    getDataSourceData(page): Observable<any> {
        let params = new HttpParams();

        if (this.manageFactory.isV2Route(this.currentRoute)) {
            /*
             * Supporting parameters:
             *   limit        - max number of objects to return
             *   offset       - index to offset by
             *   sort         - column to sort by
             *   sortOrder    - sort ascending or descending ('ASC' or 'DESC')
             *   searchTerm   - search term
             *   searchFields - columns to search
             */
            let pageSize = parseInt(this.pageSize);
            params = params.append('limit', pageSize);
            params = params.append('offset', page * pageSize);

            if (this.sortColumn) {
                params = params.append('sort', this.convertField(this.sortColumn));
            }
            if (this.sortOrder) {
                params = params.append('sortOrder', this.sortOrder);
            }
            if (this.searchString) {
                params = params.append('searchTerm', this.searchString);
                let searchFields = this.displayedColumns
                    .filter(c => c !== 'select').map(this.convertField)
                    .join(',');
                params = params.append('searchFields', searchFields);
            }
        } else {
            /*
             * Supporting parameters:
             *   page_number - page number for pagination
             *   page_size   – number of elements on page
             *   sort        - sorting result by column name
             *   sort_order  - sorting direction (can be 'asc' and 'desc')
             */
            params = params.append('page_size', this.pageSize);
            params = params.append('page_number', page);

            if (this.sortColumn) {
                params = params.append('sort', this.sortColumn);
            }
            if (this.sortOrder) {
                params = params.append('sort_order', this.sortOrder);
            }
            if (this.searchString) {
                params = params.append('search_like', this.searchString);
                params = params.append('search_like_cols', this.displayedColumns.filter(c => c !== 'select').join(','));
            }
        }

        if (this.filterListBy && this.fromPage) {
            switch (this.currentRoute) {
                case 'administrators':
                    break;
                case 'oems':
                    if (this.fromPage === 'administrators') {
                        params = params.append('administrator_id', this.filterListBy.toString());
                    }
                    break;
                case 'dealers':
                    if (this.fromPage === 'administrators') {
                        params = params.append('administrator_id', this.filterListBy.toString());
                    } else if (this.fromPage === 'oems') {
                        params = params.append('oem_id', this.filterListBy.toString());
                    }
                    break;
                case 'clients':
                    if (this.fromPage == 'administrators') {
                        params = params.append('administrator_id', this.filterListBy.toString());
                    } else if (this.fromPage === 'oems') {
                        params = params.append('oem_id', this.filterListBy.toString());
                    } else if (this.fromPage === 'dealers') {
                        params = params.append('dealer_id', this.filterListBy.toString());
                    }
                    break;
                case 'users':
                    params = params.append('customer_id', this.filterListBy.toString());
                    break;
                case 'alerts':
                    params = params.append('model_id', this.filterListBy.toString());
                    break;
                case 'models':
                case 'dashboards':
                case 'widgets':
                case 'rules':
                default:
                    break;
            }
        }

        this.isLoadingResults = true;
        if (this.manageFactory.isV2Route(this.currentRoute)) {
            return this.manageFactory[this.currentRoute].getMany(params)
                .pipe(tap((_) => { this.isLoadingResults = false; }));
        } else {
            return this.manageFactory[this.currentRoute].read({ params })
                .pipe(tap((_) => { this.isLoadingResults = false; }));
        }

    }

    handleSortChange(ev) {
        this.dataSource.destroy();
        this.sortColumn = ev.active || '';
        this.sortOrder = ev.direction || '';
        this.initDatasource();
    }

    // set the columns for the table and icons in the menu
    setColumns() {
        switch (this.currentRoute) {
            case 'administrators':
                this.displayedColumns = this.isMobile ? ['select', 'customer_name'] : ['select', 'customer_name', 'address', 'phone', 'full_name'];
                this.toolBar = ['users', 'edit'];
                if (this.userRole <= this.constants.user_read_write) this.toolBar.push('delete', 'add');
                this.multipleSelect = true;
                break;
            case 'oems':
                this.displayedColumns = this.isMobile ? ['select', 'customer_name'] : ['select', 'customer_name', 'address', 'phone', 'full_name'];
                this.toolBar = ['dealers', 'clients', 'users', 'devices', 'edit'];
                if (this.userRole <= this.constants.user_read_write) this.toolBar.push('delete', 'add');
                this.multipleSelect = true;
                break;
            case 'dealers':
                this.displayedColumns = this.isMobile ? ['select', 'customer_name'] : ['select', 'customer_name', 'address', 'phone', 'full_name'];
                this.toolBar = ['clients', 'users', 'devices', 'edit'];
                if (this.userRole <= this.constants.user_read_write) this.toolBar.push('delete', 'add');
                this.multipleSelect = true;
                break;
            case 'clients':
                this.displayedColumns = this.isMobile ? ['select', 'customer_name'] : ['select', 'customer_name', 'address', 'phone', 'full_name'];
                this.toolBar = ['users', 'devices', 'edit'];
                if (this.userRole <= this.constants.user_read_write) this.toolBar.push('delete', 'add');
                this.multipleSelect = true;
                break;
            case 'users':
                this.displayedColumns = this.isMobile ? ['select', 'full_name'] : ['select', 'full_name', 'is_locked', 'role_name', 'customer_name', 'phone', 'email'];
                this.toolBar = (this.userRole <= this.constants.user_read_write) ? ['lock', 'reset', 'edit', 'delete', 'add'] : ['edit'];
                this.multipleSelect = true;
                break;
            case 'alerts':
                this.displayedColumns = this.isMobile ? ['select', 'parameters_name'] : ['select', 'parameters_name', 'customer_name', 'model_name'];
                this.toolBar = ['edit', 'onOffAlert'];
                if (this.userRole <= this.constants.user_read_write) this.toolBar.push('delete', 'add');
                this.multipleSelect = true;
                break;
            case 'models':
                this.displayedColumns = this.isMobile ? ['name'] : ['name', 'model_type'];
                this.toolBar = ['dashboard', 'edit'];
                if (this.userRole <= this.constants.user_read_write) this.toolBar.push('delete', 'add');
                this.multipleSelect = false;
                if (this.customerRole <= this.constants.role_administrator) this.displayedColumns.push(OemName);
                if (this.customerRole <= this.constants.role_oem_user) this.displayedColumns.push(DealerName);
                break;
            case 'dashboards':
                this.displayedColumns = this.isMobile ? ['select', 'dashboard_name'] : ['select', 'dashboard_name', 'device_list', 'customer_name'];
                this.toolBar = ['dashboard'];
                if (this.userRole <= this.constants.user_read_write) {
                    this.toolBar = ['copyDashboard', 'editDashName', 'deleteDash', 'dashboard', 'addDash'];
                }
                if (this.customerRole <= this.constants.role_oem_user) {
                    this.toolBar = ['viewCustomWidgets', ...this.toolBar];
                }
                this.multipleSelect = true;
                break;
            case 'widgets':
                this.displayedColumns = this.isMobile ? ['select', 'widgetName'] : ['select', 'widgetName', 'customerName'];
                this.toolBar = ['edit'];
                if (this.userRole <= this.constants.user_read_write) {
                    this.toolBar.push('delete', 'add');
                }
                this.multipleSelect = true;
                break;
            case 'rules':
                this.displayedColumns = this.isMobile ? ['select', 'title'] : ['select', 'title', 'last_updated'];
                this.toolBar = ['edit'];
                if (this.userRole <= this.constants.user_read_write) this.toolBar.push('delete', 'add');
                this.multipleSelect = true;
                break;
            default:
                this.displayedColumns = [];
                this.toolBar = [];
        }
    }

    onResize(event) {
        const rect = this.tableContainer.nativeElement.getBoundingClientRect();
        this.scrollWidth = rect.width;
        this.scrollHeight = rect.height;

        this.isMobile = (event.target.innerWidth) < 650;
        this.setColumns();
    }

    // allows for single or multiple row selection
    selectRow(row) {
        // dynamically added items run out of Angular
        this.zone.run(() => {
            if (this.multipleSelect) {
                this.selection.toggle(row);
            } else {
                if (this.selection.isSelected(row)) {
                    this.selection.clear();
                } else {
                    this.selection.clear();
                    this.selection.select(row);
                }
            }
        });
    }

    toolbarShow(tool): boolean {
        return this.toolBar.includes(tool);
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.matTableDataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.matTableDataSource.data.forEach(row => this.selection.select(row));
    }

    filterResults(id) {
        // if there is a filter applied on load then we change the datasource to only contain the filtered data
        this.dataSource.matTableDataSource.filterPredicate = (data, filter) => data.oem_id == id || data.dealer_id == id || data.customer_id == id;
        this.dataSource.matTableDataSource.filter = id;

        this.finishFilter(this.dataSource.matTableDataSource.filteredData);

    }

    /**
     * This method is called to filter the data source based on the applied filter.
     * @param id Contains the id of the devices or models to filter
     * @param data Contains the data to filter
     * @returns
     */
    filterAlertResults(id: number, data: AlertDetails[]): AlertDetails[] {
        const ds = [];

        for (const obj of data) {
            const devices = obj['devices'] && typeof obj.devices === 'string' ? obj['devices'].split(';') : [];
            const modelId: number = obj['model_id'] ? obj['model_id'] : 0;
            let found = false;
            for (const dev of devices) {
                if (+dev == id) {
                    found = true;
                }
            }
            if (modelId == id) {
                found = true;
            }
            if (found) {
                ds.push(obj);
            }
        }
        return ds;
    }

    finishFilter(ds) {
        // this.dataSource = new MatTableDataSource(ds);
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.matTableDataSource.sort = this.sort;
        // this.resultsLength = ds.length;
        // this.isLoadingResults = false;
        // if (ds.length > 100) this.pageLengthOptions = [10, 25, 50, 100, this.resultsLength];
    }

    applyFilter(filterValue: string) {
        this.dataSource.matTableDataSource.filter = filterValue ? filterValue.trim().toLowerCase() : null;
    }

    seeAll() {
        let goTo = this.router.url.split(';')[0];
        this.dataSource.matTableDataSource.filter = '';
        this.filterListBy = null;
        this.router.navigate([goTo]);
        this.initDatasource();
    }

    delete(): void {
        let dialog: MatDialogRef<AssignedDetailsDialog | ConfirmDialogComponent>;
        let extraWarning: string = '';
        const type: string = this.currentRoute[0].toUpperCase() + this.currentRoute.slice(1);
        const item: string = this.selection.selected.length > 1 ? type : type.slice(0, -1);
        const descId: string = this.selection.selected.length > 1 ? Translations.PromptDescriptionOne : Translations.PromptDescriptionTwo;
        const confirmDeleteHeading: string = Translations.ConfirmDeleteHeading;
        const confirmDeleteButton: string = Translations.BtnDelete;
        const translationKeys: string[] = [confirmDeleteHeading, confirmDeleteButton, descId, item];
        const route: string = type === UserRoles.Oems ? item.toUpperCase() : item;

        if (type === UserRoles.Oems || type === UserRoles.Dealers || type === UserRoles.Clients) {
            extraWarning = 'list_view.extra_warning_' + item.toLowerCase();
            translationKeys.push(extraWarning);
        }

        this.translate.get(translationKeys, { route })
            .subscribe((translations: string[]) => {
                let desc: string = extraWarning ? translations[extraWarning] + '\n' + translations[descId] : translations[descId];

                if (type === UserRoles.Oems || type === UserRoles.Dealers) {
                    const userIds: number[] = this.selection.selected.map(m => m.customer_id);
                    const paramKey: string = type === UserRoles.Dealers ? RequestedParam.DealerId : RequestedParam.OemId;
                    const apiCategory: string = type === UserRoles.Dealers ? UserRoles.Clients.toLowerCase() : UserRoles.Dealers.toLowerCase();
                    let params: HttpParams = new HttpParams();
                    params = params.append(paramKey, userIds.join(','));

                    this.manageFactory[apiCategory].read({ params }).subscribe((result: ClientDetail[]) => {
                        if (result.length > 0) {
                            desc = Translations.WarningDeletingUser;
                        }

                        this.translate.get(desc, { route, user: apiCategory })
                            .subscribe((translation: string) => {
                                dialog = this.dialog.open(ConfirmDialogComponent, {
                                    data: {
                                        title: translations[confirmDeleteHeading],
                                        text: translation,
                                        confirm: translations[confirmDeleteButton],
                                        isClientsFound: result.length > 0 ? true : false
                                    }
                                });
                                dialog.afterClosed().subscribe(this.dialogCallback.bind(this, type, item, []));
                            });
                    });
                } else if (type.toLowerCase() === Route.MODELS) {
                    let deviceCheck: boolean = false;
                    const selectedId: number = this.selection.selected[0].model_id;

                    this.manageFactory.models.getAssociationCount(selectedId).subscribe((associatedCount: AssociatedCount) => {
                        let modelDesc: string;
                        const everyValueEmptyCheck: boolean = associatedCount.values.every(key => key.count === 0);
                        const isDeviceFound: boolean = associatedCount.values
                            .filter(({ category }) => category === AssociationCategories.Devices)
                            .some(({ count }) => count > 0);

                        if (isDeviceFound) {
                            deviceCheck = true;
                            modelDesc = Translations.DevicesFound;
                        } else if (everyValueEmptyCheck) {
                            modelDesc = Translations.NoAssociatedFound;
                        } else {
                            modelDesc = Translations.NoAssociatedDeviceFound;
                        }

                        this.translate.get(modelDesc).subscribe((description: string) => {
                            dialog = this.dialog.open(AssignedDetailsDialog, {
                                data: {
                                    title: translations[confirmDeleteHeading],
                                    text: description,
                                    confirm: translations[confirmDeleteButton],
                                    dependedCount: associatedCount,
                                    deviceCheck: deviceCheck,
                                    previousUrl: this.currentRoute
                                } as AssociationDetails
                            });
                            const apiObservableArray: Subscription[] = this.getDeletedItemsObservable(associatedCount);
                            dialog.afterClosed()
                                .subscribe(this.dialogCallback.bind(this, type, item, apiObservableArray));
                        });
                    });
                } else {
                    dialog = this.dialog.open(ConfirmDialogComponent, {
                        data: { title: translations[confirmDeleteHeading], text: desc, confirm: translations[confirmDeleteButton] }
                    });
                    dialog.afterClosed().subscribe(this.dialogCallback.bind(this, type, item, []));
                }
            });
    }

    add() {
        if (this.currentRoute == 'widgets') {
            this.addWidget();
        } else {
            let goTo = this.currentRoute.slice(0, -1);
            this.router.navigate([goTo]);
        }
    }

    addDash() {
        this.router.navigate([`../dashboard/new`], { relativeTo: this.activatedRoute });
        localStorage.setItem('previous_url', PreviousUrl.DASHBOARDS);
    }

    addWidget() {
        this.translate.get('title.custom_widget')
            .subscribe((name: string) => {
                let customerId = +sessionStorage.getItem('customerId');
                this.manageFactory.widgets.create({ name, customerId })
                    .subscribe((result) => {
                        let id = result['id'] ?? -1;
                        if (id >= 0) {
                            this.router.navigate(['widget/' + id]);
                        }
                    });
            });
    }

    viewCustomWidgets() {
        this.router.navigate(['/widgets']);
    }

    copyDashboard() {
        const dialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titleTerm: 'dialog.confirm_copy_dashboard_title',
                textTerm: 'dialog.confirm_copy_dashboard_text',
                confirmTerm: 'btn.copy'
            }
        });
        dialog.afterClosed().subscribe(result => {
            if (result) {
                this.translate.get('dashboard.prefix_copy_of')
                    .subscribe((prefixTranslation: string) => {

                        const obj = { copyDashboardId: this.selection.selected[0].dashboard_id, prefix: prefixTranslation };
                        this.manageFactory.dashboards.new(obj)
                            .subscribe((res: any) => {
                                this.translate.get('list_view.dashboard_copied_successfully')
                                    .subscribe((translation: string) => {
                                        this.snackBar.open(translation, '', {
                                            duration: 4000,
                                            horizontalPosition: 'left'
                                        });
                                    });
                                this.refresh();
                            });
                    });
            }
        });
    }

    editDashName() {
        this.translate.get(['list_view.dashboard_name', 'list_view.dashboard_name_desc', 'list_view.uploaded_successfully']).subscribe((translated: string) => {
            let dialog = this.dialog.open(TextInputDialogComponent, {
                panelClass: 'toolbar-dialog',
                data: { title: translated['list_view.dashboard_name'], text: translated['list_view.dashboard_name_desc'], input: this.selection.selected[0].dashboard_name }
            });

            dialog.afterClosed()
                .subscribe(result => {
                    if (result) {
                        this.selection.selected[0].dashboard_name = result;
                        this.manageFactory.dashboards.updateDashboardName(this.selection.selected[0])
                            .subscribe(result => {
                                this.snackBar.open(translated['list_view.uploaded_successfully'], "", {
                                    duration: 4000,
                                    horizontalPosition: 'left'
                                });
                                this.selection.clear();
                            })
                    }
                });
        });
    }

    openDashboard() {
        const item = this.selection.selected[0];
        if (this.currentRoute === 'models') {
            this.router.navigate([`../modelDashboard/${item.model_id}`], { relativeTo: this.activatedRoute });
        } else {
            this.router.navigate([`../dashboard/${item.dashboard_id}`], { relativeTo: this.activatedRoute });
        }
    }

    getPath() {
        let path;
        if (window.location.origin.includes('app.spoke.zone')) {
            path = window.location.origin.replace("app", "dashboard");
        } else {
            path = window.location.origin.replace("4200", "3000");
        }
        return path;
    }

    edit() {
        let param;
        switch (this.currentRoute) {
            case 'users':
                param = this.selection.selected[0]['user_id'];
                break;
            case 'alerts':
                param = this.selection.selected[0]['parameters_id'];
                break;
            case 'models':
                param = this.selection.selected[0]['model_id'];
                break;
            case 'rules':
                param = this.selection.selected[0]['name_id'];
                break;
            case 'widgets':
                param = this.selection.selected[0]['id'];
                break;
            default:
                param = this.selection.selected[0]['customer_id'];
        }
        let goTo = this.currentRoute.slice(0, -1) + '/' + param;
        this.router.navigate([goTo]);
    }

    filterView(page) {
        let goTo = page;
        let param = this.selection.selected[0]['customer_id'];

        // Take the name of the page and capitalize the first letter
        localStorage.setItem('tab', page.charAt(0).toUpperCase() + page.slice(1));

        this.router.navigate([goTo, { from: this.currentRoute, filter: param }]);
    }

    resetPassword() {
        this.translate.get(['list_view.reset_password', 'list_view.reset_password_desc', 'list_view.reset_password_sended'], { email: this.selection.selected[0].email }).subscribe((translated: string) => {
            let dialog = this.dialog.open(ConfirmDialogComponent, {
                data: { title: translated['list_view.reset_password'], text: translated['list_view.reset_password_desc'], confirm: 'Send' }
            });

            dialog.afterClosed()
                .subscribe(response => {
                    //if we get a true from the dialog, delete the selected items
                    if (response) {
                        this.manageFactory.forgot.resetToken({ email: this.selection.selected[0].email, applicationId: 'CGW' })
                            .subscribe(result => {
                                this.snackBar.open(translated['list_view.reset_password_sended'], "", {
                                    duration: 4000,
                                    horizontalPosition: 'left'
                                });
                                this.selection.clear();
                            });
                    }
                });
        });
    }

    lockUser() {
        let enableDisable = this.selection.selected[0].is_locked == 1 ? 'Unlock' : 'Lock';
        let dlg_title = 'list_view.user_' + enableDisable.toLowerCase();
        let dlg_desc = 'list_view.user_' + enableDisable.toLowerCase() + '_desc';
        let result_title = 'list_view.user_' + enableDisable.toLowerCase() + '_result';
        this.translate.get([dlg_title, dlg_desc, result_title]).subscribe((translated: string) => {
            let dialog = this.dialog.open(ConfirmDialogComponent, {
                data: { title: translated[dlg_title], text: translated[dlg_desc], confirm: enableDisable }
            });

            dialog.afterClosed()
                .subscribe(response => {
                    //if we get a true from the dialog change the user's locked status
                    if (response) {
                        this.selection.selected[0].is_locked = !(this.selection.selected[0].is_locked);
                        let userObj = {
                            user_id: this.selection.selected[0].user_id,
                            is_locked: this.selection.selected[0].is_locked
                        }

                        this.manageFactory.users.update(userObj)
                            .subscribe(result => {
                                this.snackBar.open(translated[result_title], "", {
                                    duration: 4000,
                                    horizontalPosition: 'left'
                                });
                                this.selection.clear();
                            })
                    }
                })
        });
    }

    doubleClickRow(row) {
        // dynamically added items run out of Angular
        this.zone.run(() => {
            if (this.selection != null) {
                this.selection.clear();
            }

            this.selectRow(row);

            if (this.currentRoute == "dashboards") {
                this.openDashboard();
            } else {
                this.edit();
            }
        });
    }

    refresh() {
        this.dataSource.destroy();
        this.initDatasource();
    }

    setPageSize() {
        this.pageSize = sessionStorage.getItem('page_size');
        if (!this.pageSize || parseInt(this.pageSize, 10) > this.pageLengthOptions[this.pageLengthOptions.length - 1]) {
            this.pageSize = '50';
        }
    }

    getPageSize(e) {
        sessionStorage.setItem('page_size', e.pageSize);
    }

    /**
     * Handles the callback from a dialog confirmation.
     * @param {string} type - The type of item being deleted.
     * @param {string} item - The name or description of the item being deleted.
     * @param {Subscription[]} apiObservableArray - An array of API observables (optional).
     * @param {boolean} response - The response from the dialog confirmation.
     */
    private dialogCallback(type: string, item: string, apiObservableArray: Subscription[] = null, response: boolean) {
        // if we get a true from the dialog, delete the selected items
        if (response) {
            if (this.manageFactory.isV2Route(this.currentRoute)) {
                const idList: number[] = this.selection.selected.map(m => m.id);
                let params = new HttpParams();
                params = params.append('ids', idList.join(','));

                this.manageFactory[this.currentRoute].delete(params)
                    .subscribe((result) => {
                        for (let id of idList) {
                            const index: number = this.dataSource.matTableDataSource.data.findIndex(f => f.id === id);
                            this.dataSource.matTableDataSource.data.splice(index, 1);
                            this.dataSource.matTableDataSource.data = [...this.dataSource.matTableDataSource.data];
                        }
                        this.dataSource.getNextPage(); // for correct virtual scroll work
                        if (result['success']) {
                            this.translate.get('list_view.successfully_deleted', { who_is: item })
                                .subscribe((title: string) => {
                                    this.snackBar.open(title, '', {
                                        duration: SNACKBAR_DURATION,
                                        horizontalPosition: SNACKBAR_POSITION
                                    });
                                });
                        }
                    });
            } else {
                const taskSubscriptions: Subscription[] = [];
                for (let x in this.selection.selected) {
                    let selectedId, index;
                    switch (type.toLowerCase()) {
                        case 'users':
                            selectedId = this.selection.selected[x].user_id;
                            index = this.dataSource.matTableDataSource.data.findIndex(i => i.user_id === selectedId);
                            break;
                        case 'alerts':
                            selectedId = this.selection.selected[x].parameters_id;
                            index = this.dataSource.matTableDataSource.data.findIndex(i => i.parameters_id === selectedId);
                            break;
                        case 'dashboards':
                            selectedId = this.selection.selected[x].dashboard_id;
                            index = this.dataSource.matTableDataSource.data.findIndex(i => i.dashboard_id === selectedId);
                            break;
                        case 'rules':
                            selectedId = this.selection.selected[x].name_id;
                            index = this.dataSource.matTableDataSource.data.findIndex(i => i.name_id === selectedId);
                            break;
                        case 'models':
                            selectedId = this.selection.selected[x].model_id;
                            index = this.dataSource.matTableDataSource.data.findIndex(i => i.model_id === selectedId);
                            break;
                        default:
                            selectedId = this.selection.selected[x].customer_id;
                            index = this.dataSource.matTableDataSource.data.findIndex(i => i.customer_id === selectedId);
                            break;
                    }
                    this.dataSource.matTableDataSource.data.splice(index, 1);
                    this.dataSource.matTableDataSource.data = [...this.dataSource.matTableDataSource.data];

                    taskSubscriptions.push(this.manageFactory[this.currentRoute].destroy({ id: selectedId }));
                };
                if (apiObservableArray && apiObservableArray.length > 0) {
                    forkJoin(apiObservableArray).subscribe(res => {
                        if (res) {
                            this.deleteV1RouteElement(taskSubscriptions, item);
                        }
                    });
                } else {
                    this.deleteV1RouteElement(taskSubscriptions, item);
                }
            }
        }
        this.selection.clear();
    }

    /**
     * Deletes the V1 route elements based on the provided task subscriptions.
     * After successful deletion, it triggers a refresh and displays a success snackbar notification.
     *
     * @param {Subscription[]} taskSubscriptions - An array of task subscriptions representing the deletion tasks.
     * @param {string} item - The name or description of the item that was deleted.
     */
    private deleteV1RouteElement(taskSubscriptions: Subscription[], item: string): void {
        forkJoin(taskSubscriptions).subscribe(response => {
            this.refresh();
            this.translate.get(Translations.SuccessfullyDeleted, { who_is: item }).subscribe((title: string) => {
                this.snackBar.open(title, '', {
                    duration: SNACKBAR_DURATION,
                    horizontalPosition: SNACKBAR_POSITION
                });
            });
        });
    }

    /**
     * Retrieves an array of API observables for deleting associated items based on the provided associated count.
     *
     * @param {AssociatedCount} associatedCount - The associated count object containing information about the associations.
     * @returns {Subscription[]} An array of API observables representing the deletion tasks.
     */
    private getDeletedItemsObservable(associatedCount: AssociatedCount): Subscription[] {
        let apiObservableArray = [];
        associatedCount.values.forEach(element => {
            switch (element.category) {
                case AssociationCategories.Alerts:
                    apiObservableArray = apiObservableArray
                        .concat(...element.ids.map(id => this.manageFactory[element.category.toLowerCase()].destroy({ id: id })));
                    break;
                case AssociationCategories.Statistics:
                    apiObservableArray = apiObservableArray
                        .concat(...element.ids.map(id => this.manageFactory.statistics.delete(id)));
                    break;
            }
        });
        return apiObservableArray;
    }

    /**
     * toggle alert activation
     * @returns
     */
    toggleAlert(): void {
        if (!this.selection || !this.selection.selected || !this.selection.selected.length) {
            return;
        }

        const alert: AlertDetails = this.selection.selected[0],
            isActive: boolean = alert?.is_active,
            title: string = 'dialog.confirm_alert_on_off',
            text: string = isActive ? 'dialog.confirm_alert_off_text' : 'dialog.confirm_alert_on_text',
            confirmBtnText: string = 'btn.ok',
            translationKey: string = isActive ? 'list_view.alert_off_msg' : 'list_view.alert_on_msg',
            obj = { ...alert };

        if (typeof obj.devices === 'string') {
            obj.devices = obj.devices.split(';').map(Number);
        }
        obj.is_active = !isActive;

        const dialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
                titleTerm: title,
                textTerm: text,
                confirmTerm: confirmBtnText
            } as IConfirmDialogData
        });
        dialog.afterClosed().subscribe((result: boolean) => {
            if (!result) {
                return;
            }

            this.manageFactory.alerts.updateIsActive(obj)
                .subscribe(_response => {
                    alert.is_active = !isActive;
                    this.translate.get(translationKey).subscribe((translation: string) => {
                        this.snackBar.open(translation, '', {
                            duration: SNACKBAR_DURATION,
                            horizontalPosition: SNACKBAR_POSITION
                        });
                    });
                });
        });
    }
}
