<form
  [formGroup]="form"
  class="formDecoration">
  <mat-grid-list [cols]="(isDesktopWidth)? 2 : 1" rowHeight="65px" gutterSize="1em" (window:resize)="onResize($event)">
    <mat-grid-tile>
      <mat-form-field class="full-width">
        <input
          matInput
          formControlName="name"
          placeholder="*{{'library_editor.name' | translate}}"
        >
        <mat-error *ngIf="form.controls['name'].hasError('pattern')">
          {{'library_editor.err.symbol_name' | translate}}
        </mat-error>
        <mat-error *ngIf="form.controls['name'].hasError('maxLength')">
          {{('library_editor.err.max_length' | translate: {number: 200})}}
        </mat-error>
        <mat-error *ngIf="form.controls['name'].hasError('required')">
          {{'library_editor.err.empty' | translate}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field class="full-width">
        <input
          matInput
          type="number"
          placeholder="{{'library_editor.data_length_bytes' | translate}}"
          [formControl]="symbolDataLengthFormControl"
        >
        <mat-error *ngIf="symbolDataLengthFormControl.hasError('pattern')"> {{'library_editor.err.natural_number' | translate}} </mat-error>
        <mat-error *ngIf="(symbolDataLengthFormControl.hasError('min') || symbolDataLengthFormControl.hasError('max')) && !symbolDataLengthFormControl.hasError('pattern')"> {{'library_editor.err.out_of_bounds' | translate}} </mat-error>
        <mat-error *ngIf="symbolDataLengthFormControl.hasError('required')"> {{'library_editor.err.empty' | translate}} </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field class="full-width">
        <span matPrefix class="input-prefix-suffix">0x&nbsp;</span>
        <input
          matInput
          type="text"
          formControlName="can_id"
          inputmode="numeric"
          hexadecimal
          placeholder="{{'library_editor.can_id_hex' | translate}}"
        >
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field class="full-width">
        <input
          matInput
          type="number"
          placeholder="{{'library_editor.send_period' | translate}}"
          [formControl]="cycleTimeFormControl"
        >
        <mat-error *ngIf="cycleTimeFormControl.hasError('pattern')"> {{'library_editor.err.natural_number' | translate}} </mat-error>
        <mat-error *ngIf="(cycleTimeFormControl.hasError('min') || cycleTimeFormControl.hasError('max')) && !cycleTimeFormControl.hasError('pattern')"> {{'library_editor.err.out_of_bounds' | translate}} </mat-error>
        <mat-error *ngIf="cycleTimeFormControl.hasError('required')"> {{'library_editor.err.empty' | translate}} </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field class="full-width">
        <mat-label>{{'library_editor.data_type' | translate}}</mat-label>
        <mat-select
          formControlName="type"
        >
          <mat-option [value]="0">Standard</mat-option>
          <mat-option [value]="1">J1939</mat-option>
          <mat-option [value]="2">FD Standard</mat-option>
          <mat-option [value]="3">FD Extended</mat-option>
          <mat-option [value]="4">Extended</mat-option>
          <mat-option [value]="5">NMEA2000</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <div fxLayout="row wrap" fxLayoutGap='24px'>
        <mat-checkbox formControlName="multipacket">
          {{ 'library_editor.multipacket' | translate }}
        </mat-checkbox>
        <mat-checkbox formControlName="is_logged">
          {{ 'library_editor.is_logged' | translate }}
        </mat-checkbox>
        <mat-checkbox formControlName="is_mqtt">
          {{ 'library_editor.is_mqtt' | translate }}
        </mat-checkbox>
        <mat-checkbox formControlName="is_retained" >
          {{ 'library_editor.is_retained' | translate }}
        </mat-checkbox>
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field class="full-width">
        <mat-label>{{'library_editor.direction' | translate}}</mat-label>
        <mat-select
          formControlName="direction"
        >
          <mat-option [value]="0">{{'library_editor.dir.send' | translate}}</mat-option>
          <mat-option [value]="1">{{'library_editor.dir.receive' | translate}}</mat-option>
          <mat-option [value]="2">{{'library_editor.dir.bi_directional' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field class="full-width">
        <input
          matInput
          formControlName="comment"
          placeholder="{{'library_editor.comment' | translate}}"
        >
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
</form>
