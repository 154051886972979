<div class="scrollBelow-full-screen" (window:resize)="onResize($event)">
    <button mat-fab color="primary" class="floating-button" (click)="openFilter()">
        <mat-icon aria-label="Filter devices icon">filter_list</mat-icon>
    </button>
    <agm-map [latitude]="lat" [longitude]="lng" [fitBounds]="bounds" [zoom]="zoom" [minZoom]="2.5" [mapTypeControl]='true'
        (mapReady)="onMapReady($event)" (zoomChange)="onZoomChange($event)">
        <agm-marker class="markerClass" *ngFor="let mark of shownMarkers" [latitude]="mark['lat']"
            [longitude]="mark['lng']" [title]="mark['title']" [animation]="'DROP'" [agmFitBounds]="true">
            <agm-info-window>
                <div class="infoWin">
                    <div class="infoWinData img" *ngIf="!isMobile && mark['modelName']">
                        <img class="modelImgThumb" [src]="mark.productImage | secure | async" />
                        <p class="tableLabel center">{{mark['modelName']}}</p>
                    </div>
                    <div class="infoWinData">
                        <table>
                            <tr *ngIf="isMobile && mark['modelName']">
                                <td class="tableLabel">{{'live_map.model' | translate}}:</td>
                                <td>{{mark['modelName']}}</td>
                            </tr>
                            <tr>
                                <td class="tableLabel">{{'live_map.identifier' | translate}}:</td>
                                <td>{{mark['identifier']}}</td>
                            </tr>
                            <tr>
                                <td class="tableLabel">{{'live_map.oem' | translate}}:</td>
                                <td>{{mark['oemName']}}</td>
                            </tr>
                            <tr>
                                <td class="tableLabel">{{'live_map.dealer' | translate}}:</td>
                                <td>{{mark['dealerName']}}</td>
                            </tr>
                            <tr>
                                <td class="tableLabel">{{'live_map.client' | translate}}:</td>
                                <td>{{mark['clientName']}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div>
                    <button mat-icon-button class="alert" [disabled]="false" (click)="goToAlerts(mark['id'])">
                        <mat-icon matTooltip="{{'live_map.go_to_alerts' | translate}}">new_releases</mat-icon>
                    </button>
                    <button mat-icon-button class="dashboard" [disabled]="false" (click)="openDashboard(mark)">
                        <mat-icon matTooltip="{{'live_map.go_to_dashboard' | translate}}">dashboard</mat-icon>
                    </button>
                </div>
            </agm-info-window>
        </agm-marker>
    </agm-map>
</div>
