<div class="scrollBelow">
    <form class="user-form" [formGroup]="customerEditorForm" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
        fxLayoutAlign="start">
        <!-- -------------------------this is the first editor form section------------------------------------ -->
        <div class="input">
            <table class="full-width">
                <tr>
                    <mat-icon class="form-icon">account_circle</mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field class="full-width">
                            <input matInput class="form-control" id="customer_name" formControlName="customer_name"
                                placeholder="{{'customer_editor.name' | translate}}" mat-hint="Name" required>
                            <mat-error *ngIf="customerName.touched && customerName.invalid">
                                <mat-error *ngIf="customerName.errors.required">{{'form.name_required' | translate}}</mat-error>
                                <mat-error *ngIf="customerName.errors.duplicateCustomerName">{{'form.customer_exists' | translate}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </tr>
                <tr *ngIf="id">
                    <mat-icon class="form-icon">group</mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field class="full-width">
                            <mat-select id="user_id" placeholder="{{'customer_editor.primary_contact' | translate}}" formControlName="user_id">
                                <mat-option [value]="null">
                                    <em>{{'customer_editor.select_user' | translate}}</em>
                                </mat-option>
                                <mat-option *ngFor="let cusUser of customerUserList" [value]="cusUser.user_id">
                                    {{ cusUser.full_name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </tr>
                <tr>
                    <mat-icon class="form-icon">phone</mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field>
                            <input matInput class="form-control" formControlName="phone" id="phone"
                                placeholder="{{'customer_editor.phone_number' | translate}}" (blur)="validatePhone()">
                            <mat-error *ngIf="phone.touched && phone.invalid" class="alert alert-danger">
                                <mat-error *ngIf="phone.errors.invalid">{{'form.phone_invalid' | translate}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="country-field">
                            <mat-select [(ngModel)]="country" [ngModelOptions]="{standalone: true}"
                                placeholder="{{'customer_editor.country' | translate}}" (selectionChange)="validatePhone()"
                                style="height:10px;margin-top:-10px;">
                                <mat-option default="US" *ngFor="let country of countryList" [value]="country.code">
                                    {{ country.code }} - {{ country.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </tr>
                <tr>
                    <mat-icon class="form-icon">email</mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field class="full-width">
                            <input matInput class="form-control" id="customer_email" formControlName="customer_email"
                                placeholder="{{'customer_editor.support_email' | translate}}" mat-hint="Support Email" required>
                            <mat-error *ngIf="customerEmail.touched && customerEmail.invalid">
                                <mat-error *ngIf="customerEmail.errors.required">{{'form.email_required' | translate}}</mat-error>
                                <mat-error *ngIf="customerEmail.errors.customEmail">{{'form.email_required' | translate}}</mat-error>
                                <mat-error *ngIf="customerEmail.errors.duplicateCustomerSupportEmail">{{'form.email_exists' | translate}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </tr>
                <tr>
                    <mat-icon class="form-icon">place</mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field class="full-width">
                            <input matInput class="form-control" id="address" formControlName="address"
                                placeholder="{{'customer_editor.address' | translate}}" mat-hint="Address">
                        </mat-form-field>
                    </div>
                </tr>
                <tr>
                    <mat-icon class="form-icon">insert_link</mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field class="full-width">
                            <input matInput class="form-control" id="website_url" formControlName="website_url"
                                placeholder="{{'customer_editor.website' | translate}}" mat-hint="Website">
                        </mat-form-field>
                    </div>
                </tr>
                <tr
                    *ngIf="userCustomerRole < constants.role_oem_user && (currentRoute == 'dealers' || currentRoute == 'clients')">
                    <mat-icon class="form-icon">domain</mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field class="full-width">
                            <mat-select formControlName="oem" placeholder="{{'customer_editor.oem' | translate}}" mat-hint="OEM" required>
                                <mat-option [value]="null">
                                    <em>{{'customer_editor.select_oem' | translate}}</em>
                                </mat-option>
                                <mat-option *ngFor="let oemCustomer of oemCustomerList"
                                    [value]="oemCustomer.customer_id">
                                    {{ oemCustomer.customer_name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </tr>
                <tr *ngIf="userCustomerRole < constants.role_dealer_user && (currentRoute == 'clients')">
                    <mat-icon class="form-icon">recent_actors</mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field class="full-width">
                            <mat-select formControlName="dealer" placeholder="{{'customer_editor.dealer' | translate}}" mat-hint="Dealer" required>
                                <mat-option [value]="null">
                                    <em>{{'customer_editor.select_dealer' | translate}}</em>
                                </mat-option>
                                <mat-option *ngFor="let dealerCustomer of dealerCustomerList"
                                    [value]="dealerCustomer.customer_id">
                                    {{ dealerCustomer.customer_name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </tr>
                <tr>
                    <mat-icon class="form-icon">subject</mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field class="full-width">
                            <textarea matInput class="form-control" id="notes" formControlName="notes"
                                placeholder="{{'customer_editor.notes' | translate}}" mat-hint="Notes"></textarea>
                        </mat-form-field>
                    </div>
                </tr>
            </table>
        </div>

        <!-- -----------------------------------This is the second column upload section---------------------------------------- -->
        <div class="upload" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">
            <div class="logo-upload">
                <label>{{'logo' | translate}}</label>
                <div class="image-container">
                    <img class="img" id="logo" [src]="getLogo() | secure | async">
                </div>
                <div class="imageUpload">
                    <input name="files" hidden accept="image/*" type="file" (change)="onLogoChange($event)"
                        id="logoUpload">
                    <button mat-raised-button for="logoUpload" [disabled]="!canEdit" (click)="openLogoUpload()">
                        <mat-icon>
                            <i class="material-icons">file_upload</i>
                        </mat-icon>
                        <span>{{'btn.upload' | translate}}</span>
                    </button>
                </div>
            </div>

            <div class="icon-upload">
                <label>{{'icon' | translate}}</label>
                <div class="image-container">
                    <img class="img" id="favicon" [src]="getFavicon()">
                </div>
                <div class="imageUpload">
                    <input name="files" hidden accept="image/x-icon" type="file" (change)="onIconChange($event)"
                        id="iconUpload">
                    <button [disabled]="!canEdit" for="iconUpload" mat-raised-button (click)="openIconInput()">
                        <mat-icon>
                            <i class="material-icons">file_upload</i>
                        </mat-icon>
                        <span>{{'btn.upload' | translate}}</span>
                    </button>
                </div>
            </div>

            <div class="theme">
                <label>{{'theme' | translate}}</label>
                <mat-form-field>
                    <mat-select (selectionChange)="changeTheme()" formControlName="theme">
                        <mat-select-trigger>
                            <mat-icon [ngStyle]="{'color': currentThemeHex}">fiber_manual_record</mat-icon>
                            <span class="aligned-with-icon">{{ customerEditorForm.controls['theme'].value }}</span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let theme of themes" [value]="theme.label">
                            <mat-icon [ngStyle]="{'color': theme.hex}">fiber_manual_record</mat-icon>
                            {{theme.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="save-cancel">
        <button mat-raised-button (click)="cancel()">
            {{'btn.cancel' | translate}}
        </button>
        <button mat-raised-button class="mat-primary confirm" [disabled]="customerEditorForm.invalid || !canEdit"
            (click)="saveSettings()">
            {{'btn.save' | translate}}
        </button>
    </div>
</div>