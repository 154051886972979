import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICountry } from 'src/app/shared/models/common.model';
import countriesData from 'countries-list/dist/countries.json';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public isEchartDataAvailable$ = new BehaviorSubject<boolean>(false);
  sanctionedCountries: string[] = [
    'RU', // Russia 
    'BY', // Belarus
    'AF', // Afghanistan
    'SY', // Syria
    'IQ', // Iraq
    'IR', // Iran
    'MM', // Myanmar [Burma]
    'KP', // North Korea
    'YE', // Yemen
    'SO', // Somalia
    'TN', // Tunisia
    'LY', // Libya
    'SD', // Sudan
    'ZW', // Zimbabwe
    'CD', // Democratic Republic of the Congo
    'CF', // Central African Republic
    'SS', // South Sudan
    'NI', // Nicaragua
    'VE', // Venezuela
  ];
  defaultSortKey: string = 'id';

  constructor() { }

  sort<T extends Record<string, any>>(arr: T[], key: keyof T = this.defaultSortKey): T[] {
    for (const element of arr) {
      if (!element.hasOwnProperty(key)) { return arr; }
    }
    return arr.sort((a, b) => (a[key] > b[key]) ? 1 : -1);
  }

  /**
   * This method is used to round off a number to a given number of decimal places
   * @param {number} numValue - The value to be rounded off
   * @param {number} [decimalPlaces = 5] - The number of decimal places to round off the value to
   * @returns {number} - The rounded number to the desired number of decimal places
   */
  roundNumber(numValue: number, decimalPlaces: number = 5): number {
    const decimalValue: number = parseInt('1' + '0'.repeat(decimalPlaces));
    return Math.round(numValue * decimalValue) / decimalValue;
  }

  /**
   * Filters out sanctioned countries from a given list of countries.
   * @returns {ICountry[]} The list of non-sanctioned countries.
   */
  getCountries(): ICountry[] {
    const countries: ICountry[] = Object.keys(countriesData)
      .map((code: string) => ({ code: code, name: countriesData[code].name } as ICountry));
    return countries.filter((c: ICountry) => !this.sanctionedCountries.includes(c.code));
  }
}
