export enum Translations {
    SuccessfullyDeleted = 'list_view.successfully_deleted',
    WarningDeletingUser = 'list_view.warning_deleting_user',
    ConfirmDeleteHeading = 'dialog.confirm_delete',
    BtnDelete = 'btn.delete',
    DevicesFound = 'models.devices_found',
    NoAssociatedFound = 'models.no_associated_found',
    NoAssociatedDeviceFound = 'models.no_associated_device_found',
    PromptDescriptionOne = 'list_view.desc_1',
    PromptDescriptionTwo = 'list_view.desc_2'
}

export enum RequestedParam {
    DealerId = 'dealer_id',
    OemId = 'oem_id'
}

export enum UserRoles {
    Oems = 'Oems',
    Dealers = 'Dealers',
    Clients = 'Clients'
}

export interface AlertDetails {
    parameters_id: number;
    model_id: number;
    parameters_name: string;
    customer_name: string;
    model_name: string;
    devices: string | number[];
    is_active: boolean;
}
