import {  Optional, LOCALE_ID, Inject, Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import moment from 'moment';
import { DateTimeService } from './date-time.service';

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
    inner: MomentDateAdapter;

    constructor(private _dateTimeService: DateTimeService, @Optional() @Inject(LOCALE_ID) localeId: any) {
        super(localeId);
    }

    public format(date: moment.Moment, displayFormat: string): string {
        const format = this._dateTimeService.getDateFormat();
        return date.format(format);
    }

    public parse(date: moment.Moment, parseFormat: string) {
        const format = this._dateTimeService.getDateFormat();
        if (date) {
          return moment(date, format);
        }
        return null;
    }
}
