import { Component, OnInit, ViewChild } from '@angular/core';
import { appConstants } from '../services/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageFactoryService } from '../services/manage-factory/manage-factory.service';
import { AmazingTimePickerService } from '@jonijnm/amazing-time-picker';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { AlertEmailDialogComponent } from '../dialogs/alert-email-dialog/alert-email-dialog.component';
import { AlertTextDialogComponent } from '../dialogs/alert-text-dialog/alert-text-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: 'app-alert-editor',
    templateUrl: './alert-editor.component.html',
    styleUrls: ['./alert-editor.component.css']
})
export class AlertEditorComponent implements OnInit {
    id: any;
    isAdmin = false;
    canEdit = false;
    isMobile: boolean;
    constants = appConstants;
    modelArray: any = [];
    paramArray: any[] = [];
    dateParamArray: any = [];
    libraryArray: any[] = [];
    alertArray = {
        email: [],
        text: [],
        log: []
    };
    origDevices: any = [];
    // Keep a running record of all the devices that are loaded.
    devicesData = {};
    alert = {
        parameters_name: null,
        alert_frequency: null,
        model_id: null,
        device_select_type: null
    };

    deviceDatasource: any;
    displayedColumnsDevice: any;
    selectedDevices = new SelectionModel(true, []);
    deviceResultsLength: any;

    emailDatasource: any;
    displayedColumnsEmail = ['select', 'emailAddresses', 'email_subject'];
    selectedEmail = new SelectionModel(true, []);

    textDatasource: any;
    displayedColumnsText = ['select', 'phoneNumbers', 'text_message'];
    selectedText = new SelectionModel(true, []);

    pageSize = '50';
    pageLengthOptions = [10, 25, 50, 100];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    deviceSelectArray = [
        { type_id: 1, type_desc: "alert_editor.select_specific_devices" },
        { type_id: 2, type_desc: "alert_editor.select_all_devices" }
    ];
    operatorArray = [
        { text: "alert_editor.condition.less_than", value: "<" },
        { text: "alert_editor.condition.less_than_or_eq", value: "<=" },
        { text: "alert_editor.condition.greater_than", value: ">" },
        { text: "alert_editor.condition.greater_than_or_eq", value: ">=" },
        { text: "alert_editor.condition.equal", value: "==" },
        { text: "alert_editor.condition.not_equal", value: "!=" }
    ];
    private _statistics = ['average', 'minimum', 'maximum', 'sum'];
    daysOfWeek = [
        { value: 1, name: 'sunday' },
        { value: 10, name: 'monday' },
        { value: 100, name: 'tuesday' },
        { value: 1000, name: 'wednesday' },
        { value: 10000, name: 'thursday' },
        { value: 100000, name: 'friday' },
        { value: 1000000, name: 'saturday' }
    ];

    modelTypeRestrictions: Map<Number, any> = new Map();

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private manageFactory: ManageFactoryService, private atp: AmazingTimePickerService, public dialog: MatDialog, public snackBar: MatSnackBar, private translate: TranslateService) {
        this.deviceDatasource = new MatTableDataSource<any>();
        this.deviceDatasource.paginator = this.paginator;
        this.deviceDatasource.sort = this.sort;
        this.emailDatasource = new MatTableDataSource<any>();
        this.textDatasource = new MatTableDataSource<any>();
        this.activatedRoute.params.subscribe(params => {
            if (params['id'] != undefined) {
                this.id = params['id'];
                this.loadExisting();
            }
        });
    }

    ngOnInit() {
        this.isMobile = (window.innerWidth) < 960;
        this.displayedColumnsDevice = this.isMobile ? ['select', 'identifier'] : ['select', 'identifier', 'name', 'device_type', 'dealer_name', 'client_name'];
        this.canEdit = (+sessionStorage.getItem('userRole') == this.constants.user_read_write) ? true : false;
        this.manageFactory.models.read().subscribe(result => { this.modelArray = result; });
        this.manageFactory.models.getModelTypes().subscribe((result: any) => {
            this.modelTypeRestrictions = new Map();
            Array.from(result).forEach((item: any) => {
                let alertRestrictions = {};
                if (item.restrictions.alerts) {
                    alertRestrictions = item.restrictions.alerts;
                }
                this.modelTypeRestrictions.set(item.type_id, alertRestrictions);
            });
        });
        this.setPageSize();
    }

    loadExisting() {
        this.paramArray = [];
        this.dateParamArray = [];
        this.manageFactory.alerts.getParams(this.id)
            .subscribe(paramResult => {
                this.alert.parameters_name = paramResult[0]['parameters_name'];
                this.alert.alert_frequency = paramResult[0]['alert_frequency'];
                this.alert.model_id = paramResult[0]['model_id'];
                this.alert.device_select_type = paramResult[0]['device_select_type'];

                this.paramArray = [];
                Object.keys(paramResult).forEach(idx => {
                    if (typeof (paramResult[idx].value_id) !== 'undefined' && paramResult[idx].value_id !== null) {
                        this.paramArray.push(paramResult[idx]);
                    };
                });
                this.manageFactory.alerts.getDevicesByParam(this.id)
                    .subscribe(devResult => {
                        this.origDevices = Object.values(devResult).map((row) => row.device_id);
                        this.modelChange(true);
                    });
            })
        this.manageFactory.alerts.getDateParams(this.id)
            .subscribe(dateResult => {
                this.dateParamArray = dateResult;
                for (let x in this.dateParamArray) {
                    this.dateParamArray[x]['dayList'] = dateResult[x]['days'] ? this.getDateArray(dateResult[x]['days']) : [];
                    this.dateParamArray[x]['startTime'] = dateResult[x]['start_time'];
                    this.dateParamArray[x]['endTime'] = dateResult[x]['end_time'];
                }
            })
        this.manageFactory.alerts.getConfig(this.id)
            .subscribe(configResult => {
                if (configResult) {
                    this.alertArray.text = [];
                    this.alertArray.email = [];
                    this.alertArray.log = [];
                    for (let x in configResult) {
                        let obj = configResult[x];
                        // result is a text message
                        if (obj.typeId == 1) {
                            obj.phoneNumbers = obj.phone_nbr.split(',');
                            this.alertArray.text.push(obj);
                            // result is an email
                        } else if (obj.typeId == 2) {
                            obj.emailAddresses = obj.email_addresses.split(',');
                            this.alertArray.email.push(obj);
                            //result is a log
                        } else if (obj.typeId == 3) {
                            this.alertArray.log.push(obj);
                        }
                    }
                    this.emailDatasource.data = this.alertArray.email;
                    this.textDatasource.data = this.alertArray.text;
                }
            })
    }

    onResize(event) {
        this.isMobile = (event.target.innerWidth) < 960;
        this.displayedColumnsDevice = this.isMobile ? ['select', 'identifier'] : ['select', 'identifier', 'name', 'device_type', 'dealer_name', 'client_name'];
    }

    confirmModelChange(e) {
        const oldVal = this.alert.model_id;
        if (oldVal) {
            this.translate.get(["alert_editor.confirm_model_change", "alert_editor.confirm_model_change_desc", "btn.update"]).subscribe((translated: string) => {
                let dialog = this.dialog.open(ConfirmDialogComponent, {
                    data: { title: translated['alert_editor.confirm_model_change'], text: translated['alert_editor.confirm_model_change_desc'], confirm: translated['btn.update'] }
                });

                dialog.afterClosed()
                    .subscribe(response => {
                        //if we get a true from the dialog, change the model
                        if (response) {
                            this.paramArray = [];
                            this.dateParamArray = [];
                            this.alert.device_select_type = 1;
                            this.selectedDevices.clear();
                            this.modelChange(false);
                            this.addParam();
                        } else {
                            this.alert.model_id = oldVal;
                        }
                    })
            });
        } else {
            this.alert.model_id = e;
            this.modelChange(false);
            this.addParam();
        }
    }

    modelChange(isNew) {
        if (this.alert.model_id) {
            this.manageFactory.alerts.getValueNames(this.alert.model_id)
                .subscribe((valResult: any[]) => {
                    this.libraryArray = valResult;

                    this.paramArray.forEach(fe => {
                        const library = this.libraryArray.find(f => f.library_id === fe.library_id);
                        if (library) {
                            const message = library.messages.find(f => f.message_id === fe.message_id);
                            fe.valueArray = (message && message.message_values) ? message.message_values : [];
                        }
                    });
                });
            this.manageFactory.alerts.getAllDeviceByModel({ modelId: this.alert.model_id })
                .subscribe((devResult) => {
                    Array.from(devResult as any).forEach(element => {
                        this.devicesData[element['device_id']] = element;
                    });
                    this.deviceDatasource.data = devResult;
                    this.deviceResultsLength = this.deviceDatasource.data.length;
                    if (isNew && this.origDevices) {
                        for (let x of this.origDevices) {
                            let row = this.deviceDatasource.data.find(i => i['device_id'] == x);
                            if (row) this.selectedDevices.select(row);
                        }
                    }
                    setTimeout(() => {
                        this.deviceDatasource.paginator = this.paginator;
                        this.deviceDatasource.sort = this.sort;
                    });
                })
        }
    }

    messageChange(message, param, valueId) {
        param.valueArray = message.message_values;
        param.value_id = '';
        param.value_name = '';
        valueId.control.markAsTouched();
    }

    valueChange(param) {
        let findUom = param.valueArray.find(o => o.message_value_id === param.value_id);
        param.value_uom = findUom.value_uom ? findUom.value_uom : '';
        param.value_name = findUom.value_name;
    }

    canAddParam(): boolean {
        if (!this.alert.model_id || !this.canEdit || this.modelTypeRestrictions.size == 0) {
            return false;
        }
        let maxNumParams: number;
        for (let model of this.modelArray) {
            if (model.model_id == this.alert.model_id) {
                maxNumParams = this.getRestrictions(model.device_type_id).max_num_params;
                break;
            }
        }
        return maxNumParams == undefined || this.paramArray.length < maxNumParams;
    }

    addParam() {
        if (this.canAddParam()) {
            let parameterObj = {
                operator: '==',
                parameter: '0',
                value_name: '',
                message_id: '',
                value_id: '',
                value_uom: '',
                statistic: 'average',
                period: '0'
            };
            this.paramArray.push(parameterObj);
        }
    }

    deleteParam(param) {
        let index = this.paramArray.indexOf(param);
        this.paramArray.splice(index, 1);
    }

    addDateParam() {
        if (this.canAddDateParam()) {
            let dateParam = {
                days: [],
                startTime: '',
                endTime: ''
            };
            this.dateParamArray.push(dateParam);
        }
    }

    canAddDateParam(): boolean {
        if (!this.alert.model_id || !this.canEdit || this.modelTypeRestrictions.size == 0) {
            return false;
        }
        let maxNumDateParams: number;
        for (let model of this.modelArray) {
            if (model.model_id == this.alert.model_id) {
                maxNumDateParams = this.getRestrictions(model.device_type_id).max_num_date_params;
                break;
            }
        }
        return maxNumDateParams == undefined || this.dateParamArray.length < maxNumDateParams;
    }

    deleteDateParam(param) {
        let index = this.dateParamArray.indexOf(param);
        this.dateParamArray.splice(index, 1);
    }

    addEmail(edit) {
        let item, currentAlert, index;
        if (!edit) {
            item = 'Add Email Alert';
            currentAlert = {
                emailAddresses: [],
                email_body: '',
                email_subject: '',
                typeId: 2
            };
        } else {
            item = 'Edit Email Alert';
            currentAlert = this.selectedEmail.selected[0];
            index = this.alertArray.email.indexOf(currentAlert);
        }

        let dialog = this.dialog.open(AlertEmailDialogComponent, {
            panelClass: 'toolbar-dialog',
            data: { title: item, alert: currentAlert }
        });

        dialog.afterClosed()
            .subscribe(response => {
                if (response) {
                    if (!edit) {
                        this.alertArray.email.push(response);
                    } else {
                        this.alertArray.email[index] = response;
                    }
                    this.emailDatasource.data = this.alertArray.email;
                }
            })
    }

    addText(edit) {
        let item, currentAlert, index;
        if (!edit) {
            item = 'Add Text Alert';
            currentAlert = {
                phoneNumbers: [],
                phone_nbr: '',
                text_message: '',
                typeId: 1
            };
        } else {
            item = 'Edit Text Alert';
            currentAlert = this.selectedText.selected[0];
            index = this.alertArray.text.indexOf(currentAlert);
        }

        let dialog = this.dialog.open(AlertTextDialogComponent, {
            panelClass: 'toolbar-dialog',
            data: { title: item, alert: currentAlert }
        });

        dialog.afterClosed()
            .subscribe(response => {
                if (response) {
                    if (!edit) {
                        this.alertArray.text.push(response);
                    } else {
                        this.alertArray.text[index] = response;
                    }
                    this.textDatasource.data = this.alertArray.text;
                }
            })
    }

    deleteEmail() {
        this.translate.get(["alert_editor.email_alerts", "alert_editor.email_alert"]).subscribe((translated: string) => {
            let item = this.selectedEmail.selected.length > 1 ? translated['alert_editor.email_alerts'] : translated['alert_editor.email_alert'];
            this.translate.get(["btn.delete", "alert_editor.confirm_delete", "alert_editor.confirm_delete_desc"], { 'alert': item }).subscribe((translated_dlg: string) => {
                let dialog = this.dialog.open(ConfirmDialogComponent, {
                    data: { title: translated_dlg['alert_editor.confirm_delete'], text: translated_dlg['alert_editor.confirm_delete_desc'], confirm: translated_dlg['btn.delete'] }
                });

                dialog.afterClosed()
                    .subscribe(response => {
                        //if we get a true from the dialog, delete the selected items
                        if (response) {
                            for (let x in this.selectedEmail.selected) {
                                let selected = this.selectedEmail.selected[x];
                                let index = this.alertArray.email.indexOf(selected);
                                this.alertArray.email.splice(index, 1);
                            };
                        }
                        this.selectedEmail.clear();
                        this.emailDatasource.data = this.alertArray.email;
                    })
            });
        });
    }

    deleteText() {
        this.translate.get(["alert_editor.text_alerts", "alert_editor.text_alert"]).subscribe((translated: string) => {
            let item = this.selectedText.selected.length > 1 ? translated['alert_editor.text_alerts'] : translated['alert_editor.text_alert'];
            this.translate.get(["btn.delete", "alert_editor.confirm_delete", "alert_editor.confirm_delete_desc"], { 'alert': item }).subscribe((translated_dlg: string) => {
                let dialog = this.dialog.open(ConfirmDialogComponent, {
                    data: { title: translated_dlg['alert_editor.confirm_delete'], text: translated_dlg['alert_editor.confirm_delete_desc'], confirm: translated_dlg['btn.delete'] }
                });

                dialog.afterClosed()
                    .subscribe(response => {
                        //if we get a true from the dialog, delete the selected items
                        if (response) {
                            for (let x in this.selectedText.selected) {
                                let selected = this.selectedText.selected[x];
                                let index = this.alertArray.text.indexOf(selected);
                                this.alertArray.text.splice(index, 1);
                            };
                        }
                        this.selectedText.clear();
                        this.textDatasource = this.alertArray.text;
                    })
            });
        });
    }

    toggleLog() {
        if (this.alertArray.log.length == 0) {
            let log = { typeId: 3 };
            this.alertArray.log.push(log);
        } else {
            this.alertArray.log = [];
        }
    }

    updateDeviceSelection() {
        if (this.alert.device_select_type == 1) this.selectedDevices.clear();
        if (this.alert.device_select_type == 2) this.deviceDatasource.data.forEach(row => this.selectedDevices.select(row));
    }

    selectRowDevice(row) {
        if (this.alert.device_select_type == 1) this.selectedDevices.toggle(row);
    }

    goBack() {
        let goTo = 'alerts';
        this.router.navigate([goTo]);
    }

    async prepareToSave() {
        var paramObj = {
            parameter: [],
            alertOptions: [],
            dates: [],
            devices: [],
            modelId: this.alert.model_id,
            alertFrequency: this.alert.alert_frequency,
            name: this.alert.parameters_name,
            customerId: sessionStorage.getItem('customerId'),
            deviceSelectOption: this.alert.device_select_type
        };
        for (let i in this.paramArray) {
            let tempParam = {
                value: '',
                operator: '',
                parameter: 0,
                period: 0,
                statistic: '',
                name: '',
                uom: ''
            };
            tempParam['value'] = this.paramArray[i]['value_id'];
            tempParam['operator'] = this.paramArray[i]['operator'];
            tempParam['parameter'] = parseFloat(this.paramArray[i]['parameter']);
            tempParam['period'] = parseFloat(this.paramArray[i]['period']);
            tempParam['statistic'] = this.paramArray[i]['statistic'];
            tempParam['name'] = this.paramArray[i]['value_name'];
            tempParam['uom'] = this.paramArray[i]['value_uom'];
            tempParam['condition_id'] = this.paramArray[i]['condition_id'];
            paramObj.parameter.push(tempParam);
        }
        for (let j in this.dateParamArray) {
            let tempDate = {
                days: 0,
                startTime: '',
                endTime: ''
            }
            let daysValue = 0;
            for (let h in this.dateParamArray[j]['dayList']) {
                daysValue += this.dateParamArray[j]['dayList'][h];
            }
            //tempDate['startTime'] = moment(this.dateParamArray[j]['startTime']).format('HH:mm:00');
            //tempDate['endTime'] = moment(this.dateParamArray[j]['endTime']).format('HH:mm:00');
            tempDate['startTime'] = this.dateParamArray[j]['startTime'];
            tempDate['endTime'] = this.dateParamArray[j]['endTime'];
            tempDate['days'] = daysValue;
            paramObj.dates.push(tempDate);
        }

        for (let x in this.selectedDevices.selected) {
            paramObj.devices.push(this.selectedDevices.selected[x]['device_id'])
        }

        // get all the devices affected by the changes before save.
        for (let y in paramObj.devices) {
            let deviceId = paramObj.devices[y];
            // Check if this a new device for the alert.
            if (this.origDevices.indexOf(deviceId) == -1) {
                let device = this.devicesData[deviceId];
                // Check if the device can't have more alerts.
                if (device && !device.can_add_alert) {
                    // The device has the maximum number of alerts. Show snackbar error message.
                    this.translate.get("alert_editor.too_many_alerts", { identifier: device.identifier }).subscribe((text: string) => {
                        this.snackBar.open(text, "", { duration: 8000, horizontalPosition: 'left' });
                    });
                    return;
                }
                this.origDevices.push(deviceId);
            }
        }

        // checks if there are any currently saved config options and adds them to the alertOptions
        for (let x in this.alertArray.text) {
            paramObj.alertOptions.push(this.alertArray.text[x]);
        }

        for (let x in this.alertArray.email) {
            paramObj.alertOptions.push(this.alertArray.email[x]);
        }

        for (let x in this.alertArray.log) {
            paramObj.alertOptions.push(this.alertArray.log[x]);
        }
        if (!this.id) {
            this.manageFactory.alerts.create(paramObj)
                .subscribe(result => {
                    this.manageFactory.alerts.updateDeviceJson(this.origDevices)
                        .subscribe(jsonResult => {
                            this.translate.get("alert_editor.alert_created").subscribe((text: string) => {
                                this.snackBar.open(text, "", {
                                    duration: 4000,
                                    horizontalPosition: 'left'
                                })
                                //reload page with id so any more changes will update instead of creating a new alert
                                let newId = result['insertId'];
                                let goTo = 'alert/' + newId;
                                this.router.navigate([goTo]);
                            });
                        })
                })
        } else {
            paramObj['savedAlert'] = this.id;
            this.manageFactory.alerts.deleteSettings(this.id)
                .subscribe(async (result) => {
                    this.manageFactory.alerts.update(paramObj)
                        .subscribe(async (updateResult) => {
                            this.manageFactory.alerts.updateDeviceJson(this.origDevices)
                                .subscribe(async (jsonResult) => {
                                    this.translate.get("alert_editor.alert_updated").subscribe((text: string) => {
                                        this.snackBar.open(text, "", {
                                            duration: 4000,
                                            horizontalPosition: 'left'
                                        });

                                        this.paramArray = [];
                                        this.dateParamArray = [];
                                        this.selectedDevices.clear();
                                        this.loadExisting();
                                    });
                                });
                        });
                })
        }
    }

    getDateArray(item) {
        // gets the "value" of all days in week and puts into an array
        let holder = "0000000" + item;
        let str = ("" + holder).split("").reverse();
        let holderArray = [];
        for (let i = 0; i <= 7; i++) {
            if (str[i] == "1") {
                let sub = Math.pow(10, i);
                holderArray.push(sub);
            }
        }
        return holderArray;
    }

    setPageSize() {
        this.pageSize = sessionStorage.getItem('page_size');
        if (!this.pageSize || parseInt(this.pageSize, 10) > this.pageLengthOptions[this.pageLengthOptions.length - 1]) {
            this.pageSize = '50';
        }
    }

    getPageSize(e) {
        sessionStorage.setItem('page_size', e.pageSize);
    }

    getRestrictions(modelTypeId: number) {
        if (this.modelTypeRestrictions.has(modelTypeId)) {
            return this.modelTypeRestrictions.get(modelTypeId);
        } else {
            return {};
        }
    }

    filteredStatistics() {
        let statisticsFilter: Array<string>;
        for (let model of this.modelArray) {
            if (model.model_id == this.alert.model_id) {
                statisticsFilter = this.getRestrictions(model.device_type_id).statistics;
                break;
            }
        }
        if (statisticsFilter != undefined) {
            return this._statistics.filter((stat) => statisticsFilter.includes(stat));
        } else {
            return this._statistics;
        }
    }
}
