import { ManageFactoryService } from "../services/manage-factory/manage-factory.service";
import { Subscription, zip } from "rxjs";
import moment from "moment";
import { SearchService } from "./../services/search/search.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { ConfirmDialogComponent } from "../dialogs/confirm-dialog/confirm-dialog.component";
import { TextInputDialogComponent } from "../dialogs/text-input-dialog/text-input-dialog.component";
import { InvoiceFilterDialogComponent } from "../dialogs/invoice-filter-dialog/invoice-filter-dialog.component";
import { InvoiceFilterDialogResult } from "../dialogs/invoice-filter-dialog/invoice-filter-dialog-result";
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-invoices",
    templateUrl: "./invoices.component.html",
    styleUrls: ["./invoices.component.css"]
})
export class InvoicesComponent implements OnInit {
    displayedColumns: string[];
    selectedDatePreset: number;
    dataSource: MatTableDataSource<any>;
    searchSubscription: Subscription;
    toolBar: string[];
    isLoadingResults = false;
    resultsLength = 0;
    pageSize = '50';
    pageLengthOptions = [10, 25, 50, 100];
    selection = new SelectionModel(true, []);
    multipleSelect: boolean = true;
    unfilteredData: any;
    dialogFilterResult: InvoiceFilterDialogResult;
    dateTimeFormat = sessionStorage.getItem('date_format') + " " + sessionStorage.getItem('time_format');

    private isMobile: boolean;

    datePresets = [
        {
            preset_id: 1,
            period_name: "invoice.date_presets.lifetime",
            days_in_period: 0,
            weeks_in_period: null
        },
        {
            preset_id: 2,
            period_name: "invoice.date_presets.today",
            days_in_period: 1,
            weeks_in_period: null
        },
        {
            preset_id: 3,
            period_name: "invoice.date_presets.yesterday",
            days_in_period: 2,
            weeks_in_period: null
        },
        {
            preset_id: 4,
            period_name: "invoice.date_presets.last7days",
            days_in_period: 7,
            weeks_in_period: null
        },
        {
            preset_id: 5,
            period_name: "invoice.date_presets.last14days",
            days_in_period: 14,
            weeks_in_period: null
        },
        {
            preset_id: 6,
            period_name: "invoice.date_presets.last30days",
            days_in_period: 30,
            weeks_in_period: null
        },
        {
            preset_id: 7,
            period_name: "invoice.date_presets.this_week",
            days_in_period: null,
            weeks_in_period: 1
        },
        {
            preset_id: 8,
            period_name: "invoice.date_presets.last_week",
            days_in_period: null,
            weeks_in_period: 2
        }
    ];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private manageFactory: ManageFactoryService,
        private searchService: SearchService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private translate: TranslateService
    ) {
        this.dataSource = new MatTableDataSource([]);
        this.isLoadingResults = true;
        this.selectedDatePreset = 1;
        this.dialogFilterResult = new InvoiceFilterDialogResult();
    }

    ngOnInit() {
        this.isMobile = window.innerWidth < 650;

        this.setColumns();
        this.getData();

        this.searchSubscription = this.searchService.searchObservable$.subscribe(
            res => {
                if (
                    res.hasOwnProperty("option") &&
                    res.option === "call_child"
                ) {
                    this.applyFilter(res.value);
                }
            }
        );
        this.setPageSize();
    }

    ngOnDestroy() {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
    }

    onResize(event) {
        this.isMobile = event.target.innerWidth < 650;
        this.setColumns();
    }

    selectRow(row) {
        if (this.multipleSelect) {
            this.selection.toggle(row);
        } else {
            if (this.selection.isSelected(row)) {
                this.selection.clear();
            } else {
                this.selection.clear();
                this.selection.select(row);
            }
        }
    }

    getStatusName(status: number): string {
        var statusName = "";

        switch (status) {
            case 1:
                statusName = "invoice.sent";
                break;
            case 2:
                statusName = "invoice.not_sent";
                break;
            case 3:
                statusName = "invoice.paid";
                break;
            case 4:
                statusName = "invoice.overdue";
                break;
            default:
                statusName = "invoice.unknown";
                break;
        }

        return statusName;
    }

    private setColumns(): void {
        this.displayedColumns = [
            "select",
            "invoice_number",
            "created_date",
            "customer_name",
            "amount",
            "pay_period",
            "payment_status_name"
        ];
        this.toolBar = ["add", "edit", "datePresets"];
    }

    private applyFilter(filterValue: string): void {
        this.dataSource.filter = filterValue
            ? filterValue.trim().toLowerCase()
            : null;
    }

    private getData(): void {
        this.manageFactory.invoices.read().subscribe((result: any) => {
            this.unfilteredData = result;
            this.dataSource = new MatTableDataSource(result);
            this.datePresets = [
                {
                    preset_id: 1,
                    period_name: "invoice.date_presets.lifetime",
                    days_in_period: 0,
                    weeks_in_period: null
                },
                {
                    preset_id: 2,
                    period_name: "invoice.date_presets.today",
                    days_in_period: 1,
                    weeks_in_period: null
                },
                {
                    preset_id: 3,
                    period_name: "invoice.date_presets.yesterday",
                    days_in_period: 2,
                    weeks_in_period: null
                },
                {
                    preset_id: 4,
                    period_name: "invoice.date_presets.last7days",
                    days_in_period: 7,
                    weeks_in_period: null
                },
                {
                    preset_id: 5,
                    period_name: "invoice.date_presets.last14days",
                    days_in_period: 14,
                    weeks_in_period: null
                },
                {
                    preset_id: 6,
                    period_name: "invoice.date_presets.last30days",
                    days_in_period: 30,
                    weeks_in_period: null
                },
                {
                    preset_id: 7,
                    period_name: "invoice.date_presets.this_week",
                    days_in_period: null,
                    weeks_in_period: 1
                },
                {
                    preset_id: 8,
                    period_name: "invoice.date_presets.last_week",
                    days_in_period: null,
                    weeks_in_period: 2
                }
            ];
            this.filterInvoiceResults(this.selectedDatePreset);
        });
    }

    private filterInvoiceResults(presetId: number): void {
        let ds = [];
        var selectedPreset;

        for (let preset of this.datePresets) {
            if (preset.preset_id == presetId) {
                selectedPreset = preset;
            }
        }

        if (
            selectedPreset.days_in_period == 0 &&
            selectedPreset.weeks_in_period == null
        ) {
            ds = this.dataSource.data;
        } else if (selectedPreset.days_in_period != null) {
            if (
                selectedPreset.days_in_period == 1 ||
                selectedPreset.days_in_period == 2
            ) {
                var start = moment().startOf("day")
                    .subtract(selectedPreset.days_in_period - 1, "day");
                var end = moment().endOf("day")
                    .subtract(selectedPreset.days_in_period - 1, "day");
                
                for (let obj of this.dataSource.data) {
                    var created = moment(obj.date_created, this.dateTimeFormat);
                    if (created.isBetween(start, end, undefined, "[]")) {
                        ds.push(obj);
                    }
                }
            } else {
                var start = moment().startOf("day")
                    .subtract(selectedPreset.days_in_period, "day");
                var end = moment().endOf("day");

                for (let obj of this.dataSource.data) {
                    var created = moment(obj.date_created, this.dateTimeFormat);
                    if (created.isBetween(start, end, undefined, "[]")) {
                        ds.push(obj);
                    }
                }
            }
        } else {
            if (selectedPreset.weeks_in_period == 1) {
                var start = moment().startOf("week");
                var end = moment().endOf("week");

                for (let obj of this.dataSource.data) {
                    var created = moment(obj.date_created, this.dateTimeFormat);
                    if (created.isBetween(start, end, undefined, "[]")) {
                        ds.push(obj);
                    }
                }
            } else {
                var start = moment()
                    .startOf("week")
                    .subtract(selectedPreset.weeks_in_period - 1, "week");
                var end = moment()
                    .endOf("week")
                    .subtract(selectedPreset.weeks_in_period - 1, "week");

                for (let obj of this.dataSource.data) {
                    var created = moment(obj.date_created, this.dateTimeFormat);
                    if (created.isBetween(start, end, undefined, "[]")) {
                        ds.push(obj);
                    }
                }
            }
        }

        this.finishFilter(ds);
    }

    private finishFilter(ds): void {
        this.dataSource = new MatTableDataSource(ds);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.resultsLength = ds.length;
        this.isLoadingResults = false;
        if (ds.length > 100)
            this.pageLengthOptions = [10, 25, 50, 100, this.resultsLength];
    }

    onDatePresetChange(event) {
        this.dialogFilterResult = new InvoiceFilterDialogResult();
        this.selection.clear();
        this.selectedDatePreset = event.source.value;
        this.getData();
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    delete() {
        this.translate.get(["dialog.confirm_delete", "invoice.confirm_delete_desc", "btn.delete"]).subscribe((translated: string) => {
            let dialog = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: translated['dialog.confirm_delete'],
                    text: translated['invoice.confirm_delete_desc'],
                    confirm: translated['btn.delete']
                }
            });

            dialog.afterClosed().subscribe(response => {
                if (response) {
                    let isSuccessful = 0;
                    for (let x in this.selection.selected) {
                        let selectedId = -1,
                            index = -1;
                        selectedId = this.selection.selected[x].invoice_id;

                        index = this.dataSource.data.findIndex(
                            i => i.invoice_id === selectedId
                        );

                        this.dataSource.data.splice(index, 1);

                        this.manageFactory.invoices
                            .destroy({ id: selectedId })
                            .subscribe(result => {
                                isSuccessful += 1;
                                // only throw up the snackBar the first time we go through
                                if (isSuccessful === 1) {
                                    this.translate.get(this.selection.selected.length == 1 ? "invoice.success_delete_one" : "invoice.success_delete_few").subscribe((success_str: string) => {
                                        this.snackBar.open(
                                            success_str,
                                            "",
                                            {
                                                duration: 4000,
                                                horizontalPosition: "left"
                                            }
                                        );
                                    });
                                }
                            });
                    }
                }

                this.selection.clear();
                this.dataSource.paginator = this.paginator;
            });
        });
    }

    openFilter() {
        this.translate.get(["invoice.filter", "btn.confirm"]).subscribe((translated: string) => {
            let dialog = this.dialog.open(InvoiceFilterDialogComponent, {
                panelClass: "toolbar-dialog",
                data: {
                    title: translated['invoice.filter'],
                    confirm: translated['btn.confirm'],
                    state: this.dialogFilterResult
                }
            });

            dialog.afterClosed().subscribe(response => {
                if (response) {
                    var result = <InvoiceFilterDialogResult>response;
                    let allData = this.unfilteredData;

                    if (!result.isEmpty()) {
                        var resultStartPayDate = new Date(
                            result.filterStartPayPeriod
                        );
                        resultStartPayDate.setHours(0, 0, 0, 0);

                        var resultEndPayDate = new Date(result.filterEndPayPeriod);
                        resultEndPayDate.setHours(0, 0, 0, 0);

                        allData = allData.filter(invoice => {
                            var formatDate = invoice.invoice_date.split("T")[0];
                            let payDate = new Date(formatDate);

                            var ret =
                                (result.filterCustomerId == null ||
                                    invoice.customer_id ==
                                    result.filterCustomerId) &&
                                (result.filterStatusId == null ||
                                    invoice.status == result.filterStatusId);

                            var validatePayPeriod = false;

                            if (
                                result.filterStartPayPeriod != null &&
                                result.filterEndPayPeriod != null
                            ) {
                                validatePayPeriod = this.isDateWithinRange(
                                    resultStartPayDate,
                                    resultEndPayDate,
                                    payDate
                                );
                            } else {
                                var start =
                                    result.filterStartPayPeriod == null ||
                                    payDate.getTime() ===
                                    resultStartPayDate.getTime();

                                var end =
                                    result.filterEndPayPeriod == null ||
                                    payDate.getTime() ===
                                    resultEndPayDate.getTime();

                                validatePayPeriod = start && end;
                            }

                            return ret && validatePayPeriod;
                        });
                    }

                    this.selectedDatePreset = 1;
                    this.dialogFilterResult = result;
                    this.dataSource.data = allData;
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.resultsLength = this.dataSource.data.length;
                    this.pageLengthOptions =
                        this.resultsLength > 100
                            ? [10, 25, 50, 100, this.resultsLength]
                            : [10, 25, 50, 100];
                }
            });
        });
    }

    private isDateWithinRange(from: Date, to: Date, value: Date): boolean {
        var fromMoment = moment(from);
        var toMoment = moment(to);
        var valueMoment = moment(value);

        return valueMoment.isBetween(fromMoment, toMoment, null, "[]");
    }

    setPageSize() {
        this.pageSize = sessionStorage.getItem('page_size');
        if (!this.pageSize || parseInt(this.pageSize, 10) > this.pageLengthOptions[this.pageLengthOptions.length - 1]) {
            this.pageSize = '50';
        }
    }

    getPageSize(e) {
        sessionStorage.setItem('page_size', e.pageSize);
    }
}
