import moment from "moment";
import { ChartType } from "../log-chart/model/log-chart.model";

export interface IChartSymbol {
    id: number;
    name: string;
    deviceId: number;
    variables: IChartVariable[];
}

export interface IChartVariable {
    id: number;
    deviceId: number;
    name: string;
    variableUom: string;
}

export interface IChartVariableData {
    deviceId: number;
    symbolId: number;
    symbolName: string;
    variableId: number;
    variableName: string;
    variableUom: string;
}

export interface IChartThreshold {
    yAxis: number;
}

export interface IEchartData {
    id: number;
    values: number[][];
    deviceId: number;
}

export interface IReportAggregate {
    min: number;
    max: number;
    mean: number;
    selectedVariable: string;
}

export interface ITooltipVariable {
    marker: string;
    axisValueLabel: string;
    seriesName: string;
    value: [Date, number];
}

export interface IPercentageFrequency {
    range: string;
    value: string;
}

export interface IFrequencyDistribution {
    selectedVariable: string;
    minValue: number;
    maxValue: number;
    frequency: number;
}

export enum ChartXAxisType {
    Time = 'time',
    Category = 'category'
}

export enum DispatchActionType {
    Downplay = 'downplay',
    Highlight = 'highlight',
    ShowTip = 'showTip'
}

export interface Marker {
    lat: number;
    lng: number;
    label: string;
    time: string;
    draggable: boolean;
    isOpenInfo: boolean;
}

export class Chart {
    id: number;
    type: ChartType;
    selectedVariables: IChartVariableData[];
    private loaderFunction?: Function;
    chartPoints: any[];
    labels: string[];
    echartData: IEchartData[];
    loading: boolean;
    chartThresholds: IChartThreshold[];
    columnWidth: string; 

    constructor(
        type: ChartType,
        selectedVariables: IChartVariableData[],
        _chartThresholds: IChartThreshold[],
        columnWidth: string,
        loaderFunction?: Function,
    ) {
        this.type = type;
        this.selectedVariables = selectedVariables;
        this.loaderFunction = loaderFunction;
        this.chartThresholds = _chartThresholds;
        this.columnWidth = columnWidth;
        this.id = Math.floor(Math.random() * 90 + 10); // this logic is needed to generate a unique id for each chart.
    }

    load() {
        if (this.loaderFunction) {
            this.loaderFunction(this);
        }
    }
    
    variableName(variableData: IChartVariableData): string {
        if (variableData.variableUom !== '') {
            return variableData.variableName + ' [' + variableData.variableUom + ']';
        }
        return variableData.variableName;
    }

    variableUnit(variableData: any) {
        if (variableData.variableUom !== '') {
            return '  ' + variableData.variableUom;
        }
        return '';
    }

    convertPoints(data) {
        let cp = [];
        let scount = data.length;
        let idxs = this.initArrayAndFill(scount, 0);
        let point;
        let tm;
        while (true) {
            for (let i = 0; i < scount; i++) {
                if (!data[i].values) {
                    continue;
                }

                if (idxs[i] >= 0 && idxs[i] >= data[i].values.length) {
                    // if idxs[0] == -1 then we reach end of this data array
                    idxs[i] = -1;
                }
            }

            tm = null;
            for (let i = 0; i < scount; i++) {
                if (!data[i].values) {
                    continue;
                }

                if (idxs[i] >= 0) {
                    // tm = min(time)
                    if (tm === null || data[i].values[idxs[i]][0] < tm) {
                        tm = Math.floor(data[i].values[idxs[i]][0]);
                    }
                }
            }

            if (tm !== null) {
                point = this.initArrayAndFill(scount, null);
                for (let i = 0; i < scount; i++) {
                    if (!data[i].values) {
                        continue;
                    }

                    while (idxs[i] >= 0) {
                        if (Math.floor(data[i].values[idxs[i]][0]) === tm) {
                            point[i] = data[i].values[idxs[i]][1];
                            idxs[i] += 1;
                            if (idxs[i] >= data[i].values.length) {
                                idxs[i] = -1;
                            }
                        } else {
                            break;
                        }
                    }
                }

                // local time
                let date = new Date(tm * 1000);

                cp.push([].concat(date, point));
            } else {
                break;
            }
        }
        return cp;
    }

    initArrayAndFill(length, fillValue) {
        return Array(length).fill(fillValue);
    }
}