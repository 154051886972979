import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ManageFactoryService } from '../manage-factory/manage-factory.service';
import * as frontendVersionHistory from '../../../../releases/history.json';

@Injectable({
    providedIn: 'root'
})
export class VersionService {

    projects = ['frontend', 'backend'];
    projectNames = {};
    history = {};

    get versionString(): string {
        return this.projects.map((project) =>
            this.projectName(project) + ': ' + (this.projectVersion(project) ?? '?.?.?')
        ).join(' | ');
    }

    constructor(
        private translate: TranslateService,
        private manageFactory: ManageFactoryService
    ) {
        this.projects.forEach((project) => {
            this.translate.get('about.' + project).subscribe((name: string) => {
                this.projectNames[project] = name;
            });
        });
    }
    
    // Loads version history into history variable for use in components.
    // Usage in service constructor results in empty backend history.
    loadHistory() {
        this.history['frontend'] = this.convertHistory(frontendVersionHistory);
        this.manageFactory.versions.history().subscribe(backendVersionHistory => {
            this.history['backend'] = this.convertHistory(backendVersionHistory)
        });
    }

    convertHistory(history: any) {
        return Array.from(history as any).map((item) => ({
            version: item['version'],
            date: new Date(item['date'] * 1000),
            notes: item['notes'] ?? [],
        }));
    }

    projectName(project: string): string {
        return this.projectNames[project] ?? '';
    }

    projectVersion(project: string): string {
        if (this.history[project] == null || this.history[project].length == 0) {
            return null;
        }
        return this.history[project][0].version;
    }
}
