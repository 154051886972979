<mat-toolbar class="header">
    <span class="spacer"></span>
    <button mat-icon-button [hidden]="type != 'log'"
            matTooltip="{{'log_charts.add_chart' | translate}}"
            (click)="addBtnClick()">
        <mat-icon>add</mat-icon>
    </button>
</mat-toolbar>

<div class="content fix-height">
    <chart-card #logChart *ngFor="let chart of charts; let i = index"
                [chart]="chart" [showToolBar]="type != 'gps'"
                [index]="i" (editEvent)='editChart(chart)'
                (deleteEvent)='deleteChart(i)'>
    </chart-card>
</div>