import { ManageFactoryService } from "../services/manage-factory/manage-factory.service";
import { AuthFactoryService } from "../services/auth-factory/auth-factory.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { PayPeriod } from "../data/pay-period";
import { zip, Observable } from "rxjs";
import { CreateInvoice } from "../data/create-invoice";
import { SaveInvoice, SaveInvoiceData } from "../data/save-invoice";
import { ActivatedRoute, Params } from "@angular/router";
import { Invoice } from "../data/invoice";
import { Subject, Subscription, of } from "rxjs";
import {
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from "@angular/forms";
import { EditorPayPeriod } from "./editor-pay-period";
import { EditorCustomer } from "./editor-customer";
import { EditorYear } from "./editor-year";
import {
    InvoiceEditorReactiveForm,
    InvoiceEditorForm
} from "./invoice-editor-reactive-form";
import moment from "moment";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: "app-invoice-editor",
    templateUrl: "./invoice-editor.component.html",
    styleUrls: ["./invoice-editor.component.css"]
})
export class InvoiceEditorComponent implements OnInit {
    allPayPeriods: Array<EditorPayPeriod>;
    payPeriods: Array<EditorPayPeriod>;
    years: Array<EditorYear>;
    customers: Array<EditorCustomer>;

    private routerSubscription: Subscription;
    private dataSubscription: Subscription;

    invoiceForm: InvoiceEditorReactiveForm;
    invoice: Invoice;
    saveValid: boolean;
    items: any;

    constructor(
        private readonly manageFactory: ManageFactoryService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly fb: FormBuilder,
        private readonly auth: AuthFactoryService,
        private translate: TranslateService
    ) {
        this.invoiceForm = new InvoiceEditorReactiveForm(fb);
    }

    ngOnInit(): void {
        this.routerSubscription = this.activatedRoute.params.subscribe(
            params => {
                var id = params["id"];
                if (id != null) {
                    // edit invoice
                    this.manageFactory.invoices
                        .readOne({ id: id })
                        .subscribe((data: any) => {
                            this.invoice = data.invoice;
                            this.items = data.items;
                            this.applyState();
                        });
                } else {
                    // create invoice
                    this.applyState();
                }
            }
        );
    }

    ngOnDestroy(): void {}

    private applyState(): void {
        this.payPeriods = new Array<EditorPayPeriod>();
        this.years = new Array<EditorYear>();
        this.customers = new Array<EditorCustomer>();

        this.dataSubscription = zip(
            this.manageFactory.invoices.getPayPeriods(),
            this.manageFactory.clients.read()
        ).subscribe((results: any) => {
            var periods: PayPeriod[] = results[0];

            this.allPayPeriods = periods.map(period => {
                var date = period.date.split("-");
                return new EditorPayPeriod(
                    period.pay_period_id,
                    date[0],
                    date[1] + "-" + date[2]
                );
            });

            this.years = Array.from(
                new Set(periods.map((pay: PayPeriod) => pay.date.split("-")[0]))
            ).map((year: string) => new EditorYear(year));

            this.customers = (<any[]>results[1]).map(
                customer =>
                    new EditorCustomer(
                        customer.customer_id,
                        customer.customer_name
                    )
            );

            this.applySelections();
        });
    }

    private applySelections(): void {
        if (this.invoice != null) {
            this.invoiceForm.payPeriodDisabled = false;

            this.invoiceForm.customer = this.customers.find(
                (customer: EditorCustomer) =>
                    customer.id == this.invoice.customer_id
            );

            this.invoiceForm.year = this.years.find(
                year => year.value == this.invoice.invoice_date.split("-")[0]
            );

            this.payPeriods = this.allPayPeriods.filter(
                (payPeriod: EditorPayPeriod) =>
                    payPeriod.year == this.invoiceForm.year.value
            );

            this.invoiceForm.payPeriod = this.allPayPeriods.find(
                (payPeriod: EditorPayPeriod) => {
                    var convMonthDay =
                        this.invoice.invoice_date.split("-")[1] +
                        "-" +
                        this.invoice.invoice_date.split("-")[2];
                    return payPeriod.monthDay == convMonthDay.substring(0, 5);
                }
            );
        }
    }

    private applyYearSelection(year: EditorYear): void {
        if (year.value == null) {
            this.invoiceForm.payPeriodDisabled = true;
            this.invoiceForm.year = EditorYear.empty();
            this.invoiceForm.payPeriod = EditorPayPeriod.empty();
            this.payPeriods = new Array<EditorPayPeriod>();
        } else {
            this.invoiceForm.payPeriod = EditorPayPeriod.empty();
            this.payPeriods = this.allPayPeriods.filter(
                (v: EditorPayPeriod) => v.year == year.value
            );
            this.invoiceForm.year = year;
            this.invoiceForm.payPeriodDisabled = false;
        }
    }

    changeSelectedYear(data: any): void {
        this.applyYearSelection(
            data.source.value == null
                ? EditorPayPeriod.empty()
                : data.source.value
        );
    }

    cancel(): void {
        // ...go back to other page
    }

    save(form: InvoiceEditorForm): void {
        if (this.invoice == null) {
            this.manageFactory.invoices
                .create(
                    JSON.stringify(
                        new CreateInvoice(
                            form.customer.id,
                            "0000000000",
                            null,
                            null,
                            null,
                            null,
                            1,
                            null,
                            moment().format(sessionStorage.getItem('date_format'))
                        )
                    )
                )
                .subscribe(v => {});
        } else {
            this.manageFactory.invoices
                .update(
                    this.invoice.invoice_id,
                    JSON.stringify(
                        new SaveInvoice(
                            new SaveInvoiceData(
                                this.invoice.invoice_id,
                                this.invoice.invoice_number,
                                form.payPeriod.year +
                                    "-" +
                                    form.payPeriod.monthDay,
                                this.invoice.due_date
                                    ? this.invoice.due_date.split("T")[0]
                                    : null,
                                this.invoice.date_sent
                                    ? this.invoice.date_sent.split("T")[0]
                                    : null,
                                this.invoice.date_paid
                                    ? this.invoice.date_paid.split("T")[0]
                                    : null,
                                this.invoice.status,
                                this.invoice.comments,
                                this.invoice.date_created
                                    ? this.invoice.date_created.split("T")[0]
                                    : null,
                                form.customer.id
                            ),
                            { ...this.items }
                        )
                    )
                )
                .subscribe(v => {});
        }
    }
}
