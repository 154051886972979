import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IUser } from './auth-factory.model';

@Injectable({
    providedIn: 'root'
})
export class AuthFactoryService {


    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
    ) { }

    public isValid: boolean;
    public token: string;
    sessionObs: Subject<IUser> = new Subject();

    login(username, pass, rememberMe: boolean) {
        return this.http.post(environment.apiUrl + '/login', {
            username: username,
            password: pass
        }).pipe(map(result => {
            this.applyToSessionStorage(result);
            if (rememberMe) {
                this.cookieService.set('loginCookie', result['token'],
                    { expires: 7, sameSite: 'Lax' });
            }
            return result;
        }));
    }

    loginRememberMe(loginCookie) {
        return this.http.post(environment.apiUrl + '/loginRememberMe', {
            token: loginCookie
        }).pipe(map(result => {
            result['token'] = loginCookie;
            this.applyToSessionStorage(result);
            return result;
        }));
    }

    logout() {
        sessionStorage.clear();
    }

    getLoginCookie(): string {
        return this.cookieService.get('loginCookie');
    }

    clearLoginCookie() {
        if (this.getLoginCookie()) this.cookieService.delete('loginCookie', '/');
    }

    applyToSessionStorage(result) {
        let user = result['user'] ? result['user'] : result;
        this.sessionObs.next(user);
        sessionStorage.setItem('token', result.token);
        sessionStorage.setItem('user', user.username);
        sessionStorage.setItem('username', user.name);
        sessionStorage.setItem('userRole', user.user_role);
        sessionStorage.setItem('userId', user.user_id);
        sessionStorage.setItem('customerId', user.customer_id);
        sessionStorage.setItem('customerRole', user.customer_role);
        sessionStorage.setItem('oemCustomerId', user.oem_id);
        sessionStorage.setItem('customer_prefix', user.customer_prefix);
        sessionStorage.setItem('applicationTitle', user.title);

        if (user.time_format) {
            sessionStorage.setItem('time_format', user.time_format);
        } else {
            sessionStorage.setItem('time_format', 'HH:mm:ss');
        }

        if (user.date_format) {
            sessionStorage.setItem('date_format', user.date_format);
        } else {
            sessionStorage.setItem('date_format', 'MM/dd/yyyy');
        }
    }

}
