export interface IStatistics {
  deviceId: number;
  parameters: IStatisticsParameters[];
  identifier: string;
  isChanged?: boolean;
}

export interface IStatistic {
  id: number;
  statisticName: string;
  modelId: string;
  devices: number[];
  identifiers: number[];
  parameters: number[];
  startDate: Date;
  endDate: Date;
  email: string;
  rule: string;
  isTemplate?: boolean;
  counts?: ICounts[];
  deviceId?: number;
  customerId?: number;
  customerRole?: number;
  oemId?: number;
  customerName: string;
}

export interface IParameters {
  parameterId: number;
  parameterName?: string;
  messageName?: string;
  messageId?: number;
}

export interface IStatisticsParameters extends IParameters {
  parameterValue: string;
  messageId?: number;
}

export interface IDeviceParameterData extends IParameters {
  parameterData: IIndexableArray[];
}

export interface IDeviceParameterDetails {
  deviceId: number;
  identifier: string;
  parameters: IDeviceParameterData[];
}

export interface IDeviceParameterDetailsReq {
  deviceId: number;
  parameters: number[];
  startDate: Date;
  endDate: Date;
}

export interface IMessageValue {
  message_value_id: number;
  value_name: string;
  value_uom: string;
}

export interface IMessage {
  message_id: number;
  descriptor: string;
  message_values: IMessageValue[];
  isChecked?: boolean;
  isSomeChecked?: boolean;
}

export interface IBuses {
  library_id: number;
  bus_number_id: number;
  bus_number_type: string;
  messages: IMessage[];
}

export interface ICounts extends IParameters {
  operatorId: number;
  operatorName?: string;
  operatorValue: number;
  threshold?: number;
}

export interface ICount {
  deviceId: number;
  counts: ICounts[];
  identifier: string;
  isChanged?: boolean;
}

export interface IDevice {
  id: number;
  identifier: string;
  isChanged?: boolean;
}

export interface IModel {
  name: string;
  model_id: number;
  customer_id: number;
  customer_name: string;
  oem_id: number;
  devices: IDeviceResponse[];
  oldModelDevices?: IDeviceResponse[];
}

export interface IDeviceResponse {
  device_id: number;
  identifier: string;
  isChanged?: boolean;
}

export interface ICountRequest {
  startDate: string;
  endDate: string;
  devices: number[];
  counts: ICounts[];
}

export interface ICountForm {
  message: number;
  operator: number;
  threshold: number;
  variable: number;
}

export interface IPoint {
  [index: number]: number | string;
}

export interface IIndexableArray {
  [index: number]: number;
}

export interface IOperator {
  operatorId: number;
  operatorName: string;
}

export interface IStatisticsRespObject {
  id: number;
  message: string;
  success: boolean;
}

export interface IMessageCountOperator {
  operatorName: string;
  operatorColspan: number;
}

export interface IMetaCount {
  messageName: string;
  messageColspan: number;
  operators: IMessageCountOperator[];
}

export interface IMetaCountObj {
  value: number;
  operators: { [key: string]: number };
}

export type ITableData = IStatistics | ICount;

export enum StatisticsRule {
  MIN = 'min',
  MAX = 'max',
  AVG = 'avg'
}

export enum StatisticsOperator {
  LESS_THEN = 1,
  LESS_THAN_OR_EQUAL_TO,
  GREATER_THAN,
  GREATER_THAN_OR_EQUAL_TO,
  EQUAL_TO,
  NOT_EQUAL_TO
}

export enum TableType {
  PARAMETER = 1,
  OPERATOR
}

export enum Property {
  PARAMETERS = 'parameters',
  COUNTS = 'counts'
}

export enum Columns {
  Select = 'select',
  StatisticName = 'statisticName',
  ModelId = 'modelId',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Email = 'email',
  CustomerName = 'customerName'
}

export enum SortHeaders {
  String = 'string',
  RecordedDate = 'recorded_date'
}

export enum Params {
  Limit = 'limit',
  Offset = 'offset',
  Sort = 'sort',
  SortOrder = 'sortOrder',
  SearchTerm = 'searchTerm',
  SearchFields = 'searchFields',
  ModelId = 'modelId',
}

export enum Storage {
  DataFilter = 'data_filter',
  PageSize = 'page_size',
  DateFormat = 'date_format',
  TimeFormat = 'time_format',
}

export enum Translations {
  DeleteFilesConfirm = 'reports.delete_files_confirm',
  DeleteFileConfirm = 'reports.delete_file_confirm',
  DeleteReport = 'reports.delete_report',
  FilesDeleted = 'reports.files_deleted',
  FileDeleted = 'reports.file_deleted',
  FileDeletedErr = 'reports.file_deleted_err',
  BtnDelete = 'btn.delete',
  BtnUpdate = 'btn.update',
  ConfirmModelChangeTitle = 'apply_model_dialog.confirm_model_change_title',
  ConfirmModelChangeText = 'apply_model_dialog.confirm_model_change_text',
  SuccessfullyCreated = 'customer_editor.successfully_created',
  SuccessfullyUpdated = 'customer_editor.successfully_updated',
  UnknownVariable = 'statistics.unknown_variable',
  UnknownMessage = 'statistics.unknown_message',
  UnknownId = 'statistics.unknown_id',
  NoData = 'statistics.no_data',
  ApplyCustomerDialogSetCustomer = 'apply_customer_dialog.set_customer',
  DeleteTemplate = 'statistics.template_delete',
  BtnSave = 'btn.save',
  BtnOk = 'btn.ok',
  WarningTitle = 'title.warning',
  ImportantTitle = 'title.important',
  SymLibModified = 'statistics.dialog.sym_lib_modified',
  SymLibDeleted = 'statistics.dialog.sym_lib_deleted',
}

export enum ViewPort {
  RenderedContentOffset = '_renderedContentOffset'
}

export enum StatisticsType {
  Statistics = 'Statistics',
  Template = 'Template'
}

export enum Action {
  Create = 'create',
  Update = 'update',
}

export enum StatisticsForm {
  StatisticName = 'statisticName',
  ModelId = 'modelId',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Email = 'email',
  DevicesFilter = 'devicesFilter',
  ParametersFilter = 'parametersFilter',
  Rule = 'rule'
}

export enum StatisticsParams {
  Devices = 'devices',
  Parameters = 'parameters',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Rule = 'rule'
}

export enum CountForm {
  Message = 'message',
  Variable = 'variable',
  Operator = 'operator',
  Threshold = 'threshold',
}

export enum MomentFormat {
  Day = 'd',
  Month = 'M',
  Year = 'y',
}

export enum CustomerCategory {
  Oem = 'OEM',
  Dealer = 'Dealer',
  Client = 'Client'
}

export enum ModelParams {
  OemId = 'oemId',
  CustomerId = 'customerId',
  StatisticId = 'statisticId',
}
