<div id="content" class="scrollBelow">
    <div id="header" fxLayout="column">
        <div id="img-background" class="mat-elevation-z6" fxFlexAlign="center">
            <img src="assets/img/mrs-logo.png" />
        </div>
        <p id="version-text" class="mat-subheading-2" fxFlexAlign="center">{{ versionService.versionString }}</p>
        <button mat-stroked-button class="center" ngxClipboard (click)="copy()" fxFlexAlign="center">
            <span style="padding-right: 8px;">{{'about.copy_versions' | translate}}</span>
            <mat-icon>content_copy</mat-icon>
        </button>
    </div>
    <div id="version-history" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
        <div fxFlex="1 1" fxFlexFill *ngFor="let project of versionService.projects">
            <h2>{{ versionService.projectName(project) }} {{'about.release_notes' | translate}}</h2>
            <mat-accordion>
                <mat-expansion-panel *ngFor="let item of versionService.history[project]">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ item.version ? 'v' + item.version : '' }}
                        </mat-panel-title>
                        <mat-panel-description>
                            {{item.date | date: [dateTimeFormat]}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div>
                        <p *ngFor="let note of item.notes">{{ note }}</p>
                        <p *ngIf="item.notes.length == 0">{{'about.no_release_notes' | translate}}</p>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>