import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-client-editor',
    templateUrl: './client-editor.component.html',
    styleUrls: ['./client-editor.component.css']
})
export class ClientEditorComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
