<mat-toolbar class="settings">
    <span class="spacer"></span>

    <!-- Switch from filtered results to all results -->
    <button mat-icon-button *ngIf="filterListBy" (click)="seeAll()">
        <mat-icon matTooltip="{{'list_view.see_all' | translate}}">apps
        </mat-icon>
    </button>

    <!-- Customer Buttons -->
    <button mat-icon-button *ngIf="toolbarShow('oems')"
            [disabled]="selection.selected.length != 1"
            (click)="filterView('oems')">
        <mat-icon svgIcon="domain"
                  matTooltip="{{'list_view.view_oems' | translate}}"></mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('dealers')"
            [disabled]="selection.selected.length != 1"
            (click)="filterView('dealers')">
        <mat-icon svgIcon="account-box-multiple"
                  matTooltip="{{'list_view.view_dealers' | translate}}">
        </mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('clients')"
            [disabled]="selection.selected.length != 1"
            (click)="filterView('clients')">
        <mat-icon svgIcon="account-outline"
                  matTooltip="{{'list_view.view_clients' | translate}}">
        </mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('users')"
            [disabled]="selection.selected.length != 1"
            (click)="filterView('users')">
        <mat-icon svgIcon="account-multiple"
                  matTooltip="{{'list_view.view_users' | translate}}">
        </mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('devices')"
            [disabled]="selection.selected.length != 1"
            (click)="filterView('devices')">
        <mat-icon svgIcon="hubspot"
                  matTooltip="{{'list_view.view_devices' | translate}}">
        </mat-icon>
    </button>

    <!-- User Buttons -->
    <button mat-icon-button *ngIf="toolbarShow('lock')"
            [disabled]="selection.selected.length != 1" (click)="lockUser()">
        <mat-icon *ngIf="selection.hasValue() && selection.selected[0].is_locked"
                  matTooltip="{{'list_view.user_unlock' | translate}}">lock_open
        </mat-icon>
        <mat-icon *ngIf="!selection.hasValue() || !selection.selected[0].is_locked"
                  matTooltip="{{'list_view.user_lock' | translate}}">lock_open
        </mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('reset')"
            [disabled]="selection.selected.length != 1"
            (click)="resetPassword()">
        <mat-icon matTooltip="{{'list_view.reset_password' | translate}}">
            settings_backup_restore</mat-icon>
    </button>

    <!-- Dashboard Buttons -->
    <button mat-icon-button *ngIf="toolbarShow('viewCustomWidgets')"
            (click)="viewCustomWidgets()">
        <mat-icon matTooltip="{{'title.custom_widgets' | translate}}"
                  [svgIcon]="routerIcon.CUSTOM_WIDGETS"></mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('copyDashboard')"
            [disabled]="selection.selected.length != 1"
            (click)="copyDashboard()">
        <mat-icon matTooltip="{{'list_view.copy_dashboard' | translate}}">
            content_copy</mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('editDashName')"
            [disabled]="selection.selected.length != 1"
            (click)="editDashName()">
        <mat-icon matTooltip="{{'list_view.edit_dashboard_name' | translate}}">
            edit</mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('dashboard')"
            [disabled]="selection.selected.length != 1"
            (click)="openDashboard()">
        <mat-icon matTooltip="{{'list_view.go_to_dashboard' | translate}}">
            dashboard</mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('deleteDash')"
            [disabled]="selection.selected.length < 1" (click)="delete()">
        <mat-icon matTooltip="{{'btn.delete' | translate}}">delete</mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('addDash')" (click)="addDash()">
        <mat-icon matTooltip="{{'list_view.add_dashboard' | translate}}">
            add_circle_outline</mat-icon>
    </button>

    <!-- Alert On/Off-->
    <button mat-icon-button *ngIf="toolbarShow('onOffAlert')" [disabled]="selection.selected.length != 1"
        (click)="toggleAlert()">
        <ng-container [ngSwitch]="selection?.selected[0]?.is_active">
            <ng-template [ngSwitchCase]="false">
                <mat-icon [matTooltip]="'list_view.alert_on' | translate">notifications_active</mat-icon>
            </ng-template>
    
            <ng-template [ngSwitchCase]="true">
                <mat-icon [matTooltip]="'list_view.alert_off' | translate">notifications_paused</mat-icon>
            </ng-template>
    
            <ng-template [ngSwitchDefault]>
                <mat-icon [matTooltip]="'list_view.alert_on_off' | translate">notifications</mat-icon>
            </ng-template>
        </ng-container>
    </button>

    <!-- Shared Buttons -->
    <button mat-icon-button *ngIf="toolbarShow('edit')"
            [disabled]="selection.selected.length != 1" (click)="edit()">
        <mat-icon matTooltip="{{'btn.edit' | translate}}">edit</mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('delete')"
            [disabled]="selection.selected.length < 1" (click)="delete()">
        <mat-icon matTooltip="{{'btn.delete' | translate}}">delete</mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('add')" (click)="add()">
        <mat-icon matTooltip="{{'btn.add' | translate}}">add_circle_outline
        </mat-icon>
    </button>
    <button mat-icon-button (click)="refresh()">
        <mat-icon svgIcon="refresh" matTooltip="Refresh"></mat-icon>
    </button>
</mat-toolbar>

<div #tableContainer class="table-container scrollBelow"
     (window:resize)="onResize($event)">
    <div>

        <cdk-virtual-scroll-viewport #viewPort itemSize="48" matSort
                                     [style.width]="scrollWidth + 'px'"
                                     [style.height]="scrollHeight + 'px'">
            <table mat-table [dataSource]="dataSource" matSort
                   matSortActive="created" matSortDisableClear
                   matSortDirection="asc"
                   (matSortChange)="handleSortChange($event)">
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef
                        [style.top]="inverseOfTranslation">
                        <mat-checkbox *ngIf="multipleSelect"
                                      (change)="$event ? masterToggle() : null"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="
                            selection.hasValue() && !isAllSelected()
                        ">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      (change)="$event ? selectRow(row) : null"
                                      [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="customer_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">
                        {{'list_view.account' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.customer_name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="customerName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">
                        {{'list_view.account' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.customerName }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">
                        {{'list_view.address' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.address }}</td>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">{{'list_view.phone' |
                        translate}}</th>
                    <td mat-cell *matCellDef="let row">{{ row.phone }}</td>
                </ng-container>

                <ng-container matColumnDef="full_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">
                        {{(currentRoute === 'users' ? 'list_view.name' :
                        'list_view.primary_contact') | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.full_name }}</td>
                </ng-container>

                <ng-container matColumnDef="is_locked">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">
                        {{'list_view.status' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon class="red" *ngIf="row.is_locked">lock
                        </mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="role_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">
                        {{'list_view.account_type' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.role_name }}</td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">{{'list_view.email' |
                        translate}}</th>
                    <td mat-cell *matCellDef="let row">{{ row.email }}</td>
                </ng-container>

                <ng-container matColumnDef="model_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">{{'list_view.model' |
                        translate}}</th>
                    <td mat-cell *matCellDef="let row">{{ row.model_name }}</td>
                </ng-container>

                <ng-container matColumnDef="parameters_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">
                        {{'list_view.alert_name' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        {{ row.parameters_name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">{{'list_view.model' |
                        translate}}</th>
                    <td mat-cell *matCellDef="let row">
                        <div class="model-img-wrap">
                            <img *ngIf="!isMobile" class="modelImgThumb"
                                 [src]="row.product_image | secure | async" />
                            <p class="bold-model">{{ row.name }}</p>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="dashboard_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">
                        {{'list_view.dashboard' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.dashboard_name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="widgetName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">
                        {{'list_view.name' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.name }}</td>
                </ng-container>

                <ng-container matColumnDef="device_list">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">
                        {{'list_view.devices' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.device_list }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="model_type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">
                        {{'list_view.model_type' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.model_type }}</td>
                </ng-container>

                <ng-container matColumnDef="oem_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">
                        {{ 'list_view.oem' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.oem_name }}</td>
                </ng-container>

                <ng-container matColumnDef="dealer_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">
                        {{ 'list_view.dealer' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.dealer_name }}</td>
                </ng-container>

                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">{{'list_view.rule' |
                        translate}}</th>
                    <td mat-cell *matCellDef="let row">{{ row.title }}</td>
                </ng-container>

                <ng-container matColumnDef="last_updated">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.top]="inverseOfTranslation">
                        {{'list_view.last_updated' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        {{ row.last_updated | date: [dateTimeFormat] }}
                    </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"
                    (dblclick)="doubleClickRow(row)" (click)="selectRow(row)"
                    [ngClass]="{
                    'selected-row': selection.isSelected(row),
                    disableUserRow: row.is_locked || !(row?.is_active ?? true)
                }"></tr>
            </table>
        </cdk-virtual-scroll-viewport>
    </div>

    <!-- show spinner while loading is happening -->
    <div class="loading-shade" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>

    <div *ngIf="dataSource.matTableDataSource.data.length < 1 && !isLoadingResults"
         class="no-results">
        {{'list_view.no_items' | translate:{route: currentRoute_tr} }}
    </div>
</div>