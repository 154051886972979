import {NgModule} from '@angular/core';
import {AppMaterialModule} from '../../services/app-material/app-material.module';
import {AgmCoreModule} from '@agm/core';
import {MapLogComponent} from './map-log.component';


@NgModule({
  imports: [
    AppMaterialModule,
    AgmCoreModule
  ],
  exports: [MapLogComponent],
  declarations: [MapLogComponent]
})
export class MapLogModule {}
