
<mat-toolbar class="mat-primary">
  <h2>{{ data.title }}</h2>
  <span class="spacer"></span>
  <button mat-icon-button (click)="dialogRef.close()">
    <mat-icon>
      close
    </mat-icon>
  </button>
</mat-toolbar>

<mat-dialog-content>
  <div>
    <mat-form-field class="full-width">
      <mat-select placeholder="{{'customer_editor.customer' | translate}}" [(ngModel)]="selectedCustomer.id" name="identifier">
        <mat-option>{{'dialog.none' | translate}}</mat-option>
        <mat-option *ngFor="let cust of customerList" [value]="cust.id">
          {{cust.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="full-width">
      <mat-select placeholder="{{'invoice.status' | translate}}" [(ngModel)]="selectedStatus.id" name="identifier">
        <mat-option>{{'dialog.none' | translate}}</mat-option>
        <mat-option *ngFor="let status of statusList" [value]="status.id">
          {{status.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>

    <mat-form-field class="full-width">
      <input matInput [matDatepicker]="start_date" placeholder="{{'dialog.start_pay_period' | translate}}"
        [value]="selectedStartPayPeriod.date"
        (dateChange)="completeStartDate($event)">
      <mat-datepicker-toggle matSuffix [for]="start_date"></mat-datepicker-toggle>
      <mat-datepicker #start_date></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput [matDatepicker]="end_date" placeholder="{{'dialog.end_pay_period' | translate}}"
        [value]="selectedEndPayPeriod.date"
        (dateChange)="completeEndDate($event)">
      <mat-datepicker-toggle matSuffix [for]="end_date"></mat-datepicker-toggle>
      <mat-datepicker #end_date></mat-datepicker>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="dialogRef.close()">
    {{'btn.cancel' | translate}}
  </button>
  <button mat-button class="mat-primary confirm" [disabled]="validate()"
    (click)="confirm()">
    {{ data.confirm }}
  </button>
</mat-dialog-actions>
