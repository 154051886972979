import { ManageFactoryService } from '../../services/manage-factory/manage-factory.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { IConfirmDialogData, Icon } from 'src/app/shared/models/common.model';
import { Translations } from './apply-model-dialog.model';

@Component({
    selector: 'app-apply-model-dialog',
    templateUrl: './apply-model-dialog.component.html',
    styleUrls: ['./apply-model-dialog.component.css']
})
export class ApplyModelDialogComponent implements OnInit {
    modelList: any = [];
    modelResult: any = [];
    selectedModel;
    filterValue: string;
    breakpoint: number;

    constructor(
        private manageFactory: ManageFactoryService,
        private dialogRef: MatDialogRef<ApplyModelDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string, isModelIdAvailable: boolean },
        private translate: TranslateService,
        private dialog: MatDialog
    ) {
        this.manageFactory.models.read()
            .subscribe(result => {
                this.modelResult = result;
                this.modelList = result;
            })
    }
    ngOnInit() {
        this.breakpoint = (window.innerWidth <= 500) ? 1 : (window.innerWidth <= 800) ? 2 : 4;
    }

    onResize(event) {
        this.breakpoint = (event.target.innerWidth <= 500) ? 1 : (event.target.innerWidth <= 800) ? 2 : 4;
    }

    selectModel(item) {
        this.selectedModel = item;
    }

    isSelected(item) {
        return (this.selectedModel == item);
    }

    filterModels() {
        this.modelList = this.filterValue ? this.modelResult.filter(model => model.name.toLowerCase().includes(this.filterValue.toLowerCase())) : this.modelResult;
    }

    /**
     * Close the dialog popup
     */
    handleCancel(): void {
        this.dialogRef.close();
    }

    /**
     * This method is called to select new model from the list of models
     */
    confirm(): void {
        const icon: string = Icon.Warning;
        const translationsArray: string[] = [];
        translationsArray.push(Translations.ConfirmModelChangeTitle);
        translationsArray.push(Translations.ConfirmModelChangeText);
        translationsArray.push(Translations.UpdateBtn);

        if (!this.data.isModelIdAvailable) {
            this.dialogRef.close(this.selectedModel);
            return;
        }

        this.translate.get(translationsArray).subscribe((translated: string[]) => {
            const dialog: MatDialogRef<ConfirmDialogComponent, boolean> = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: translated[Translations.ConfirmModelChangeTitle],
                    text: translated[Translations.ConfirmModelChangeText],
                    confirm: translated[Translations.UpdateBtn],
                    icon: icon
                } as IConfirmDialogData
            });

            dialog.afterClosed()
                .subscribe((response: boolean) => {
                    if (!response) {
                        return;
                    }
                    // if we get a true from the dialog, change the model and close the dialog
                    this.dialogRef.close(this.selectedModel);
                });
        });
    }
}
