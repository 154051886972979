import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ManageFactoryService} from 'src/app/services/manage-factory/manage-factory.service';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {DeviceRatePlan} from '../../shared/device.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-device-sim-dialog',
  templateUrl: './device-sim-dialog.component.html',
  styleUrls: ['./device-sim-dialog.component.scss']
})
export class DeviceSimDialogComponent implements OnInit, OnDestroy {

  hasSim: any = false;
  simError = false;
  isMobile: boolean;
  obj: any = {};
  simStatusValues = [
    {value: 'ACTIVATED', view: 'Activated'},
    {value: 'ACTIVATION_READY', view: 'Activation Ready'},
    {value: 'DEACTIVATED', view: 'Deactivated'},
    {value: 'NOT_AVAILABLE', view: 'Not Available'},
    {value: 'TEST_READY', view: 'Test Ready'}
  ];

  destroy$ = new Subject();

  form: FormGroup;
  iccid: FormControl;
  simStatus: FormControl;
  userIdentifier: FormControl;
  identifier: FormControl;
  internationalPlan: FormControl;

  ratePlans: any[];
  data: any;
  isMultipleEdit: boolean;

  isLenticul: boolean = environment.isLenticul;

  constructor(
    private manageFactory: ManageFactoryService,
    public dialogRef: MatDialogRef<DeviceSimDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataObj: any,
    private translate: TranslateService,
    private fb: FormBuilder
  ) {
    this.isMultipleEdit = dataObj.isMultiple;
    this.data = dataObj.device;
    if (this.data == undefined) {
      this.data = {};
    }
    if (dataObj.plans) {
      this.ratePlans = dataObj.plans.map(p => ({
        name: DeviceRatePlan[p.name] ? DeviceRatePlan[p.name] : p.name,
        value: p.id
      }));
    }
  }

  ngOnInit() {
    this.identifier = new FormControl({value: '', disabled: this.isMultipleEdit}, [
      Validators.maxLength(45),
      Validators.required
    ]);
    this.iccid = new FormControl({value: this.data.iccid, disabled: !this.data.adminRole}, [Validators.maxLength(25)], );
    this.simStatus = new FormControl({value: this.isMultipleEdit ? null : this.data.simStatus, disabled: !this.data.iccid && !this.isMultipleEdit });
    this.userIdentifier = new FormControl({value: this.isMultipleEdit ? null : this.data.userIdentifier, disabled: this.isMultipleEdit}, [
      Validators.maxLength(45),
      Validators.required
    ]);
    this.internationalPlan = new FormControl({value: this.isMultipleEdit ? null : this.data.internationalPlan, disabled: !this.data.iccid && !this.isMultipleEdit });

    this.form = this.fb.group({
      identifier: this.identifier,
      userIdentifier: this.userIdentifier,
      simStatus: this.simStatus,
      iccid: this.iccid,
      deviceTypeId: new FormControl(this.isMultipleEdit ? null : this.data.deviceTypeId),
      ratePlanId: new FormControl({value: this.isMultipleEdit ? null : this.data.ratePlanId, disabled: !this.data.iccid && !this.isMultipleEdit }),
      internationalPlan: this.internationalPlan
    });

    if (!this.isMultipleEdit) {
      this.form.patchValue(this.data, {emitEvent: false});

      this.hasSim = this.data['iccid'] ? this.data['iccid'] : false;
      if (this.hasSim) {
        this.findSimSettings();
      }
    }

    this.isMobile = (window.innerWidth) < 650;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onResize(event) {
    this.isMobile = (event.target.innerWidth) < 650;
  }

  findSimSettings() {
    if (this.isLenticul) {
      return;
    }

    this.manageFactory.devices.getSimData({id: this.hasSim})
      .subscribe(result => {
        this.obj = result;
        this.simStatus.setValue(result['status']);
        this.hasSim = false;
      }, err => {
        console.log(err['status'] + ': ' + err['statusText']);
        this.hasSim = false;
        this.simError = true;
      });
  }

  submit() {
    if (this.isMultipleEdit) {
      this.dialogRef.close(this.form.value);
      return;
    }

    const data = Object.assign({}, this.data, this.form.value);
    if (data.adminRole && this.iccid.value && !this.isLenticul) {
      this.manageFactory.devices.updateIccid(data)
        .subscribe(result => {
          this.dialogRef.close(data);
        }, error => {
          if (error['status'] === 500) {
            this.translate.get('dialog.iccid_already_use').subscribe((text: string) => {
              alert(text);
            });
          }
        });
    } else {
      this.dialogRef.close(data);
    }
  }

  getStatusColor(status) {
    switch (status) {
      case 'NOT_AVAILABLE':
        return 'gray';
      case 'DEACTIVATED':
        return 'red';
      case 'ACTIVATION_READY':
      case 'TEST_READY':
        return '#CC8500';
      case 'ACTIVATED':
        return '#2BAA1D';
      case 'Yes':
        return '#2BAA1D';
      case 'No':
        return 'red';
      default:
        return 'gray';
    }
  }

  /* Enable/disable some form controls based on the ICCID value. */
  changeIccid() {
    if (this.iccid != undefined) {
      let hasIccid: Boolean = this.iccid.value;
      if (!hasIccid && !this.isMultipleEdit) {
        this.simStatus.disable();
        this.internationalPlan.disable();
        this.form.controls.ratePlanId.disable();
      } else {
        this.simStatus.enable();
        this.internationalPlan.enable();
        this.form.controls.ratePlanId.enable();
      }
    }
  }

  clearIccid() {
    this.iccid.setValue(null);
    this.changeIccid();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
