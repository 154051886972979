<mat-toolbar class="settings">
    <span class="spacer"></span>

    <!-- Switch from filtered results to all results -->
    <button mat-icon-button *ngIf="filterListBy" (click)="seeAll()">
        <mat-icon matTooltip="{{'devices.see_all' | translate}}">apps</mat-icon>
    </button>

    <!-- Device Buttons -->
    <button
      mat-icon-button
      *ngIf="customerRole === constants.role_super"
      [disabled]="selection.selected.length !== 1"
      (click)="handleDownloadLogs()"
    >
        <mat-icon
          matTooltip="{{'devices.download_logs' | translate}}"
        >
          file_download
        </mat-icon>

    </button>
    <button mat-icon-button *ngIf="toolbarShow('enabled')" (click)="showEnabled()">
        <mat-icon *ngIf="enabledDevices" matTooltip="{{'devices.view_disabled' | translate}}">lightbulb_outline</mat-icon>
        <mat-icon *ngIf="!enabledDevices" class="red" matTooltip="{{'devices.view_enabled' | translate}}">highlight_off</mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('model') && customerRole < constants.role_client_user" [disabled]="selection.selected.length < 1"
        (click)="applyModel()">
        <mat-icon matTooltip="{{'devices.apply_model' | translate}}">collections_bookmark</mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('customer')" [disabled]="selection.selected.length < 1"
        (click)="applyCustomer()">
        <mat-icon matTooltip="{{'devices.apply_customer' | translate}}">perm_identity</mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('dashboard')" [disabled]="selection.selected.length != 1"
        (click)="openDashboard()">
        <mat-icon matTooltip="{{'devices.go_to_dashboard' | translate}}">dashboard</mat-icon>
    </button>
    <button mat-icon-button *ngIf="toolbarShow('identifier')" [disabled]="!selection.selected.length"
        (click)="editIdentifier()">
        <mat-icon matTooltip="{{'devices.edit_identifier' | translate}}">edit</mat-icon>
    </button>
      <button mat-icon-button *ngIf="toolbarShow('disableEnable') && customerRole <= constants.role_oem_user" [disabled]="selection.selected.length < 1"
        (click)="enableDisableDevices()">
        <mat-icon *ngIf="enabledDevices" matTooltip="{{'devices.dialog.title_enabled' | translate}}">no_sim</mat-icon>
        <mat-icon *ngIf="!enabledDevices" matTooltip="{{'devices.dialog.title_disabled' | translate}}">no_sim</mat-icon>
      </button>
    <button mat-icon-button (click)="refresh()">
        <mat-icon svgIcon="refresh" matTooltip="Refresh"></mat-icon>
    </button>
</mat-toolbar>

<div
  #tableContainer
  class="table-container scrollBelow"
  (window:resize)="onResize($event)
">
    <div>

      <cdk-virtual-scroll-viewport
        #viewPort
        itemSize="48"
        matSort
        [style.width]="scrollWidth + 'px'"
        [style.height]="scrollHeight + 'px'"
      >

        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          matSortActive="created"
          matSortDisableClear
          matSortDirection="asc"
          (matSortChange)="handleSortChange($event)"
        >

            <ng-container matColumnDef="select">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  [style.top]="inverseOfTranslation"
                >
                    <mat-checkbox *ngIf="multipleSelect" (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectRow(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="oemName">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [style.top]="inverseOfTranslation"
                >{{'devices.oem' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.oemName}}</td>
            </ng-container>

            <ng-container matColumnDef="dealerName">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [style.top]="inverseOfTranslation"
                >{{'devices.dealer' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.dealerName}}</td>
            </ng-container>

            <ng-container matColumnDef="clientName">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [style.top]="inverseOfTranslation"
                >{{'devices.client' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.clientName}}</td>
            </ng-container>

            <ng-container matColumnDef="deviceType">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [style.top]="inverseOfTranslation"
                >{{'devices.device_type' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.deviceType}}</td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [style.top]="inverseOfTranslation"
                >{{'devices.model' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.name}}</td>
            </ng-container>

            <!-- Clients will see the userIdentifier (if present) in bold and the identifier in regular weight -->
            <!-- All others will see the identifier in bold and the serial_number in regular weight -->
            <ng-container matColumnDef="identifier">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [style.top]="inverseOfTranslation"
                >{{'devices.device_identifier' | translate}}</th>
                <td mat-cell *matCellDef="let row">
                    <div *ngIf="!isLenticul" class="status-circle">
                        <mat-icon [ngStyle]="{'color': getStatusColor(row.simStatus)}">brightness_1</mat-icon>
                    </div>
                    <div *ngIf="row.userIdentifier && customerRole == constants.role_client_user" class="bold">
                        {{ row.userIdentifier }}</div>
                    <div *ngIf="row.identifier" [ngClass]="{'bold': (customerRole != constants.role_client_user)}"
                        [ngStyle]="centerDiv(row)">{{ row.identifier }}</div>
                    <div *ngIf="row.serialNumber && customerRole != constants.role_client_user">{{ row.serialNumber }}
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                (dblclick)="doubleClickRow(row)"
                (click)="selectRow(row)"
                [ngClass]="{'selected-row': selection.isSelected(row)}"
            ></tr>
        </table>

      </cdk-virtual-scroll-viewport>
    </div>

    <!-- show spinner while loading is happening -->
    <div class="loading-shade" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>

    <div *ngIf="dataSource.matTableDataSource.data.length < 1 && !isLoadingResults" class="no-results">{{'devices.no_devices' | translate}}</div>
</div>
