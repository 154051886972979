import { Injectable } from '@angular/core';
import moment from 'moment';
import { SessionStorage } from '../shared/models/common.model';

@Injectable({ providedIn: 'root' })
export class DateTimeService {
  defaultFormat: string = 'YYYY-MM-DD HH:mm:ss';
  defaultDateFormat: string = 'MM/dd/yyyy';
  
  /**
   * Gets the date format from session storage, or returns the default date format if not found. 
   * @returns {string} The date format in uppercase. 
   */
  public getDateFormat(): string {
    if (sessionStorage.getItem(SessionStorage.DateFormat) === null) {
      return this.defaultDateFormat;
    }
    return sessionStorage.getItem(SessionStorage.DateFormat).toUpperCase();
  }

  /**
   * This method is used to get the time format according to the user settings
   * @returns {string} return time format as string
   */
  public getTimeFormat(): string {
    let timeFormat: string = sessionStorage.getItem('time_format');

    if (timeFormat === null) {
      timeFormat = 'HH:mm:ss a';
    }
    return timeFormat;
  }

  /**
   * This method is used to get the date and time format according to the user settings
   * @param isMoment check if the date and time is in moment format and return boolean value
   * @returns {string} return date and time format as string
   */
  public getDateAndTimeFormat(isMoment: boolean = false): string {
    let dateFormat: string = sessionStorage.getItem('date_format');
    let timeFormat: string = sessionStorage.getItem('time_format');

    if (sessionStorage.getItem('date_format') === null) {
      dateFormat = 'dd/MM/yyyy';
    }
    if (sessionStorage.getItem('time_format') === null) {
      timeFormat = 'HH:mm:ss a';
    }

    if (isMoment) {
      dateFormat = dateFormat.toUpperCase();
      timeFormat = this.capitalizeCharacter(timeFormat, 'a');
    }

    return `${dateFormat} ${timeFormat}`;
  }

  /**
   * Capitalizes a specific character in a given string and returns a new string
   * @param {string} str - The input string
   * @param {string} character - The character to be capitalized
   * @returns {string} - The modified string with the capitalized character
   */
  private capitalizeCharacter(str: string, character: string): string {
    return str.replace(character, character.toUpperCase());
  }

  setUtcTime(toUTC = true) {
    sessionStorage.setItem('utcTime', toUTC.toString());
  }

  isUtcTime(): boolean {
    return sessionStorage.getItem('utcTime') === 'true';
  }

  convertLocalToUTC(date) {

    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds(),
    );
  }

  /**
   * This method translates date into a specific format
   * @param date Date to be modified
   * @returns date in specific format
   */
  getDatetimeStr(date: Date | string): string {
    return date instanceof Date ? moment(date).format(this.defaultFormat) : moment(date).utc().format(this.defaultFormat);
  }
}
