import {EventEmitter} from '@angular/core';
import {Observable} from 'rxjs';

export enum WidgetType {
  GAUGE = 'gauge',
  BUTTON_WIDGET = 'button',
  SLIDE_SWITCH = 'slide_switch',
  NUMERIC_UP_DOWN = 'numericUpDown',
  INDICATOR = 'indicator',
  LINE_WIDGET = 'sparkline',
  NUMBER_WIDGET = 'text_widget',
  PICTURE_WIDGET = 'picture',
  MAP_WIDGET = 'google_map',
  POINTER_WIDGET = 'pointer',
  INTERACTIVE_INDICATOR_WIDGET = 'interactive_indicator',
  TEXT_BOX_WIDGET = 'Textbox',
  INFO_PANEL_WIDGET = 'infoPanel',
  MONITOR_GRID_WIDGET = 'monitor_grid',
  SINGLE_MONITOR_WIDGET = 'single_monitor',
  TWO_ACTION_WIDGET = 'twoAction',
  HTML_WIDGET = 'html',
  RESPONSIVE_3D_MODEL_WIDGET = 'responsive_3d_model',
  HANDLEBARS_WIDGET = 'handlebarsWidget',
  DONUT_WIDGET = 'donut_widget',
  PIE_WIDGET = 'pie_widget',
  BAR_WIDGET = 'bar_widget',
  HEX_WIDGET = 'hex_widget',
  WATER_FILTER_WIDGET = 'water-filter_widget',
  CUSTOM_WIDGET = 'custom_widget',
  REBOOT_COMMAND_WIDGET = 'reboot_command',
  DEVICE_STATUS_WIDGET = 'device_status_widget',
  TABLE_WIDGET = 'table_widget',
}

export const WidgetDescriptions = {
  [WidgetType.GAUGE]                        : { name: 'dashboard.widget.gauge'                       , descriptions: 'dashboard.widget.gauge_description' },
  [WidgetType.BUTTON_WIDGET]                : { name: 'dashboard.widget.button'                      , descriptions: 'dashboard.widget.button_description' },
  [WidgetType.SLIDE_SWITCH]                 : { name: 'dashboard.widget.slide_switch'                , descriptions: 'dashboard.widget.slide_switch_description' },
  [WidgetType.NUMERIC_UP_DOWN]              : { name: 'dashboard.widget.numeric_up_down'             , descriptions: 'dashboard.widget.numeric_up_down_description' },
  [WidgetType.INDICATOR]                    : { name: 'dashboard.widget.indicator_light'             , descriptions: 'dashboard.widget.indicator_light_description' },
  [WidgetType.LINE_WIDGET]                  : { name: 'dashboard.widget.sparkline'                   , descriptions: 'dashboard.widget.sparkline_description' },
  [WidgetType.NUMBER_WIDGET]                : { name: 'dashboard.widget.text'                        , descriptions: 'dashboard.widget.text_description' },
  [WidgetType.PICTURE_WIDGET]               : { name: 'dashboard.widget.picture'                     , descriptions: 'dashboard.widget.picture_description' },
  [WidgetType.MAP_WIDGET]                   : { name: 'dashboard.widget.google_map'                  , descriptions: 'dashboard.widget.google_map_description' },
  [WidgetType.POINTER_WIDGET]               : { name: 'dashboard.widget.pointer'                     , descriptions: 'dashboard.widget.pointer_description' },
  [WidgetType.INTERACTIVE_INDICATOR_WIDGET] : { name: 'dashboard.widget.interactive_indicator_light' , descriptions: 'dashboard.widget.interactive_indicator_light_description' },
  [WidgetType.TEXT_BOX_WIDGET]              : { name: 'dashboard.widget.textbox'                     , descriptions: 'dashboard.widget.textbox_description' },
  [WidgetType.INFO_PANEL_WIDGET]            : { name: 'dashboard.widget.info_panel'                  , descriptions: 'dashboard.widget.info_panel_description' },
  [WidgetType.MONITOR_GRID_WIDGET]          : { name: 'dashboard.widget.monitor_grid'                , descriptions: 'dashboard.widget.monitor_grid_description' },
  [WidgetType.SINGLE_MONITOR_WIDGET]        : { name: 'dashboard.widget.single_monitor'              , descriptions: 'dashboard.widget.single_monitor_description' },
  [WidgetType.TWO_ACTION_WIDGET]            : { name: 'dashboard.widget.two_action_control'          , descriptions: 'dashboard.widget.two_action_control_description' },
  [WidgetType.HTML_WIDGET]                  : { name: 'dashboard.widget.html'                        , descriptions: 'dashboard.widget.html_description' },
  [WidgetType.RESPONSIVE_3D_MODEL_WIDGET]   : { name: 'dashboard.widget.responsive_3d_model'         , descriptions: 'dashboard.widget.responsive_3d_model_description' },
  [WidgetType.HANDLEBARS_WIDGET]            : { name: 'dashboard.widget.handlebars'                  , descriptions: 'dashboard.widget.handlebars_description' },
  [WidgetType.DONUT_WIDGET]                 : { name: 'dashboard.widget.donut'                       , descriptions: 'dashboard.widget.donut_description' },
  [WidgetType.PIE_WIDGET]                   : { name: 'dashboard.widget.pie'                         , descriptions: 'dashboard.widget.pie_description' },
  [WidgetType.BAR_WIDGET]                   : { name: 'dashboard.widget.bar'                         , descriptions: 'dashboard.widget.bar_description' },
  [WidgetType.HEX_WIDGET]                   : { name: 'dashboard.widget.hex'                         , descriptions: 'dashboard.widget.hex_description' },
  [WidgetType.WATER_FILTER_WIDGET]          : { name: 'dashboard.widget.argo_hytos_filter'           , descriptions: 'dashboard.widget.argo_hytos_filter_description' },
  [WidgetType.CUSTOM_WIDGET]                : { name: 'dashboard.widget.custom'                      , descriptions: 'dashboard.widget.custom_description'},
  [WidgetType.REBOOT_COMMAND_WIDGET]        : { name: 'dashboard.widget.reboot_command'              , descriptions: 'dashboard.widget.reboot_command_description' },
  [WidgetType.DEVICE_STATUS_WIDGET]         : { name: 'dashboard.widget.device_status'               , descriptions: 'dashboard.widget.device_status_description'},
  [WidgetType.TABLE_WIDGET]                 : { name: 'dashboard.widget.table'                       , descriptions: 'dashboard.widget.table_description'},
};

export enum GaugeType {
  ORIGINAL,
  CUSTOM
}

export enum GaugeNeedleType {
  ARROW = 'arrow',
  LINE = 'line'
}

export interface IWidgetDialogComponent {
  settingsChanged: EventEmitter<any>;
  onInit: EventEmitter<any>;
  setData: (settings: any) => void;
  validForm?: EventEmitter<any>;
}

export enum UserRole {
  SUPER,
  ADMINISTRATOR,
  OEM,
  DEALER,
  CLIENT,
}

export enum UserEdit {
  EDIT = UserRole.ADMINISTRATOR,
}

export enum IndicatorIconType {
  POWER = 1,
  LED
}

export const IndicatorIcon = {
  [IndicatorIconType.POWER]: { name: 'Power', icon: 'power_settings_new' },
  [IndicatorIconType.LED]: { name: 'LED', icon: 'lightbulb' }
};

export enum IndicatorIconColor {
  Red = 'red',
  Green = 'green',
  Blue = 'blue',
  Yellow = 'yellow'
}

export const IndicatorColor = {
  [IndicatorIconColor.Red]: 'Red',
  [IndicatorIconColor.Green]: 'Green',
  [IndicatorIconColor.Blue]: 'Blue',
  [IndicatorIconColor.Yellow]: 'Yellow'
};

export interface IDashboardResponse {
  customer_id: number | string;
  customer_name: string;
  dashboard_id?: number;
  model_id?: number;
  dashboard_json?: any;
  model_json?: any;
  name: string;
}

export interface IMRSMqttSymbol {
  bus_id: number;
  protocol: string;
  messages: IMRSSymbolMessage[];
}

export interface IMRSSymbolMessage {
  message_id: number;
  descriptor: string;
  from_device: number;
  from_vehicle: number;
  is_logged: number;
  is_mqtt: number;
  is_retained: number;
  can_id: number;
  data_length: number;
  cycle_time: number;
  timeout: any;
  library_id: number;
  original_can_id: number;
  direction: number;
  message_type: number;
  color: number;
  comment: any;
  multipacket: number;
  values: IMRSMessageValue[];
}

export interface IMRSMessageValue {
  message_value_id: number;
  value_name: string;
  starting_bit: number;
  length: number;
  value_scalar: number;
  value_offset: number;
  value_uom: string;
  message_id: number;
  minimum_value: number;
  maximum_value: number;
  display_decimal_places: number;
  is_intel: number;
  is_signed: number;
  data_type: string;
  long_name: any;
  comment: any;
  variableMinimumValue?: number;
  variableMaximumValue?: number;
}

export interface ICANInfo {
  can_id: number;
  device_id: number;
  bus_id: number;
  values: IMRSMessageValue[];
  descriptor;
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export const regexpDatasourceValue = /[^[\]"?]+(?="?])/gi;
export const regexpDatasourceValueGroup = /datasources(?<source>\[".*"\]*)/i;

export enum ChartTheme {
  DEFAULT = 'default',
  DARK = 'dark',
  LIGHT = 'light',
}

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

export enum CustomSettingsType {
  CALCULATED = 'calculated',
  CALCULATED_MULTIPLE = 'calculated_multiple',
  TEXT = 'text'
}

export enum PreviousUrl {
  DASHBOARDS = 'dashboards',
  DEVICES = 'devices',
  NEW_TAB = 'newTab'
}

export interface IWidgetTopicParse {
  source: {
    full: string;
    name: string;
    permission: string;
    bus: string;
    can: string;
    variable: string;
  };
  code: string;
}

export interface IWidgetSubTopic {
  [key: string]: IWidgetTopicParse;
}

export interface ITableSettings {
  value: { value: string }[];
  row: number;
  col: number;
  height: number;
}

export interface ITableProps {
  key: string;
  value: string;
}

export interface IHighlight {
  from: string;
  to: string;
  color: string;
  text: string;
}

export enum WidgetDescription {
  DefaultDescription = 'dashboard.widget.default_description'
}

export abstract class WidgetCommon {

  readonly DEFAULT_TOPIC_LENGTH = 5;
  calcProps: {
    [key: string]: IWidgetTopicParse | IWidgetTopicParse[] | IWidgetSubTopic
  };
  settings: any;
  topicLength = this.DEFAULT_TOPIC_LENGTH; // e.g. for WidgetHex is 3;


  /**
   * @param data
   * topicObj - obj with datasourceName, widgetValueType props;
   */
  parseTopic_(data): IWidgetTopicParse {
    const {busNames, canNames, topicStr, topicObj} = data;
    const defaultResult = {
      source: null,
      code: topicStr,
      codeForDB: topicStr
    };

    if (!topicStr) {
      return defaultResult;
    }

    const groups = topicStr.match(regexpDatasourceValueGroup);

    if (!groups) {
      return defaultResult;
    }
    const source = topicStr.match(regexpDatasourceValueGroup).groups.source;
    const resUI = source.match(regexpDatasourceValue);

    if (!resUI) {
      return defaultResult;
    }

    const regex = /(mon|ctrl)\/([a-zA-Z]*)([0-9]*)\/([a-fA-F0-9]*)/i;
    const m = regex.exec(resUI[1]); // resUI[1] from start it is topic mon/0/...

    let action;
    let bus_id;
    let can_id;

    let code;
    let busN;
    let canN;
    let newSourceStr;

    // if already parsed
    if (!m && resUI.length === this.topicLength) {

      action = resUI[1];
      busN = resUI[2];
      canN = resUI[3];
      code = topicStr;

      if (this.topicLength === this.DEFAULT_TOPIC_LENGTH) { // for now it is only for WidgetHex - so skip next condition
        // because it can be any json obj
        if (!canNames.ids.hasOwnProperty(canN) || !busNames.hasOwnProperty(busN)) {
          return defaultResult;
        }
      }

      topicObj.datasourceName = resUI[0];
      topicObj.widgetValueType = resUI[4];

      const can = canN ? `["${canN}"]` : '';
      const value = topicObj.widgetValueType ? `["${topicObj.widgetValueType}"]` : '';

      newSourceStr = `datasources["${topicObj.datasourceName}"]["${action}"]["${busN}"]${can}${value}`;
    } else if (m && m.length === 5) {
      action = m[1];
      bus_id = m[3];
      can_id = m[4];

      // The CAN ID may be in hex format, so let's check and convert it to integer
      if (isNaN(can_id)) {
        can_id = parseInt(can_id, 16);
      }

      busN = busNames[bus_id];
      canN = canNames.names[can_id];

      const can = canN ? `["${canN}"]` : '';
      const value = topicObj.widgetValueType ? `["${topicObj.widgetValueType}"]` : '';

      newSourceStr = `datasources["${topicObj.datasourceName}"]["${action}"]["${busN}"]${can}${value}`;

      const reg = /(datasources\[.*\])/gi;
      const res = topicStr ? reg.exec(topicStr) : null;
      // @ts-ignore
      code = res ? topicStr.replaceAll(res[0], newSourceStr) : '';
    } else {
      return defaultResult;
    }

    return {
      source: {
        full: newSourceStr,
        name: topicObj.datasourceName,
        permission: action,
        bus: busN,
        can: canN,
        variable: topicObj.widgetValueType
      },
      code
    };
  }

  setParsedData(data) {
    const {busNames, canNames} = data;
    Object.keys(this.calcProps).forEach((prop) => {
      this.calcProps[prop] = this.parseTopic_({
        busNames,
        canNames,
        topicStr: this.settings[prop],
        topicObj: this
      });
      this.settings[prop] = (this.calcProps[prop] as IWidgetTopicParse).code;
    });
  }

  initialDatasourceParse(valueStr): string[] {
    if (!valueStr) {
      return null;
    }

    const match = valueStr.match(regexpDatasourceValueGroup);

    if (!match) {
      return null;
    }
    const source = match.groups.source;
    return source.match(regexpDatasourceValue);
  }

  isItForThisWidget(obj: any, props: IWidgetTopicParse): boolean {
    const sourceName = props.source.name;
    const perm = props.source.permission;
    const bus = props.source.bus;
    const can = props.source.can;

    return obj && obj.hasOwnProperty(sourceName) && obj[sourceName].hasOwnProperty(perm) && obj[sourceName][perm].hasOwnProperty(bus) && obj[sourceName][perm][bus].hasOwnProperty(can);
  }

  getWidgetValue(match: string[]) {
    if (!match) {
      return null;
    }

    return match[2] ? match[match.length - 1] : match[2];
  }

}

export enum Class {
  toolbarDialog = 'toolbar-dialog',
}

export enum Translations {
  ApplyCustomerDialogSetCustomer = 'apply_customer_dialog.set_customer',
}
