<mat-toolbar class="mat-primary">
    <h2>{{ title | translate }}</h2>
    <span class="spacer"></span>
    <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content (keydown.enter)="submitByEnter()">
    <mat-form-field class="full-width">
        <input
            matInput
            type="number"
            placeholder="{{'library_editor.value' | translate}}"
            [(ngModel)]="data.value1"
            name="value"
            cdkFocusInitial
        />
    </mat-form-field>
    <mat-form-field class="full-width">
        <input
            matInput
            placeholder="{{'library_editor.name' | translate}}"
            [(ngModel)]="data.name"
            name="name"
            maxlength="64"
        />
    </mat-form-field>
    <mat-form-field class="full-width">
        <input
            matInput
            placeholder="{{'library_editor.comment' | translate}}"
            [(ngModel)]="data.comment"
            name="comment"
        />
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{'library_editor.dialog.cancel' | translate}}</button>
    <button mat-button [mat-dialog-close]="data">{{'library_editor.dialog.apply' | translate}}</button>
</mat-dialog-actions>
