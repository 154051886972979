<body [ngClass]="accountSettings.getTheme()" class="mat-typography">
    <mat-sidenav-container class="mat-primary">
        <mat-sidenav #drawer class="sidenav unselectable">
            <mat-toolbar class="mat-primary" layout="row" id="drawer-toolbar">
                <mat-toolbar-row>
                    <img class="logo" [src]="getLogo() | secure | async" />
                </mat-toolbar-row>
                <mat-toolbar-row>
                    <span class="mat-body-2">{{ getUsername() }}</span>
                    <span class="spacer"></span>
                    <button mat-icon-button [matMenuTriggerFor]="menu"
                            aria-label="User Tools">
                        <mat-icon>
                            more_vert
                        </mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" xPosition="before">
                        <section class="menuItems">
                            <button mat-menu-item class="mat-body-2"
                                    [routerLink]="'/accountSettings'" (click)="
                                    drawer.toggle();
                                    isSelected('Account Settings')
                                ">
                                <mat-icon>
                                    <i class="material-icons">settings</i>
                                </mat-icon>
                                {{'title.account_settings' | translate}}
                            </button>
                            <button mat-menu-item (click)="logout()"
                                    class="mat-body-2">
                                <mat-icon>
                                    <i class="material-icons">exit_to_app</i>
                                </mat-icon>
                                {{'auth.sign_out' | translate}}
                            </button>
                        </section>
                    </mat-menu>
                </mat-toolbar-row>
            </mat-toolbar>

            <div class="flexContent navMenu" role="navigation">
                <mat-nav-list fxLayout="column" fxFill class="nav">
                    <div>
                        <mat-list-item id='home-list-item'
                                       (click)='drawer.toggle(); isSelected(menuNavItem.HOME)'
                                       [routerLink]='["/home"]'
                                       routerLinkActive='active-link'>
                            <p class='mat-body-2'>
                                <mat-icon class='list-icon'
                                          [svgIcon]="routerIcon.HOME">
                                </mat-icon>
                                {{'title.home' | translate}}
                            </p>
                        </mat-list-item>
                        <mat-expansion-panel hideToggle="true"
                                             [class.mat-elevation-z0]="true"
                                             dense #devicesPanel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="mat-body-2">
                                        <mat-icon class="list-icon"
                                                  svgIcon="hubspot"></mat-icon>
                                        {{'title.devices' | translate}}
                                    </div>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <mat-icon>{{ devicesPanel.expanded ?
                                        'keyboard_arrow_up' :
                                        'keyboard_arrow_down' }}</mat-icon>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <mat-list-item (click)="drawer.toggle(); isSelected(menuNavItem.DASHBOARDS)"
                                           [routerLink]="['/dashboards']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              [svgIcon]="routerIcon.DASHBOARDS">
                                    </mat-icon>
                                    {{'title.dashboards' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item (click)="drawer.toggle(); isSelected(menuNavItem.LIVE_MAP)"
                                           [routerLink]="['/liveMap']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              [svgIcon]="routerIcon.LIVE_MAP">
                                    </mat-icon>
                                    {{'title.live_map' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item (click)="drawer.toggle(); isSelected(menuNavItem.DEVICES)"
                                           [routerLink]="['/devices']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              [svgIcon]="routerIcon.DEVICES">
                                    </mat-icon>
                                    {{'title.devices' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item *ngIf="role <= constants.role_dealer_user"
                                           (click)="drawer.toggle(); isSelected(menuNavItem.MODELS)"
                                           [routerLink]="['/models']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              [svgIcon]="routerIcon.MODELS">
                                    </mat-icon>
                                    {{'title.models' | translate}}
                                </p>
                            </mat-list-item>
                        </mat-expansion-panel>
                        <mat-expansion-panel *ngIf="role <= constants.role_dealer_user"
                                             hideToggle="true"
                                             [class.mat-elevation-z0]="true"
                                             dense #eventsPanel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="mat-body-2">
                                        <mat-icon class="list-icon"
                                                  svgIcon="bell-alert">
                                        </mat-icon>
                                        {{'title.events' | translate}}
                                    </div>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <mat-icon>{{ eventsPanel.expanded ?
                                        'keyboard_arrow_up' :
                                        'keyboard_arrow_down' }}</mat-icon>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <mat-list-item *ngIf="role <= constants.role_oem_user"
                                           (click)="drawer.toggle(); isSelected(menuNavItem.ALERTS)"
                                           [routerLink]="['/alerts']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              [svgIcon]="routerIcon.ALERTS">
                                    </mat-icon>
                                    {{'title.alerts' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item *ngIf="role <= constants.role_dealer_user"
                                           (click)="drawer.toggle(); isSelected(menuNavItem.GEO_FENCING)"
                                           [routerLink]="['/rules']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              [svgIcon]="routerIcon.GEO_FENCING">
                                    </mat-icon>
                                    {{'title.geo_fencing' | translate}}
                                </p>
                            </mat-list-item>
                        </mat-expansion-panel>
                        <mat-expansion-panel hideToggle="true"
                                             [class.mat-elevation-z0]="true"
                                             dense #dataPanel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="mat-body-2">
                                        <mat-icon class="list-icon"
                                                  svgIcon="database"></mat-icon>
                                        {{'title.data' | translate}}
                                    </div>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <mat-icon>{{ dataPanel.expanded ?
                                        'keyboard_arrow_up' :
                                        'keyboard_arrow_down' }}</mat-icon>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <mat-list-item (click)="drawer.toggle(); isSelected(menuNavItem.REPORTS)"
                                           [routerLink]="['/data']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              [svgIcon]="routerIcon.REPORTS">
                                    </mat-icon>
                                    {{'title.data_files' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item *ngIf="false"
                                           (click)="drawer.toggle(); isSelected(menuNavItem.INVOICES)"
                                           [routerLink]="['/invoices']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              [svgIcon]="routerIcon.INVOICES">
                                    </mat-icon>
                                    {{'title.invoices' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item (click)="drawer.toggle(); isSelected(menuNavItem.CUSTOM_REPORTS)"
                                           [routerLink]="['/reports']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              [svgIcon]="routerIcon.CUSTOM_REPORTS">
                                    </mat-icon>
                                    {{'title.reports' | translate}}
                                </p>
                            </mat-list-item>
                            <!-- mat list item for statistics module -->
                            <mat-list-item (click)="drawer.toggle(); isSelected(menuNavItem.STATISTICS)"
                                           [routerLink]="[route.STATISTICS]"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon" [svgIcon]="routerIcon.STATISTICS"></mat-icon>
                                    {{ 'title.statistics' | translate }}
                                </p>
                            </mat-list-item>
                        </mat-expansion-panel>
                        <mat-expansion-panel hideToggle="true"
                                             [class.mat-elevation-z0]="true"
                                             dense #administrationPanel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="mat-body-2">
                                        <mat-icon class="list-icon"
                                                  svgIcon="shield-account">
                                        </mat-icon>
                                        {{'title.administration' | translate}}
                                    </div>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <mat-icon>{{ administrationPanel.expanded ?
                                        'keyboard_arrow_up' :
                                        'keyboard_arrow_down' }}</mat-icon>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <mat-list-item *ngIf="role <= constants.role_super"
                                           (click)="drawer.toggle(); isSelected(menuNavItem.ADMINISTRATORS)"
                                           [routerLink]="['/administrators']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              [svgIcon]="routerIcon.ADMINISTRATORS">
                                    </mat-icon>
                                    {{'title.administrators' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item *ngIf="role <= constants.role_administrator"
                                           (click)="drawer.toggle(); isSelected(menuNavItem.OEMS)"
                                           [routerLink]="['/oems']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              [svgIcon]="routerIcon.OEMS">
                                    </mat-icon>
                                    {{'title.oems' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item *ngIf="role <= constants.role_oem_user"
                                           (click)="drawer.toggle(); isSelected(menuNavItem.DEALERS)"
                                           [routerLink]="['/dealers']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              [svgIcon]="routerIcon.DEALERS">
                                    </mat-icon>
                                    {{'title.dealers' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item *ngIf="role <= constants.role_dealer_user"
                                           (click)="drawer.toggle(); isSelected(menuNavItem.CLIENTS)"
                                           [routerLink]="['/clients']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              [svgIcon]="routerIcon.CLIENTS">
                                    </mat-icon>
                                    {{'title.clients' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item (click)="drawer.toggle(); isSelected(menuNavItem.USERS)"
                                           [routerLink]="['/users']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              [svgIcon]="routerIcon.USERS">
                                    </mat-icon>
                                    {{'title.users' | translate}}
                                </p>
                            </mat-list-item>
                        </mat-expansion-panel>
                        <mat-expansion-panel hideToggle="true"
                                             [class.mat-elevation-z0]="true"
                                             dense #helpPanel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="mat-body-2">
                                        <mat-icon class="list-icon"
                                                  svgIcon="lifebuoy"></mat-icon>
                                        {{'title.help' | translate}}
                                    </div>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <mat-icon>{{ helpPanel.expanded ?
                                        'keyboard_arrow_up' :
                                        'keyboard_arrow_down' }}</mat-icon>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <mat-list-item (click)="drawer.toggle(); isSelected(menuNavItem.HELP)"
                                           [routerLink]="['/about']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              svgIcon="information-outline">
                                    </mat-icon>
                                    {{'title.about' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item (click)="drawer.toggle(); isSelected(menuNavItem.HELP)">
                                <a href="https://help.lenticul.net/1_User_Guide_Information/1.1_General_Information.html" target="_blank"
                                    class="mat-body-2">
                                    <mat-icon class="list-icon" svgIcon="file-account-outline"></mat-icon>
                                    {{ 'help.user_documentation' | translate }}
                                </a>
                            </mat-list-item>
                            <mat-list-item (click)="drawer.toggle(); isSelected(menuNavItem.HELP)"
                                           [routerLink]="['/help/api-doc']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon" svgIcon="api">
                                    </mat-icon>
                                    {{'help.api_documentation' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item (click)="drawer.toggle(); isSelected(menuNavItem.HELP)"
                                           [routerLink]="['/help/license']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              svgIcon="license"></mat-icon>
                                    {{'help.license' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item (click)="drawer.toggle(); isSelected(menuNavItem.HELP)"
                                           [routerLink]="['/help/terms']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon" svgIcon="draw">
                                    </mat-icon>
                                    {{'help.terms_and_conditions' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item (click)="drawer.toggle(); isSelected(menuNavItem.HELP)"
                                           [routerLink]="['/help/send-feedback']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon"
                                              svgIcon="message-text"></mat-icon>
                                    {{'help.send_feedback' | translate}}
                                </p>
                            </mat-list-item>
                            <mat-list-item (click)="drawer.toggle(); isSelected(menuNavItem.HELP)"
                                           *ngIf="role <= constants.role_super"
                                           [routerLink]="['help/generate-service-downtime']"
                                           routerLinkActive="active-link">
                                <p class="mat-body-2">
                                    <mat-icon class="list-icon" svgIcon="update"></mat-icon>
                                    {{'title.generate_service_downtime' | translate}}
                                </p>
                            </mat-list-item>
                        </mat-expansion-panel>
                    </div>
                    <span fxFlex></span>
                    <div>
                        <div class="version" (click)="drawer.toggle()"
                             [routerLink]="['/about']" fxLayoutAlign="left">
                            {{ versionService.versionString }}
                        </div>
                    </div>
                </mat-nav-list>
            </div>
        </mat-sidenav>

        <mat-sidenav-content class="mat-primary">
            <div class="service-wrapper" *ngIf="showNotification && this.notificationMsg">
                <p>
                    <b>{{ 'service_downtime.dear_user'| translate }}</b><br>
                    {{ notificationMsg.description }}
                </p>
                <mat-icon (click)="hideDowntimeNotification()">cancel</mat-icon>
            </div>
            <mat-toolbar class="mat-primary" id="mainToolbar">
                <div layout="row">
                    <button mat-icon-button aria-label="Menu"
                            (click)="drawer.toggle()">
                        <mat-icon>
                            <i class="material-icons">menu</i>
                        </mat-icon>
                    </button>
                    <div class="title" layout="row">
                        <mat-icon class="list-icon" [svgIcon]="icon">
                        </mat-icon>
                        <span class="title">{{ getTitle() | translate }}</span>
                    </div>
                </div>
                <span class="spacer"></span>
                <button *ngIf='canSearch' mat-icon-button
                        (click)="toggleSearch()">
                    <mat-icon>
                        <i class="material-icons">search</i>
                    </mat-icon>
                </button>
                <div class="searchBox">
                    <input #searchTextBox matInput class="searchStuff"
                           [@slide]="stateName" [(ngModel)]="searchValue"
                           (keyup)="returnSearch()"
                           placeholder="{{'header.search' | translate}}"
                           type="text" />
                    <button mat-icon-button [@toggle]="stateName"
                            class="closeButton" (click)="toggleSearch()">
                        <mat-icon>
                            close
                        </mat-icon>
                    </button>
                </div>
            </mat-toolbar>
            <mat-divider class="divider"></mat-divider>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</body>