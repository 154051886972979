<div class="scrollBelow">
    <form class="user-form" [formGroup]="userForm" fxLayout="column" fxLayoutAlign="start">

        <div fxLayout="column" fxLayout.gt-xs="row">
            <div fxFlex="100">
                <div>
                    <mat-icon class="form-icon">people</mat-icon>
                </div>
                <mat-form-field fxFlex="calc(60% - 15px)">
                    <input matInput class="form-control" id="first_name" formControlName="first_name" placeholder="{{'account.first_name' | translate}}" mat-hint="First Name" required>
                    <mat-error *ngIf="first_name.touched && first_name.invalid" class="alert alert-danger">
                        <mat-error *ngIf="first_name.errors.required">
                            {{'account.first_name_required' | translate}}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="50">
                <div class="space-to-right" fxFlex="100">
                    <mat-form-field fxFlex="calc(60% - 15px)">
                        <input matInput id="last_name" formControlName="last_name" placeholder="{{'account.last_name' | translate}}" mat-hint="Last Name" required>
                        <mat-error *ngIf="userForm.get('last_name').touched && userForm.get('last_name').invalid">
                            {{'account.last_name_required' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div fxLayout="column" fxLayout.gt-xs="row">
            <div fxFlex="100">
                <div>
                    <mat-icon class="form-icon">email</mat-icon>
                </div>
                <mat-form-field fxFlex="100">
                    <input matInput id="email" formControlName="email" placeholder="{{'user.email' | translate}}" mat-hint="Email" required>
                    <mat-error *ngIf="email.touched && email.invalid" class="alert alert-danger">
                        <mat-error *ngIf="email.errors.required">{{'form.email_required' | translate}}</mat-error>
                        <mat-error *ngIf="email.errors.customEmail">{{'form.email_invalid' | translate}}</mat-error>
                        <mat-error *ngIf="email.errors.duplicateEmail">{{'form.email_exists' | translate}}</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div fxLayout="column" fxLayout.gt-xs="row">
            <div fxFlex="50">
                <div>
                    <mat-icon class="form-icon">phone</mat-icon>
                </div>
                <mat-form-field fxFlex="calc(60% - 15px)">
                    <input matInput formControlName="phone" id="phone" placeholder="{{'account.phone_number' | translate}}"
                        (blur)="validatePhone()">
                    <mat-error *ngIf="phone.touched && phone.invalid" class="alert alert-danger">
                        <mat-error *ngIf="phone.errors.invalid">
                            {{'account.phone_number_invalid' | translate}}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="space-to-right" fxFlex="50">
                <mat-form-field fxFlex="calc(60% - 15px)">
                    <mat-error *ngIf="userForm.get('country').touched && userForm.get('country').invalid">
                        {{'user.country_required' | translate}}
                    </mat-error>
                    <mat-select id="country" placeholder="{{'user.country' | translate}}" formControlName="country" (blur)="validatePhone()">
                        <mat-option default="US" *ngFor="let country of countryList" [value]="country.code">
                            {{ country.code }} - {{ country.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div fxLayout="column" fxLayout.gt-xs="row">
            <div fxFlex="100">
                <div>
                    <mat-icon class="form-icon">domain</mat-icon>
                </div>
                <mat-form-field fxFlex="100">
                    <mat-select id="customer_id" formControlName="customer_id" placeholder="{{'user.association' | translate}}"
                        mat-hint="Association" required>
                        <mat-optgroup *ngFor="let id of customersArray" [label]="id.userRole">
                            <mat-option *ngFor="let user of id.users" [value]="user.customer_id">
                                {{user.customer_name}}
                            </mat-option>
                        </mat-optgroup>

                    </mat-select>
                    <mat-error *ngIf="userForm.get('customer_id').touched && userForm.get('customer_id').invalid">
                        {{'user.association_required' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="PCbox">
            <mat-checkbox formControlName="is_primary_contact">{{'user.primary_contact_for_association' | translate}}</mat-checkbox>
        </div>
        <div fxLayout="column" fxLayout.gt-xs="row">
            <div fxFlex="100">
                <div>
                    <mat-icon class="form-icon">security</mat-icon>
                </div>
                <mat-form-field fxFlex="100">
                    <mat-select id="user_role_id" placeholder="{{'user.permissions' | translate}}" formControlName="user_role_id" required>
                        <mat-option *ngFor="let permission of permissionArray" [value]="permission.user_role_id">
                            {{permission.role_name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="userForm.get('user_role_id').touched && userForm.get('customer_id').invalid">
                        {{'user.permissions_required' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="column" fxLayout.gt-xs="row">
            <div fxFlex="100">
                <div>
                    <mat-icon class="form-icon">compare_arrows</mat-icon>
                </div>
                <mat-form-field fxFlex="100">
                    <mat-select id="mqtt_permission_id" placeholder="{{'user.mqtt_permissions' | translate}}"
                        formControlName="mqtt_permission_id" required>
                        <mat-option *ngFor="let permission of permissionArray" [value]="permission.user_role_id">
                            {{permission.role_name}}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="userForm.get('mqtt_permission_id').touched && userForm.get('customer_id').invalid">
                        {{'user.mqtt_permissions_required' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="start center">
            <div id="reset-button" fxLayoutAlign.xs="center">
                <button mat-raised-button (click)="openDialog()">
                    <mat-icon>settings_backup_restore</mat-icon>
                    {{'account.reset_password' | translate}}
                </button>
            </div>
            <div id="slide-toggle" fxLayout="column" fxLayoutAlign.xs="center">
                <div fxLayout.xs="center">
                    <h4 fxLayoutAlign.xs="center">{{'user.current_status' | translate}}</h4>
                </div>
                <div>
                    <mat-slide-toggle class="mat-primary" [disabled]='!canEdit'
                        [checked]='user.is_locked == constants.user_unlocked' (change)="toggleLock()">
                        <span *ngIf='user.is_locked == constants.user_unlocked'>
                            {{'user.user_enabled' | translate}}
                        </span>
                        <span *ngIf='user.is_locked == constants.user_locked'>
                            {{'user.user_locked' | translate}}
                        </span>
                    </mat-slide-toggle>
                </div>
            </div>
        </div>
    </form>
    <div class="save-cancel">
        <button mat-raised-button (click)="closeEditor()">
            {{'btn.cancel' | translate}}
        </button>
        <button mat-raised-button class="mat-primary confirm" [disabled]="userForm.invalid || !canEdit"
            (click)="saveSettings()">{{'btn.save' | translate}}</button>
    </div>
</div>
