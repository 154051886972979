import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DataSource } from '@angular/cdk/table';
import { HttpParams } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import {
    exhaustMap,
    filter,
    map, shareReplay,
    startWith,
    switchMap, takeUntil,
    tap
} from 'rxjs/operators';
import { ManageFactoryService } from 'src/app/services/manage-factory/manage-factory.service';
export class DebugLogDataSource extends DataSource<any> {
    private _pageSize = 1000; // elements
    private _pageOffset = 0; // elements
    private _pageCache = new Set<number>();
    private _subscription: Subscription;
    private _viewPort: CdkVirtualScrollViewport;
    private _manageFactory: ManageFactoryService;
    private _reportId: Number;
    isLoading = false;

    // Create MatTableDataSource so we can have all sort,filter bells and whistles
    matTableDataSource: MatTableDataSource<any> = new MatTableDataSource();

    destroy$ = new Subject();
    scrollStartRange$ = new BehaviorSubject(0);

    // Expose dataStream to simulate VirtualForOf.dataStream
    dataStream = this.matTableDataSource.connect().asObservable();

    constructor(data) {
        super();
        this._reportId = data.reportId;
        this._manageFactory = data.manageFactory;
    }

    attach(viewPort: CdkVirtualScrollViewport) {
        if (!viewPort) {
            throw new Error('ViewPort is undefined');
        }
        this._viewPort = viewPort;

        this.initFetchingOnScrollUpdates();

        this._viewPort.detach();
        // Attach DataSource as CdkVirtualForOf so ViewPort can access dataStream
        this._viewPort.attach(this as any);
        this._viewPort.checkViewportSize();
        // Trigger range change so that 1st page can be loaded
        this._viewPort.setRenderedRange({ start: 0, end: 1 });
    }

    // Called by CDK Table
    connect(): Observable<any[]> {
        const tableData = this.matTableDataSource.connect();
        const filtered =
            this._viewPort === undefined
                ? tableData
                : this.filterByRangeStream(tableData);

        return filtered.pipe(shareReplay(1));
    }

    disconnect(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    destroy() {
        this._viewPort.scrollToIndex(0);
        this.destroy$.next();
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    private initFetchingOnScrollUpdates() {
        this._subscription = this._viewPort.renderedRangeStream
            .pipe(
                tap(range => {
                    this.scrollStartRange$.next(range.start);
                }),
                switchMap(range => this._getPagesToDownload(range)),
                filter(page => !this._pageCache.has(page)),
                exhaustMap(page => this._simulateFetchAndUpdate(page)),
                takeUntil(this.destroy$)
            )
            .subscribe();
    }

    async jumpTo(index: number)  {
        let range = { start: this._viewPort.getRenderedRange().start, end: index + (+this._pageSize) };
        let pages = this._getPagesToDownload(range as any).filter(page => !this._pageCache.has(page));
        for (let page of pages) {
            await this._simulateFetchAndUpdate(page).toPromise();
        }
        this._viewPort.scrollToIndex(index);
    }

    private _getPagesToDownload({ start, end }: { start: number; end: number }) {
        const startPage = this._getPageForIndex(start);
        const endPage = this._getPageForIndex(end + this._pageOffset);
        const pages: number[] = [];
        for (let i = startPage; i <= endPage; i++) {
            if (!this._pageCache.has(i)) {
                pages.push(i);
            }
        }
        return pages;
    }

    private _getPageForIndex(index: number): number {
        return Math.floor(index / this._pageSize);
    }

    private filterByRangeStream(tableData: Observable<any[]>) {
        const rangeStream = this._viewPort.renderedRangeStream.pipe(
            startWith({} as ListRange),
            takeUntil(this.destroy$)
        );
        const filtered = combineLatest(tableData, rangeStream).pipe(
            map(([data, { start, end }]) =>
                start === null || end === null ? data : data.slice(start, end)
            ),
            takeUntil(this.destroy$)
        );
        return filtered;
    }

    private _simulateFetchAndUpdate(page: number): Observable<any[]> {
        this.isLoading = true;
        return of(page).pipe(
            filter(page => !this._pageCache.has(page)),
            switchMap(() => {
                return this._updateCallback(page);
            }),
            tap(() => this._pageCache.add(page)),
            tap(users => {
                const newData = [...this.matTableDataSource.data];
                newData.splice(page * this._pageSize, this._pageSize, ...users);
                this.matTableDataSource.data = newData;
                this.isLoading = false;
            }),
            takeUntil(this.destroy$)
        );
    }

    private _updateCallback(page) {
        let params = new HttpParams()
            .set('page_size', this._pageSize)
            .set('page_number', page);
        return this._manageFactory.reports
            .getDebugLog({ id: this._reportId, params: params }).pipe(tap(result => {
            })) as Observable<any[]>;
    }

}
