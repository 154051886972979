<mat-toolbar class="mat-primary">
    <h2>{{ title | translate }}</h2>
    <span class="spacer"></span>
    <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<!-- h1 mat-dialog-title>{{title}}</h1 -->
<mat-dialog-content (keydown.enter)="submitByEnter()">
    <mat-form-field class="full-width">
        <input
            matInput
            placeholder="{{'library_editor.name' | translate}}"
            [(ngModel)]="symbol_var.name"
            name="name"
            maxlength="100"
            cdkFocusInitial
        />
    </mat-form-field>
    <mat-grid-list [cols]="2" rowHeight="65px" gutterSize="1em">
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <mat-label>{{'library_editor.data_type' | translate}}</mat-label>
                <mat-select
                    [(ngModel)]="sym_symbol_variable_data_type"
                    name="sym_variable_type"
                >
                    <mat-option [value]="1">Bit</mat-option>
                    <mat-option [value]="2">Char</mat-option>
                    <mat-option [value]="3">String</mat-option>
                    <mat-option [value]="4">Signed</mat-option>
                    <mat-option [value]="5">Unsigned</mat-option>
                    <mat-option [value]="6">Float</mat-option>
                    <mat-option [value]="7">Enum</mat-option>
                    <mat-option [value]="8">Double</mat-option>
                    <mat-option [value]="9">Raw</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input
                    matInput
                    placeholder="{{'library_editor.unit_of_measure' | translate}}"
                    [(ngModel)]="symVariableValueUom"
                    name="symVariableValueUom"
                    maxlength="100"
                />
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="isEnum(symbol_var)" colspan="2">
            <mat-form-field class="full-width">
                <mat-label>{{'library_editor.dialog.enum' | translate}}</mat-label>
                <mat-select
                    [(ngModel)]="sym_symbol_variable_enum"
                    name="sym_variable_data_type_ex"
                >
                    <mat-option
                        *ngFor="let enum of sym_lib.enums"
                        [value]="enum.name"
                        >{{ enum.name }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="isFactorShown(symbol_var)">
            <mat-form-field class="full-width">
                <input
                    matInput
                    type="number"
                    placeholder="{{'library_editor.factor' | translate}}"
                    [(ngModel)]="sym_variable_value_scalar"
                    name="sym_variable_factor"
                />
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="isOffsetShown(symbol_var)">
            <mat-form-field class="full-width">
                <input
                    matInput
                    type="number"
                    placeholder="{{'library_editor.offset' | translate}}"
                    [(ngModel)]="sym_variable_value_offset"
                    name="sym_variable_offset"
                />
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input
                    matInput
                    type="number"
                    placeholder="{{'library_editor.start_bit' | translate}}"
                    [formControl]="startBitFormControl"
                    name="sym_variable_start_bit"
                    min="0"
                />
                <mat-error *ngIf="startBitFormControl.hasError('pattern')">
                    {{'library_editor.err.natural_number' | translate}}
                </mat-error>
                <mat-error
                    *ngIf="
                        (startBitFormControl.hasError('min') ||
                            startBitFormControl.hasError('max')) &&
                        !startBitFormControl.hasError('pattern')
                    "
                >
                    {{'library_editor.err.out_of_bounds' | translate}}
                </mat-error>
                <mat-error *ngIf="startBitFormControl.hasError('required')">
                    {{'library_editor.err.empty' | translate}}
                </mat-error>
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-form-field class="full-width">
                <input
                    matInput
                    type="number"
                    placeholder="{{'library_editor.length' | translate}}"
                    [formControl]="lengthFormControl"
                    name="sym_variable_length"
                    min="1"
                    [value]="sym_variable_length"
                    [readonly]="sym_symbol_variable_data_type == 1"
                />
                <mat-error *ngIf="lengthFormControl.hasError('pattern')">
                    {{'library_editor.err.natural_number' | translate}}
                </mat-error>
                <mat-error
                    *ngIf="
                        (lengthFormControl.hasError('min') ||
                            lengthFormControl.hasError('max')) &&
                        !lengthFormControl.hasError('pattern')
                    "
                >
                    {{'library_editor.err.out_of_bounds' | translate}}
                </mat-error>
                <mat-error *ngIf="lengthFormControl.hasError('required')">
                    {{'library_editor.err.empty' | translate}}
                </mat-error>
            </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-checkbox
                class="full-width"
                [(ngModel)]="sym_variable_data_format"
                name="sym_variable_data_format"
                labelPosition="after"
            >
                {{ data_format_title | translate }}
            </mat-checkbox>
        </mat-grid-tile>
    </mat-grid-list>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{'library_editor.dialog.cancel' | translate}}</button>
    <button
        mat-button
        [mat-dialog-close]="symbol_var"
        [disabled]="lengthFormControl.invalid || lengthFormControl.invalid"
    >
        {{'library_editor.dialog.apply' | translate}}
    </button>
</mat-dialog-actions>
