import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageFactoryService } from '../services/manage-factory/manage-factory.service';
import { appConstants } from '../services/constants';
import { SelectionModel } from '@angular/cdk/collections';
import { AlertEmailDialogComponent } from '../dialogs/alert-email-dialog/alert-email-dialog.component';
import { AlertTextDialogComponent } from '../dialogs/alert-text-dialog/alert-text-dialog.component';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { SelectDialogComponent } from '../dialogs/select-dialog/select-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import * as L from 'leaflet';
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";

@Component({
    selector: 'app-rule',
    templateUrl: './rule-editor.component.html',
    styleUrls: ['./rule-editor.component.css']
})
// Geo-Fencing Rule Editor
export class RuleEditorComponent implements OnInit {
    id: any;
    constants = appConstants;
    canEdit: boolean;
    isMobile: boolean;
    map: L.Map;
    origDevices: any = [];
    deviceSelectArray: any = [];
    oemArray: any = [];
    dealerArray: any = [];

    streetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        detectRetina: true,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });
    southWest = L.latLng(-90, -180);
    northEast = L.latLng(90, 180);
    bounds = L.latLngBounds(this.southWest, this.northEast);
    minZoomRatio = 2;

    options = {
        layers: [this.streetMaps],
        zoom: 2,
        center: L.latLng({ lat: 20, lng: 0 }),
        maxBounds: this.bounds,
        maxBoundsViscosity: 1.0
    };

    drawOptions: any;
    featureGroup = L.featureGroup();
    layers = [this.featureGroup];
    fitBounds: any;
    fitBoundsOptions = {
        padding: [10, 10],
        animate: false,
        duration: 0.2,
        noMoveStart: true
    };

    rule = {
        title: null,
        dealerSelectOption: null,
        deviceSelectOption: null,
        selectedOem: (+sessionStorage.getItem('customerRole') == this.constants.role_oem_user) ? +sessionStorage.getItem('customerId') : null,
        selectedDealer: (+sessionStorage.getItem('customerRole') == this.constants.role_dealer_user) ? +sessionStorage.getItem('customerId') : null,
        geoJsonShapes: null
    };
    alertArray = {
        email: [],
        text: [],
        log: [],
        mqtt: []
    };
    whenArray = [
        { text: "On Entering" },
        { text: "On Leaving" },
        { text: "On Entering & Leaving" }
    ];

    deviceDatasource: any;
    displayedColumnsDevice: any;
    selectedDevices = new SelectionModel(true, []);
    deviceResultsLength: any;

    emailDatasource: any;
    displayedColumnsEmail = ['select', 'emailAddresses', 'email_subject', 'when'];
    selectedEmail = new SelectionModel(true, []);

    textDatasource: any;
    displayedColumnsText = ['select', 'phoneNumbers', 'text_message', 'when'];
    selectedText = new SelectionModel(true, []);

    logDatasource: any;
    displayedColumnsLog = ['select', 'when'];
    selectedLog = new SelectionModel(true, []);

    mqttDatasource: any;
    displayedColumnsMqtt = ['select', 'when'];
    selectedMqtt = new SelectionModel(true, []);

    isSavingProcess = false;

    pageSize = '50';
    pageLengthOptions = [10, 25, 50, 100];
    private paginator: MatPaginator;
    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.deviceDatasource.paginator = this.paginator;
    }
    @ViewChild(MatSort) sort: MatSort;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private manageFactory: ManageFactoryService, public dialog: MatDialog, public snackBar: MatSnackBar, private translate: TranslateService) {
        this.deviceDatasource = new MatTableDataSource<any>();
        this.emailDatasource = new MatTableDataSource<any>();
        this.textDatasource = new MatTableDataSource<any>();
        this.logDatasource = new MatTableDataSource<any>();
        this.mqttDatasource = new MatTableDataSource<any>();
        this.activatedRoute.params.subscribe(params => {
            if (params['id'] != undefined) {
                this.id = params['id'];
                this.loadExisting();
            }
        });
    }

    ngOnInit() {
        this.isMobile = (window.innerWidth) < 960;
        this.minZoomRatio = (Math.round(window.innerWidth / (window.innerHeight * 0.7)) < 2) ? 2 : Math.round(window.innerWidth / (window.innerHeight * 0.7));
        this.displayedColumnsDevice = this.isMobile ? ['select', 'identifier'] : ['select', 'identifier', 'name', 'device_type', 'dealer_name', 'client_name'];
        this.canEdit = (+sessionStorage.getItem('userRole') == this.constants.user_read_write) ? true : false;
        if (this.canEdit) {
            this.drawOptions = {
                position: 'topleft',
                draw: {
                    marker: false,
                    circlemarker: false,
                    polyline: false,
                },
                edit: {
                    featureGroup: this.featureGroup
                }
            }
        } else {
            this.drawOptions = {
                draw: false,
                edit: {
                    edit: false,
                    featureGroup: this.featureGroup,
                    remove: false
                }
            }
        }

        this.manageFactory.rules.readDeviceSelectOptions()
            .subscribe(devResult => {
                this.deviceSelectArray = devResult;
            });

        this.setPageSize();
    }

    loadExisting() {
        this.manageFactory.rules.readOne({ id: this.id })
            .subscribe(result => {
                this.rule.title = result[0]['title'];
                this.rule.dealerSelectOption = result[0]['device_dealer_id'];
                this.rule.deviceSelectOption = result[0]['device_select_type_type_id'];
                if (this.rule.deviceSelectOption == 2) this.rule.selectedOem = result[0]['device_dealer_id'];
                if (this.rule.deviceSelectOption == 3) this.rule.selectedDealer = result[0]['device_dealer_id'];
                this.manageFactory.rules.getDevices(this.id)
                    .subscribe(devResult => {
                        this.origDevices = devResult;
                        this.updateDeviceSelection(true);
                    });
                this.manageFactory.rules.getShapes(this.id)
                    .subscribe(geoResult => {
                        this.rule.geoJsonShapes = geoResult;
                        L.geoJSON(this.rule.geoJsonShapes, {
                            onEachFeature: (feature, layer) => {
                                // circles require special set up
                                if (layer['feature']['geometry']['type'] == 'Point') {
                                    let layerHold = L.circle([layer['feature']['geometry']['coordinates'][1], layer['feature']['geometry']['coordinates'][0]], { radius: layer['feature']['properties']['radius'] });
                                    layerHold['feature'] = layer['feature'];
                                    layer = layerHold;
                                }
                                this.featureGroup.addLayer(layer);
                            }
                        })
                        this.fitBounds = this.featureGroup.getBounds();
                    })
            })

        this.manageFactory.rules.getConfig({ id: this.id })
            .subscribe(configResult => {
                if (configResult) {
                    for (let x in configResult) {
                        let obj = configResult[x];
                        obj.when = this.getWhen(obj);
                        // result is a text message
                        if (obj.typeId == 1) {
                            obj.phoneNumbers = obj.phone_nbr.split(',');
                            this.alertArray.text.push(obj);
                            // result is an email
                        } else if (obj.typeId == 2) {
                            obj.emailAddresses = obj.email_addresses.split(',');
                            this.alertArray.email.push(obj);
                            //result is a log
                        } else if (obj.typeId == 3) {
                            this.alertArray.log.push(obj);
                            //result is an mqtt
                        } else if (obj.typeId == 4) {
                            this.alertArray.mqtt.push(obj);
                        }
                    }
                }
                this.emailDatasource.data = this.alertArray.email;
                this.textDatasource.data = this.alertArray.text;
                this.logDatasource.data = this.alertArray.log;
                this.mqttDatasource.data = this.alertArray.mqtt;
            })
    }

    onResize(event) {
        this.isMobile = (event.target.innerWidth) < 960;
        this.displayedColumnsDevice = this.isMobile ? ['select', 'identifier'] : ['select', 'identifier', 'name', 'device_type', 'dealer_name', 'client_name'];
        this.minZoomRatio = (Math.round(window.innerWidth / (window.innerHeight * 0.7)) < 2) ? 2 : Math.round(window.innerWidth / (window.innerHeight * 0.7));
    }

    selectRowDevice(row) {
        if (this.rule.deviceSelectOption == 1) this.selectedDevices.toggle(row);
    }

    pageEvent(event) {
        this.deviceDatasource.paginator = this.paginator;
    }

    onMapReady(map: L.Map) {
        this.map = map;

        this.map.on('drag', () => {
            this.map.panInsideBounds(this.bounds, { animate: false });
        });
        // Leaflet doesn't like being hidden! This is how we get the baselayer to reappear after moving between tabs.
        let theBase = this.streetMaps;
        this.map.removeLayer(theBase);
        this.map.addLayer(theBase);
        this.map.invalidateSize(false);

        if (Object.keys(this.featureGroup['_layers']).length === 0) {
            // There are no shapes on this map, so let's try to find the user
            this.map.locate();
            // when the locate method finds the user's location,
            // update the center of the map to the user's location
            this.map.on('locationfound', (e) => {
                map.setView(new L.LatLng(e['latitude'], e['longitude']), 10);
            })
            // called if the locate method failed
            this.map.on('locationerror', (e) => {
                console.log(e);
            })
        }
    }

    onDrawCreated(e) {
        this.fitBounds = this.featureGroup.getBounds();
    }

    addEmail(edit) {
        let item, currentAlert, index;
        if (!edit) {
            item = 'rule_editor.add_email_alert';
            currentAlert = {
                emailAddresses: [],
                email_body: '',
                email_subject: '',
                when: '',
                on_enter: 0,
                on_exit: 0,
                typeId: 2
            };
        } else {
            item = 'rule_editor.edit_email_alert';
            currentAlert = this.selectedEmail.selected[0];
            index = this.alertArray.email.indexOf(currentAlert);
        }

        this.translate.get(item).subscribe((text: string) => {
            let dialog = this.dialog.open(AlertEmailDialogComponent, {
                panelClass: 'toolbar-dialog',
                data: { title: text, alert: currentAlert, whenArray: this.whenArray }
            });

            dialog.afterClosed()
                .subscribe(response => {
                    if (response) {
                        if (!edit) {
                            this.alertArray.email.push(response);
                        } else {
                            this.alertArray.email[index] = response;
                        }
                        this.emailDatasource.data = this.alertArray.email;
                    }
                })
            });
    }

    addText(edit) {
        let item, currentAlert, index;
        if (!edit) {
            item = 'rule_editor.add_text_alert';
            currentAlert = {
                phoneNumbers: [],
                phone_nbr: null,
                text_message: '',
                when: '',
                on_enter: 0,
                on_exit: 0,
                typeId: 1
            };
        } else {
            item = 'rule_editor.edit_text_alert';
            currentAlert = this.selectedText.selected[0];
            index = this.alertArray.text.indexOf(currentAlert);
        }

        this.translate.get(item).subscribe((text: string) => {
            let dialog = this.dialog.open(AlertTextDialogComponent, {
                panelClass: 'toolbar-dialog',
                data: { title: text, alert: currentAlert, whenArray: this.whenArray }
            });

            dialog.afterClosed()
                .subscribe(response => {
                    if (response) {
                        if (!edit) {
                            this.alertArray.text.push(response);
                        } else {
                            this.alertArray.text[index] = response;
                        }
                        this.textDatasource.data = this.alertArray.text;
                    }
                })
        });
    }

    addLog(edit) {
        let item, currentAlert, index, val;
        if (!edit) {
            item = 'rule_editor.add_log_alert';
            val = null;
            currentAlert = {
                when: '',
                on_enter: 0,
                on_exit: 0,
                typeId: 3
            };
        } else {
            item = 'rule_editor.edit_log_alert';
            val = this.selectedLog.selected[0]['when'];
            currentAlert = this.selectedLog.selected[0];
            index = this.alertArray.log.indexOf(currentAlert);
        }

        this.translate.get([item, 'btn.apply', 'rule_editor.send_alert_when']).subscribe((translated: string) => {
            let dialog = this.dialog.open(SelectDialogComponent, {
                panelClass: 'toolbar-dialog',
                data: { title: translated[item], confirm: translated['btn.apply'], label: translated['rule_editor.send_alert_when'], val: val, array: this.whenArray }
            });

            dialog.afterClosed()
                .subscribe(response => {
                    if (response) {
                        console.log(response);
                        currentAlert['when'] = response;
                        currentAlert['on_enter'] = currentAlert['when'].includes('Entering') ? 1 : 0;
                        currentAlert['on_exit'] = currentAlert['when'].includes('Leaving') ? 1 : 0;
                        if (!edit) {
                            this.alertArray.log.push(currentAlert);
                        } else {
                            this.alertArray.log[index] = currentAlert;
                        }
                        this.logDatasource.data = this.alertArray.log;
                    }
                })
        });
    }

    addMqtt(edit) {
        let item, val, currentAlert, index;
        if (!edit) {
            item = 'rule_editor.add_mqtt_alert';
            val = null;
            currentAlert = {
                when: '',
                on_enter: 0,
                on_exit: 0,
                typeId: 4
            };
        } else {
            item = 'rule_editor.edit_mqtt_alert';
            currentAlert = this.selectedMqtt.selected[0];
            val = this.selectedLog.selected[0]['when'];
            index = this.alertArray.mqtt.indexOf(currentAlert);
        }

        this.translate.get([item, 'btn.apply', 'rule_editor.send_alert_when']).subscribe((translated: string) => {
            let dialog = this.dialog.open(SelectDialogComponent, {
                panelClass: 'toolbar-dialog',
                data: { title: translated[item], confirm: translated['btn.apply'], label: translated['rule_editor.send_alert_when'], val: val, array: this.whenArray }
            });

            dialog.afterClosed()
                .subscribe(response => {
                    if (response) {
                        currentAlert['when'] = response;
                        currentAlert['on_enter'] = currentAlert['when'].includes('Entering') ? 1 : 0;
                        currentAlert['on_exit'] = currentAlert['when'].includes('Leaving') ? 1 : 0;
                        if (!edit) {
                            this.alertArray.mqtt.push(currentAlert);
                        } else {
                            this.alertArray.mqtt[index] = currentAlert;
                        }
                        this.mqttDatasource.data = this.alertArray.mqtt;
                    }
                })
        });
    }

    deleteEmail() {
        let item = this.selectedEmail.selected.length > 1 ? 'rule_editor.confirm_delete_email_alerts_desc' : 'rule_editor.confirm_delete_email_alert_desc';
        this.translate.get([item, 'btn.delete', 'dialog.confirm_delete']).subscribe((translated: string) => {
            let dialog = this.dialog.open(ConfirmDialogComponent, {
                data: { title: translated['dialog.confirm_delete'], text: translated[item], confirm: translated['btn.delete'] }
            });

            dialog.afterClosed()
                .subscribe(response => {
                    //if we get a true from the dialog, delete the selected items
                    if (response) {
                        for (let x in this.selectedEmail.selected) {
                            let selected = this.selectedEmail.selected[x];
                            let index = this.alertArray.email.indexOf(selected);
                            this.alertArray.email.splice(index, 1);
                        };
                    }
                    this.selectedEmail.clear();
                    this.emailDatasource.data = this.alertArray.email;
                })
        });
    }

    deleteText() {
        let item = this.selectedText.selected.length > 1 ? 'rule_editor.confirm_delete_text_alerts_desc' : 'rule_editor.confirm_delete_text_alert_desc';
        this.translate.get([item, 'btn.delete', 'dialog.confirm_delete']).subscribe((translated: string) => {
            let dialog = this.dialog.open(ConfirmDialogComponent, {
                data: { title: translated['dialog.confirm_delete'], text: translated[item], confirm: translated['btn.delete'] }
            });

            dialog.afterClosed()
                .subscribe(response => {
                    //if we get a true from the dialog, delete the selected items
                    if (response) {
                        for (let x in this.selectedText.selected) {
                            let selected = this.selectedText.selected[x];
                            let index = this.alertArray.text.indexOf(selected);
                            this.alertArray.text.splice(index, 1);
                        };
                    }
                    this.selectedText.clear();
                    this.textDatasource.data = this.alertArray.text;
                })
        });
    }

    deleteLog() {
        let item = this.selectedLog.selected.length > 1 ? 'rule_editor.confirm_delete_log_alerts_desc' : 'rule_editor.confirm_delete_log_alert_desc';
        this.translate.get([item, 'btn.delete', 'dialog.confirm_delete']).subscribe((translated: string) => {
            let dialog = this.dialog.open(ConfirmDialogComponent, {
                data: { title: translated['dialog.confirm_delete'], text: translated[item], confirm: translated['btn.delete'] }
            });

            dialog.afterClosed()
                .subscribe(response => {
                    //if we get a true from the dialog, delete the selected items
                    if (response) {
                        for (let x in this.selectedLog.selected) {
                            let selected = this.selectedLog.selected[x];
                            let index = this.alertArray.log.indexOf(selected);
                            this.alertArray.log.splice(index, 1);
                        };
                    }
                    this.selectedLog.clear();
                    this.logDatasource.data = this.alertArray.log;
                })
        });
    }

    deleteMqtt() {
        let item = this.selectedMqtt.selected.length > 1 ? 'rule_editor.confirm_delete_mqtt_alerts_desc' : 'rule_editor.confirm_delete_mqtt_alert_desc';
        this.translate.get([item, 'btn.delete', 'dialog.confirm_delete']).subscribe((translated: string) => {
            let dialog = this.dialog.open(ConfirmDialogComponent, {
                data: { title: translated['dialog.confirm_delete'], text: translated[item], confirm: translated['btn.delete'] }
            });

            dialog.afterClosed()
                .subscribe(response => {
                    //if we get a true from the dialog, delete the selected items
                    if (response) {
                        for (let x in this.selectedMqtt.selected) {
                            let selected = this.selectedMqtt.selected[x];
                            let index = this.alertArray.mqtt.indexOf(selected);
                            this.alertArray.mqtt.splice(index, 1);
                        };
                    }
                    this.selectedMqtt.clear();
                    this.mqttDatasource.data = this.alertArray.mqtt;
                })
        });
    }

    updateDeviceSelection(isNew) {
        this.selectedDevices.clear();
        this.deviceDatasource.data = [];
        if (this.rule.deviceSelectOption == 1) {
            this.manageFactory.devices.read({
                enabled: true
            }).subscribe(deviceResult => {
                this.deviceDatasource.data = deviceResult;
                this.deviceResultsLength = this.deviceDatasource.data.length;
                this.deviceDatasource.paginator = this.paginator;
                this.deviceDatasource.sort = this.sort;
                if (isNew && this.origDevices) {
                    for (let x of this.origDevices) {
                        let row = this.deviceDatasource.data.find(i => i['device_id'] == x['device_id']);
                        if (row) this.selectedDevices.select(row);
                    }
                }
            })

            // select all devices by OEM
        } else if (this.rule.deviceSelectOption == 2) {
            if (+sessionStorage.getItem('customerRole') < this.constants.role_oem_user) {
                this.manageFactory.oems.read().subscribe(oemResult => {
                    this.oemArray = oemResult;
                })
            }
            if (this.rule.selectedOem) this.oemChange();

            // select all devices by dealer
        } else if (this.rule.deviceSelectOption == 3) {
            if (+sessionStorage.getItem('customerRole') < this.constants.role_dealer_user) {
                this.manageFactory.dealers.read().subscribe(dealerResult => {
                    this.dealerArray = dealerResult;
                })
            }
            if (this.rule.selectedDealer) this.dealerChange();
        }
    }

    oemChange() {
        this.manageFactory.rules.getAllDevices(this.rule.selectedOem)
            .subscribe(result => {
                this.deviceDatasource.data = result;
                this.deviceDatasource.data.forEach(row => this.selectedDevices.select(row));
                this.deviceResultsLength = this.deviceDatasource.data.length;
                setTimeout(() => {
                    this.deviceDatasource.paginator = this.paginator;
                    this.deviceDatasource.sort = this.sort;
                });
            })
    }

    dealerChange() {
        this.manageFactory.rules.getAllDevices(this.rule.selectedDealer)
            .subscribe(result => {
                this.deviceDatasource.data = result;
                this.deviceDatasource.data.forEach(row => this.selectedDevices.select(row));
                this.deviceResultsLength = this.deviceDatasource.data.length;
                setTimeout(() => {
                    this.deviceDatasource.paginator = this.paginator;
                    this.deviceDatasource.sort = this.sort;
                });
            })
    }

    save() {
        let saveObject = this.getSaveShapes();
        if (this.id) {
            saveObject['nameId'] = this.id;

            this.isSavingProcess = true;
            this.manageFactory.rules.deleteShapes({ id: this.id })
                .subscribe(result => {
                    this.manageFactory.rules.update(saveObject)
                        .subscribe(updateResult => {
                          this.isSavingProcess = false;
                            this.translate.get('rule_editor.successfully_updated').subscribe((text: string) => {
                                this.snackBar.open(text, "", {
                                    duration: 4000,
                                    horizontalPosition: 'left'
                                });
                            });
                        }, error => {
                          this.isSavingProcess = false;
                          this.snackBar.open('Something went wrong!', '', {
                            duration: 4000,
                            horizontalPosition: 'left'
                          });
                        });
                }, error => {
                  this.isSavingProcess = false;
                  this.snackBar.open('Something went wrong!', '', {
                    duration: 4000,
                    horizontalPosition: 'left'
                  });
                });
        } else {
            this.manageFactory.rules.create(saveObject)
                .subscribe(result => {
                    this.translate.get('rule_editor.successfully_created').subscribe((text: string) => {
                        this.snackBar.open(text, "", {
                            duration: 4000,
                            horizontalPosition: 'left'
                        });

                        //reload page with id so any more changes will update instead of creating a new rule
                        let newId = result['insertId'];
                        let goTo = 'rule/' + newId;
                        this.router.navigate([goTo]);
                    });
                })

        }
    }

    cancel() {
        let goTo = 'rules';
        this.router.navigate([goTo]);
    }

    getSaveShapes() {
        let jsonShapes = {
            title: this.rule.title,
            shapes: [],
            configOptions: [],
            customer_id: sessionStorage.getItem('customerId'),
            isDeleted: 0,
            deviceSelectOption: this.rule.deviceSelectOption,
            devices: this.getSelectedDevices(),
            dealerSelectOption: (this.rule.deviceSelectOption == 1 ? null : (this.rule.deviceSelectOption == 2 ? this.rule.selectedOem : this.rule.selectedDealer))
        };

        // loops through each layer on the leaflet map
        for (let key in this.featureGroup['_layers']) {
            let layer = this.featureGroup['_layers'][key];
            // circles have _latlng because there is only one point coordinate associated with them
            if (layer['_latlng'] != null) {
                if (layer['_mRadius'] != null) {
                    let tempCircle = {};
                    let tempShape = {};
                    tempCircle['latitude'] = layer['_latlng']['lat'];
                    tempCircle['longitude'] = layer['_latlng']['lng'];
                    tempCircle['radius'] = layer['_mRadius'];
                    tempShape['points'] = [tempCircle];
                    // existing layers (ones loaded into the map) have features
                    // if layer is existing, we are updating it
                    if (layer['feature'] != null) {
                        tempShape['updateFlag'] = "update";
                        // new layers do not yet have features
                        // if layer is new, give it the new flag
                    } else {
                        tempShape['updateFlag'] = "new";
                    }
                    jsonShapes.shapes.push(tempShape);
                }
                //everything else (polygons, rectangles, etc.) has _latlngs because multiple points are associated with them
            } else if (layer['_latlngs'] != null) {
                let polyArray = [];
                let tempShape = {};
                for (let i = 0; i < layer['_latlngs'][0].length; i++) {
                    let tempPoint = {
                        "latitude": layer['_latlngs'][0][i]['lat'],
                        "longitude": layer['_latlngs'][0][i]['lng']
                    };
                    polyArray.push(tempPoint);
                }
                tempShape['points'] = polyArray;
                // existing layers (ones loaded into the map) have features
                // if layer is existing, we are updating it
                if (layer['feature'] != null) {
                    tempShape['updateFlag'] = "update";
                    // new layers do not yet have features
                    // if layer is new, give it the new flag
                } else {
                    tempShape['updateFlag'] = "new";
                }
                jsonShapes.shapes.push(tempShape);
            }
        }
        // adds in alerts to configOptions by type
        for (let w in this.alertArray.text) {
            jsonShapes.configOptions.push(this.alertArray.text[w]);
        }
        for (let x in this.alertArray.email) {
            jsonShapes.configOptions.push(this.alertArray.email[x]);
        }
        for (let y in this.alertArray.log) {
            jsonShapes.configOptions.push(this.alertArray.log[y]);
        }
        for (let z in this.alertArray.mqtt) {
            jsonShapes.configOptions.push(this.alertArray.mqtt[z]);
        }
        return jsonShapes;
    }


    getWhen(rule) {
        let when = "";
        if (rule.on_enter == 1 && rule.on_exit == 1) {
            when = "On Entering & Leaving";
        } else if (rule.on_enter == 1) {
            when = "On Entering";
        } else if (rule.on_exit == 1) {
            when = "On Leaving";
        }
        return when;
    }

    getSelectedDevices() {
        // returns a list of all selected devices
        let devList = [];
        for (let x of this.selectedDevices.selected) {
            devList.push(x['device_id']);
        }
        return devList;
    }

    preventSave() {
        if (!this.rule.title) return true;
        if (Object.keys(this.featureGroup['_layers']).length === 0) return true;
        if (this.alertArray.email.length < 1 && this.alertArray.text.length < 1 && this.alertArray.log.length < 1 && this.alertArray.mqtt.length < 1) return true;
        if (this.selectedDevices.selected.length == 0) return true;
        if (!this.canEdit) return true;

        if (this.isSavingProcess) {
          return true;
        }

        return false;
    }

    setPageSize() {
        this.pageSize = sessionStorage.getItem('page_size');
        if(!this.pageSize || parseInt(this.pageSize, 10) > this.pageLengthOptions[this.pageLengthOptions.length - 1]) {
            this.pageSize = '50';
        }
    }

    getPageSize(e) {
        sessionStorage.setItem('page_size', e.pageSize);
    }
}
