import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { VersionService } from '../services/version/version.service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
    dateTimeFormat = sessionStorage.getItem('date_format') + " ";

    constructor(
        public versionService: VersionService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private clipboard: ClipboardService
    ) { }

    ngOnInit(): void {
        this.versionService.loadHistory();
    }

    copy() {
        const frontendVersion = this.versionService.projectVersion('frontend') ?? '?.?.?';
        const backendVersion = this.versionService.projectVersion('backend') ?? '?.?.?';
        const dateString = (new Date()).toISOString();
        const copyString = `Frontend: ${frontendVersion} | Backend: ${backendVersion} | Date: ${dateString}`;

        this.clipboard.copy(copyString);
        this.translate.get('about.versions_copied').subscribe((msg) => {
            this.snackBar.open(msg, '', {
                duration: 4000,
                horizontalPosition: 'left'
            });
        });
    }
}
