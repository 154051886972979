export interface SymbolFile {
  baud_rate_id: number;
  baud_rate_value: string;
  bus_number_id: number;
  bus_number_type: string;
  created_date: string;
  file_location: string;
  is_active: boolean;
  library_id: number;
  model_id: number;
  version_number: string;
  tempSymbolFileName: string;
  messages: string;
}

export interface IFirmwareFile {
  created_date: string;
  file_location: string;
  is_active: boolean;
  firmware_id: number;
  model_id: number;
  version_number: string;
  customerId?: number;
}

export interface DocumentFile {
  doc_id: number;
  model_id: number;
  file_location: string;
  name: string;
  created_date: string;
  size: string;
  version_number: number;
  is_active: number;
  type: string;
  ext?: string;
}

export enum FirmwareFormControls {
  CustomerId = 'customer_id'
}

export enum Translations {
  NoAssociationFound = 'model_editor.del_sym.no_association_found',
  DeleteSymAssociationFound = 'model_editor.del_sym.association_found',
  ConfirmDelete = 'model_editor.confirm_delete',
  Delete = 'btn.delete',
  VariableAssociationFound = 'library_editor.variable_association_found',
  BtnSaveAnyway = 'library_editor.dialog.btn_save_anyway',
  TitleBindedAlert = 'library_editor.dialog.title_binded_alert'
}
