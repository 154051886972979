<div id='confirm-dialog' class='mat-typography'>
    <h1>{{ data.title }}</h1>
    <mat-dialog-content>
        <p>{{ data.text }}</p>
        <ng-container *ngFor="let i of data.dependedCount?.values">
            <ng-container *ngIf="data.symLibrary; else modelPage">
                <ng-container *ngIf="i.count > 0">
                    <div class="list-wrap">
                        <div>
                            <h3><b>{{ i.category }}:</b> {{ i.name }}</h3>
                            <p>
                                {{ (i.category == AssociationCategories.Alert ? 'assigned_details_dialog.alert_rules' : 'assigned_details_dialog.parameters') | translate }}
                                <span>{{ i.count }}</span>
                            </p>
                        </div>
                        <button (click)="filterView(i.category, i.id)" mat-stroked-button class="mat-primary">{{ 'btn.view_details' | translate }}</button>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #modelPage>
                <ng-container *ngIf="i.count > 0">
                    <div class="list-wrap">
                        <h3>{{ i.category }} <span>{{ i.count }}</span></h3>
                        <button (click)="filterView(i.category, data.dependedCount.modelId)" mat-stroked-button class="mat-primary">
                            {{ 'btn.view_details' | translate }}
                        </button>
                    </div>
                </ng-container>
            </ng-template>
        </ng-container>
    </mat-dialog-content>

    <div id='confirm-dialog-buttons' fxLayout='row' fxLayoutAlign='end' fxLayoutGap='15px'>
        <button mat-stroked-button (click)="close()">
            {{ 'btn.cancel' | translate }}
        </button>
        <button mat-stroked-button class="mat-primary" id="confirm-btn" (click)="confirm()" *ngIf="!data.deviceCheck">
            {{ data.confirm }}
        </button>
    </div>
</div>