import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { appConstants } from 'src/app/services/constants';
import { ManageFactoryService } from 'src/app/services/manage-factory/manage-factory.service';
import { zip } from 'rxjs';
import { InvoiceFilterDialogResult } from './invoice-filter-dialog-result';
import { CustomerFilterItem, StatusFilterItem, PayPeriodFilterItem } from './invoice-filter-dialog-item';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { DateTimeService } from 'src/app/services/date-time.service';

@Component({
  selector: 'app-invoice-filter-dialog',
  templateUrl: './invoice-filter-dialog.component.html',
  styleUrls: ['./invoice-filter-dialog.component.css']
})
export class InvoiceFilterDialogComponent implements OnInit {

	customerList : CustomerFilterItem[];
	statusList : StatusFilterItem[];
	payPeriodList : PayPeriodFilterItem[];

	selectedCustomer: CustomerFilterItem;
	selectedStatus: StatusFilterItem;
	selectedStartPayPeriod: PayPeriodFilterItem;
	selectedEndPayPeriod: PayPeriodFilterItem;

	constructor(
		private manageFactory: ManageFactoryService,
		public dialogRef: MatDialogRef<InvoiceFilterDialogComponent>,
        private _dateTimeService: DateTimeService,
        private translate: TranslateService,
		@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	ngOnInit(): void {
    this._dateTimeService.getDateFormat();
		this.refreshData();
	}

	validate(): boolean {
		return false;
	}

	confirm(): void {
  		var result = new InvoiceFilterDialogResult(
  			this.selectedCustomer.id,
  			this.selectedStatus.id,
  			this.selectedStartPayPeriod.date,
  			this.selectedEndPayPeriod.date);

     	this.dialogRef.close(result);
	}

	completeStartDate(event:any) : void {

		if (event.value == null) {
			this.selectedStartPayPeriod = PayPeriodFilterItem.empty();
			this.selectedEndPayPeriod = PayPeriodFilterItem.empty();
			return;
		}

		var start = moment(event.value);
		var end = this.selectedEndPayPeriod.date == null ?
			moment(event.value) : moment(this.selectedEndPayPeriod.date);

		if (!end.isAfter(start)) {
			end = moment(event.value);
			end.add(1, 'day');
			end.endOf('day');
		}

	    this.selectedStartPayPeriod = new PayPeriodFilterItem(start.toDate());
	    this.selectedEndPayPeriod = new PayPeriodFilterItem(end.toDate());
  	}

  	completeEndDate(event:any) : void {

  		if (event.value == null) {
			this.selectedStartPayPeriod = PayPeriodFilterItem.empty();
			this.selectedEndPayPeriod = PayPeriodFilterItem.empty();
			return;
		}

  		var start = this.selectedStartPayPeriod.date == null ?
  			  moment(event.value) : moment(this.selectedStartPayPeriod.date);

  		var end = moment(event.value);

  		if (!start.isBefore(end)) {
  			start = moment(event.value);
	  		start.subtract(1, 'day');
	  		start.startOf('day');
  		}

  		end.endOf('day');

  		this.selectedStartPayPeriod = new PayPeriodFilterItem(start.toDate());
  		this.selectedEndPayPeriod = new PayPeriodFilterItem(end.toDate());
  	}

	private refreshData(): void {

		this.selectedCustomer = CustomerFilterItem.empty();
		this.selectedStatus = StatusFilterItem.empty();
		this.selectedStartPayPeriod = PayPeriodFilterItem.empty();
		this.selectedEndPayPeriod = PayPeriodFilterItem.empty();

		zip(
			this.manageFactory.clients.read(),
			this.manageFactory.invoices.getStatuses())
		.subscribe(results => {
			this.customerList = (<any[]>results[0])
				.map(acc =>  new CustomerFilterItem(acc.customer_id, acc.customer_name));

			this.statusList = (<any[]>results[1])
				.map(status => new StatusFilterItem(status.status_id, status.status_name));

			this.applyState();
		});
	}

	private applyState () : void {
		var state : InvoiceFilterDialogResult = this.data.state;

		this.selectedCustomer = this.customerList
			.filter(acc =>  acc.id == state.filterCustomerId)[0];

		if (this.selectedCustomer == null) {
			this.selectedCustomer = CustomerFilterItem.empty();
		}

		this.selectedStatus = this.statusList
			.filter(status => status.id == state.filterStatusId)[0];

		if (this.selectedStatus == null) {
			this.selectedStatus = StatusFilterItem.empty();
		}

		if (state.filterStartPayPeriod) {
			this.selectedStartPayPeriod = new PayPeriodFilterItem(state.filterStartPayPeriod);
		} else {
			this.selectedStartPayPeriod = PayPeriodFilterItem.empty();
		}

		if (state.filterEndPayPeriod) {
			this.selectedEndPayPeriod = new PayPeriodFilterItem(state.filterEndPayPeriod);
		} else {
			this.selectedEndPayPeriod = PayPeriodFilterItem.empty();
		}
	}
}
