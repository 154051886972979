import { SymEnum } from './sym_enum';
import { SymSymbol } from './sym_symbol';
import { SymVirtualVar } from './sym_virtual_variable';


export enum SymLibraryCustomDecimalSeparator {
    AUTO = 0,
    POINT = 1,
    COMMA = 2,
}

export class SymLibrary {
    public filename: string;
    public id: string|number;
    public title: string;
    public version: string;
    public can_id_mask: string;
    public comment: string;
    public is_active: boolean;
    public created_date: number;
    public changed_date: number;
    public custom_decimal_separator: SymLibraryCustomDecimalSeparator;
    public decimal_places: number;
    public has_unique_names: boolean;
    // additional fields
    public model_id: number;
    public bus_number_id: number;
    public baud_rate_id: number;
    public protocol_id: number;

    public _data: any;

    public enums: SymEnum[] = [];
    public symbols: SymSymbol[] = [];
    public virtual_variables: SymVirtualVar[] = [];
     
    constructor(data: any) {
        this._data = data;
        this.parse_data(data);
    }

    parse_data(data: any) {
        this.id = data.library_id;
        this.filename = (typeof data.file_location !== 'undefined') ? data.file_location : '';
        this.title = (typeof data.title !== 'undefined') ? data.title : '';
        this.version = data.version_number||'1.0';
        this.comment = (typeof data.comment !== 'undefined') ? data.comment : '';
        this.is_active = data.is_active;
        this.created_date = data.created_date;
        this.changed_date = data.changed_date;
        this.custom_decimal_separator = data.decimal_separator||SymLibraryCustomDecimalSeparator.AUTO;
        this.decimal_places = data.default_bit_rate||2;
        this.has_unique_names = (typeof data.has_unique_names !== 'undefined')?data.has_unique_names:true;
        this.can_id_mask = data.can_id_mask;
        this.model_id = data.model_id;
        this.bus_number_id = data.bus_number_id;
        this.baud_rate_id = data.baud_rate_id;
        this.protocol_id = data.protocol_id;

        this.enums = [];
        this.symbols = [];
        if (typeof data.enums !== 'undefined') {
            data.enums.forEach((el: any) => {
                this.enums.push(new SymEnum(el));
            });
        }
        if (typeof data.symbols !== 'undefined') {
            data.symbols.forEach((el: any) => {
                let symbol = new SymSymbol(el);
                symbol.fillEnums(this.enums);
                this.symbols.push(symbol);
            });
        }
        // fill empty(?) fields defaults
        if (typeof this.can_id_mask === 'undefined') {
            this.can_id_mask = '1FFFFFFFh';
        }
    }

    dump() {
        let rv = {
            title: this.title,
            version_number: this.version,
            file_location: this.filename,
            is_active: this.is_active ? 1 : 0,
            can_id_mask: this.can_id_mask,
            model_id : this.model_id,
            bus_number_id : this.bus_number_id,
            baud_rate_id : this.baud_rate_id,
            protocol_id : this.protocol_id,
            enums: <any>[],
            symbols: <any>[],
        };
        this.enums.forEach((e: SymEnum) => {
            rv.enums.push(e.dump());
        });
        this.symbols.forEach((s: SymSymbol) => {
            rv.symbols.push(s.dump());
        });
        return rv;
    }
}
