
export class InvoiceFilterDialogResult {
	filterCustomerId: number;
	filterStatusId: number;
	filterStartPayPeriod: Date;
	filterEndPayPeriod: Date;

	constructor (
		filterCustomerId: number = null,
		filterStatusId: number = null,
		filterStartPayPeriod: Date = null,
		filterEndPayPeriod: Date = null) {

		this.filterCustomerId = filterCustomerId;
		this.filterStatusId = filterStatusId;
		this.filterStartPayPeriod = filterStartPayPeriod;
		this.filterEndPayPeriod = filterEndPayPeriod;
	}

	isEmpty():boolean {
		return (this.filterCustomerId == null 
			&& this.filterStatusId == null 
			&& this.filterStartPayPeriod == null
			&& this.filterEndPayPeriod == null)
	}
}