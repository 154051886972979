import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ManageFactoryService } from '../../services/manage-factory/manage-factory.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarHorizontalPosition } from "@angular/material/snack-bar";
import { SNACKBAR_DURATION, SNACKBAR_POSITION, Topics, TopicsNames } from 'src/app/shared/models/common.model';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.css'],
    encapsulation: ViewEncapsulation.None

})
export class FeedbackComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<FeedbackComponent>, private manageFactory: ManageFactoryService, private router: Router, public snackBar: MatSnackBar, private translate: TranslateService) { }
    topicsArray: { key: string | Topics, name: string }[];
    form = new FormGroup({
        topic: new FormControl('', [Validators.required]),
        body: new FormControl('', [Validators.required])
    });
    sending: boolean = false;

    ngOnInit(): void {
        this.topicsArray = Object.values(Topics)
            .filter(f => Number(f))
            .map(key => ({ key, name: TopicsNames[key] }));
    }

    saveFeedback() {
        // this will start the progress bar while the feedback is sent to Slack
        this.sending = true;

        let state = (this.router.url).split('/');
        let about = {
            environment: window.location.origin,
            currentState: state.pop(),
            userEmail: sessionStorage.getItem('user'),
            firstName: sessionStorage.getItem('username'),
            customerId: +sessionStorage.getItem('customerId'),
            userRole: +sessionStorage.getItem('userRole')
        };
        const obj = {
            topic: this.form.get('topic').value,
            message: this.form.get('body').value,
            userData: about
        };

        this.manageFactory.values.sendFeedback(obj)
            .subscribe(
                result => {
                    this.snackBarAction('dialog.thanks_for_feedback');
                    this.sending = false;
                    this.closeDialog();
                }, error => {
                    this.sending = false;
                    this.snackBarAction('dialog.error_no', error['status']);
                }
            );
    }

    /**
     * Displays a snack bar message with optional error number and duration.
     * @param {string} message - The message to be displayed.
     * @param {string} [errorNo=''] - An error number associated with the message.
     */
    snackBarAction(message: string, errorNo: string = '') {
        this.translate.get(message, { error_no: errorNo }).subscribe((text: string) => {
            this.snackBar.open(text, '', {
                duration: SNACKBAR_DURATION,
                horizontalPosition: SNACKBAR_POSITION
            });
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
