<div class="scrollBelow">
    <form class="settings-form" [formGroup]="settingsForm" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">
        <div class="input">
            <table class="full-width">
                <tr>
                    <mat-icon class="form-icon">
                        people
                    </mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field class="full-width">
                            <input matInput class="form-control" formControlName="first_name" placeholder="{{'account.first_name' | translate}}">
                            <mat-error *ngIf="first_name.touched && first_name.invalid" class="alert alert-danger">
                                <mat-error *ngIf="first_name.errors.required">{{'account.first_name_required' | translate}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <input matInput class="form-control" formControlName="last_name" placeholder="{{'account.last_name' | translate}}">
                            <mat-error *ngIf="last_name.touched && last_name.invalid" class="alert alert-danger">
                                <mat-error *ngIf="last_name.errors.required">{{'account.last_name_required' | translate}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </tr>
                <tr>
                    <mat-icon class="form-icon">
                        email
                    </mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field class="full-width">
                            <input matInput class="form-control" formControlName="email" id="email"
                                placeholder="{{'account.email_address' | translate}}">
                            <mat-error *ngIf="email.touched && email.invalid" class="alert alert-danger">
                                <mat-error *ngIf="email.errors.required">{{'form.email_required' | translate}}</mat-error>
                                <mat-error *ngIf="email.errors.customEmail">{{'form.email_invalid' | translate}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </tr>
                <tr>
                    <mat-icon class="form-icon">
                        phone
                    </mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field>
                            <input matInput class="form-control" formControlName="phone" id="phone"
                                placeholder="{{'account.phone_number' | translate}}" (blur)="validatePhone()">
                            <mat-error *ngIf="phone.touched && phone.invalid" class="alert alert-danger">
                                <mat-error *ngIf="phone.errors.invalid">{{'account.phone_number_invalid' | translate}}</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </tr>
                <tr>
                    <mat-icon class="form-icon">
                        my_location
                    </mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field>
                            <mat-select placeholder="{{'account.country' | translate}}" [(ngModel)]="country" [ngModelOptions]="{standalone: true}"
                                (selectionChange)="validatePhone()" style="height:10px;margin-top:-10px;">
                                <mat-option *ngFor="let country of countryList" [value]="country.code">
                                    {{ country.code}} - {{country.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </tr>
                <tr>
                    <mat-icon class="form-icon">
                        translate
                    </mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field class="full-width" id="time">
                            <mat-select placeholder="{{'account.language' | translate}}" style="height:10px;margin-top:-10px;"
                                formControlName="language" class="form-control">
                                <mat-option *ngFor="let l of languages" [value]="l.key">
                                    {{l.text}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </tr>
                <tr class="time-format">
                    <mat-icon class="form-icon">
                        access_time
                    </mat-icon>
                    <div class="form-field-holder">
                        <mat-form-field class="full-width" id="time_format">
                            <mat-select placeholder="{{'account.time_format' | translate}}" style="height:10px;margin-top:-10px;"
                                formControlName="time_format" class="form-control">
                                <mat-option *ngFor="let x of timeFormatList" [value]="x">
                                    {{ x }} , {{ date_now | date: x }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </tr>
                <tr class="date-format">
                    <mat-icon class="form-icon">
                        date_range
                    </mat-icon>
                    <div class="form-field-holder" id="date_format">
                        <mat-form-field class="full-width" id="time">
                            <mat-select placeholder="{{'account.date_format' | translate}}" style="height:10px;margin-top:-10px;"
                                formControlName="date_format" class="form-control">
                                <mat-option *ngFor="let x of dateFormatList" [value]="x">
                                    {{ x }} , {{ date_now | date: x }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </tr>

                <tr class="local-time-row">
                  <mat-icon svgIcon="more-time"></mat-icon>
                    <div class="form-field-holder local_time">
                      <mat-checkbox
                        [formControl]="local_time"
                      >
                        {{'account.local_time' | translate}}
                      </mat-checkbox>
                    </div>
                </tr>

                <button mat-raised-button (click)="openDialog()">
                    <mat-icon>
                        settings_backup_restore
                    </mat-icon>
                    {{'account.reset_password' | translate}}
                </button>
            </table>
        </div>

        <!-- upload section  -->
        <div class="upload" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column">
            <div class="logo-upload">
                <label>{{'logo' | translate}}</label>
                <div class="image-container">
                    <img class="img" id="logo" [src]="getLogo() | secure | async">
                </div>
                <div class="imageUpload">
                    <input name="files" hidden accept="image/*" type="file" (change)="onLogoChange($event)"
                        id="logoUpload" />
                    <button mat-raised-button for="logoUpload" [disabled]="!canEdit" (click)="openLogoUpload()">
                        <mat-icon>
                            <i class="material-icons">file_upload</i>
                        </mat-icon>
                        <span>{{'btn.upload' | translate}}</span>
                    </button>
                </div>
            </div>


            <div class="icon-upload">
                <label>{{'icon' | translate}}</label>
                <div class="image-container">
                    <img class="img" id="favicon" [src]="getFavicon()">
                </div>
                <div class="imageUpload">
                    <input name="files" hidden accept="image/x-icon" type="file" (change)="onIconChange($event)"
                        id="iconUpload" />
                    <button [disabled]="!canEdit" for="iconUpload" mat-raised-button (click)="openIconInput()">
                        <mat-icon>
                            <i class="material-icons">file_upload</i>
                        </mat-icon>
                        <span>{{'btn.upload' | translate}}</span>
                    </button>
                </div>
            </div>

            <div class="theme">
                <label>
                    {{'theme' | translate}}
                </label>
                <mat-form-field>
                    <mat-select (selectionChange)="changeTheme()" formControlName="theme">
                        <mat-select-trigger>
                            <mat-icon class="mat-primary">fiber_manual_record</mat-icon><span class="aligned-with-icon">{{
                settingsForm.controls['theme'].value }}</span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let theme of themes" [value]="theme.label">
                            <mat-icon [ngStyle]="{'color': theme.hex}">fiber_manual_record </mat-icon>
                            {{theme.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>

    <div class="save-cancel">
        <button mat-raised-button (click)="cancel()">
            {{'btn.cancel' | translate}}
        </button>
        <button mat-raised-button class="mat-primary confirm" [disabled]="settingsForm.invalid"
            (click)="saveSettings()">
            {{'btn.save' | translate}}
        </button>
    </div>
</div>
