import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { IChartSymbol, IChartVariable, IChartVariableData } from 'src/app/chart/chart.model';
import { ManageFactoryService } from '../../services/manage-factory/manage-factory.service';

@Component({
    selector: 'app-variable-selection-dialog',
    templateUrl: './variable-selection-dialog.component.html',
    styleUrls: ['./variable-selection-dialog.component.scss'],
})
export class VariableSelectionDialog implements OnInit, OnDestroy {
    public selectedSymbol?: IChartSymbol;
    public symbols: IChartSymbol[];
    public alreadySelectedVariables: IChartVariableData[];
    public selectedVariables: IChartVariableData[];
    public deviceSymbols: any = [];
    public isDevicesExist: boolean = true;
    public variableOptions: IChartVariable[];

    destroy$ = new Subject();

    constructor(
        public dialogRef: MatDialogRef<VariableSelectionDialog>,
        private manageFactory: ManageFactoryService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.symbols = data.symbols ?? [];
        this.alreadySelectedVariables = data.selectedVariables ?? [];
    }

    ngOnInit() {
        if (this.symbols.length) {
            this.selectedSymbol = this.symbols[0];
            this.selectedSymbolIdFunc();
        }
        this.selectedVariables = [];
        this.manageFactory.devices.getDevicesWithReports().subscribe(devices => {
            if (devices && Array.isArray(devices) && devices.length) {
                this.deviceSymbols = this.createSymbolsGroup(devices);
                this.isDevicesExist = this.deviceSymbols && this.deviceSymbols.length ? true : false;
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    /**
     * This method is used to get the selected symbols and variables against that symbol
     */
    selectedSymbolIdFunc(): void {
        this.symbols.forEach((sym: IChartSymbol) => {
            if (this.selectedSymbol && sym.id === this.selectedSymbol.id && sym.deviceId === this.selectedSymbol.deviceId) {
                this.selectedSymbol = sym;
                this.variableOptions = sym.variables.filter(v => !this.isVariableHidden(v))
                    .map(m => ({ deviceId: sym.deviceId, ...m }));
            }
        });
    }

    /**
     * Select all the variables for the currently selected symbol, and close
     * the dialog with the result.
     */
    selectAllVariables() {
        for (let v of this.variableOptions) {
            let foundIndex: number = this.findSelectedVariableIndex(v);
            if (foundIndex < 0) {
                // Add the variable
                this.selectedVariables.push({
                    deviceId: this.selectedSymbol.deviceId,
                    symbolId: this.selectedSymbol.id,
                    symbolName: this.selectedSymbol.name,
                    variableId: v.id,
                    variableName: v.name,
                    variableUom: v.variableUom || '',
                });
            }
        }
        this.handleSave();
    }

    /**
     * Find the index of the variable in the selectedVariables array.
     * Return -1 if the variable is not selected.
     */
    findSelectedVariableIndex(v: IChartVariable): number {
        for (let i = 0; i < this.selectedVariables.length; i++) {
            let selectedVariable = this.selectedVariables[i];
            if (selectedVariable.deviceId == this.selectedSymbol.deviceId &&
                selectedVariable.symbolId == this.selectedSymbol.id &&
                selectedVariable.variableId == v.id) {
                return i;
            }
        }
        return -1;
    }

    /**
     * If the variable is not selected, select it.
     * If the variable is selected, deselect it. 
     */
    toggleVariable(v: IChartVariable) {
        let foundIndex: number = this.findSelectedVariableIndex(v);
        if (foundIndex >= 0) {
            // Remove the variable
            this.selectedVariables.splice(foundIndex, 1);
        } else {
            // Add the variable
            this.selectedVariables.push({
                deviceId: v.deviceId,
                symbolId: this.selectedSymbol.id,
                symbolName: this.selectedSymbol.name,
                variableId: v.id,
                variableName: v.name,
                variableUom: v.variableUom || '',
            });
        }
    }

    /**
     * Check if the variable is selected.
     */
    isVariableSelected(v: IChartVariable): boolean {
        return this.findSelectedVariableIndex(v) >= 0;
    }

    /**
     * Check if the variable is hidden (because it was already selected).
     */
    isVariableHidden(v: IChartVariable): boolean {
        for (let i = 0; i < this.alreadySelectedVariables.length; i++) {
            let selectedVariable = this.alreadySelectedVariables[i];
            if (selectedVariable.deviceId == this.selectedSymbol.deviceId &&
                selectedVariable.symbolId == this.selectedSymbol.id &&
                selectedVariable.variableId == v.id) {
                return true;
            }
        }
        return false;
    }

    handleSave() {
        this.dialogRef.close(this.selectedVariables);
    }

    handleCancel(): void {
        this.dialogRef.close();
    }

    createSymbolsGroup(devices: any[]) {
        devices.forEach(device => {
            device.symbols = this.symbols.filter(f => f.deviceId === device.device_id);
        });
        return devices.filter(a => a.symbols.length !== 0);
    }
}
