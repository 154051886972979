<mat-card class='mat-elevation-z0'>

    <div *ngIf="chart.loading" class="loader-holder">
        <div class="loader"></div>
    </div>

    <ng-container [ngSwitch]="chart?.type">

        <ng-template [ngSwitchCase]="chartType.MAP">
            
            <ng-container *ngIf="!chart.chartPoints || !chart.chartPoints.length; else elseGenerateMap">
                <h2 class="empty-data">{{ 'reports.no_gps_data_text' | translate }}</h2>
            </ng-container>
            <ng-template #elseGenerateMap>
                <map-log [data]="chart.chartPoints" (mapLoaded)="checkCardLoading($event)"></map-log>
            </ng-template>
            
        </ng-template>

        <ng-template [ngSwitchCase]="chartType.TABLE">
            <data-table [data]="chart.chartPoints" [labels]="chart.labels"></data-table>
        </ng-template>

        <ng-template [ngSwitchCase]="chartType.LINE">
            <div class="data-stats">
                <ul>
                    <li *ngFor="let stat of minMaxArray">
                        <span class="variable-name">{{ stat.selectedVariable }}</span>
                        <p>{{ 'reports.min_value' | translate}}: <span>{{ stat.min ?? '-' }}</span></p>
                        <p>{{ 'reports.max_value' | translate}}: <span>{{ stat.max ?? '-' }}</span></p>
                        <p>{{ 'reports.mean_value' | translate}}: <span>{{ stat.mean ?? '-' }}</span></p>
                    </li>
                </ul>
            </div>
            <line-chart #eLineChart [index]="index" [data]="chart.echartData" [labels]="chart.labels" [chartAxisView]="timesView"
                [thresholds]="chart.chartThresholds" (filteredData)="calculateMinMax($event)" (zoomApplied)="checkZoom($event)" (chartLoaded)="checkCardLoading($event)">
            </line-chart>
        </ng-template>

    </ng-container>

    <div *ngIf="percentageFrequency && percentageFrequency.length" class="distribution-wrap">
        <h4><strong>{{ 'reports.frequency_distribution_text' | translate }}{{ selectedVariableName }}</strong></h4>
        <div class="table-wrap">
            <table CELLSPACING=0 CELLPADDING=0>
                <thead>
                    <tr>
                        <th>{{ 'frequency_distribution.range_heading' | translate }}</th>
                        <th>{{ 'frequency_distribution.frequency_heading' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let frequency of percentageFrequency">
                        <td>{{ frequency.range }}</td>
                        <td>{{ frequency.value }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <mat-toolbar *ngIf="showToolBar">
        <span class="spacer"></span>
        <button mat-icon-button [matMenuTriggerFor]="gridSubMenu">
            <mat-icon [matTooltip]="'reports.grid_size' | translate">
                grid_on
            </mat-icon>
        </button>
        <mat-menu #gridSubMenu="matMenu">
            <button mat-menu-item (click)="handleInnerColumnWidth(grid)" *ngFor="let grid of gridColumnList">
                {{ 'chart_cards.column_label' | translate: {columnValue: grid} }}
            </button>
        </mat-menu>
        <mat-slide-toggle class="mat-primary" *ngIf="chart.type === chartType.LINE" (change)="toggleChartView()">
            <span class="echart-type" *ngIf='timesView; else categoryView'>
                {{ 'chart_cards.hide_empty_regions' | translate }}
            </span>
            <ng-template #categoryView>
                <span class="echart-type">
                    {{ 'chart_cards.show_empty_regions' | translate }}
                </span>
            </ng-template>
        </mat-slide-toggle>
        <button mat-icon-button *ngIf="chart?.labels && chart?.chartPoints?.length > 0 && (chart?.type === chartType.LINE || chart?.type === chartType.TABLE)"
            [matTooltip]="'reports.calculate_frequency_distribution' | translate" (click)="checkPercentageDistribution()">
            <mat-icon>equalizer</mat-icon>
        </button>
        <button *ngIf="chart.type === chartType.LINE" mat-icon-button [disabled]="!isZoomApplied"
            [matTooltip]="'reports.clear_chart_zoom' | translate" (click)="resetChartData()">
            <mat-icon>zoom_out</mat-icon>
        </button>
        <button *ngIf="chart.labels && chart.chartPoints.length > 0" mat-icon-button
            matTooltip="{{'btn.export' | translate}}" (click)="exportToCsv(chart)">
            <mat-icon svgIcon="download"></mat-icon>
        </button>
        <button *ngIf="chart.type === chartType.LINE" mat-icon-button
            matTooltip="{{'reports.toggle_y_axis_scale' | translate}}" (click)="toggleScale()">
            <mat-icon svgIcon="arrow-expand-vertical"></mat-icon>
        </button>
        <button *ngIf='!isReadOnly' mat-icon-button matTooltip="{{'btn.edit' | translate}}"
            (click)="editChart()">
            <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf='!isReadOnly' mat-icon-button matTooltip="{{'btn.delete' | translate}}"
            (click)="deleteChart()">
            <mat-icon>delete</mat-icon>
        </button>
    </mat-toolbar>
</mat-card>