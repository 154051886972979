import {
    FormGroup,
    FormControl,
    Validators,
    FormBuilder,
    AbstractControl
} from "@angular/forms";
import { EditorPayPeriod } from "./editor-pay-period";
import { EditorCustomer } from "./editor-customer";
import { EditorYear } from "./editor-year";
import { tap } from "rxjs/operators";
import { Subject } from "rxjs";

export interface InvoiceEditorForm {
    year: EditorYear;
    customer: EditorCustomer;
    payPeriod: EditorPayPeriod;
    overdue: boolean;
    shutoff: boolean;
    sendInvoice: boolean;
    paid: boolean;
}

export class InvoiceEditorReactiveForm implements InvoiceEditorForm {
    private _group: FormGroup;

    readonly YEAR: string = "year";
    readonly PAY_PERIOD: string = "payPeriod";
    readonly CUSTOMER: string = "customer";
    readonly OVERDUE: string = "overdue";
    readonly SHUTOFF: string = "shutoff";
    readonly SEND_INVOICE: string = "send_invoice";
    readonly PAID: string = "paid";

    constructor(private readonly fb: FormBuilder) {
        this._group = this.fb.group({
            year: new FormControl(EditorYear.empty(), [Validators.required]),
            payPeriod: new FormControl(
                { value: EditorPayPeriod.empty(), disabled: true },
                [Validators.required]
            ),
            customer: new FormControl(EditorCustomer.empty(), [
                Validators.required
            ]),
            overdue: new FormControl(false),
            shutoff: new FormControl(false),
            paid: new FormControl({ value: false, disabled: false }),
            sendInvoice: new FormControl({ value: false, disabled: false })
        });
    }

    get value() {
      return this._group.value;
    }

    get group(): FormGroup {
        return this._group;
    }

    get year(): EditorYear {
        return this._group.get(this.YEAR).value;
    }

    get yearControl(): AbstractControl {
        return this._group.get(this.YEAR);
    }

    get payPeriod(): EditorPayPeriod {
        return this._group.get(this.PAY_PERIOD).value;
    }

    get payPeriodControl(): AbstractControl {
        return this._group.get(this.PAY_PERIOD);
    }

    get payPeriodDisabled(): boolean {
        return this._group.get(this.PAY_PERIOD).disabled;
    }

    get customer(): EditorCustomer {
        return this._group.get(this.CUSTOMER).value;
    }

    get customerControl(): AbstractControl {
        return this._group.get(this.CUSTOMER);
    }

    get overdue(): boolean {
        return this._group.get(this.OVERDUE).value;
    }

    get shutoff(): boolean {
        return this._group.get(this.SHUTOFF).value;
    }

    get sendInvoice(): boolean {
        return this._group.get(this.SEND_INVOICE).value;
    }

    get paid(): boolean {
        return this._group.get(this.PAID).value;
    }

    set year(value: EditorYear) {
        this._group.get(this.YEAR).setValue(value);
    }

    set payPeriod(value: EditorPayPeriod) {
        this._group.get(this.PAY_PERIOD).setValue(value);
    }

    set customer(value: EditorCustomer) {
        this._group.get(this.CUSTOMER).setValue(value);
    }

    set payPeriodDisabled(value: boolean) {
        if (value) {
            this._group.get(this.PAY_PERIOD).reset();
            this._group.get(this.PAY_PERIOD).disable();
        } else {
            this._group.get(this.PAY_PERIOD).enable();
        }
    }

    set overdue(value: boolean) {
        this._group.get(this.OVERDUE).setValue(value);
    }

    set shutoff(value: boolean) {
        this._group.get(this.SHUTOFF).setValue(value);
    }

    set sendInvoice(value: boolean) {
        this._group.get(this.SEND_INVOICE).setValue(value);
    }

    set paid(value: boolean) {
        this._group.get(this.PAID).setValue(value);
    }

    get valid(): boolean {
        return (
            this.year.value != null &&
            (this.payPeriod != null && this.payPeriod.monthDay != null) &&
            (this.customer != null && this.customer.value != null)
        );
    }
}
