<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content class='mat-typography' (keydown.enter)='handleSave()'>
    <mat-form-field class='full-width'>
        <mat-label>{{'chart_setup.select_chart_type' | translate}}</mat-label>
        <mat-select name='symbol' [formControl]='chartTypeSelect'>
            <mat-option *ngFor='let type of chartTypes' [value]='type.id'>
                {{type.name | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class='centered-container'>
        <button mat-flat-button color='primary' (click)='addVariables()' [disabled]="!symbols || !symbols.length || selectedVariables.length >= variablesCount">
            <mat-icon svgIcon='plus' class='btn-icon'></mat-icon>
            <span>{{'chart_setup.add_variables' | translate}}</span>
        </button>
    </div>
    <div class='centered-container'>
        <mat-error *ngIf="selectedVariables.length >= variablesCount">{{ 'chart_setup.variable_error_msg' | translate }} {{ variablesCount }}</mat-error>
        <mat-error *ngIf="!symbols || !symbols.length">{{'chart_setup.no_symbol_found' | translate}}</mat-error>
    </div>
    <div *ngIf="chartTypeSelect.value == 0">
        <div class="threshold-heading">
            <h3>{{ 'chart_setup.chart_threshold' | translate }}</h3>
            <button mat-button (click)='addThreshold()' [matTooltip]="'chart_setup.add_threshold' | translate" [disabled]="thresholdFormArray.controls.length == thresholdCount">
                <mat-icon>add_circle_outline</mat-icon>
            </button>
        </div>
        <form [formGroup]="thresholdForm" class="threshold-form">
            <div formArrayName="threshold">
                <div class="threshold-fields" *ngFor="let threshold of thresholdFormArray.controls; let i = index" [formGroupName]="i">
                    <mat-form-field>
                        <input matInput type="number" [placeholder]="'chart_setup.chart_threshold_placeholder' | translate" formControlName="yAxis">
                    </mat-form-field>
                    <button mat-icon-button type="button" [matTooltip]="'chart_setup.delete_threshold' | translate" (click)="removeThreshold(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class='list-container'>
        <div cdkDropList class='variable-list'
             (cdkDropListDropped)='drop($event)'>
            <div class='item-shape variable-box'
                 *ngFor='let variable of selectedVariables; let i = index' cdkDrag
                 cdkDragPreviewContainer='parent'
                 cdkDragBoundary='.list-container' cdkDragLockAxis='y'>
                <div class='item-shape variable-placeholder'
                     *cdkDragPlaceholder>
                </div>
                <div class='variable-container' fxLayout='row'
                     fxLayoutAlign='start center'>
                    <div class='drag-handle' cdkDragHandle
                         fxFlexAlign='stretch'>
                        <mat-icon svgIcon='drag-horizontal'>
                        </mat-icon>
                    </div>
                    <div class='variable-body' fxLayout='column'>
                        <div class='variable-name'>{{ variable.variableName + '-' + (i + 1) }}
                        </div>
                        <div class='symbol-name'>{{ variable.symbolName }}</div>
                    </div>
                    <button class='variable-delete' mat-icon-button color='warn'
                            (click)='removeVariable(variable)'>
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align='center'>
    <button mat-button (click)='handleCancel()'>
        {{'btn.cancel' | translate}}
    </button>
    <button mat-flat-button color='primary' (click)='handleSave()'
            [disabled]='thresholdForm.invalid || selectedVariables.length==0'>
        {{'btn.ok' | translate}}
    </button>
</div>