export const ParameterId = 'parameterId';
export const OperatorName = 'operatorName';
export const MessageName = 'messageName';
export const OperatorValue = 'operatorValue';
export const ParameterValue = 'parameterValue';
export const ParameterName = 'parameterName';
export const Devices = 'Devices';
export const Parameters = 'parameters';
export const Smooth = 'smooth';
export const PivotContainer = '.pivot-container';
export const Time = 'Time';
export const _statistics_ = '_statistics_';
export const Timestamp = 'timestamp';
export const Year = 'Year';
export const Month = 'Month';
export const date = 'Date';
export const InnerWidth = 'innerWidth';
export const ModelName = 'modelName';
export const Select = 'select';
export const IOS = 'iOS';
export const PageSize = '50';
export const ToolbarDialog = 'toolbar-dialog';
export const ToolbarWidth = '450px';
export const Utc = '(UTC)';
export const Px = 'px';
export const DeviceViewPort = '0px';
export const InverseViewPort = '-0px';
export const ModelId = 'modelId';
export const Warning = 'warning';
export const New = 'new';
export const Update = 'Update';
export const Save = 'Save';
export const Id = 'id';
export const Counts = 'counts';
export const Count = 'count';
export const CustomerId = 'customerId';
export const CustomerRole = 'customerRole';
export const DevicesFilter = 'devicesFilter';
export const ParametersFilter = 'parametersFilter';
export const Slice = 'slice';
export const MonthFormat = 'MMMM';
export const InfoMessage = '.info-message';
export const OemId = 'oem_id';
export const DefaultColumn = 'id';
export const DefaultOrder = 'desc';
export const MaxScroll = 200;
export const MinScroll = 60;
export const ItemSize = 48;
export const MinWidth = 650;
export const Offset = 48;
export const PageLengthOptions = [10, 25, 50, 100];
