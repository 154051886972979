import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { IChartSymbol, IChartVariableData } from 'src/app/chart/chart.model';
import { ChartType } from '../../log-chart/model/log-chart.model';
import { VariableSelectionDialog } from '../variable-selection-dialog/variable-selection-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-chart-setup-dialog',
    templateUrl: './chart-setup-dialog.component.html',
    styleUrls: ['./chart-setup-dialog.component.scss']
})
export class ChartSetupDialogComponent implements OnInit, OnDestroy {

    public title: string;
    public type: ChartType = ChartType.LINE;
    public selectedSymbol?: IChartSymbol;
    public symbols: IChartSymbol[];
    public selectedVariables: IChartVariableData[];
    public variablesCount: number = 8;
    public thresholdForm: FormGroup;
    public thresholdCount: number = 6;
    public columnWidth: string;
    public defaultColumnWidth: string = "100%";

    public chartTypes = [{
        name: 'chart_types.line',
        id: ChartType.LINE,
    }, {
        name: 'chart_types.table',
        id: ChartType.TABLE
    }, {
        name: 'chart_types.map',
        id: ChartType.MAP
    }];
    destroy$ = new Subject();
    chartTypeSelect = new FormControl(ChartType.LINE);

    @Input()
    set selectedSymbolId(_sel: any) {
        this.symbols.forEach((sym: any) => {
            if (sym.id === _sel) {
                this.selectedSymbol = sym;
            }
        });
    }

    get selectedSymbolId() {
        return this.selectedSymbol.id;
    }

    constructor(
        public dialogRef: MatDialogRef<ChartSetupDialogComponent>,
        private translate: TranslateService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.symbols = data.symbols;
        if (data.type != undefined) {
            this.type = data.type;
        }
        let titleTerm = data.selected ? 'title.edit_chart' : 'title.new_chart';
        this.translate.get(titleTerm).subscribe((title: string) => {
            this.title = title;
        });
        this.initThresholdForm();
        if (data.chartThresholds) {
            for (let threshold of data.chartThresholds) {
                this.addThreshold(threshold.yAxis);
            }
        }
        this.columnWidth = data.columnWidth ? data.columnWidth : this.defaultColumnWidth; 
    }

    ngOnInit() {
        this.chartTypeSelect = new FormControl(this.type);

        if (this.symbols.length) {
            this.selectedSymbolId = this.symbols[0].id;
        }
        this.selectedVariables = [];
        if (this.data.selected) {
            let selectedSymbol = undefined;
            for (let i = (this.data.selected.length - 1); i >= 0; i--) {
                for (let s of this.symbols) {
                    for (let v of s.variables) {
                        if (v.id === this.data.selected[i].variableId) {
                            selectedSymbol = s;
                            break;
                        }
                    }
                    if (selectedSymbol) {
                        break;
                    }
                }
            }
            if (typeof selectedSymbol !== 'undefined') {
                this.selectedSymbol = selectedSymbol;
            }
            this.selectedVariables = Array.from(this.data.selected);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    /**
     * Open the VariableSelectionDialog to select variables to add.
     */
    addVariables() {
        let dialogData = {
            width: '450px',
            data: {
                symbols: this.symbols,
                selectedVariables: this.selectedVariables
            }
        };
        this.dialog.open(VariableSelectionDialog, dialogData)
            .afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                if (data) {
                    this.selectedVariables.push(...data);
                }
                if (this.selectedVariables.length > this.variablesCount) {
                    this.selectedVariables = this.selectedVariables.slice(0, this.variablesCount);
                }
            });
    }

    /**
     * Handle a reordering event in the selected variables list.
     */
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.selectedVariables, event.previousIndex, event.currentIndex);
    }

    /**
     * Remove the given selected variable.
     */
    removeVariable(variable: IChartVariableData) {
        let index = this.selectedVariables.indexOf(variable);
        this.selectedVariables.splice(index, 1);
    }

    handleSave() {
        if (this.thresholdForm.invalid) {
            return;
        }

        const type = this.chartTypeSelect.value;
        const selectedVariables = this.selectedVariables;
        const chartThresholds = this.thresholdFormArray.value;
        const columnWidth: string = this.columnWidth;
        this.dialogRef.close({ type, selectedVariables, chartThresholds, columnWidth });
    }

    handleCancel(): void {
        this.dialogRef.close();
    }

    private initThresholdForm(): void {
        const threshold = new FormArray([]);
        this.thresholdForm = new FormGroup({
            'threshold': threshold
        });
    }

    get thresholdFormArray(): FormArray {
        return (this.thresholdForm.controls['threshold'] as FormArray);
    }

    removeThreshold(value: number): void {
        this.thresholdFormArray.removeAt(value);
    }

    addThreshold(value: string = ''): void {
        this.thresholdFormArray.push(
            new FormGroup({
                'yAxis': new FormControl(value, Validators.required)
            })
        );
    }
}
