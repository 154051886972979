<mat-toolbar id="debug-log-toolbar">
    <span [hidden]="!dataSource.isLoading">{{'debug_log.loading' | translate}}</span>
    <span class="spacer"></span>
    <span class="search-result-counter" [hidden]="searchResults.length == 0">({{searchResultIndex +
        1}}/{{searchResults.length}})</span>
    <button mat-icon-button [disabled]="searchResults.length < 2" (click)="previousSearchResult()">
        <mat-icon matTooltip="{{'debug_log.previous_result' | translate}}">arrow_upward</mat-icon>
    </button>
    <button mat-icon-button [disabled]="searchResults.length < 2" (click)="nextSearchResult()">
        <mat-icon matTooltip="{{'debug_log.next_result' | translate}}">arrow_downward</mat-icon>
    </button>
</mat-toolbar>

<div #tableContainer class="table-container scrollBelow">
    <cdk-virtual-scroll-viewport #viewPort itemSize="20" [style.width]="scrollWidth + 'px'"
        [style.height]="scrollHeight + 'px'">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="line-number">
                <th mat-header-cell *matHeaderCellDef>#</th>
                <td mat-cell *matCellDef="let line">{{line.index + 1}}</td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let line">{{line.date}}</td>
            </ng-container>
            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef>Time</th>
                <td mat-cell *matCellDef="let line">{{line.time}}</td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let line">{{line.type}}</td>
            </ng-container>
            <ng-container matColumnDef="method">
                <th mat-header-cell *matHeaderCellDef>Method</th>
                <td mat-cell *matCellDef="let line">{{line.method}}</td>
            </ng-container>
            <ng-container matColumnDef="message">
                <th mat-header-cell *matHeaderCellDef>Message</th>
                <td mat-cell *matCellDef="let line" [innerHtml]="highlight(line.message)"></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" id="line-{{row.index}}"
                [ngStyle]="rowStyle(row.index)"></tr>
        </table>
    </cdk-virtual-scroll-viewport>
</div>
