<mat-toolbar class="mat-primary">
    <div>
        <h2>{{'dialog.enter_your_new_password' | translate}}</h2>
    </div>
    <span class="spacer"></span>
    <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>
            close
        </mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content layout="row" [formGroup]="form">
    <div>
        <mat-icon class="form-icon">
            lock
        </mat-icon>
        <mat-form-field class="full-width">
            <input matInput class="form-control" type="password" formControlName="currentPassword"
                placeholder="{{'dialog.current_password' | translate}}">
            <mat-error *ngIf="current.touched && current.invalid" class="alert alert-danger">
                <mat-error *ngIf="current.errors.required">{{'dialog.current_password_required' | translate}}</mat-error>
                <mat-error *ngIf="current.errors.incorrect">{{'dialog.current_password_incorrect' | translate}}</mat-error>
            </mat-error>
        </mat-form-field>
    </div>

    <div>
        <mat-icon class="form-icon">
            lock
        </mat-icon>
        <mat-form-field class="full-width">
            <input matInput class="form-control" type="password" formControlName="newPassword"
                placeholder="{{'reset_password.new_password' | translate}}" (blur)="isOld()">
            <mat-error *ngIf="new.touched && new.invalid" class="alert alert-danger">
                <mat-error *ngIf="new.errors.required">{{'form.password_is_required' | translate}}</mat-error>
                <mat-error *ngIf="new.errors.notNew">{{'form.new_password_cant_match_current' | translate}}</mat-error>
            </mat-error>
        </mat-form-field>
    </div>

    <div>
        <mat-icon class="form-icon">
            lock
        </mat-icon>
        <mat-form-field class="full-width">
            <input matInput class="form-control" type="password" formControlName="confirmPassword"
                placeholder="{{'reset_password.confirm_password' | translate}}" pattern="{{new.value}}" minlength="8" required>
            <mat-error *ngIf="confirm.touched && confirm.invalid" class="alert alert-danger">
                <mat-error *ngIf="confirm.errors.required">{{'form.confirm_password_required' | translate}}</mat-error>
            </mat-error>
        </mat-form-field>
    </div>

    <div style="margin-top:20px" class="form-group col-md-12">{{'reset_password.description_p1' | translate}}
        <span [ngStyle]="{ 'color' : !isMatch() ? 'red' : 'green' }">{{'reset_password.description_p2' | translate}}</span>{{'reset_password.description_p3' | translate}}
        <span [ngStyle]="{ 'color' : new.hasError('minlength') ? 'red' : 'green' }">{{'reset_password.description_p4' | translate}}</span>{{'reset_password.description_p5' | translate}}
        <br />
        <ul>
            <li [ngStyle]="{ 'color' : !hasUpper() && new.touched  ? 'red' : 'green' }">
                {{'reset_password.warning.upper' | translate}}
            </li>
            <li [ngStyle]="{ 'color' : !hasLower() && new.touched ? 'red' : 'green' }">
                {{'reset_password.warning.lower' | translate}}
            </li>
            <li [ngStyle]="{ 'color' : !hasNumber() && new.touched ? 'red' : 'green' }">
                {{'reset_password.warning.number' | translate}}
            </li>
            <li [ngStyle]="{ 'color' : !hasSpecial() && new.touched ? 'red' : 'green' }">
                {{'reset_password.warning.special_character' | translate}}
            </li>
        </ul>
    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()">
        {{'btn.cancel' | translate}}
    </button>
    <button mat-button class="mat-primary confirm" (click)="savePassword()"
        [disabled]="!getRequirements() || !isMatch() || new.invalid">
        {{'btn.update' | translate}}
    </button>
</mat-dialog-actions>