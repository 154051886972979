<mat-toolbar class="mat-primary">
    <h2>{{(isSym ? 'dialog.symbol_file' : 'dialog.firmware_file') | translate}}</h2>
    <span class="spacer"></span>
    <button mat-icon-button (click)="cancel()">
        <mat-icon>
            close
        </mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <div class="symbolContent">
        <h4>{{'dialog.file_attributes_for' | translate}} {{ item.file_location | fileName }}</h4>
        <mat-form-field>
            <input matInput [(ngModel)]="item.version_number" placeholder="Version">
        </mat-form-field>
        <mat-form-field *ngIf="isSym">
            <mat-select [(ngModel)]="item.bus_number_id" placeholder="{{'model_editor.bus_number' | translate}}" (selectionChange)="checkActiveLibrary($event)">
                <mat-option *ngFor="let bus of busNumberArray" [value]="bus.bus_number_id">
                    {{ bus.bus_number_type }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-error *ngIf="isActiveLibPresent && isSym">{{ 'model_editor.bus_selection_error' | translate }}</mat-error>
        <mat-form-field *ngIf="isSym && item.bus_number_id != null">
            <mat-select [(ngModel)]="item.baud_rate_id" placeholder="{{'model_editor.baund_rate' | translate}}">
                <mat-option *ngFor="let baud of baudRateArray" [value]="baud.baud_rate_id">
                    {{ baud.baud_rate_value }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isSym">
            <mat-select [(ngModel)]="item.protocol_id" placeholder="{{'model_editor.protocol' | translate}}">
                <mat-option *ngFor="let proto of protocolArray" [value]="proto.protocol_ref_id">
                    {{ proto.protocol_name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-checkbox class="active-checkbox" [(ngModel)]="item.is_active"
            (ngModelChange)="isSym ? checkAssociation() : showAlert(item.is_active)">
            {{ 'dialog.active' | translate }}
        </mat-checkbox>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="cancel()">
        {{'btn.cancel' | translate}}
    </button>
    <button mat-button class="mat-primary confirm"
        [disabled]="!item.version_number || (isSym && item.bus_number_id === null) || (isSym && !item.protocol_id) || (isSym && !item.baud_rate_id) || isActiveLibPresent"
        (click)="confirm()">
        {{'btn.apply' | translate}}
    </button>
</mat-dialog-actions>