import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ManageFactoryService } from '../../../services/manage-factory/manage-factory.service';
import { UserBrowserInfo } from '../../model/user_browser_info';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CsvExportService } from 'src/app/services/csv-export/csv-export.service';
import { ChartType } from '../../model/log-chart.model';
import { Chart, IChartThreshold, IChartVariableData } from 'src/app/chart/chart.model';
import { ChartCardComponent } from 'src/app/chart/chart-card/chart-card.component';
import { ChartSetupDialogComponent } from '../../../dialogs/chart-setup-dialog/chart-setup-dialog.component';
import { SharedService } from '../../../services/shared/shared.service';

@Component({
    selector: 'log-chart',
    templateUrl: './log-chart.component.html',
    styleUrls: ['./log-chart.component.scss'],
    providers: [
        UserBrowserInfo
    ],
})
export class LogChartComponent implements OnInit, OnDestroy {

    @ViewChildren('logChart') logCharts: QueryList<ChartCardComponent>;

    private id: number;
    public type = '';
    public symbols: any;
    public charts: Chart[] = [];
    destroy$ = new Subject();
    mapData: any;
    chartType = ChartType;

    constructor(private activateRoute: ActivatedRoute,
        public browserInfo: UserBrowserInfo,
        public dialog: MatDialog,
        public csvExportService: CsvExportService,
        private manageFactory: ManageFactoryService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private router: Router,
        private sharedService: SharedService
    ) {
    }

    ngOnInit() {
        this.activateRoute.params.subscribe((params: any) => {
            this.id = params['id'];

            setTimeout(() => {
                this.update();
            }, 100);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    update() {
        this.translate.get('log_charts.loading').subscribe((text: string) => {
            this.snackBar.open(text);
        });
        this.manageFactory.traces.readInfo(this.id).subscribe(
            (res: any) => {
                if (res.status == 200) {
                    this.type = res.data.type;
                    if (this.type == 'debug') {
                        // redirect to debug log viewer.
                        this.router.navigate([`/debug-log/${this.id}`]);
                        return;
                    }
                    this.symbols = res.data.symbols;
                } else {
                    this.symbols = [];
                }

                if (this.type == 'log') {
                    this.snackBar.dismiss();
                    this.addBtnClick();
                } else if (this.type == 'gps') {
                    this.manageFactory.reports
                        .getGps({ id: this.id }).subscribe(
                            (res: any) => {
                                this.addGpsChart(res);
                                this.snackBar.dismiss();
                            }
                        );
                }
            },
            (err: any) => {
                this.symbols = [];
                console.log(err);
                this.snackBar.dismiss();
            });
    }

    addBtnClick() {
        this.getDialog(null)
            .afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                if (data) {
                    const { selectedVariables, type, chartThresholds, columnWidth } = data;
                    const chart = new Chart(type, selectedVariables, chartThresholds, columnWidth,
                        (chart) => {
                            this.readValues(chart);
                        });
                    chart.id = this.id;
                    chart.load();
                    this.charts.push(chart);
                }
            });
    }

    addGpsChart(points) {
        const map = new Chart(ChartType.MAP, [], [], '', (chart) => {
            this.readValues(chart);
        });
        map.id = this.id;
        this.charts.push(map);
        let output = [];
        points.forEach(element => {
            output.push([new Date(element.timestamp), element.lat, element.lon]);
        });
        map.chartPoints = output;
    }

    editChart(chart) {
        this.getDialog(chart.selectedVariables, chart.type, chart.chartThresholds)
            .afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                if (data) {
                    const { selectedVariables, type, chartThresholds } = data;
                    chart.type = type;
                    chart.selectedVariables = selectedVariables;
                    chart.chartThresholds = chartThresholds;
                    chart.load();
                }
            });
    }

    deleteChart(index) {
        this.charts.splice(index, 1);
    }

    getDialog(selected?: IChartVariableData[], type?: ChartType, chartThresholds?: IChartThreshold[]) {
        return this.dialog.open(ChartSetupDialogComponent, {
            width: '450px',
            data: { symbols: this.symbols, type: type, selected, chartThresholds: chartThresholds }
        });
    }

    readValues(chart) {
        let variableIds = [];
        let labels = [];
        for (let variableData of chart.selectedVariables) {
            variableIds.push(variableData.variableId);
            labels.push(chart.variableName(variableData));
        }
        if (variableIds.length) {
            this.manageFactory.traces.readValues(chart.id, variableIds)
                .subscribe((res: any) => {
                    const points = res.data.map(p =>
                        ({ id: p.id, values: p.values.map(this.roundOfValues()) })
                    );
                    chart.echartData = points;
                    chart.chartPoints = chart.convertPoints(points);
                    chart.labels = labels;
                    if (chart.echartData) {
                        this.sharedService.isEchartDataAvailable$.next(true);
                    }
                });
        }
    }

    private roundOfValues(): any {
        return v => typeof v[1] === 'number' ? [v[0], this.sharedService.roundNumber(v[1])] : v;
    }
}
