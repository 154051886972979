import { Component, Input, Output, EventEmitter } from "@angular/core";
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from "@angular/material/tooltip";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { SelectionModel } from "@angular/cdk/collections";
import { TranslateService } from '@ngx-translate/core';

import { SymElementType } from "../../model/types";
import { SymEnum, SymEnumValue } from "../../model/sym_enum";
import {
    SymSymbolVariable,
    SymSymbolVaribaleType
} from "../../model/sym_symbol_variable";
import { SymSymbol } from "../../model/sym_symbol";
import { SymLibrary } from "../../model/sym_library";
import { UserBrowserInfo } from "../../model/user_browser_info";
import { TooltipDefaults } from "../../model/tooltip_defaults";
import { EnumEditPropertyValueDialog } from "../../dialogs/enum-edit-property-value.component";

// SWIPE component: should be moved there
import {
    trigger,
    style,
    keyframes,
    transition,
    animate,
    query,
    stagger
} from "@angular/animations";

const Constants = {
    DEFAULT_SLIDE_THRESHOLD: 40,
    NUMBER_OF_DELETE_ICONS: 2,
    DEFAULT_CLASS_NAME: `ngstd-main-canvas`
};
// SWIPE component: should be moved there

@Component({
    selector: "enum-prop",
    templateUrl: "./enum-properties.component.html",
    styleUrls: ["./enum-properties.component.css"],
    providers: [
        UserBrowserInfo,
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: TooltipDefaults }
    ],
    // SWIPE component: should be moved there
    animations: [
        trigger("slideLeft", [
            transition(
                "* => *",
                animate(
                    100,
                    keyframes([
                        style({ left: "*", offset: 0 }),
                        style({ left: "0", offset: 1 })
                    ])
                )
            )
        ])
    ]
    // SWIPE component: should be moved there
})
export class EnumPropComponent {
    @Input() public sym_lib: SymLibrary;
    private _selected_enum: SymEnum;

    @Output() onAddEnumValue = new EventEmitter<SymEnum>();

    enumValuesDataDisplayedColumns: string[] = [
        "select",
        "value",
        "title",
        "comment"
    ];
    enumValuesDataSource = new MatTableDataSource<SymEnumValue>();
    enumSelection = new SelectionModel<SymEnumValue>(true, []);

    enumUsedVarsDataDisplayedColumns: string[] = ["name"];
    enumUsedVarsDataSource = new MatTableDataSource<SymSymbolVariable>();

    constructor(
        public browserInfo: UserBrowserInfo,
        public enumEditPropDialog: MatDialog,
        private translate: TranslateService
    ) {
        if (this.browserInfo.is_mobile)
            this.enumValuesDataDisplayedColumns.splice(0, 1);
    }

    ngOnInit() {}

    @Input()
    set selected_enum(selected_enum: SymEnum) {
        this._selected_enum = selected_enum;
        if (this._selected_enum) {
            this.enumSelection.clear();
            this.enumValuesDataSource.data = this._selected_enum.values;

            let e_tbl_data: SymSymbolVariable[] = [];
            for (let i = 0; i < this.sym_lib.symbols.length; i++) {
                for (
                    let j = 0;
                    j < this.sym_lib.symbols[i].variables.length;
                    j++
                ) {
                    if (
                        this.sym_lib.symbols[i].variables[j].data_type ===
                            SymSymbolVaribaleType.ENUM &&
                        this.sym_lib.symbols[i].variables[j]._data.data_type ===
                            this._selected_enum.name
                    ) {
                        e_tbl_data.push(this.sym_lib.symbols[i].variables[j]);
                    }
                }
            }
            this.enumUsedVarsDataSource.data = e_tbl_data;
        }
    }
    get selected_enum() {
        return this._selected_enum;
    }

    isAllSelected() {
        const numSelected = this.enumSelection.selected.length;
        const numRows = this.enumValuesDataSource.data.length;
        return numSelected === numRows;
    }
    masterToggle() {
        this.isAllSelected()
            ? this.enumSelection.clear()
            : this.enumValuesDataSource.data.forEach(row =>
                  this.enumSelection.select(row)
              );
    }
    checkboxLabel(row?: SymEnumValue): string {
        if (!row) {
            return `${this.isAllSelected() ? "select" : "deselect"} all`;
        }
        return `${
            this.enumSelection.isSelected(row) ? "deselect" : "select"
        } row ${row.value1 + 1}`;
    }

    tableEnumRowClick(row: any) {
        const dialogRef = this.enumEditPropDialog.open(
            EnumEditPropertyValueDialog,
            {
                panelClass: "toolbar-dialog",
                width: "450px",
                data: row.copy()
            }
        );

        dialogRef.afterClosed().subscribe(result => {
            console.log("The dialog was closed", result);
            if (result) {
                row.value1 = result.value1;
                row.name = result.name;
                row.comment = result.comment;
            }
        });
    }

    addBtnClick() {
        const dialogRef = this.enumEditPropDialog.open(
            EnumEditPropertyValueDialog,
            {
                panelClass: "toolbar-dialog",
                width: "450px",
                data: new SymEnumValue({})
            }
        );

        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.selected_enum.values.push(data);

                this.enumValuesDataSource.data = this._selected_enum.values;

                if (this.onAddEnumValue) {
                    this.onAddEnumValue.emit(this.selected_enum);
                }
            }
        });
    }

    deleteBtnClick() {
        let sel = this.enumSelection.selected;
        if (sel.length) {
            sel.forEach((row: SymEnumValue) => {
                this.selected_enum.values.forEach(
                    (_enum: SymEnumValue, idx: number) => {
                        if (_enum === row) {
                            this.selected_enum.values.splice(idx, 1);
                        }
                    }
                );
            });
            this.enumSelection.clear();
            this.enumValuesDataSource.data = this._selected_enum.values;
        }
    }
    // SWIPE component: should be moved there
    private _isLeftSign: boolean = true;
    ngstdIndexNumber: number = null;
    numberOfDeleteIcon: number = Constants.NUMBER_OF_DELETE_ICONS;
    slideThreshold: number = Constants.DEFAULT_SLIDE_THRESHOLD;

    getClassName() {
        return `${Constants.DEFAULT_CLASS_NAME}`;
    }
    isLeftSign() {
        return this._isLeftSign;
    }
    isRightSign() {
        return this.numberOfDeleteIcon === 2 && !this.isLeftSign();
    }
    alignComplete(event: any) {
        event.element.style.left = "0px";
        this._isLeftSign = event.element.offsetLeft > 0;
        this.ngstdIndexNumber = null;
    }
    panend(action, index, elementRefrence) {
        const currentMargin = this.getLeftPosition(elementRefrence);
        if (
            currentMargin > this.slideThreshold ||
            (currentMargin < -this.slideThreshold &&
                this.numberOfDeleteIcon === Constants.NUMBER_OF_DELETE_ICONS)
        ) {
            this.removeElement(index);
        } else {
            this.ngstdIndexNumber = index;
        }
    }
    panmove(action, elementRefrence) {
        if (action.deltaX > 0)
            // to right side only
            elementRefrence.style.left = action.deltaX + "px";
        this._isLeftSign = elementRefrence.offsetLeft > 0;
    }
    removeElement(index) {
        this.selected_enum.values.splice(index, 1);
        this.enumValuesDataSource.data = this.selected_enum.values;
    }
    getLeftPosition(elementRefrence) {
        const currentleftPosition = elementRefrence.style.left.slice(0, -2);
        if (currentleftPosition !== null) {
            return (
                (parseInt(currentleftPosition, 10) * 100) / window.innerWidth
            );
        } else {
            return 0;
        }
    }
    // SWIPE component: should be moved there
}
