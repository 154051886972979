import { Component, OnInit, Inject } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom.validators';
import { TranslateService } from '@ngx-translate/core';
import {MatChipInputEvent} from "@angular/material/chips/chip-input";

@Component({
    selector: 'app-alert-email-dialog',
    templateUrl: './alert-email-dialog.component.html',
    styleUrls: ['./alert-email-dialog.component.css']
})
export class AlertEmailDialogComponent implements OnInit {
    emailAlert: any;
    whenArray: any;
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    form = new FormGroup({
        email: new FormControl('', CustomValidator.email),
        email_body: new FormControl('', Validators.required),
        email_subject: new FormControl('', Validators.required),
        when: new FormControl('')
    })

    get emailInput() {
        return this.form.get('email');
    }

    get emailBody() {
        return this.form.get('email_body');
    }

    get emailSubject() {
        return this.form.get('email_subject');
    }

    get sendWhen() {
        return this.form.get('when');
    }

    constructor(public dialogRef: MatDialogRef<AlertEmailDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService) { }

    ngOnInit() {
        this.form.patchValue(this.data['alert']);
        this.emailAlert = this.data['alert'];
        if (this.data['whenArray']) this.whenArray = this.data['whenArray'];
    }

    confirm() {
        this.emailAlert['email_subject'] = this.emailSubject.value;
        this.emailAlert['email_body'] = this.emailBody.value;
        this.emailAlert['email_addresses'] = this.emailAlert['emailAddresses'].join(',');
        if (this.data['whenArray']) {
            this.emailAlert['when'] = this.sendWhen.value;
            this.emailAlert['on_enter'] = this.emailAlert['when'].includes('Entering') ? 1 : 0;
            this.emailAlert['on_exit'] = this.emailAlert['when'].includes('Leaving') ? 1 : 0;
        }
        this.dialogRef.close(this.emailAlert);
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if (this.form.controls['email'].valid) {
            if ((value || '').trim()) this.emailAlert.emailAddresses.push(value.trim());
            if (input) {
                input.value = '';
                this.form.controls['email'].markAsPristine;
            }
        } else {
            if (input.value != '') this.form.controls['email'].setErrors({ 'emailFormat': true });
        }
    }

    remove(email: string): void {
        const index = this.emailAlert.emailAddresses.indexOf(email);
        if (index >= 0) this.emailAlert.emailAddresses.splice(index, 1);
    }

}
