import { Component, OnDestroy, OnInit } from '@angular/core';
import { IMqttServiceOptions, MqttService } from 'ngx-mqtt';
import { mqttOptions } from '../shared/models/mqtt.models';
import { Subscription } from 'rxjs';
import { DowntimeNotification, MomentCategory } from '../shared/models/common.model';
import moment from 'moment';
import { Route } from '../shared/constants/routes.constant';
import { Router } from '@angular/router';
import { DOWNTIME_SUBSCRIBE_ADDRESS } from '../services/constants';

@Component({
  selector: 'app-service-downtime',
  templateUrl: './service-downtime.component.html',
  styleUrls: ['./service-downtime.component.scss']
})
export class ServiceDowntimeComponent implements OnInit, OnDestroy {
  private connectSubscription: Subscription;
  private subscription: Subscription;
  notificationMsg: DowntimeNotification;
  timeOutRef: NodeJS.Timeout;

  constructor(
    private mqttService: MqttService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // mqtt connect
    const clientId: string = 'downtimeServiceId-' + Math.random().toString(16).substr(2, 8);
    const options: IMqttServiceOptions = mqttOptions({ clientId });
    this.mqttService = new MqttService(options);

    this.connectSubscription = this.mqttService.onConnect.subscribe(() => {
      this.onConnect();
    });
  }

  /**
   * This method is called to subscribe to the mqtt topic to observe message
   */
  onConnect() {
    this.subscription = this.mqttService.observe(DOWNTIME_SUBSCRIBE_ADDRESS).subscribe((message) => {
      this.notificationMsg = JSON.parse(message.payload.toString());
      const endDate: Date = new Date(this.notificationMsg.toDate);
      const currentDate: Date = new Date();
      const endTimeout: number = moment(endDate).diff(currentDate, MomentCategory.MilliSeconds);

      if (endTimeout > 0 && !this.timeOutRef) {
        this.timeOutRef = setTimeout(() => {
          this.router.navigate([Route.HOME]);
        }, endTimeout);
      }
    });
  }

  ngOnDestroy(): void {
    this.mqttService.disconnect(true);
    this.connectSubscription.unsubscribe();
    if (typeof this.subscription !== 'undefined') {
      this.subscription.unsubscribe();
    }
  }
}
