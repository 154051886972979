import { ManageFactoryService } from './manage-factory/manage-factory.service';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpErrorResponse, HttpParams
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthFactoryService } from './auth-factory/auth-factory.service';
import { CustomEncoder } from './custom-encoder';
import { Route } from '../shared/constants/routes.constant';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    newParams: HttpParams;
    reservedCharacters: string[] = ['#', '&', '=', '?', '+', '@'];
    serverErrorCodes: number[] = [0];

    constructor(
        public manageFactory: ManageFactoryService,
        private authenticationService: AuthFactoryService,
        public router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const params: HttpParams = request.params;
        this.newParams = new HttpParams({ encoder: new CustomEncoder() });

        if (sessionStorage.getItem('token')) {
            request = request.clone({
                setHeaders: {
                    'x-access-token': sessionStorage.getItem('token'),
                    'x-key': sessionStorage.getItem('user')
                },
                params: this.encodeReservedCh(params)
            });
        } else {
            let loginCookie: string = this.authenticationService.getLoginCookie();
            if (loginCookie) {
                request = request.clone({
                    setHeaders: {
                        'x-access-token': loginCookie,
                    },
                    params: this.encodeReservedCh(params)
                });
                // the check is so we don't get into an infinite loop
                if (!request.url.endsWith("loginRememberMe")) {
                    // get the user info from the server and load into session storage.
                    this.authenticationService.loginRememberMe(loginCookie).subscribe();
                }
            }
            
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.authenticationService.clearLoginCookie();
                    this.router.navigate(['/login'], { state: { goTo: this.router.url } });
                } else if (error.status === 403) {
                    this.router.navigate(['/not-auth']);
                } else if (this.serverErrorCodes.includes(error.status) && !sessionStorage.getItem('token')) {
                    this.router.navigate([Route.SERVICE_DOWNTIME]);
                }
                return throwError(error);
            })
        );
    }

    /**
     * Replaces reserved characters in the HttpParams object with their respective escape sequences.
     * @param {HttpParams} params An HttpParams object containing the parameters of the request.
     * @returns {HttpParams} An HttpParams object with the replaced characters.
     */
    encodeReservedCh(params: HttpParams): HttpParams {
        params.keys().forEach(key => {
            const value = this.replaceCharacters(params.get(key));
            this.newParams = this.newParams.set(key, value);
        });
        return this.newParams;
    }

    /**
     * Replaces reserved characters with their actual character representation.
     * @param {string} value - The string to be parsed.
     * @return {string} The parsed string.
     */
    replaceCharacters(value: string): string {
        this.reservedCharacters.forEach(ch => {
            if (value.includes(ch)) {
                value = value.replace(/\`${ch}`/g, ch);
            }
        });
        return value;
    }
}