import { Component, OnInit, Inject } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { parseNumber, formatNumber, CountryCode } from 'libphonenumber-js';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatChipInputEvent} from "@angular/material/chips/chip-input";
import { SharedService } from 'src/app/services/shared/shared.service';
import { ICountry } from 'src/app/shared/models/common.model';

@Component({
    selector: 'app-alert-text-dialog',
    templateUrl: './alert-text-dialog.component.html',
    styleUrls: ['./alert-text-dialog.component.css']
})
export class AlertTextDialogComponent implements OnInit {
    textAlert: any;
    whenArray: any;
    country: CountryCode;
    countryList: ICountry[] = [];
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    form = new FormGroup({
        text: new FormControl(''),
        text_message: new FormControl('', Validators.required),
        when: new FormControl('')
    })

    get textInput() {
        return this.form.get('text');
    }

    get textMessage() {
        return this.form.get('text_message');
    }

    get sendWhen() {
        return this.form.get('when');
    }

    constructor(
        public dialogRef: MatDialogRef<AlertTextDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sharedService: SharedService
    ) { }

    ngOnInit() {
        this.form.patchValue(this.data.alert);
        this.textAlert = this.data.alert;
        if (this.data['whenArray']) this.whenArray = this.data['whenArray'];
        if (this.data.alert['phoneNumbers'][0]) {
            let thisCountry = parseNumber(this.data.alert['phoneNumbers'][0]);
            this.country = thisCountry['country'] ? thisCountry['country'] : 'US';
        } else {
            this.country = 'US';
        }
        // get list of countries which are not sanctioned
        this.countryList = this.sharedService.getCountries();
    }

    confirm() {
        this.textAlert['text_message'] = this.textMessage.value;
        this.textAlert['phone_nbr'] = this.textAlert['phoneNumbers'].join(',');
        if (this.data['whenArray']) {
            this.textAlert['when'] = this.sendWhen.value;
            this.textAlert['on_enter'] = this.textAlert['when'].includes('Entering') ? 1 : 0;
            this.textAlert['on_exit'] = this.textAlert['when'].includes('Leaving') ? 1 : 0;
        }
        this.dialogRef.close(this.textAlert);
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;

        let phoneNumber = this.validatePhone(input.value);
        if (phoneNumber != false) {
            this.textAlert.phoneNumbers.push(phoneNumber.replace(/\s+/g, ''));
            if (input) {
                input.value = '';
                this.form.controls['text'].markAsPristine;
            }
        } else {
            if (input.value != '') this.form.controls['text'].setErrors({ 'phoneFormat': true });
        }
    }

    remove(text: string): void {
        const index = this.textAlert.phoneNumbers.indexOf(text);
        if (index >= 0) this.textAlert.phoneNumbers.splice(index, 1);
    }

    validatePhone(input) {
        let phoneNum = (input != null) ? input : false;
        if (phoneNum) {
            let parsed = parseNumber(phoneNum, this.country);
            // @ts-ignore
            let formatted = (parsed['country'] == this.country) ? formatNumber(parsed, 'International') : false;
            return formatted;
        } else {
            return false;
        }
    }

}
