import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ManageFactoryService } from '../services/manage-factory/manage-factory.service';
import { CustomerRole } from '../services/constants';
import { canWrite, hasRole } from '../shared/common';

@Component({
    selector: 'app-custom-widget-editor',
    templateUrl: './custom-widget-editor.component.html',
    styleUrls: ['./custom-widget-editor.component.scss']
})
export class CustomWidgetEditorComponent implements OnInit {
    id: number;
    isLoading = true;
    customerList: any[] = [];
    currentName = '';
    currentCustomerId = -1;
    currentCode = '';
    dirtyCode = '';
    nameControl = new FormControl('', Validators.required);
    customerControl = new FormControl('', Validators.required);

    get isReadOnly(): boolean {
        return !canWrite();
    }

    get isDirtyName(): boolean {
        return this.currentName != this.nameControl.value;;
    }

    get isDirtyCustomer(): boolean {
        return this.currentCustomerId != this.customerControl.value;
    }

    get isDirtyCode(): boolean {
        return this.currentCode != this.dirtyCode;
    }

    get isDirty(): boolean {
        return this.isDirtyName || this.isDirtyCustomer || this.isDirtyCode;
    }

    constructor(
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private manageFactory: ManageFactoryService
    ) {
        if (this.isReadOnly) {
            this.nameControl.disable();
            this.customerControl.disable();
        }
        this.activatedRoute.params.subscribe(params => {
            this.id = params['id'];
            this.load();
        });
    }

    ngOnInit() {
        if (!hasRole(CustomerRole.OEM)) {
            this.router.navigate(['not-auth']);
        }
    }

    onCodeChanged(value) {
        this.dirtyCode = value;
    }

    async load() {
        const customers$ = this.manageFactory.customers.getMany();
        let customerResult = await customers$.toPromise();
        this.customerList = (customerResult as any[]).filter(customer => customer.role <= CustomerRole.OEM);

        const widget$ = this.manageFactory.widgets.getOne(this.id);
        let widgetResult = await widget$.toPromise();
        this.currentName = widgetResult['name'];
        this.nameControl.setValue(this.currentName);
        this.currentCustomerId = widgetResult['customerId'];
        this.customerControl.setValue(this.currentCustomerId);

        const script$ = this.manageFactory.widgets.getScript(this.id);
        let scriptResult = await script$.toPromise();
        this.currentCode = scriptResult;
        this.dirtyCode = scriptResult;
        this.isLoading = false;
    }

    handleSave() {
        // Only update what needs updated.
        if (this.isDirtyName) {
            let name = this.nameControl.value;
            this.manageFactory.widgets.update(this.id, { name })
                .subscribe(() => this.currentName = name);
        }
        if (this.isDirtyCustomer) {
            let customerId = this.customerControl.value;
            this.manageFactory.widgets.update(this.id, { customerId })
                .subscribe(() => this.currentCustomerId = customerId);
        }
        if (this.isDirtyCode) {
            this.manageFactory.widgets.uploadScript(this.id, this.dirtyCode)
                .subscribe(() => { this.currentCode = this.dirtyCode });
        }
    }
}
