<div class="date-container">
    <h1>{{ 'service_downtime.heading' | translate }}</h1>

    <form [formGroup]="form" fxLayout="column">
        <mat-form-field>
            <input matInput [matDatepicker]="start_date" [min]="minDate" [placeholder]="'dialog.start_date' | translate"
                formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="start_date"></mat-datepicker-toggle>
            <mat-datepicker #start_date></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <input matInput type="time" formControlName="startTime"
                [placeholder]="'alert_editor.btn.start_time' | translate">
        </mat-form-field>

        <mat-form-field>
            <input matInput [matDatepicker]="end_date" [min]="minDate" [placeholder]="'dialog.end_date' | translate"
                formControlName="endDate">
            <mat-datepicker-toggle matSuffix [for]="end_date"></mat-datepicker-toggle>
            <mat-datepicker #end_date></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <input matInput type="time" formControlName="endTime"
                [placeholder]="'alert_editor.btn.end_time' | translate">
        </mat-form-field>
        <mat-error *ngIf="isInvalidDate">{{'service_downtime.invalid_date' | translate}}</mat-error>

        <mat-form-field>
            <textarea matInput cols="30" rows="5" formControlName="desc" readonly="true"
                [placeholder]="'service_downtime.description_label' | translate"></textarea>
        </mat-form-field>

        <mat-form-field>
            <textarea matInput cols="30" rows="4" formControlName="additionalInfo"
                [placeholder]="'service_downtime.additional_info_label' | translate"></textarea>
        </mat-form-field>
    </form>

    <div class="form-action-btns-wrap">
        <button mat-raised-button class="mat-primary confirm" [disabled]="form.invalid" [disabled]="isInvalidDate"
            (click)="generateServiceDowntime()">
            {{ 'btn.save' | translate }}
        </button>
    </div>
</div>
