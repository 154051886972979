<div class="fixed-action-btn">
    <a mat-fab color="primary" class="floating-button" href="help/{{this.document}}#top" *ngIf="isShow">
        <svg class="center" style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="#ffffff" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
        </svg>
    </a>
</div>

<div id="mark" class="main" (scroll)="checkScroll()">
    <markdown [src]="markdown">
    </markdown>
</div>
