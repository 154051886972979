import {
    Component,
    OnInit,
    HostBinding,
    ViewChild,
    ElementRef, OnDestroy
} from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from "@angular/animations";
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { AuthFactoryService } from "../services/auth-factory/auth-factory.service";
import { MatDialog } from "@angular/material/dialog";
import { ManageFactoryService } from "../services/manage-factory/manage-factory.service";
import { AppSettingsService } from "../services/user/app-settings.service";
import { Title } from "@angular/platform-browser";
import { DOWNTIME_SUBSCRIBE_ADDRESS, appConstants } from "../services/constants";
import { OverlayContainer } from "@angular/cdk/overlay";
import { SearchService } from "../services/search/search.service";
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { MenuNavItem, RouterIcon } from './model/root.model';
import { VersionService } from '../services/version/version.service';
import { Route } from '../shared/constants/routes.constant';
import moment from 'moment';
import { IMqttServiceOptions, MqttService } from 'ngx-mqtt';
import { mqttOptions } from '../shared/models/mqtt.models';
import { DowntimeNotification, MomentCategory } from '../shared/models/common.model';

@Component({
    selector: "app-root",
    templateUrl: "./root.component.html",
    styleUrls: ["./root.component.css"],
    animations: [
        trigger("slide", [
            state(
                "hide",
                style({
                    width: "0px",
                    display: "none"
                })
            ),
            state("show", style({ width: "*" })),
            transition("hide => show", animate("0.25s ease-in-out")),
            transition("show => hide", animate("0.25s ease-in-out"))
        ]),
        trigger("toggle", [
            state("show", style({})),
            state(
                "hide",
                style({
                    display: "none"
                })
            )
        ])
    ]
})
export class RootComponent implements OnInit, OnDestroy {
    @ViewChild("searchTextBox", { static: true }) searchElement: ElementRef;

    theme = this.accountSettings.getTheme();
    constants = appConstants;
    selection = "";
    canSearch: boolean;
    icon: string;
    get role() {
        return +sessionStorage.getItem("customerRole");
    }
    searchOpen = false;
    searchValue: string;
    destroy$ = new Subject();
    menuNavItem = MenuNavItem;
    routerIcon = RouterIcon;
    route = Route;
    routeConfigData: Data;
    showNotification: boolean = true;
    private connectSubscription: Subscription;
    private subscription: Subscription;
    notificationMsg: DowntimeNotification;
    timeOutRef: NodeJS.Timeout;
    notificationDays: number = 7;
    negativeDateDiff: number = -1;

    constructor(
        public overlayContainer: OverlayContainer,
        public accountSettings: AppSettingsService,
        public versionService: VersionService,
        private manageFactory: ManageFactoryService,
        private authenticationService: AuthFactoryService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private searchService: SearchService,
        public dialogue: MatDialog,
        private translate: TranslateService,
        private appService: AppService,
        private mqttService: MqttService,
    ) {
        // empty search bar if route changes
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd && this.searchOpen == true) {
                this.toggleSearch();
            }
        });
    }

    @HostBinding("class") componentCssClass;

    // returns whether search bar should be hidden(then used in animation)
    get stateName() {
        return this.searchOpen ? "show" : "hide";
    }

    ngOnInit() {
        this.versionService.loadHistory();
        this.appService.browserRefresh$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(() => {
            let data = this.activatedRoute.firstChild.routeConfig.data;

            // this check is for statistics module to show/hide search icon and get relevant translations
            if (this.activatedRoute?.firstChild?.firstChild?.routeConfig?.data?.IsStatistics) {
                data = this.activatedRoute.firstChild.firstChild.routeConfig.data;
            }
            this.routeConfigData = data;

            this.translate.get(data['title']).subscribe((t => {
                this.canSearch = true;
                if (data['canSearch'] != undefined) {
                    this.canSearch = !!data['canSearch'];
                }
                this.icon = data['icon'];
                this.titleService.setTitle(t);
            }));
        });

        // retrieve settings
        this.manageFactory.accountSettings
            .getSettings()
            .subscribe(settingsResult => {
                this.accountSettings.setCustomer(
                    +sessionStorage.getItem("customerId")
                );
                this.accountSettings.setTheme(settingsResult["theme"]);
                this.accountSettings.setLogo(settingsResult["logo"]);
                this.accountSettings.setFavicon(settingsResult["favicon"]);

                // update favicon
                var src =
                    this.accountSettings.getFaviconImage() +
                    "?=" +
                    Math.random();
                let link = document.createElement("link"),
                    oldLink = document.getElementById("favicon");
                link.id = "favicon";
                link.rel = "shortcut icon";
                link.href = src;
                if (oldLink) document.head.removeChild(oldLink);
                document.head.appendChild(link);

                // update theme
                this.overlayContainer
                    .getContainerElement()
                    .classList.add(this.accountSettings.getTheme().toString());
                sessionStorage.setItem(
                    "theme",
                    this.accountSettings.getTheme()
                );
            });

        // loads the correct title/tab if just signed in
        if (this.appService.afterLogin$.getValue()) {
            this.router.navigate([this.router.url]);
            this.isSelected(MenuNavItem.HOME);
        }

        // mqtt connect
        const clientId: string = 'downtimeServiceId-' + Math.random().toString(16).substr(2, 8);
        const options: IMqttServiceOptions = mqttOptions({ clientId });
        this.mqttService = new MqttService(options);

        this.connectSubscription = this.mqttService.onConnect.subscribe(() => {
            this.onConnect();
        });
    }

    ngOnDestroy(): void {
        this.mqttService.disconnect(true);
        this.connectSubscription.unsubscribe();
        if (typeof this.subscription !== 'undefined') {
            this.subscription.unsubscribe();
        }
        this.destroy$.next();
    }

    toggleSearch() {
        this.searchValue = this.searchValue ? '' : null;
        this.returnSearch();
        this.searchOpen = !this.searchOpen;

        // wait for animations to finish, then set focus
        setTimeout(() => {
            if (this.searchOpen) {
                this.searchElement.nativeElement.focus();
            } else {
                this.searchElement.nativeElement.blur();
            }
        }, 300);
    }

    returnSearch() {
        this.searchService.searchNotify({
            option: "call_child",
            value: this.searchValue
        });
    }

    getUsername() {
        return sessionStorage.getItem("username");
    }

    getLogo() {
        return this.accountSettings.getLogoImage();
    }

    logout() {
        // remove theme so we don't get any lingering themes if we log in under a different customer
        this.overlayContainer
            .getContainerElement()
            .classList.remove(sessionStorage.getItem("theme"));
        this.authenticationService.clearLoginCookie();
        this.authenticationService.logout();
        this.router.navigate(['login']);
    }

    // sets the title string when user clicks on tab
    isSelected(tab) {
        if (tab !== MenuNavItem.REPORTS) {
            this.removeFilter();
        }
    }

    removeFilter() {
        sessionStorage.removeItem("data_filter");
    }

    //returns title string
    getTitle() {
        return this.routeConfigData.title;
    }

    /**
     * This method is called to subscribe to the mqtt topic to observe message
     */
    onConnect() {
        this.subscription = this.mqttService.observe(DOWNTIME_SUBSCRIBE_ADDRESS).subscribe((message) => {
            this.notificationMsg = JSON.parse(message.payload.toString());
            const startDate: Date = new Date(this.notificationMsg.fromDate);
            const endDate: Date = new Date(this.notificationMsg.toDate);
            const currentDate: Date = new Date();
            const isDateBeforeEnding: boolean = moment(currentDate).isBefore(moment(endDate));
            const dateDifference: number = moment(startDate).diff(currentDate, MomentCategory.Days);
            const startTimeout: number = moment(startDate).diff(currentDate, MomentCategory.MilliSeconds);
            const endTimeout: number = moment(endDate).diff(currentDate, MomentCategory.MilliSeconds);

            if (startTimeout > 0 && !this.timeOutRef && this.role > appConstants.role_super) {
                this.timeOutRef = setTimeout(() => {
                    this.router.navigate([Route.SERVICE_DOWNTIME]);
                }, startTimeout);
            }

            if (startTimeout < 0 && endTimeout > 0 && this.role > appConstants.role_super) {
                this.router.navigate([Route.SERVICE_DOWNTIME]);
            }

            if (isDateBeforeEnding && dateDifference > this.negativeDateDiff && dateDifference <= this.notificationDays) {
                this.showNotification = true;
            } else {
                this.showNotification = false;
            }
        });
    }

    /**
     * Hide service downtime notification from the app.
     */
    hideDowntimeNotification() {
        this.showNotification = false;
    }
}
