import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MINIMUM_VALUE } from 'src/app/services/constants';

@Component({
  selector: 'app-time-difference-dialog',
  templateUrl: './time-difference-dialog.component.html',
  styleUrls: ['./time-difference-dialog.component.scss']
})
export class TimeDifferenceDialog implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<TimeDifferenceDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { timeDifferenceValue: number }
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      time: [this.data.timeDifferenceValue, [Validators.required, Validators.min(MINIMUM_VALUE)]]
    });
  }

  /**
  * Closes dialog when called.
  */
  close(): void {
    this.dialogRef.close();
  }

  /**
   * Method is called when time difference value changes and dialog is closed.
   */
  setTimeDifferenceValue(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
