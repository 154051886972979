<form [formGroup]="form" class="container" (ngSubmit)="resetPassword()">
    <div class="panel panel-default">
        <mat-card-header>
            <mat-card-title>
                <strong>{{'reset_password.title' | translate}}</strong>
            </mat-card-title>
        </mat-card-header>
    </div>
    <mat-card class="col-mat-4 col-mat-offset-4">
        <div class="panel-body">
            <section id="inputForm">
                <div>
                    <mat-form-field class="input-field">
                        <input matInput formControlName="password" class="form-control" type="password"
                            placeholder="{{'reset_password.new_password' | translate}}" minlength="8" required>
                        <mat-error *ngIf="form.get('password').touched  && form.get('password').invalid">
                            <mat-error *ngIf="form.get('password').errors.required">{{'form.password_is_required' | translate}}</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field class="input-field">
                    <input matInput formControlName="confirmPassword" class="form-control" type="password"
                        placeholder="{{'reset_password.confirm_password' | translate}}" required pattern="{{form.get('password').value}}" minlength="8">
                    <mat-error *ngIf="form.get('confirmPassword').touched && form.get('confirmPassword').invalid"
                        class="alert alert-danger">
                        <mat-error *ngIf="form.get('confirmPassword').errors.required">{{'form.confirm_password_required' | translate}}
                        </mat-error>
                        <mat-error *ngIf="!isMatch()">{{'form.must_match_new_password' | translate}}</mat-error>
                    </mat-error>
                </mat-form-field>

            </section>
            <div class="form-group col-md-12">{{'reset_password.description_p1' | translate}}
                <span [ngStyle]="{ 'color' : !isMatch() ? 'red' : 'green' }">{{'reset_password.description_p2' | translate}}</span>{{'reset_password.description_p3' | translate}}
                <span [ngStyle]="{ 'color' : form.get('password').hasError('minlength') ? 'red' : 'green' }">{{'reset_password.description_p4' | translate}}</span>{{'reset_password.description_p5' | translate}}
                <br />
                <ul>
                    <li [ngStyle]="{ 'color' : !hasUpper() && form.get('password').dirty  ? 'red' : 'green' }">
                        {{'reset_password.warning.upper' | translate}}
                    </li>
                    <li [ngStyle]="{ 'color' : !hasLower() && form.get('password').dirty ? 'red' : 'green' }">
                        {{'reset_password.warning.lower' | translate}}
                    </li>
                    <li [ngStyle]="{ 'color' : !hasNumber() && form.get('password').dirty ? 'red' : 'green' }">
                        {{'reset_password.warning.number' | translate}}
                    </li>
                    <li [ngStyle]="{ 'color' : !hasSpecial() && form.get('password').dirty ? 'red' : 'green' }">
                        {{'reset_password.warning.special_character' | translate}}
                    </li>
                </ul>
            </div>
        </div>


        <div class="button-row" layout="row" layout-margin>
            <button flex mat-raised-button class="cancel-btn" (click)="cancel()">{{'btn.cancel' | translate}}</button>
            <button flex mat-raised-button mat-primary class="send-btn" type="submit"
                [disabled]="!getRequirements() || !isMatch() || form.get('password').invalid">{{'reset_password.title' | translate}}</button>
        </div>
    </mat-card>
