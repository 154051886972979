import { SymElementType } from "./types";
import { SymEnum } from "./sym_enum";
import { SymSymbolVariable } from "./sym_symbol_variable";

export enum SymSymbolDirection {
    SEND = 0,
    RECEIVE = 1,
    BOTH = 2
}

export enum SymSymbolType {
    STANDARD = 0,
    J1939 = 5,
    FD_STANDARD = 2,
    FD_EXTENDED = 3,
    EXTENDED = 4,
    NMEA2000 = 5
}

export enum SymSymbolDisplayType {
    OFF = 0,
    ON = 1
}

export class SymSymbol {
    readonly sym_type: SymElementType = SymElementType.SYMBOL;
    private symbol_id: number;
    public name: string;
    public direction: SymSymbolDirection;
    public variables: SymSymbolVariable[];
    public comment: string;
    public can_id: number;
    public original_can_id: number;
    public type: SymSymbolType;
    public data_length: number;
    public cycle_time: number;
    public is_logged: boolean;
    public is_mqtt: boolean;
    public is_retained: boolean;
    public timeout: number;
    public is_paused: boolean;
    public display_mode: SymSymbolDisplayType;
    public min_interval: number;
    public valid_for_all_length: boolean;
    public color: string | number;
    public multipacket: boolean;
    public _data: any;

    constructor(data: any) {
        this.parse_prms(data);
        this._data = data;
    }

    parse_prms(data: any) {
        this.symbol_id = typeof data.message_id !== "undefined" ? data.message_id : 0;
        this.name =
            typeof data.descriptor !== "undefined" ? data.descriptor : "";
        this.can_id = parseInt(
            typeof data.can_id !== "undefined" ? data.can_id : 0
        );
        this.direction =
            typeof data.direction !== "undefined"
                ? data.direction
                : SymSymbolDirection.SEND;
        this.data_length =
            typeof data.data_length !== "undefined" ? data.data_length : 1;
        this.comment = typeof data.comment !== "undefined" ? data.comment : "";
        this.original_can_id = parseInt(
            typeof data.original_can_id !== "undefined"
                ? data.original_can_id
                : data.can_id
        );
        this.cycle_time =
            typeof data.cycle_time !== "undefined" ? data.cycle_time : 0;
        this.is_logged = data.is_logged ? true : false;
        this.is_mqtt = data.is_mqtt ? true : false;
        this.is_retained = data.is_retained ? true : false;
        this.timeout = typeof data.timeout !== "undefined" ? data.timeout : 50;
        this.is_paused = data.is_paused ? true : false;
        this.display_mode =
            typeof data.display_mode !== "undefined"
                ? data.display_mode
                : SymSymbolDisplayType.ON;
        this.type =
            typeof data.message_type !== "undefined"
                ? data.message_type
                : SymSymbolType.STANDARD;
        this.min_interval =
            typeof data.min_interval !== "undefined" ? data.min_interval : 0;
        this.valid_for_all_length =
            typeof data.valid_for_all_length !== "undefined"
                ? data.valid_for_all_length
                : true;
        this.multipacket = data.multipacket ? true : false;
        this.color = data.color;

        this.variables = [];
        let el: any;
        if (typeof data.variables !== "undefined") {
            data.variables.forEach((el: any) => {
                this.variables.push(new SymSymbolVariable(el));
            });
            this.sortVariables();
        }
    }

    sortVariables() {
        // sort variables by starting bit
        this.variables.sort((v1, v2) => {
            return v1.starting_bit - v2.starting_bit;
        });
    }
    
    dump() {
        let rv = {
            message_id: (typeof this.symbol_id !== 'undefined' && this.symbol_id > 0) ? this.symbol_id : 0,
            can_id: this.can_id,
            original_can_id: this.original_can_id || this.can_id,
            data_length: this.data_length,
            descriptor: this.name,
            is_logged: this.is_logged ? 1 : 0,
            is_mqtt: this.is_mqtt ? 1 : 0,
            is_retained: this.is_retained ? 1 : 0,
            timeout: this.timeout,
            direction: this.direction,
            cycle_time: this.cycle_time,
            message_type: this.type,
            color: this.color,
            multipacket: this.multipacket ? 1 : 0,
            comment: this.comment,

            variables: <any>[]
        };

        this.variables.forEach((v: SymSymbolVariable) => {
            rv.variables.push(v.dump());
        });

        return rv;
    }

    fillEnums(enums: SymEnum[]) {
        this.variables.forEach((v: SymSymbolVariable) => {
            v.fillEnum(enums);
        });
    }
}
