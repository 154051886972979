export class EditorYear {
    value: string;

    constructor(value: string) {
        this.value = value;
    }

    static empty(): EditorYear {
        return new EditorYear(undefined);
    }
}
