import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertTextDialogComponent } from '../alert-text-dialog/alert-text-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-select-dialog',
    templateUrl: './select-dialog.component.html',
    styleUrls: ['./select-dialog.component.css']
})
export class SelectDialogComponent implements OnInit {
    selectArray: any;
    form = new FormGroup({
        returnText: new FormControl('', Validators.required)
    })

    get returnText() {
        return this.form.get('returnText');
    }

    constructor(public dialogRef: MatDialogRef<AlertTextDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService) { }

    ngOnInit() {
        this.selectArray = this.data['array'];
        if (this.data['val']) this.returnText.setValue(this.data['val']);
    }

    confirm() {
        this.dialogRef.close(this.returnText.value);
    }

}
