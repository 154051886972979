import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'reset-password-success',
    templateUrl: './reset-password-success.component.html',
    styleUrls: ['./reset-password-success.component.css']
})
export class ResetPasswordSuccessComponent {

    constructor(private translate: TranslateService) { }

}
