import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { SymLibrary } from '../model/sym_library';
import { SymEnum, SymEnumValue } from '../model/sym_enum';
import { UserBrowserInfo } from '../model/user_browser_info';
import { TooltipDefaults } from '../model/tooltip_defaults';

// SWIPE component: should be moved there
import { trigger, style, keyframes, transition, animate, query, stagger } from '@angular/animations';

const Constants = {
    DEFAULT_SLIDE_THRESHOLD: 60,
    NUMBER_OF_DELETE_ICONS: 2,
    DEFAULT_CLASS_NAME: `ngstd-main-canvas`
};
// SWIPE component: should be moved there


@Component({
    selector: 'enum-editor',
    templateUrl: './enum-editor.component.html',
    styleUrls: [ './enum-editor.component.css' ],
    providers: [
        UserBrowserInfo,
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: TooltipDefaults },
     ],
// SWIPE component: should be moved there
    animations: [
                    trigger('slideLeft', [
                        transition('* => *', animate(100, keyframes([
                            style({ left: '*', offset: 0 }),
                            style({ left: '0', offset: 1 }),
                        ])))
                    ])
                ]
// SWIPE component: should be moved there
})
export class EnumEditorComponent {
    @Input() public sym_lib: SymLibrary;
    @Input() public selected_enum: SymEnum;

    @Output() onSelectEnum = new EventEmitter<any>();
    @Output() onDeleteElement = new EventEmitter<SymEnum>();

    // events from parent
    private eventsSubscriptionUpdateLibrary: any = null;
    @Input() updateLibraryEvents: Observable<void>;

    constructor (public browserInfo: UserBrowserInfo,
                 public enumEditPropDialog: MatDialog) {
    }

    ngOnInit() {
        if (this.updateLibraryEvents) {
            this.eventsSubscriptionUpdateLibrary = this.updateLibraryEvents.subscribe((dt: any) => {
                console.log('from enum_editor eventsSubscriptionUpdateLibrary: ', dt);
            });
        }
    }

    ngOnDestroy() {
        if (this.eventsSubscriptionUpdateLibrary) {
            this.eventsSubscriptionUpdateLibrary.unsubscribe();
        }
    }

    onEnumClick(_enum: SymEnum) {
        if (this.onSelectEnum) {
            this.onSelectEnum.emit(_enum);
        }
    }

    addNewEnumValue(_enum: SymEnum) {
        console.log('New enum val:', _enum);
    }

// SWIPE component: should be moved there
    private _isLeftSign: boolean = true;
    ngstdIndexNumber: number = null;
    numberOfDeleteIcon: number = Constants.NUMBER_OF_DELETE_ICONS;
    slideThreshold: number = Constants.DEFAULT_SLIDE_THRESHOLD;

    getClassName() { return `${Constants.DEFAULT_CLASS_NAME}`; }
    isLeftSign() { return this._isLeftSign }
    isRightSign() { return this.numberOfDeleteIcon === 2 && !this.isLeftSign(); }
    alignComplete(event: any) {
        event.element.style.left = '0px';
        this._isLeftSign = (event.element.offsetLeft > 0);
        this.ngstdIndexNumber = null;
    }
    panend(action, index, elementRefrence) {
        const currentMargin = this.getLeftPosition(elementRefrence);
        if (currentMargin > this.slideThreshold ||
            (currentMargin < -this.slideThreshold && this.numberOfDeleteIcon === Constants.NUMBER_OF_DELETE_ICONS)) {
            this.removeElement(index);
        }
        else {
            this.ngstdIndexNumber = index;
        }
    }
    panmove(action, elementRefrence) {
        if(action.deltaX > 0) // to right side only
            elementRefrence.style.left = action.deltaX + 'px';
        this._isLeftSign = (elementRefrence.offsetLeft > 0);

    }
    removeElement(index) {
        //const deletedItem = this.items[index];
        const deletedItem = this.sym_lib.enums[index];
        //this.items.splice(index, 1);
        //this.deletedItem.emit(deletedItem);
        if (deletedItem && this.onDeleteElement)
            this.onDeleteElement.emit(deletedItem);
    }
    getLeftPosition(elementRefrence) {
        const currentleftPosition = elementRefrence.style.left.slice(0, -2);
        if (currentleftPosition !== null) {
            return (parseInt(currentleftPosition, 10) * 100) / window.innerWidth;
        }
        else {
            return 0;
        }
    }
// SWIPE component: should be moved there
}
