
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { UserBrowserInfo } from '../model/user_browser_info';
import { TooltipDefaults } from '../model/tooltip_defaults';
import { UpdateLibraryEventType } from '../model/types';
import { SymSymbol } from '../model/sym_symbol';
import { SymLibrary } from '../model/sym_library';

// SWIPE component: should be moved there
import { trigger, style, keyframes, transition, animate } from '@angular/animations';
import { LibraryEditService } from '../service/library-edit.service';


const Constants = {
    DEFAULT_SLIDE_THRESHOLD: 60,
    NUMBER_OF_DELETE_ICONS: 2,
    DEFAULT_CLASS_NAME: `ngstd-main-canvas`
};


@Component({
    selector: 'symbol-editor',
    templateUrl: './symbol-editor.component.html',
    styleUrls: ['./symbol-editor.component.css'],
    providers: [
        UserBrowserInfo,
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: TooltipDefaults },
    ],
    // SWIPE component: should be moved there
    animations: [
        trigger('slideLeft', [
            transition('* => *', animate(100, keyframes([
                style({ left: '*', offset: 0 }),
                style({ left: '0', offset: 1 }),
            ])))
        ])
    ]
    // SWIPE component: should be moved there
})
export class SymbolEditorComponent {
    @Input() public sym_lib: SymLibrary;
    @Input() public selected_symbol: SymSymbol;

    @Output() onSymbolDelete = new EventEmitter<SymSymbol>();
    @Output() onSelectSymbol = new EventEmitter<any>();

    public eventUpdateLibrary: Subject<any> = new Subject<any>();

    // events from parent
    private eventsSubscriptionUpdateLibrary: any = null;
    @Input() updateLibraryEvents: Observable<void>;

    constructor(
        public browserInfo: UserBrowserInfo,
        private translate: TranslateService,
        private libraryEditService: LibraryEditService,
    ) {
    }

    ngOnInit() {
        if (this.updateLibraryEvents) {
            this.eventsSubscriptionUpdateLibrary = this.updateLibraryEvents.subscribe((dt: any) => {
                console.log('from sym_editor eventsSubscriptionUpdateLibrary: ', dt);
            });
        }
    }

    ngOnDestroy() {
        if (this.eventsSubscriptionUpdateLibrary) {
            this.eventsSubscriptionUpdateLibrary.unsubscribe();
        }
    }

    public onSymbolClick(_symbol: SymSymbol) {
        if (this.onSelectSymbol) {
            this.onSelectSymbol.emit(_symbol);
        }
    }

    onSymbolUpdateEvent(symbol_updated: SymSymbol) {
        this.eventUpdateLibrary.next({ type: UpdateLibraryEventType.SYMBOL_LENGTH_CHANGE, object: symbol_updated });
    }

    handleValidFormChange(isValid) {
        this.libraryEditService.isSymbolFormEditValid$.next(isValid);
    }

    addNewSymbolVariable() {
        if (this.eventUpdateLibrary) {
            this.eventUpdateLibrary.next({ type: UpdateLibraryEventType.SYMBOL_VARIABLE_NEW, object: this.selected_symbol });
        }
    }

    // SWIPE component: should be moved there
    private _isLeftSign: boolean = true;
    ngstdIndexNumber: number = null;
    numberOfDeleteIcon: number = Constants.NUMBER_OF_DELETE_ICONS;
    slideThreshold: number = Constants.DEFAULT_SLIDE_THRESHOLD;

    getClassName() { return `${Constants.DEFAULT_CLASS_NAME}`; }
    isLeftSign() { return this._isLeftSign }
    isRightSign() { return this.numberOfDeleteIcon === 2 && !this.isLeftSign(); }
    alignComplete(event: any) {
        event.element.style.left = '0px';
        this._isLeftSign = (event.element.offsetLeft > 0);
        this.ngstdIndexNumber = null;
    }
    panend(action, index, elementRefrence) {
        const currentMargin = this.getLeftPosition(elementRefrence);
        if (currentMargin > this.slideThreshold ||
            (currentMargin < -this.slideThreshold && this.numberOfDeleteIcon === Constants.NUMBER_OF_DELETE_ICONS)) {
            this.removeElement(index);
        }
        else {
            this.ngstdIndexNumber = index;
        }
    }
    panmove(action, elementRefrence) {
        if (action.deltaX > 0) // to right side only
            elementRefrence.style.left = action.deltaX + 'px';
        this._isLeftSign = (elementRefrence.offsetLeft > 0);
    }
    removeElement(index) {
        //const deletedItem = this.items[index];
        const deletedItem = this.sym_lib.symbols[index];
        //this.items.splice(index, 1);
        //this.deletedItem.emit(deletedItem);
        if (deletedItem && this.onSymbolDelete)
            this.onSymbolDelete.emit(deletedItem);
    }
    getLeftPosition(elementRefrence) {
        const currentleftPosition = elementRefrence.style.left.slice(0, -2);
        if (currentleftPosition !== null) {
            return (parseInt(currentleftPosition, 10) * 100) / window.innerWidth;
        }
        else {
            return 0;
        }
    }
    // SWIPE component: should be moved there
}
