import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class LibraryEditService {

  isSymbolFormEditValid$ = new BehaviorSubject<boolean>(false);

  constructor() {
  }



}
