
export class CustomerFilterItem {
	constructor (public id:number, readonly name:string) {}
	static empty() : CustomerFilterItem { return new CustomerFilterItem(null, null); }
};

export class StatusFilterItem {
	constructor (public id:number, readonly name:string) {}
	static empty() : StatusFilterItem { return new StatusFilterItem(null, null); }
};

export class PayPeriodFilterItem {
	constructor (readonly date:Date) {}
	static empty() : PayPeriodFilterItem { return new PayPeriodFilterItem(null); }
}
