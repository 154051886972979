<mat-toolbar class="mat-primary">
    <h2>{{ data.title }}</h2>
    <span class="spacer"></span>
    <button mat-icon-button (click)="handleCancel()">
        <mat-icon>
            close
        </mat-icon>
    </button>
</mat-toolbar>

<mat-form-field class="filter-input">
    <input matInput type="text" placeholder="{{'dialog.filter_models' | translate}}" [(ngModel)]="filterValue" (keyup)="filterModels()" (change)="filterModels()">
    <button mat-button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="filterValue=''; filterModels()">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>

<mat-dialog-content>
    <mat-list (window:resize)="onResize($event)">
        <mat-list-item *ngFor="let model of modelList" (click)="selectModel(model)" [ngClass]="{'selected-model': isSelected(model)}">
            <div  class="image">
              <img
                class="logo-img"
                [src]="model.product_image | secure | async"
                alt="product image"
              />
            </div>
            <div class="model-name">
                {{model.name}}
            </div>
        </mat-list-item>
    </mat-list>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="handleCancel()">
        {{'btn.cancel' | translate}}
    </button>
    <button mat-button class="mat-primary confirm" [disabled]="!selectedModel" (click)="confirm()">
        {{'btn.apply' | translate}}
    </button>
</mat-dialog-actions>
