<div class="bad-route-container">
    <div class="bad-route">
        <div class="sad-face">
            <h1>:(</h1>
        </div>
        <div [ngSwitch]="kind">
            <div *ngSwitchCase="kinds.NotAuth">
                <h2>{{'bad_route.not_auth.title' | translate}}</h2>
                <p>{{'bad_route.not_auth.text' | translate}}</p>
            </div>
            <div *ngSwitchDefault>
                <h2>{{'bad_route.not_found.title' | translate}}</h2>
                <p>{{'bad_route.not_found.text' | translate}}</p>
            </div>
        </div>
        <button mat-raised-button (click)="goHome()" class="mat-primary">
            {{'bad_route.go_home' | translate}}
        </button>
    </div>
</div>