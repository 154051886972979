import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from '@ngx-translate/core';

import { SymSymbol } from "../model/sym_symbol";

@Component({
    selector: "dialog-symbol-new-dialog",
    templateUrl: "./symbol-new.component.html",
    styleUrls: ["./dialog.component.css"]
})
export class SymbolNewDialog {
    constructor(
        public dialogRef: MatDialogRef<SymbolNewDialog>,
        @Inject(MAT_DIALOG_DATA) public data: SymSymbol,
        private translate: TranslateService
    ) {}

    submitByEnter() {
        this.dialogRef.close(this.data);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
