import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { SymLibrary } from '../../model/sym_library';
import { SymSymbol } from '../../model/sym_symbol';
import { UserBrowserInfo } from '../../model/user_browser_info';
import { Subject } from 'rxjs';
import { AppService } from "../../../app.service";

@Component({
    selector: 'symbol-prop',
    templateUrl: './symbol-properties.component.html',
    styleUrls: ['./symbol-properties.component.css'],
    providers: [UserBrowserInfo],
})
export class SymbolPropComponent implements OnInit, OnDestroy {
    @Input() public sym_lib: SymLibrary;
    private _selected_symbol: SymSymbol;

    @Output() onSymbolUpdate = new EventEmitter<SymSymbol>();
    @Output() onValidFormChange = new EventEmitter<boolean>();

    form: FormGroup;
    destroy$ = new Subject();
    isDesktopWidth: boolean;

    symbolDataLengthFormControl: FormControl = new FormControl(0, [
        Validators.required,
        Validators.min(1),
        Validators.max(100),
        Validators.pattern('[0-9]*')
    ]);
    cycleTimeFormControl: FormControl = new FormControl(0, [
        Validators.required,
        Validators.min(0),
        Validators.max(10000),
        Validators.pattern('[0-9]*')
    ]);

    constructor(
        public browserInfo: UserBrowserInfo,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private appService: AppService,
    ) {
    }

    ngOnInit() {

        if (!this.form) {
            this.formInit();
        }

        this.isDesktopWidth = !this.browserInfo.is_mobile;

        this.form.valueChanges.pipe(
            debounceTime(300),
            takeUntil(this.destroy$)
        ).subscribe((val) => {
            if (this.form.valid) {
                // update selected_symbol instance
                Object.assign(this._selected_symbol, val);
                // store can_id as decimal
                this._selected_symbol.can_id = parseInt(val.can_id, 16);
                this.onSymbolUpdate.emit(this._selected_symbol);
            }

            this.onValidFormChange.emit(this.form.valid);
        });
    }

    onResize(event) {
        this.handleSize();
    }
    
    handleSize() {
      this.isDesktopWidth = !this.appService.isMobileWidth$.getValue();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    @Input() set selected_symbol(selected_symbol: SymSymbol) {
        this._selected_symbol = selected_symbol;
        if (selected_symbol) {

            if (!this.form) {
                this.formInit();
            }
            this.form.patchValue(selected_symbol, { emitEvent: false });
            // put can_id as hexdecimal
            this.form.patchValue({can_id: selected_symbol.can_id.toString(16) }, { emitEvent: false });
            this.form.markAllAsTouched();

            // need this hack
            setTimeout(() => {
                const isValid = this.form ? this.form.valid : false;
                this.onValidFormChange.emit(isValid);
            });
        }
    }

    formInit() {
        this.form = this.formBuilder.group({
            name: new FormControl('', [
                Validators.pattern(/^[A-Za-z0-9\_\-\s]+$/i),
                Validators.maxLength(200),
                Validators.required,
            ]),
            data_length: this.symbolDataLengthFormControl,
            can_id: new FormControl('', [
                Validators.pattern(/[0-9A-Fa-f]*/),
                Validators.maxLength(16)
            ]),
            cycle_time: this.cycleTimeFormControl,
            type: new FormControl(''),
            multipacket: new FormControl(''),
            is_logged: new FormControl(''),
            is_mqtt: new FormControl(''),
            is_retained: new FormControl(''),
            direction: new FormControl(''),
            comment: new FormControl('', [Validators.maxLength(100)]),
        });
    }
}
