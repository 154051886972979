import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ManageFactoryService } from '../services/manage-factory/manage-factory.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomValidator } from '../shared/custom.validators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

    form: FormGroup;

    constructor(private router: Router, private manageService: ManageFactoryService, public snackBar: MatSnackBar, private translate: TranslateService) { }

    ngOnInit() {
        this.form = new FormGroup({
            email: new FormControl('', [Validators.required, CustomValidator.email])

        });
    }

    cancel() {
        this.router.navigate(['']);
    }

    send() {
        this.manageService.forgot.resetToken({
            email: this.form.get('email').value,
            applicationId: "CGW"
        })
            .subscribe(response => {
                this.translate.get("forgot_password.email_sended").subscribe((sended: string) => {
                    this.snackBar.open(sended, "", {
                        duration: 4000,
                        horizontalPosition: 'left'
                    });
                    setInterval(() => {
                        this.router.navigate(['']);
                    }, 4000);
                });
            });
    }

}
