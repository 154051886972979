import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { AppService } from '../app.service';
import { AuthFactoryService } from '../services/auth-factory/auth-factory.service';
import { CustomValidator } from './../shared/custom.validators';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']

})
export class LoginComponent implements OnInit {

    goTo: string = null;

    form = new FormGroup({
        email: new FormControl('', [Validators.required, CustomValidator.email]),
        password: new FormControl('', [Validators.required]),
        rememberMe: new FormControl(false)

    });

    get email() {
        return this.form.get('email');
    }

    get password() {
        return this.form.get('password');
    }

    constructor(
        private authenticationService: AuthFactoryService,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private appService: AppService,
        private authFactoryService: AuthFactoryService
    ) { }

    ngOnInit() {
        // if the user is logged in with remember me option checked, login page will not be accessible
        const isRememberMeChecked = this.authFactoryService.getLoginCookie();
        if (isRememberMeChecked) {
            this.router.navigate(['/']);
            return;
        }
        this.route.paramMap
          .pipe(map(() => window.history.state))
          .subscribe(state => {
            this.goTo = state.goTo || null;
        });
        localStorage.clear();
    }

    login() {
        this.authenticationService.login(
            this.email.value,
            this.password.value,
            this.form.get("rememberMe").value)
            .subscribe(
                result => {
                    this.appService.afterLogin$.next(true);
                    this.router.navigate(this.goTo ? [this.goTo] : ['']);
                },
                error => {
                    if (error.error.message) {
                        alert(error.error.message);
                    } else if (error.status === 401) {
                        this.translate.get('login.invalid_credentials').subscribe((title: string) => {
                            alert(title);
                        });
                    }
                }
            );
    }

    submit() {
        this.email.markAsTouched();
        this.password.markAsTouched();
        if (this.form.valid) this.login();
    }
}
