<video id="video" src="https://spoke.zone/videos/SpokeZoneTopVid.mp4"
       type="video/mp4" autoplay muted loop playsinline class="bg"></video>

<!-- Play video only on screens larger than 992px -->
<script type="text/javascript">
    // on load
    if (document.body.clientWidth >= 992) {
        $("video")[0].play();
    }
    // on resize
    $(window).resize(function () {
        if (document.body.clientWidth >= 992) {
            $("video")[0].play();
        }
    });
</script>

<div class="video-overlay"></div>

<nav class="navbar navbar-dark">
    <div class="navbar-header">
        <img id="nav-image" src="assets/img/favicon.ico"
             alt="Lenticul logo" />
        <span id="spoke">MRS&nbsp;&bull;</span><span
              id="zone">&nbsp;Electronic</span>
    </div>
    <a id="about" class="nav-link" href="https://spoke.zone">{{'login.about' |
        translate}}</a>
</nav>

<form (ngSubmit)="submit()" [formGroup]="form" class="login-container">
    <mat-card class="col-mat-4 col-mat-offset-4">
        <div class="panel-heading">
            <img src="assets/img/mrs-logo.png" width="240px" />
        </div>
        <div class="panel-body">
            <section id="inputForm">
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'login.email' | translate}}</mat-label>
                        <input matInput formControlName="email"
                               class="form-control" required />
                        <mat-error *ngIf="email.touched && email.invalid"
                                   class="alert alert-danger">
                            <mat-error *ngIf="email.errors.required">
                                {{'form.email_required' | translate}}
                            </mat-error>
                            <mat-error *ngIf="!email.errors.required && email.errors.customEmailSpacesDavid">
                                Remove the spaces, David!
                            </mat-error>
                            <mat-error *ngIf="!email.errors.required && email.errors.customEmailSpaces">
                                {{'form.email_not_valid_spaces' | translate}}
                            </mat-error>
                            <mat-error *ngIf="!email.errors.required && email.errors.customEmail">
                                {{'form.email_not_valid' | translate}}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>{{'login.password' | translate}}</mat-label>
                        <input class="input-field" matInput type="password"
                               class="form-control" formControlName="password"
                               required />
                        <mat-error *ngIf="password.touched && password.invalid"
                                   class="alert alert-danger">
                            {{'form.password_is_required' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div layout="row" class="check-link">
                    <mat-checkbox color="mat-indigo" mat-no-ink
                                  aria-label="rememberMe"
                                  formControlName="rememberMe"
                                  class="mat-primary" flex>
                        {{'login.remember_me' | translate}}
                    </mat-checkbox>
                    <a routerLink="/forgotPassword">{{'login.forgot_password' |
                        translate}}</a>
                </div>
                <div class="button-row">
                    <button mat-raised-button type="submit" class="submit-btn">
                        {{'login.sign_in' | translate}}
                    </button>
                </div>
            </section>
        </div>
    </mat-card>
</form>