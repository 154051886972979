import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dealers',
    templateUrl: './dealers.component.html',
    styleUrls: ['./dealers.component.css']
})
export class DealersComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
