<mat-toolbar class="settings">
    <span class="spacer"></span>

    <button mat-icon-button (click)="openFilter()">
        <mat-icon matTooltip="{{'invoice.filter_results' | translate}}">filter_list</mat-icon>
    </button>

    <button
        mat-icon-button
        [disabled]="selection.selected.length < 1"
        (click)="delete()"
    >
        <mat-icon matTooltip="{{'btn.delete' | translate}}">delete</mat-icon>
    </button>

    <mat-form-field>
        <mat-label>{{'invoice.date_presets' | translate}}</mat-label>
        <mat-select
            (selectionChange)="onDatePresetChange($event)"
            [(value)]="selectedDatePreset"
        >
            <mat-option
                *ngFor="let date of datePresets"
                [value]="date.preset_id"
            >
                {{ date.period_name | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-toolbar>

<div class="table-container scrollBelow" (window:resize)="onResize($event)">
    <div>
        <table
            mat-table
            [dataSource]="dataSource"
            matSort
            matSortActive="created"
            matSortDisableClear
            matSortDirection="asc"
        >
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                        *ngIf="multipleSelect"
                        (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="
                            selection.hasValue() && !isAllSelected()
                        "
                    >
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selectRow(row) : null"
                        [checked]="selection.isSelected(row)"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="invoice_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{'invoice.invoice_number' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">{{ row.invoice_number }}</td>
            </ng-container>

            <ng-container matColumnDef="created_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{'invoice.invoice_date' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">{{ row.date_created | date: [dateTimeFormat] }}</td>
            </ng-container>

            <ng-container matColumnDef="customer_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{'invoice.account' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">{{ row.customer_name }}</td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{'invoice.amount' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">$0.00</td>
            </ng-container>

            <ng-container matColumnDef="pay_period">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{'invoice.due_date' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">{{ row.invoice_date | date: [dateTimeFormat] }}</td>
                <td mat-cell *matCellDef="let row">due_date</td>
            </ng-container>

            <ng-container matColumnDef="payment_status_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{'invoice.status' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">{{ getStatusName(1) | translate }}</td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                (dblclick)="doubleClickRow(row)"
                (click)="selectRow(row)"
                [ngClass]="{ 'selected-row': selection.isSelected(row) }"
            ></tr>
        </table>
    </div>

    <!-- show spinner while loading is happening -->
    <div class="loading-shade" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    </div>

    <mat-paginator
        (page)="getPageSize($event)"
        [length]="resultsLength"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageLengthOptions"
        showFirstLastButtons
    ></mat-paginator>

    <div
        *ngIf="dataSource.data.length < 1 && !isLoadingResults"
        class="no-results"
    >
        {{'invoice.no_invoices' | translate}}
    </div>
</div>
