<h1 mat-dialog-title>{{ 'chart_setup.select_add_variables' | translate }}</h1>
<div class='selection-body mat-typography' mat-dialog-content
     (keydown.enter)="handleSave()">
    <mat-form-field class="full-width">
        <mat-label>{{ 'chart_setup.select_symbol' | translate }}
        </mat-label>
    
        <mat-select [(ngModel)]="selectedSymbol" name="symbol" (ngModelChange)="selectedSymbolIdFunc()">
            <ng-container *ngIf="isDevicesExist; else elseSymbols">
                <mat-optgroup *ngFor="let device of deviceSymbols" [label]="device.identifier">
                    <mat-option *ngFor="let symbol of device.symbols" [value]="symbol">
                        {{ symbol.name }}
                    </mat-option>
                </mat-optgroup>
            </ng-container>
    
            <ng-template #elseSymbols>
                <mat-option *ngFor="let symbol of symbols" [value]="symbol">
                    {{ symbol.name }}
                </mat-option>
            </ng-template>
        </mat-select>
    </mat-form-field>

    <div class='centered-container'>
        <button mat-flat-button *ngIf='variableOptions.length > 0'
                color='primary' (click)='selectAllVariables()'>
            <mat-icon svgIcon='check-all' class='btn-icon'></mat-icon>
            <span>{{'chart_setup.select_all_variables' | translate}}</span>
        </button>
        <p *ngIf='variableOptions.length == 0'>
            {{ 'chart_setup.no_symbol_variables' | translate }}
        </p>
    </div>
    <div class='selection-container'>
        <mat-selection-list *ngIf="selectedSymbol" class="full-width">
            <mat-list-option *ngFor="let variable of variableOptions"
                             [checkboxPosition]="before"
                             (click)="toggleVariable(variable)"
                             [value]="variable">{{variable.name}}
            </mat-list-option>
        </mat-selection-list>
    </div>
</div>
<div mat-dialog-actions align="center">
    <button mat-button (click)="handleCancel()">
        {{'btn.cancel' | translate}}
    </button>
    <button mat-flat-button color='primary' (click)="handleSave()">
        {{'btn.ok' | translate}}
    </button>
</div>