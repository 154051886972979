import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


export enum BadRouteKind {
    Unknown = "unknown",
    NotFound = "not-found",
    NotAuth = "not-auth",
}

@Component({
    selector: 'app-bad-route',
    templateUrl: './bad-route.component.html',
    styleUrls: ['./bad-route.component.css']
})
export class BadRouteComponent implements OnInit {
    kinds = BadRouteKind; // give the template access to the enum
    kind: BadRouteKind = BadRouteKind.Unknown;

    constructor(private router: Router, private activatedRoute: ActivatedRoute,
        private translate: TranslateService) { }

    ngOnInit() {
        this.activatedRoute.data.subscribe(data => {
            this.kind = data.kind;
        });
    }

    goHome() {
        this.router.navigate(['']);
    }
}
