<mat-toolbar class="mat-primary">
    <h2>{{ data.title }}</h2>
    <span class="spacer"></span>
    <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>
            close
        </mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <form [formGroup]="form" fxLayout="column">
        <mat-form-field class="chip-list">
            <mat-chip-list #chipList>
                <mat-chip *ngFor="let email of emailAlert.emailAddresses" [selectable]="selectable"
                    [removable]="removable" (removed)="remove(email)">
                    {{email}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="{{'dialog.email_addresses' | translate}}" formControlName="email" [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)">
                <mat-error *ngIf="emailInput.touched && emailInput.invalid" class="alert alert-danger">
                    <mat-error *ngIf="emailInput.errors.emailFormat">{{'form.email_not_valid' | translate}}</mat-error>
                </mat-error>
            </mat-chip-list>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="{{'dialog.email_subject' | translate}}" formControlName="email_subject" required>
            <mat-error *ngIf="emailSubject.touched && emailSubject.invalid" class="alert alert-danger">
                <mat-error *ngIf="emailSubject.errors.required">{{'form.email_subject_required' | translate}}</mat-error>
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <textarea matInput placeholder="{{'dialog.email_body' | translate}}" formControlName="email_body" required></textarea>
            <mat-error *ngIf="emailBody.touched && emailBody.invalid" class="alert alert-danger">
                <mat-error *ngIf="emailBody.errors.required">{{'form.email_body_required' | translate}}</mat-error>
            </mat-error>
        </mat-form-field>
        <div *ngIf="whenArray">
            <mat-form-field class="select-array">
                <mat-select formControlName="when" placeholder="{{'rule_editor.send_alert_when' | translate}}" ariaLabel="Send Alert When">
                    <mat-option *ngFor="let obj of whenArray" [value]="obj.text">
                        {{obj.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
    <div class="interpolation-message">
        {{'alert.interpolation_msg' | translate}}
        <br>
        <a [routerLink]="['../../help/user-doc']" (click)="dialogRef.close()">{{'help.user_documentation' | translate}}</a>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()">
        {{'btn.cancel' | translate}}
    </button>
    <button mat-button class="mat-primary confirm"
        [disabled]="emailBody.invalid || emailSubject.invalid || emailAlert.emailAddresses.length == 0 || (whenArray && !sendWhen.value)"
        (click)="confirm()">
        {{'btn.apply' | translate}}
    </button>
</mat-dialog-actions>