import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-administrator-editor',
  templateUrl: './administrator-editor.component.html',
  styleUrls: ['./administrator-editor.component.css']
})
export class AdministratorEditorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }
}
