import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AssociationDetails, AssociationCategories } from '../../shared/models/common.model';

@Component({
  selector: 'app-assigned-details-dialog',
  templateUrl: './assigned-details-dialog.component.html',
  styleUrls: ['./assigned-details-dialog.component.scss']
})
export class AssignedDetailsDialog implements OnInit {

  AssociationCategories: typeof AssociationCategories;

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<AssignedDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: AssociationDetails
  ) { }

  ngOnInit(): void {
    this.AssociationCategories = AssociationCategories;
  }

  /**
   * Method is called to close the dialog when cancel button is clicked.
  */
  close(): void {
    this.dialogRef.close(false);
  }

  /**
   * Method is called to close the dialog with true value when confirm button is clicked.
  */
  confirm(): void {
    this.dialogRef.close(true);
  }

  /**
   * Method is called to navigate to the desired association and filter
   * the data on that page based on the ids passed
   * @param {string} page page name to which user wants to navigate
   * @param {number} ids ids of the elements need to be filtered
   */
  filterView(page: string, ids: number): void {
    this.dialogRef.close();
    const goTo: string = page.toLowerCase();
    // Take the name of the page and capitalize the first letter
    localStorage.setItem('tab', page.charAt(0).toUpperCase() + page.slice(1));

    if (this.data.symLibrary) {
      this.router.navigate([`${goTo}/${ids}`]);
    } else {
      this.router.navigate([goTo, { from: this.data.previousUrl, filter: ids }]);
    }
  }
}
