import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManageFactoryService } from 'src/app/services/manage-factory/manage-factory.service';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { takeUntil } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { SearchService } from 'src/app/services/search/search.service';
import { Subject } from 'rxjs';
import { DebugLogDataSource } from './debug-log-data-source';

@Component({
  selector: 'app-debug-log-viewer',
  templateUrl: './debug-log-viewer.component.html',
  styleUrls: ['./debug-log-viewer.component.css']
})
export class DebugLogViewerComponent implements OnInit {
  id: string;
  dataSource: any;
  displayedColumns: Array<String>; scrollWidth;
  scrollHeight;
  searchTerm = '';
  searchResults = [];
  searchResultIndex = 0;
  destroy$ = new Subject();
  @ViewChild('viewPort', { static: true }) viewPort: CdkVirtualScrollViewport;
  @ViewChild('tableContainer', { static: true }) tableContainer: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private manageFactory: ManageFactoryService,
    private searchService: SearchService,
  ) {
    this.displayedColumns = ['line-number', 'date', 'time', 'type', 'method', 'message'];
  }

  ngOnInit(): void {
    const rect = this.tableContainer.nativeElement.getBoundingClientRect();
    this.scrollWidth = rect.width;
    this.scrollHeight = rect.height;
    this.activatedRoute.params.pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params['id'] != undefined) {
          this.id = params['id'];
          this.initDatasource();
        }
      });
    this.searchService.searchObservable$.pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.searchTerm = res.value;
        this.searchResults = [];
        this.searchResultIndex = 0;
        if (this.searchTerm.length > 0) {
          this.makeSearchRequest();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  initDatasource() {
    this.dataSource = new DebugLogDataSource({ reportId: this.id, manageFactory: this.manageFactory });
    this.dataSource.attach(this.viewPort);
  }

  /**
   * Highlight a string (add "<mark></mark>" around all the parts that match
   * the search string).
   * @param content (String) - the content to highlight.
   * @returns (String) - the highlighted version of the content.
   */
  highlight(content): String {
    if (this.searchTerm.length == 0) {
      return content;
    }
    // https://stackoverflow.com/a/6969486
    let search = this.searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // https://stackoverflow.com/a/52747318
    let re = new RegExp(search, 'gi');
    return content.replace(re, "<mark>$&</mark>");
  }

  rowStyle(lineIndex) {
    var isCurrentSearchResult = false;
    if (this.searchResults.length > 0) {
      isCurrentSearchResult = this.searchResults[this.searchResultIndex] == lineIndex;
    }
    return { 'background-color': isCurrentSearchResult ? '#F0F0F0' : 'white' };
  }

  private makeSearchRequest() {
    let params = new HttpParams().set('searchTerm', this.searchTerm);
    this.manageFactory.reports
      .searchDebugLog({ id: this.id, params: params }).subscribe((result) => {
        this.searchResults = Object.values(result);
        if (this.searchResults.length == 0) {
          return;
        }
        // Search for a result below the current line.
        let currentLine = this.viewPort.getRenderedRange().start;
        var i = 0;
        while (i < this.searchResults.length && this.searchResults[i] < currentLine) {
          i++;
        }
        // Wrap around to first search result, because there are no results
        // below the current position.
        if (i == this.searchResults.length) {
          i = 0;
        }
        this.scrollToSearchResult(i);
      });
  }

  isLineVisible(lineIndex): boolean {
    // These are needed to check if a line is visible on the screen.
    let toolbarRect = document.getElementById('debug-log-toolbar').getBoundingClientRect();
    let maxY = Math.max(document.documentElement.clientHeight, window.innerHeight);
    // Get the line element.
    let element = document.getElementById('line-' + lineIndex);
    // Check if the line is visible to the viewer.
    var isVisible = false;
    if (element) {
      var rect = element.getBoundingClientRect();
      if (rect.top >= toolbarRect.bottom && rect.bottom <= maxY) {
        isVisible = true;
      }
    }
    return isVisible;
  }

  scrollToSearchResult(searchResultIndex: number) {
    // Do a proper modulo (for handling negatives).
    let count = this.searchResults.length;
    this.searchResultIndex = ((searchResultIndex % count) + count) % count;
    // Get the lineIndex of the search result.
    let lineIndex = this.searchResults[this.searchResultIndex];

    // If the user can't see the line, scroll to it.
    if (!this.isLineVisible(lineIndex)) {
      this.dataSource.jumpTo(lineIndex);
    }
  }
  nextSearchResult() {
    this.scrollToSearchResult(this.searchResultIndex + 1);
  }

  previousSearchResult() {
    this.scrollToSearchResult(this.searchResultIndex - 1);
  }
}
