import { Component, Input, OnInit } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FeedbackComponent } from '../dialogs/feedback/feedback.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Documentation, SessionStorage } from '../shared/models/common.model';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.css', './help.component.scss']
})
export class HelpComponent implements OnInit {
    markdown: string;
    feedback: MatDialogRef<FeedbackComponent>;
    isShow: boolean;
    topPosToStartShowing = 100;
    selectedDoc: string;
    document: string;

    constructor(
        public dialog: MatDialog,
        private markdownService: MarkdownService,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
    ) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe( p => {
            this.document = p['doc'];
            if (this.document) {
                if (this.document == 'send-feedback') {
                    this.show('user-doc');
                    this.sendFeedback();
                } else {
                    this.show(this.document);
                }
            } else {
                this.show('user-doc');
            }
            this.initMarkdown();
        });
    }

    checkScroll() {
        let el = document.getElementById('mark');

        if (el.scrollTop >= this.topPosToStartShowing) {
            this.isShow = true;
        } else {
            this.isShow = false;
        }
    }

    private initMarkdown() {
        this.markdownService.renderer.heading = (text: string, level: number) => {
            const escapedText = text.toLowerCase().replace(/[^\w]+/g, '-');
            return '<h' + level + '>' +
                '<a id="'+ escapedText +'">' +
                '<span class="header-link"></span>' +
                '</a>' + text +
                '</h' + level + '>';
        };
        this.markdownService.renderer.link = (href, title, text) => {
            if (this.document === Documentation.User) {
                return this.generateLinkWithParams(href, title, text);
            } else {
                return this.generateLink(href, title, text);
            }
        };
    }

    /**
     * Generates a link with query parameters based on the provided href.
     * @param {string} href - The link URL.
     * @param {string} title - The link title.
     * @param {string} text - The link text.
     * @returns {string} The generated link HTML string.
     */
    generateLinkWithParams(href: string, title: string, text: string): string {
        const userRole: string = sessionStorage.getItem(SessionStorage.CustomerRole);
        const linkWithParams: string = `${href.replace(/'/g, '')}?role=${userRole}`;
        return `<a href="${linkWithParams}" target="_blank" rel="noopener noreferrer">${text}</a>`;
    }

    /**
     * Generates a regular link based on the provided href.
     * @param {string} href - The link URL.
     * @param {string} title - The link title.
     * @param {string} text - The link text.
     * @returns {string} The generated link HTML string.
     */
    generateLink(href, title, text): string {
        return `<a href="help/${this.document + href.toLowerCase()}">${text}</a>`;
    }

    show(doc: string) {
        this.selectedDoc = doc;

        let sub_path = this.translate.currentLang || this.translate.getDefaultLang();
        if (sub_path && sub_path !== 'en') {
            sub_path = sub_path + '/';
        } else {
            sub_path = '';
        }

        switch (doc) {
            case "api-doc":
                this.markdown = "../assets/docs/"+sub_path+"apidoc.md";
                break;
            case "license":
                this.markdown = "../assets/docs/"+sub_path+"LICENSE.md";
                break;
            case "terms":
                this.markdown = "../assets/docs/"+sub_path+"terms.md";
                break;
            case "user-doc":
            default:
                this.markdown = "../assets/docs/"+sub_path+"userdoc.md";
                break;
        }
    }

    sendFeedback() {
        this.feedback = this.dialog.open(FeedbackComponent, {
            disableClose: true
        });
    }
}
