import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'orderByTranslation'
})
export class OrderByTranslationPipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(array: Array<any>, key: string = 'name'): Array<any> {
    array.sort((a: any, b: any) => {
      if (this.translate.instant(a[key]) < this.translate.instant(b[key]))
        return -1;
      else if (this.translate.instant(a[key]) > this.translate.instant(b[key]))
        return 1;
      else
        return 0;
    });
    return array;
  }
}
