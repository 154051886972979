import { Component, OnInit, Inject } from '@angular/core';
import { ManageFactoryService } from '../../services/manage-factory/manage-factory.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AssignedDetailsDialog } from '../assigned-details-dialog/assigned-details-dialog.component';
import { FirmwareDialogTranslations, IConfirmDialogData, Icon, AssociatedCount, SymLibraryDialogTranslations, AssociationDetails } from 'src/app/shared/models/common.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-update-symbol-dialog',
    templateUrl: './update-symbol-dialog.component.html',
    styleUrls: ['./update-symbol-dialog.component.css']
})
export class UpdateSymbolDialogComponent implements OnInit {
    item: any;
    restrictions: any;
    baudRateArray: any;
    busNumberArray: any;
    protocolArray: any;
    isSym: boolean;
    isActiveLibPresent: boolean = false;

    constructor(private manageFactory: ManageFactoryService, public dialogRef: MatDialogRef<UpdateSymbolDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService, private dialog: MatDialog) { }

    ngOnInit() {
        this.item = { ...this.data.item };
        this.restrictions = {};
        if (this.data.restrictions) {
            if (this.data.restrictions.has('symbol_library')) {
                this.restrictions = this.data.restrictions.get('symbol_library');
            }
        }
        this.isSym = this.item.hasOwnProperty('library_id') ? true : false;
        this.manageFactory.models.getBaudRates()
            .subscribe((result: Array<any>) => {
                if (this.restrictions['baud_rate_ids']) {
                    result = result.filter((item) => this.restrictions['baud_rate_ids'].includes(item['baud_rate_id']));
                }
                this.baudRateArray = result;
            });
        this.manageFactory.models.getBusNumbers()
            .subscribe((result: Array<any>) => {
                if (this.restrictions['bus_number_ids']) {
                    result = result.filter((item) => this.restrictions['bus_number_ids'].includes(item['bus_number_id']));
                }
                this.busNumberArray = result;
            });
        this.manageFactory.models.getProtocols()
            .subscribe((result: Array<any>) => {
                if (this.restrictions['protocol_ids']) {
                    result = result.filter((item) => this.restrictions['protocol_ids'].includes(item['protocol_ref_id']));
                }
                this.protocolArray = result;
            })

        this.dialogRef.disableClose = true;
    }

    cancel() {
        this.dialogRef.close('delete');
    }

    confirm() {
        if (this.isSym) {
            let bus = this.busNumberArray.find(x => x['bus_number_id'] == this.item['bus_number_id']);
            this.item['bus_number_type'] = bus['bus_number_type'];

            let proto = this.protocolArray.find(x => x['protocol_ref_id'] == this.item['protocol_id']);
            this.item['protocol_name'] = proto['protocol_name'];

            let baud = this.baudRateArray.find(x => x['baud_rate_id'] == this.item['baud_rate_id']);
            this.item['baud_rate_id'] = baud ? baud['baud_rate_id'] : null;
            this.item['baud_rate_value'] = baud ? baud['baud_rate_value'] : null;
        };

        this.dialogRef.close(this.item);
    }

    /**
     * Checks the association status and shows a confirmation dialog if there are associations found.
     * If the item is not active, it retrieves the associated count and displays a dialog for confirmation.
     */
    checkAssociation(): void {
        let dialog: MatDialogRef<AssignedDetailsDialog>;
        const dialogDesc: string = SymLibraryDialogTranslations.AssociationFound;
        const dialogTitle: string = SymLibraryDialogTranslations.ConfirmDisable;
        const confirmBtn: string = SymLibraryDialogTranslations.Confirm;

        if (!this.item.is_active) {
            const modelId: number = this.item.model_id;
            const libraryId: number = this.item.library_id;

            this.manageFactory.models.getAssociationCount(modelId, libraryId).subscribe((result: AssociatedCount) => {
                const associatedCount: AssociatedCount = result;
                const everyValueEmptyCheck: boolean = result.values.some(key => key.count > 0);

                if (everyValueEmptyCheck) {
                    this.translate.get([dialogTitle, confirmBtn, dialogDesc]).subscribe((translations: string[]) => {
                        dialog = this.dialog.open(AssignedDetailsDialog, {
                            data: {
                                title: translations[dialogTitle],
                                text: translations[dialogDesc],
                                confirm: translations[confirmBtn],
                                dependedCount: associatedCount,
                                symLibrary: true
                            } as AssociationDetails
                        });
                        dialog.afterClosed().subscribe(response => {
                            this.item.is_active = response ? false : true;
                            if (!response) {
                                this.dialogRef.close();
                            }
                        });
                    });
                }
            });
        }
    }

    /**
     * Shows an alert dialog based on the given item check and firmware status.
     * @param {boolean} itemCheck - Indicates whether the item is checked.
    */
    showAlert(itemCheck: boolean): void {
        if (itemCheck && this.data?.isFirmwareActive) {
            const icon: string = Icon.Warning;
            const translationsArray: string[] = [];
            translationsArray.push(FirmwareDialogTranslations.FirmwareChangeTitle);
            translationsArray.push(FirmwareDialogTranslations.FirmwareChangeText);
            translationsArray.push(FirmwareDialogTranslations.ConfirmBtn);

            this.translate.get(translationsArray).subscribe((translated: string[]) => {
                const dialog: MatDialogRef<ConfirmDialogComponent, boolean> = this.dialog.open(ConfirmDialogComponent, {
                    data: {
                        title: translated[FirmwareDialogTranslations.FirmwareChangeTitle],
                        text: translated[FirmwareDialogTranslations.FirmwareChangeText],
                        confirm: translated[FirmwareDialogTranslations.ConfirmBtn],
                        icon: icon
                    } as IConfirmDialogData
                });

                dialog.afterClosed()
                    .subscribe((response: boolean) => {
                        if (!response) {
                            this.item.is_active = false;
                            return;
                        }
                    });
            });
        }
    }

    /**
     * Check if a active symbol library is available for the selected bus.
     * @param event - The MatSelectChange event object containing the selected value.
    */
    checkActiveLibrary(event: MatSelectChange) {
        this.isActiveLibPresent = this.data.symbolData.some(s => s.bus_number_id == event.value && s.is_active == 1);
    }
}
