import { AboutComponent } from './about/about.component';
import { AccountSettingsComponent } from "./account-settings/account-settings.component";
import { AdDirective } from "./shared/Directives/ad.directive";
import { AdministratorEditorComponent } from './administrator-editor/administrator-editor.component';
import { AdministratorsComponent } from "./administrators/administrators.component";
import { AgmCoreModule } from "@agm/core";
import { AlertEditorComponent } from "./alert-editor/alert-editor.component";
import { AlertEmailDialogComponent } from "./dialogs/alert-email-dialog/alert-email-dialog.component";
import { AlertsComponent } from "./alerts/alerts.component";
import { AlertTextDialogComponent } from "./dialogs/alert-text-dialog/alert-text-dialog.component";
import { AppComponent } from "../app/app.component";
import { ApplyCustomerDialogComponent } from "./dialogs/apply-customer-dialog/apply-customer-dialog.component";
import { ApplyModelDialogComponent } from "./dialogs/apply-model-dialog/apply-model-dialog.component";
import { AppMaterialModule } from "./services/app-material/app-material.module";
import { AppRoutingModule } from "./app-routing.module";
import { AuthFactoryService } from "./services/auth-factory/auth-factory.service";
import { BadRouteComponent } from "./bad-route/bad-route.component";
import {
    BreakPoint,
    BREAKPOINTS,
    DEFAULT_BREAKPOINTS,
    FlexLayoutModule
} from "@angular/flex-layout";
import { BrowserAnimationsModule, NoopAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule, HammerModule } from "@angular/platform-browser";
import { ChartSetupDialogComponent } from './dialogs/chart-setup-dialog/chart-setup-dialog.component';
import { ClientEditorComponent } from "./client-editor/client-editor.component";
import { ClientsComponent } from "./clients/clients.component";
import { ClipboardModule } from 'ngx-clipboard';
import { ConfirmDialogComponent } from "./dialogs/confirm-dialog/confirm-dialog.component";
import { CookieService } from "ngx-cookie-service";
import { CustomDateAdapter } from "./services/customDateAdapter";
import { CustomWidgetEditorComponent } from './custom-widget-editor/custom-widget-editor.component';
import { CustomWidgetsComponent } from './custom-widgets/custom-widgets.component';
import { CustomerEditorComponent } from "./customer-editor/customer-editor.component";
import { DashboardsComponent } from "./dashboards/dashboards.component";
import { DateAdapter, MatNativeDateModule } from "@angular/material/core";
import { DealerEditorComponent } from "./dealer-editor/dealer-editor.component";
import { DealersComponent } from "./dealers/dealers.component";
import { DebugLogViewerComponent } from "./log-chart/containers/debug-log-viewer/debug-log-viewer.component";
import { DeviceDashboardDialogComponent } from "./dialogs/device-dashboard-dialog/device-dashboard-dialog.component";
import { DeviceFilterDialogComponent } from "./dialogs/device-filter-dialog/device-filter-dialog.component";
import { DevicesComponent } from "./devices/devices.component";
import { DeviceSimDialogComponent } from "./dialogs/device-sim-dialog/device-sim-dialog.component";
import { FeedbackComponent } from "./dialogs/feedback/feedback.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HelpComponent } from "./help/help.component";
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientJsonpModule,
    HttpClientModule
} from "@angular/common/http";
import { MqttModule, MqttService } from "ngx-mqtt";
import { InvoiceEditorComponent } from "./invoice-editor/invoice-editor.component";
import { InvoiceFilterDialogComponent } from "./dialogs/invoice-filter-dialog/invoice-filter-dialog.component";
import { InvoicesComponent } from "./invoices/invoices.component";
import { LeafletDrawModule } from "@asymmetrik/ngx-leaflet-draw";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { ListViewComponent } from "./list-view/list-view.component";
import { LiveMapComponent } from "./live-map/live-map.component";
import { LoginComponent } from "./login/login.component";
import { ManageFactoryService } from "./services/manage-factory/manage-factory.service";
import { MarkdownModule, MarkedOptions, MarkedRenderer, SECURITY_CONTEXT } from "ngx-markdown";
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { ModelsComponent } from "./models/models.component";
import { MonacoEditorModule } from 'src/app/monaco-editor/monaco-editor.module';
import { NgModule, SecurityContext } from "@angular/core";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { OemEditorComponent } from "./oem-editor/oem-editor.component";
import { OemsComponent } from "./oems/oems.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ResetPasswordSuccessComponent } from "./reset-password-success/reset-password-success.component";
import { RootComponent } from "./root/root.component";
import { RuleEditorComponent } from "./rule-editor/rule-editor.component";
import { RulesComponent } from "./rules/rules.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { SearchService } from "./services/search/search.service";
import { SelectDialogComponent } from "./dialogs/select-dialog/select-dialog.component";
import { TextInputDialogComponent } from "./dialogs/text-input-dialog/text-input-dialog.component";
import { TokenInterceptor } from "./services/token.interceptor";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { UpdatePasswordComponent } from "./dialogs/update-password/update-password.component";
import { UpdateSymbolDialogComponent } from "./dialogs/update-symbol-dialog/update-symbol-dialog.component";
import { UserComponent } from "./user/user.component";
import { UsersComponent } from "./users/users.component";
import { UtcDatePipe } from "./invoices/utc-date.pipe";
import { WindowRef } from "./services/windowRef";
import { VariableSelectionDialog } from './dialogs/variable-selection-dialog/variable-selection-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AssignedDetailsDialog } from './dialogs/assigned-details-dialog/assigned-details-dialog.component';
import { FrequencyDistributionDialogComponent } from './dialogs/frequency-distribution-dialog/frequency-distribution-dialog.component';
import { GenerateServiceDowntimeComponent } from './generate-service-downtime/generate-service-downtime.component';
import { ServiceDowntimeComponent } from './service-downtime/service-downtime.component';
import { TimeDifferenceDialog } from './dialogs/time-difference-dialog/time-difference-dialog.component';

export function markedOptions(): MarkedOptions {
    const renderer = new MarkedRenderer();

    renderer.blockquote = (text: string) => {
        return (
            '<blockquote class="blockquote"><p>' + text + "</p></blockquote>"
        );
    };

    return {
        renderer: renderer,
        gfm: true,
        breaks: false,
        pedantic: false,
        smartLists: true,
        smartypants: false
    };
}

const CUSTOM = {
    'xs': 'screen and (max-width: 654px)',
    'gt-xs': 'screen and (min-width: 655px)',
    'sm': 'screen and (min-width: 655px) and (max-width: 1023px)',
    'gt-sm': 'screen and (min-width: 1024px)',
    'md': 'screen and (min-width: 1024px) and (max-width: 1440px)',
    'gt-md': 'screen and (min-width: 1440px)',
    'lg': 'screen and (min-width: 1440px) and (max-width: 1919px)',
    'gt-lg': 'screen and (min-width: 1920px)',
    'xl': 'screen and (min-width: 1920px) and (max-width: 5000px)',
};

function updateBreakpoints(it: BreakPoint) {
    const mq = CUSTOM[it.alias];
    if (!!mq) {
        it.mediaQuery = mq;
    }
    return it;
}

export function CUSTOM_BREAKPOINT_FACTORY() {
    return DEFAULT_BREAKPOINTS.map(updateBreakpoints);
}

@NgModule({
    declarations: [
        ApplyModelDialogComponent,
        ApplyCustomerDialogComponent,
        AppComponent,
        LoginComponent,
        UsersComponent,
        RootComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        ResetPasswordSuccessComponent,
        OemsComponent,
        DealersComponent,
        ClientsComponent,
        UsersComponent,
        DevicesComponent,
        ModelsComponent,
        AlertsComponent,
        LiveMapComponent,
        FeedbackComponent,
        DashboardsComponent,
        AccountSettingsComponent,
        UpdatePasswordComponent,
        ListViewComponent,
        OemEditorComponent,
        DealerEditorComponent,
        ClientEditorComponent,
        ConfirmDialogComponent,
        TextInputDialogComponent,
        AlertEditorComponent,
        UserComponent,
        UpdateSymbolDialogComponent,
        AlertEmailDialogComponent,
        AlertTextDialogComponent,
        DeviceFilterDialogComponent,
        CustomerEditorComponent,
        RulesComponent,
        RuleEditorComponent,
        DeviceFilterDialogComponent,
        SelectDialogComponent,
        DeviceSimDialogComponent,
        DeviceDashboardDialogComponent,
        BadRouteComponent,
        InvoicesComponent,
        InvoiceFilterDialogComponent,
        InvoiceEditorComponent,
        HelpComponent,
        UtcDatePipe,
        AdDirective,
        DebugLogViewerComponent,
        AdministratorsComponent,
        AdministratorEditorComponent,
        AboutComponent,
        CustomWidgetsComponent,
        CustomWidgetEditorComponent,
        ChartSetupDialogComponent,
        VariableSelectionDialog,
        FrequencyDistributionDialogComponent,
        AssignedDetailsDialog,
        GenerateServiceDowntimeComponent,
        ServiceDowntimeComponent,
        TimeDifferenceDialog
    ],
    imports: [
        FlexLayoutModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppMaterialModule,
        NoopAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatSelectModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MonacoEditorModule,
        NgxMatSelectSearchModule,
        LeafletModule.forRoot(),
        LeafletDrawModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: "AIzaSyBswgBptFslar0h-rkEC-QZM8WpPZFbgJg"
        }),
        MqttModule.forRoot({ connectOnCreate: false }),
        MarkdownModule.forRoot({
            loader: HttpClient,
            sanitize: SecurityContext.NONE, // If the sanitizer is not turned off, Angular DomSanitizer removes the id attribute as it is considered "unsafe" for XSS vulnerability. Read more on: https://github.com/jfcere/ngx-markdown/issues/211
            markedOptions: {
                provide: MarkedOptions,
                useFactory: markedOptions
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HammerModule,
        ScrollingModule,
        ClipboardModule,
        DragDropModule
    ],
    exports: [],
    providers: [
        AuthFactoryService,
        CookieService,
        ManageFactoryService,
        SearchService,
        WindowRef,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        MqttService,
        CustomDateAdapter,
        { provide: DateAdapter, useClass: CustomDateAdapter },
        {
            provide: BREAKPOINTS,
            useFactory: CUSTOM_BREAKPOINT_FACTORY
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        '/assets/i18n/',
        '.json'
    );
}
