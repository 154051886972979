<mat-toolbar class="mat-primary">
    <h2>{{'dialog.select_dashboard' | translate}}</h2>
    <span class="spacer"></span>
    <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>
            close
        </mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <form [formGroup]="form" fxLayout="column">
        <mat-form-field class="select-array">
            <mat-select formControlName="dashboardId" placeholder="{{'dialog.dashboard_name' | translate}}">
                <mat-option *ngFor="let obj of dashboardArray" [value]="obj.dashboard_id">
                    {{obj.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="checkbox-wrap">
          <mat-checkbox formControlName="oldDashboard">Legacy</mat-checkbox>
          <mat-checkbox formControlName="newTab">Open in a new tab</mat-checkbox>
        </div>

        </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()">
        {{'btn.cancel' | translate}}
    </button>
    <button mat-button class="mat-primary confirm" (click)="create()">
        {{'btn.create_new' | translate}}
    </button>
    <button mat-button class="mat-primary confirm" [disabled]="!form.valid" (click)="confirm()">
        {{'btn.goto' | translate}}
    </button>
</mat-dialog-actions>
