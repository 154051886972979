export const enum CustomerRole {
    Super = 0,
    Administrator = 1,
    OEM = 2,
    Dealer = 3,
    Client = 4,
    Mobile = 5,
    Device = 6
};

export const enum UserRole {
    ReadWrite = 1,
    ReadOnly = 2,
    Disabled = 3
};

export const appConstants = Object.freeze({
    role_super: 0,
    role_administrator: 1,
    role_oem_user: 2,
    role_dealer_user: 3,
    role_client_user: 4,

    user_read_write: 1,
    user_read_only: 2,

    user_locked: 1,
    user_unlocked: 0,

    device_active: 1,
    device_online: 2,
    device_disabled: 3,
});

export class DashboardConstants {
    public static WidgetDeviceStatus = {
        HEIGHT: 6,
        VALUE: 'datasources["Lenticul MQTT"]["mon/device_state"]'
    };

    public static TableWidget = {
        HEIGHT: 2,
        ROW: 0,
        COL: 0
    };
}

export const Coordinates = {
    MAX_LAT: 85,
    MIN_LAT: -85,
    MAX_LNG: 180,
    MIN_LNG: -180,
    INVALID_COORDINATE: 1e-10
};

export const DEFAULT_PRECISION_VALUE: number = 5;
export const DEFAULT_COUNTRY = 'DE';
export const DOWNTIME_SUBSCRIBE_ADDRESS = 'mrs/web/service_downtime';
export const MINIMUM_VALUE: number = 0.00001;
export const DEFAULT_TIME_DIFFERENCE: number = 5;
