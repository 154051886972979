<form [formGroup]="form" class="container" (ngSubmit)="send()">
    <div class="panel panel-default">
        <mat-card-header>
            <mat-card-title>
                <strong>{{'forgot_password.title' | translate}}</strong>
            </mat-card-title>
        </mat-card-header>
    </div>
    <mat-card>
        <div class="panel-body">
            <section id="inputForm">
                <div style="text-align:center;">{{'forgot_password.desc' | translate}}</div>
                <div>
                    <mat-form-field class="input-field">
                        <input matInput formControlName="email" class="form-control" placeholder="{{'forgot_password.email' | translate}}" required>
                        <mat-error *ngIf="form.get('email').touched && form.get('email').invalid"
                            class="alert alert-danger">
                            <mat-error *ngIf="form.get('email').errors.required">{{'form.email_required' | translate}}</mat-error>
                            <mat-error *ngIf="form.get('email').errors.customEmail">{{'form.email_not_valid' | translate}}</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="button-row">
                    <button mat-raised-button type="button" class="cancel-btn" (click)="cancel()">{{'btn.cancel' | translate}}</button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <button mat-raised-button mat-primary class="send-btn" [disabled]="!form.valid" type="submit">{{'btn.send_email' | translate}}</button>
                </div>
            </section>
        </div>
    </mat-card>
</form>