<form
    [formGroup]="invoiceForm.group"
    autocomplete="off"
    fxLayout="column wrap"
    fxLayoutAlign="center center"
    fxLayoutGap="10px"
    (ngSubmit)="save(invoiceForm.value)"
>
    <div fxLayout="column" fxLayoutAlign="center">
        <div fxLayout="row" fxLayoutAlign="center">
            <div>
                <mat-icon class="form-icon">date_range</mat-icon>
            </div>
            <mat-form-field>
                <mat-label>{{'invoice_editor.year' | translate}}</mat-label>
                <mat-select
                    (selectionChange)="changeSelectedYear($event)"
                    class="form-control"
                    formControlName="year"
                >
                    <mat-option *ngFor="let year of years" [value]="year">
                        {{ year.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="center">
            <div>
                <mat-icon class="form-icon">today</mat-icon>
            </div>
            <mat-form-field>
                <mat-label>{{'invoice_editor.pay_period' | translate}}</mat-label>
                <mat-select class="form-control" formControlName="payPeriod">
                    <mat-option *ngFor="let pay of payPeriods" [value]="pay">
                        {{ pay.monthDay }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="center">
            <div>
                <mat-icon class="form-icon">account_circle</mat-icon>
            </div>
            <div class="account">
                <mat-form-field>
                    <mat-label>{{'invoice_editor.account' | translate}}</mat-label>
                    <mat-select class="form-control" formControlName="customer">
                        <mat-option
                            *ngFor="let customer of customers"
                            [value]="customer"
                        >
                            {{ customer.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div fxLayout="row">
            <div>
                <mat-icon class="form-icon">payment</mat-icon>
            </div>
            <mat-slide-toggle class="mat-primary" formControlName="paid">
                {{'invoice_editor.was_paid' | translate}}
            </mat-slide-toggle>

            <mat-checkbox formControlName="overdue">{{'invoice_editor.overdue' | translate}}</mat-checkbox>
            <mat-checkbox formControlName="shutoff">{{'invoice_editor.shutoff' | translate}}</mat-checkbox>
        </div>

        <div fxLayout="row" fxLayoutAlign="center">
            <div>
                <mat-icon class="form-icon">send</mat-icon>
            </div>
            <mat-slide-toggle class="mat-primary" formControlName="sendInvoice">
                {{'invoice_editor.send_invoice' | translate}}
            </mat-slide-toggle>
        </div>
    </div>
</form>

<div class="save-cancel">
    <button mat-raised-button (click)="cancel()">{{'btn.cancel' | translate}}</button>
    <button
        mat-raised-button
        [disabled]="!invoiceForm.valid"
        (click)="save(invoiceForm)"
    >
        {{'btn.save' | translate}}
    </button>
</div>
