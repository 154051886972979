import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef, EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output, ViewChild
} from '@angular/core';
import moment from 'moment';
import { ChartSyncService } from 'src/app/services/chart-sync/chart-sync.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { ChartType, MomentCategory } from 'src/app/shared/models/common.model';
import { Marker } from '../chart.model';
import { minutesToSeconds } from 'src/app/shared/common';
import { DEFAULT_TIME_DIFFERENCE } from 'src/app/services/constants';


@Component({
  selector: 'map-log',
  templateUrl: './map-log.component.html',
  styleUrls: ['./map-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapLogComponent implements OnInit, OnDestroy {
  @ViewChild('elem', { static: true, read: ElementRef }) mapElem: ElementRef;

  @Input() withActions: boolean;
  @Input() gpsFrequency: number;
  markerChunks: Marker[][][];
  timeDifference: number = minutesToSeconds(DEFAULT_TIME_DIFFERENCE);
  strokeColor: string = 'red';
  strokeWeight: number = 2;
  defaultTimeDifference: number = DEFAULT_TIME_DIFFERENCE;
  @Output() mapLoaded = new EventEmitter();

  @Input('data') set mapData(data) {
    if (data && data.length) {
      this.markers = [];
      this.markerChunks = [];

      this.zone.runOutsideAngular(() => {
        data.forEach(d => {
          let result;
          const len = Math.floor(d.length / 2);
          for (let i = 1; i <= len; i++) {
            if (d[(i * 2) - 1]) { // check is not null
              let lat = +d[(i * 2) - 1];
              let lng = +d[i * 2];

              if (!this.coordinatesAreValid(lat, lng)) {
                return;
              }

              lat = lat > this.MAX_LAT ? this.MAX_LAT : lat;
              lat = lat < this.MIN_LAT ? this.MIN_LAT : lat;

              lng = lng > this.MAX_LNG ? this.MAX_LNG : lng;
              lng = lng < this.MIN_LNG ? this.MIN_LNG : lng;

              result = {
                lat, // [Date, lat, lon, lat, lon, ...] - i suggest that 1 - is lat, 2 -lon, 3-lat, ...
                lng,
                label: d[0] ? `${moment(d[0]).format(this.dateTimeService.getDateAndTimeFormat(true))}` : '',
                time: d[0],
                draggable: false,
                isOpenInfo: false
              };

              if (!this.markers[i - 1]) {
                this.markers[i - 1] = [];
              }

              this.markers[i - 1].push(result);
            }
          }
        });

        setTimeout(() => {
          this.createChunks(this.markers);
          this.cdr.detectChanges();
        });

        this.chartSyncService.markers = this.markers;

        if (this.markers && this.markers.length && this.markers[0] && this.markers[0].length) {
          this.marker = {
            lat: this.markers?.length ? this.markers[0][0]?.lat : 0,
            lng: this.markers?.length ? this.markers[0][0]?.lng : 0,
            label: this.markers?.length ? this.markers[0][0]?.label : ''
          };
        }

        setInterval(() => {
          this.cdr.detectChanges();
        }, 500);
      });
    }
  }

  MAX_LAT = 85;
  MIN_LAT = -85;
  MAX_LNG = 180;
  MIN_LNG = -180;

  imgSrc = '/assets/img/circle.svg';
  startIcon = {
    url: '/assets/img/pin.svg',
    scaledSize: {
      height: 30,
      width: 30,
    }
  };
  endIcon = {
    url: '/assets/img/finish-pin.svg',
    scaledSize: {
      height: 30,
      width: 30,
    }
  };
  zoom = 8;
  markers: any[] = [];
  interval;
  count = 0;
  offset = '';

  constructor(
    private zone: NgZone,
    private chartSyncService: ChartSyncService,
    private cdr: ChangeDetectorRef,
    private dateTimeService: DateTimeService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.mapLoaded.emit(true);
  }

  ngOnDestroy(): void {}
  

  public get isChartsSync(): boolean {
    return this.chartSyncService.isChartsSync;
  }

  public get marker(): any {
    return this.chartSyncService.marker;
  }

  public set marker(value: any) {
    this.chartSyncService.marker = value;
  }

  /**
  * Check if the value is inside the range.
  * @param {Number} value
  * @param {Number} min
  * @param {Number} max
  * @returns
  */
  isInBounds(value, min, max) {
    return value >= min && value <= max;
  }

  /**
  * Check that the coordinates are valid.
  * @param {Number} lat
  * @param {Number} lon
  * @returns
  */
  coordinatesAreValid(lat, lon) {
    return (this.isInBounds(lat, this.MIN_LAT, -1e-10) || this.isInBounds(lat, 1e-10, this.MAX_LAT))
      && (this.isInBounds(lon, this.MIN_LNG, -1e-10) || this.isInBounds(lon, 1e-10, this.MAX_LNG));
  }

  /**
   * Highlights a chart point on the map.
   * @param {Date} date - The date used to highlight a chart point.
   */
  getDateTime(date: Date): void {
    this.chartSyncService.highlightChartPoint(date, ChartType.CHART);
  }

  /**
   * Creates chunks from markers
   * @param {Marker[][]} markers - Array of markers
   */
  createChunks(markers: Marker[][]): void {
    let chunkArr: Marker[] = [];
    let markersArray: Marker[] = markers[0];
    this.markerChunks = [];

    markersArray.forEach((d, index) => {
      if (!markersArray[index + 1]) {
        return;
      }
      const currentElementDate: moment.Moment = moment(d.time);
      const nextElementDate: moment.Moment = moment(markersArray[index + 1].time);
      let getSecondsValue: number = nextElementDate.diff(currentElementDate, MomentCategory.Seconds);
      
      if (getSecondsValue >= this.timeDifference) {
        chunkArr.push(d);
        this.markerChunks.push([chunkArr]);
        chunkArr = [];
      } else {
        chunkArr.push(d);
      }
    });

    if (chunkArr.length > 0) {
      this.markerChunks.push([chunkArr]);
    }
  }
}
