import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-oems',
    templateUrl: './oems.component.html',
    styleUrls: ['./oems.component.css']
})
export class OemsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
