import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { Injectable } from "@angular/core";

@Injectable()
export class SwipeHammerConfig extends HammerGestureConfig {
	overrides = <any>{
		'swipe': { velocity: 0.4, threshold: 20 }, // override default settings
		'pan': { touchAction: 'auto', direction: 6 },
        'pinch': { enable: false },
        'rotate': { enable: false }
	};
}
