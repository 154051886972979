import { Invoice } from "./invoice";

export class SaveInvoiceData {
    constructor(
        readonly invoice_id: number,
        readonly invoice_number: string,
        readonly invoice_date: string,
        readonly due_date: string,
        readonly date_sent: string,
        readonly date_paid: string,
        readonly status: number,
        readonly comments: string,
        readonly date_created: string,
        readonly customer_id: number
    ) {}
}

export class SaveInvoice {
    constructor(readonly invoice: SaveInvoiceData, readonly items: []) {}
}
