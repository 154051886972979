<mat-toolbar class="mat-primary">
    <div style="min-width: 180px">
        <h2>{{'dialog.talk_to_us' | translate}}</h2>
    </div>
    <span class="spacer"></span>
    <button mat-icon-button (click)="closeDialog()">
        <mat-icon>
            <i class="material-icons">close</i>
        </mat-icon>
    </button>
</mat-toolbar>
<mat-progress-bar mode="indeterminate" *ngIf="sending"></mat-progress-bar>

<mat-dialog-content layout="row" [formGroup]="form">
    <mat-form-field class="full-width">
        <mat-label>{{ 'feedback.select_topic' | translate }}</mat-label>
        <mat-select formControlName="topic" class="form-control">
            <mat-option *ngFor="let topic of topicsArray" [value]='topic.key'>{{ topic.name | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width">
        <textarea matInput formControlName="body" class="form-control" required rows="3"
            placeholder="{{'dialog.how_can_we_help' | translate}}"></textarea>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions class="mat-dialog-actions dialog-footer">
    <button mat-raised-button (click)="closeDialog()">
        {{'btn.cancel' | translate}}
    </button>
    <button mat-raised-button class="mat-primary confirm save-btn" [disabled]="!form.valid || sending" (click)="saveFeedback()">
        {{'btn.send' | translate}}
    </button>
</mat-dialog-actions>