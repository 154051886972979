// Modified from
// - https://github.com/ngstack/code-editor
// - https://stackoverflow.com/a/71217282
// - https://github.com/atularen/ngx-monaco-editor
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { MonacoEditorService } from '../services/monaco-editor/monaco-editor-service';
import hotkeys from 'hotkeys-js';

declare var monaco: any;

@Component({
    selector: 'monaco-editor',
    templateUrl: './monaco-editor.component.html',
    styleUrls: ['./monaco-editor.component.scss'],
})
export class MonacoEditorComponent implements OnChanges, OnDestroy {
    @Input()
    language: string = 'javascript';

    @Input()
    code: string = '';

    @Input()
    readOnly: boolean = false;

    @Output()
    valueChanged = new EventEmitter<string>();

    @Output()
    save = new EventEmitter();

    private _editor: any;
    private _model: any;
    @ViewChild('editorContainer', { static: true }) _editorContainer: ElementRef;

    constructor(private monacoEditorService: MonacoEditorService) { }

    ngAfterViewInit() {
        let save = this.save;
        hotkeys.filter = (e: KeyboardEvent) => true;
        hotkeys('ctrl+s', function (event, handler) {
            event.preventDefault()
            save.emit();
        });
        this.setupEditor();
    }

    ngOnDestroy() {
        if (this._editor) {
            this._editor.dispose();
            this._editor = null;
        }
        if (this._model) {
            this._model.dispose();
            this._model = null;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.language && !changes.language.firstChange) {
            this.updateLanguage(changes.language.currentValue);
        }
        if (changes.code && !changes.code.firstChange) {
            this.updateCode(changes.code.currentValue);
        }
    }

    @HostListener('window:resize')
    onResize() {
        if (this._editor) {
            this._editor.layout();
        }
    }

    private setupEditor(): void {
        if (!this.monacoEditorService.loaded) {
            this.monacoEditorService.loadingFinished.pipe(first()).subscribe(() => {
                this.setupEditor();
            });
            return;
        }

        this._model = monaco.editor.createModel(
            this.code,
            this.language,
            monaco.Uri.file('--' + Date.now()),
        );

        let options = {
            theme: 'vs-dark',
            model: this._model,
            readOnly: this.readOnly,
        };
        this._editor = monaco.editor.create(
            this._editorContainer.nativeElement,
            options
        );

        this._model.onDidChangeContent(
            (e) => {
                const newValue = this._model.getValue();
                if (this.code) {
                    this.code = newValue;
                }
                this.valueChanged.emit(newValue);
            });
    }

    private updateLanguage(language: string) {
        if (language && this._model && typeof monaco !== undefined) {
            monaco.editor.setModelLanguage(this._model, language);
        }
    }

    private updateCode(code: string) {
        if (code) {
            setTimeout(() => {
                if (this._model) {
                    this._model.setValue(code);
                }
            });
        }
    }
}