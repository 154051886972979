<mat-toolbar class="mat-primary">
    <h2>{{ data.title }}</h2>
    <span class="spacer"></span>
    <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>
            close
        </mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <div>
        <mat-form-field class="full-width">
            <mat-select placeholder="{{'reports.device' | translate}}" [(ngModel)]="filter.identifier" name="identifier">
                <mat-option>
                    <ngx-mat-select-search [formControl]="deviceFilterCtrl" [placeholderLabel]="'dialog.search_device' | translate" [noEntriesFoundLabel]="'dialog.no_device_found' | translate" ngDefaultControl></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngIf="isFilterDeviceNone">{{'dialog.none' | translate}}</mat-option>
                <mat-option *ngFor="let dev of filteredDevices | async" [value]="dev.identifier">
                    {{dev.identifier}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="showOem">
            <mat-form-field class="full-width">
                <mat-select placeholder="{{'customer_editor.oem' | translate}}" [(ngModel)]="filter.oem_id" name="oem_id">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="oemFilterCtrl" [placeholderLabel]="'dialog.search_oem' | translate" [noEntriesFoundLabel]="'dialog.no_oem_found' | translate" ngDefaultControl></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="isFilterOEMNone">{{'dialog.none' | translate}}</mat-option>
                    <mat-option *ngFor="let oem of filteredOEMs | async" [value]="oem.customer_id">
                        {{oem.customer_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="showDealer">
            <mat-form-field class="full-width">
                <mat-select placeholder="{{'customer_editor.dealer' | translate}}" [(ngModel)]="filter.dealer_id" name="dealer_id">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="dealerFilterCtrl" [placeholderLabel]="'dialog.search_dealer' | translate" [noEntriesFoundLabel]="'dialog.no_dealer_found' | translate" ngDefaultControl></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="isFilterDealerNone">{{'dialog.none' | translate}}</mat-option>
                    <mat-option *ngFor="let dealer of filteredDealers | async" [value]="dealer.customer_id">
                        {{dealer.customer_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="showClient">
            <mat-form-field class="full-width">
                <mat-select placeholder="{{'customer_editor.client' | translate}}" [(ngModel)]="filter.client_id" name="client_id">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="clientFilterCtrl" [placeholderLabel]="'dialog.search_client' | translate" [noEntriesFoundLabel]="'dialog.no_client_found' | translate" ngDefaultControl></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="isFilterClientNone">{{'dialog.none' | translate}}</mat-option>
                    <mat-option *ngFor="let client of filteredClient | async" [value]="client.customer_id">
                        {{client.customer_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-form-field class="full-width" *ngIf="includeFilter">
            <input matInput type="text" placeholder="{{'reports.report_type' | translate}}" [(ngModel)]="filter.report_type">
            <button mat-button *ngIf="filter.report_type" matSuffix mat-icon-button aria-label="Clear"
                (click)="filter.report_type=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field class="full-width" *ngIf="includeFilter">
            <input matInput [matDatepicker]="start_date" [max]="maxDate" placeholder="{{'dialog.start_date' | translate}}"
                [value]="filter.start_date" (dateChange)="completeDate('start', $event)">
            <mat-datepicker-toggle matSuffix [for]="start_date"></mat-datepicker-toggle>
            <mat-datepicker #start_date></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="full-width" *ngIf="includeFilter">
            <input matInput [matDatepicker]="end_date" [max]="maxDate" placeholder="{{'dialog.end_date' | translate}}" [value]="filter.end_date"
                (dateChange)="completeDate('end', $event)">
            <mat-datepicker-toggle matSuffix [for]="end_date"></mat-datepicker-toggle>
            <mat-datepicker #end_date></mat-datepicker>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()">
        {{'btn.cancel' | translate}}
    </button>
    <button mat-button class="mat-primary confirm"
        [disabled]="(!filter.identifier && !filter.oem_id && !filter.dealer_id && !filter.client_id && !filter.report_type && !(filter.start_date && filter.end_date)) || (filter.start_date > filter.end_date)"
        (click)="confirm()">
        {{ data.confirm }}
    </button>
</mat-dialog-actions>
