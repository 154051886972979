
export interface IDeviceModel {
  allow_telematics: number;
  customer_id: number;
  customer_name: string;
  model_id: number;
  model_type: string;
  name: string;
  product_image: string;
  product_type: string;
}

export interface IDashboardName {
  dashboard_id: number;
  name: string;
}

export interface IDevice {
  allowTelematics: number;
  lat: number;
  lng: number;
  id: number;
  identifier: string;
  title: string;
  oemName: string;
  oemId: number;
  dealerName?: string;
  dealerId?: string;
  clientName?: string;
  clientId: number;
  modelName: string;
  productImage: string;
}

export interface ISQLResponse {
  fieldCount: number;
  affectedRows: number;
  insertId: number;
  serverStatus: number;
  warningCount: number;
  message: string;
  protocol41: boolean;
  changedRows: number;
}

export enum ActionType {
  CREATE = 'create',
  UPDATE = 'update'
}

// Duplicate entry error number in MySQL database
export const SQL_ER_DUP_ENTRY: number = 1062;

export interface ICountry {
  code: string;
  name: string;
}

export interface IConfirmDialogData {
  title: string;
  text: string;
  confirm: string;
  icon?: string;
  titleTerm?: string;
  textTerm?: string;
  confirmTerm?: string;
  hideConfirmButton?: boolean;
}

export enum ChartType {
  MAP = 'map',
  CHART = 'chart'
}

export interface IMapPoints {
  lat: number;
  lng: number;
  label: string;
  time: Date;
  draggable: boolean;
  isOpenInfo: boolean;
}

export enum Icon {
  Warning = 'warning'
}

export enum ApplyCustomerDialogType {
  Devices = 1,
  Report
}

export interface IApplyCustomerDialogData {
  title: string;
  applyCustomerDialogType: ApplyCustomerDialogType;
  customerType: number;
  customerId: number;
  disableCancelButton: boolean;
}

export interface IOem {
  customer_id: number;
  customer_name: string;
  address: string;
  phone: string;
  full_name: string;
  customer_role: number;
}

export interface IDealer extends IOem {
  website_url: string;
  oem_id: number;
  oem_name: string;
}

interface IClient extends IDealer {
  dealer_id: number;
  dealer_name: string;
}

export type CustomerType = IOem | IDealer | IClient;

export enum SortingKey {
  Identifier = 'identifier'
}

export const SNACKBAR_DURATION = 4000;
export const SNACKBAR_SHORT_DURATION = 1000;
export const SNACKBAR_POSITION = 'left';

export interface ClientDetail {
  customer_id: number;
  customer_name: string;
  address: string;
  website_url: string;
  phone: string;
  oem_id: number;
  oem_name: string;
  dealer_id: number;
  dealer_name: string;
  full_name: null;
}

export interface AssociatedCount {
  modelId?: number;
  libraryId?: number;
  values: AssociatedValue[];
}

export interface AssociatedValue {
  category: string;
  count?: number;
  id?: number;
  ids?: number[];
  name?: string;
}

export interface AssociationDetails {
  title: string;
  text: string;
  confirm: string;
  dependedCount: AssociatedCount;
  deviceCheck: boolean;
  previousUrl?: string;
  symLibrary?: boolean;
}

export enum FirmwareDialogTranslations {
  FirmwareChangeTitle = 'model_editor.confirm_firmware_change_title',
  FirmwareChangeText = 'model_editor.confirm_firmware_change_text',
  ConfirmBtn = 'btn.confirm'
}

export enum PanelClass {
  ToolbarDialog = 'toolbar-dialog'
}

export enum TimeParams {
  Time = 'time',
  Index = 0
}

export enum ErrorTranslation {
  NoGpsDataFound = 'reports.no_gps_data_found',
  NoLoggedDataFound = 'reports.no_logged_data_found'
}

export const OemName = 'oem_name';
export const DealerName = 'dealer_name';

export enum ModelForm {
  CustomerId = 'customer_id',
  DealerId = 'dealer_id'
}

export enum DealerParams {
  OemId = 'oem_id'
}

export enum SessionStorage {
  DateFormat = 'date_format',
  CustomerRole = 'customerRole'
}

export enum Documentation {
  API = 'api-doc',
  License = 'license',
  Terms = 'terms',
  User = 'user-doc',
}

export enum AssociationCategories {
  Alert = 'Alert',
  Alerts = 'Alerts',
  Statistics = 'Statistics',
  Devices = 'Devices'
}

export enum SymLibraryDialogTranslations {
  AssociationFound = 'model_editor.disable_sym.association_found',
  ConfirmDisable = 'model_editor.disable_sym.confirm_disable',
  Confirm = 'btn.confirm'
}

export interface DowntimeNotification {
  description: string;
  fromDate: string | Date;
  toDate: string | Date;
}

export interface TimeStampData {
  timeStamp: Date;
  germanyTimeStamp: string;
}

export enum MomentCategory {
  MilliSeconds = 'ms',
  Seconds = 'seconds',
  Minutes = 'minutes',
  Days = 'days'
}

export interface TimeDifferenceDetails {
  isEmptyRegionHidden: boolean;
  timeDifference: number;
}

export enum Topics {
  AskQuestion = 1,
  ReportBug = 2,
  SuggestImprovement = 3,
  Other = 4
}

export const TopicsNames = {
  [Topics.AskQuestion]: 'feedback.topic.ask_question',
  [Topics.ReportBug]: 'feedback.topic.report_bug',
  [Topics.SuggestImprovement]: 'feedback.topic.suggest_improvement',
  [Topics.Other]: 'feedback.topic.other'
};
