import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IBuses, IDevice, IStatistic, IMessage, IMessageValue, Translations } from '../interfaces/statistics.model';
import { ParameterId } from '../constants/statistics.constant';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  matTabChanged: Subject<boolean> = new Subject<boolean>();
  deviceDetail: BehaviorSubject<IStatistic> = new BehaviorSubject<IStatistic>(null);
  buses: BehaviorSubject<IBuses[]> = new BehaviorSubject<IBuses[]>([]);
  devices: IDevice[] = [];

  constructor(private translateService: TranslateService) { }

  /**
   * Associates parameter names with the given data array.
   * @template T
   * @param {T[]} data - The data array to associate parameter names with.
   * @param {IMessageValue[]} messages - The message values to use for associating parameter names.
   * @returns {T[]} The data array with associated parameter names.
   */
  associateParameterNames<T>(data: T[], messages: IMessageValue[]): T[] {
    return data.map((p: T) => {
      const parameter: IMessageValue = messages.find((message: IMessageValue) => message.message_value_id === p[ParameterId]);
      return {
        ...p,
        parameterName: parameter?.value_name ?? this.translateService.instant(Translations.UnknownVariable)
      };
    });
  }

  /**
   * Associates message names with the given data.
   * @template T The type of the data to associate message names with.
   * @param {T[]} data The data to associate message names with.
   * @param {IMessage[]} messages The messages to use for associating message names.
   * @returns {T[]} The data with associated message names.
   */
  associateMessageNames<T>(data: T[], messages: IMessage[]): T[] {
    return data.map((d: T) => {
      const message: IMessage = messages.find((m: IMessage) => m.message_values.find((x: IMessageValue) => x.message_value_id === d[ParameterId]));
      return {
        ...d,
        messageName: message?.descriptor ?? this.translateService.instant(Translations.UnknownMessage),
        messageId: message?.message_id ?? this.translateService.instant(Translations.UnknownId)
      };
    });
  }

  /**
   * Returns an array of IMessageValue objects from the given data.
   * @param {IBuses[]} data - An array of IBuses objects.
   * @returns {IMessageValue[]} An array of IMessageValue objects.
   */
  getMessageVariables(data: IBuses[]): IMessageValue[] {
    return [].concat(...data.map((m: IBuses) => [].concat(...m.messages.map((mv: IMessage): IMessageValue[] => mv.message_values))));
  }

  /**
 * Gets an array of messages from an array of buses.
 * @param {IBuses[]} data - An array of buses.
 * @returns {IMessage[]} An array of messages.
 */
  getMessages(data: IBuses[]): IMessage[] {
    return [].concat(...data.map((m: IBuses): IMessage[] => m.messages));
  }
}
