<agm-map #elem class="path-map" [class.actions]="withActions" [latitude]="marker.lat" [longitude]="marker.lng"
  [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="true" [scrollwheel]="false" [scaleControl]="false"
  [streetViewControl]="true" [mapTypeControl]="true">
  <ng-container *ngIf="markerChunks && markerChunks.length">
    <ng-container *ngFor="let markerChunk of markerChunks; let i = index">
      <ng-container *ngFor="let arr of markerChunk; let i = index">
        <ng-container *ngIf="arr && arr.length">
  
          <agm-marker *ngFor="let m of arr; let i = index" [latitude]="m.lat" [longitude]="m.lng"
            [markerDraggable]="m.draggable" [iconUrl]="i === 0 ? startIcon : (i === arr.length - 1 ? endIcon : imgSrc)"
            (markerClick)="getDateTime(m.time)">
            <agm-info-window>
              <div class="info-window-content">
                <p *ngIf="i === 0">
                  {{'log_map.start_point' | translate}}
                </p>
                <p *ngIf="i === arr.length - 1">
                  {{'log_map.last_point' | translate}}
                </p>
                {{m.label}}
              </div>
            </agm-info-window>
          </agm-marker>
  
          <agm-polyline [strokeColor]="strokeColor" [strokeWeight]="strokeWeight">
            <agm-polyline-point *ngFor="let m of arr;let i = index;" [latitude]="m.lat" [longitude]="m.lng">
            </agm-polyline-point>
          </agm-polyline>
  
          <agm-marker *ngIf="isChartsSync" [latitude]="marker.lat" [longitude]="marker.lng">
            <agm-info-window>
              <div class="info-window-content">
                {{marker.label}}
              </div>
            </agm-info-window>
          </agm-marker>
  
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

</agm-map>
<p class="warning-text" [innerHTML]="'time_difference.warning_text' | translate:{default_time_diff: defaultTimeDifference}"></p>