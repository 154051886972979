import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidator {

    static email(control: AbstractControl): ValidationErrors | null {
        const EMAIL_REGEXP = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        let email = control.value as string;

        if (EMAIL_REGEXP.test(email) != true) {
            if (email.includes(' ')) {
                // This is a special easter egg just for users with 'david' in
                // their email.
                if (email.includes('david')) {
                    return { customEmailSpacesDavid: true };
                }
                return { customEmailSpaces: true };
            }
            return { customEmail: true };
        }

        return null;
    }

    static range(min: number, max: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (c.value && (isNaN(c.value) || c.value < min || c.value > max)) {
                return { 'range': true };
            }
            return null;
        };
    }

    static isHexadecimal(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }

        return /^[0-9a-fA-F]+$/.test(control.value) ? null : { hexadecimal: true };
    }

    static maxHex(max): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const isHex = /^[0-9a-fA-F]+$/.test(control.value);

            if (isHex) {
                const intVal = parseInt(control.value, 16);
                return intVal > max ? { maxHex: true } : null;
            }

            const val = parseInt(control.value);
            return val > max ? { maxHex: true } : null;
        };
    }

    static minHex(min): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const isHex = /^[0-9a-fA-F]+$/.test(control.value);

            if (isHex) {
                const intVal = parseInt(control.value, 16);
                return intVal < min ? { minHex: true } : null;
            }

            const val = parseInt(control.value);
            return val > min ? { minHex: true } : null;
        };
    }

    static minValidation(minFormControl: AbstractControl): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            const min: number = +minFormControl.value;
            const input: number = +c.value;

            if (input < min) {
                return { 'minValidation': true };
            }
            return null;
        };
    }
}

export const AtLeastOneRequired = (validator: ValidatorFn) => (group: FormGroup): ValidationErrors | null => {
    const hasAtLeastOne = group && group.controls &&
        (Object.keys(group.controls).length === 0
            || Object.keys(group.controls).some(k => !validator(group.controls[k])));
    return hasAtLeastOne ? null : { atLeastOne: true };
};
