<div id='editor' fxLayout='column'>
    <div id='widget-form' fxLayout='row' fxLayoutGap='8px'>
        <mat-form-field appearance='standard'>
            <mat-label>{{"widgets.name" | translate}}</mat-label>
            <input matInput class='form-control' [formControl]='nameControl'
                   required>
        </mat-form-field>
        <mat-form-field appearance='standard'>
            <mat-label>{{"widgets.customer" | translate}}</mat-label>
            <mat-select [formControl]="customerControl">
                <mat-option *ngFor="let customer of customerList"
                            [value]="customer.id">
                    {{ customer.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div fxFlex fxLayoutAlign='end center'>
            <button *ngIf='!isReadOnly' mat-raised-button class='mat-primary'
                    [disabled]="!isDirty" (click)='handleSave()'>
                {{'btn.save' | translate}}
            </button>
        </div>
    </div>
    <div fxFlex>
        <div *ngIf="isLoading" class="spinner-wrap">
            <mat-spinner></mat-spinner>
        </div>
        <monaco-editor [code]='currentCode' [readOnly]='isReadOnly'
                       (save)='handleSave()'
                       (valueChanged)='onCodeChanged($event)'>
        </monaco-editor>
    </div>
</div>