import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';

const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/plain;charset=utf-8';

@Injectable({
  providedIn: 'root'
})
export class CsvExportService {

  constructor() { }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob(['\ufeff' + buffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  }

  public convertToCsvFormat(rows: object[], columns?: string[]): string {
    if (!rows || !rows.length) {
      return;
    }
  
    const separator = ', ';
    const keys = Object.keys(columns);
    const labels = Object.values(columns);

    const csvContent =
      labels.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          // If cell is null or undefined return empty string.
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];

          // Convert Date cells to string and replace quote with double quotes.
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');

          // To avoid errors on file, double quote every cell that has quote, comma or line feed.
          const needsQuoted = cell.search(/("|,|\n)/g) >= 0;
          if (needsQuoted) {
            cell = `"${cell}"`;
          }  
          return cell;
        }).join(separator);
      }).join('\n');
      
    return csvContent;
  }

  public exportToCsv(rows: object[], fileName: string, columns?: string[]) {
    const csvContent = this.convertToCsvFormat(rows, columns);
    this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);
  }
}
